import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PersonComponent } from '../../../../shared/components/person/person.component';
import { User } from '../../../models/user.model';

@Component({
  selector: 'fl-user-detail-applicant',
  templateUrl: './applicant.component.html',
  styleUrls: ['./applicant.component.scss']
})
export class UserDetailApplicantComponent implements OnDestroy, OnInit {

  @Input() form: FormGroup;
  @Input() user: User;
  @ViewChild(PersonComponent, { static: true }) personComponent: PersonComponent;
  userForm: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnDestroy() {
    // Remove the form from the parent form
    this.form.removeControl('applicantUser');
  }

  ngOnInit() {
    // This component doesn't neeed a group for itself, but rather simple as a container for its child forms
    this.userForm = this.fb.group({});

    // Add this form group to the parent form
    this.form.addControl('applicantUser', this.userForm);
  }

  setFormData(user: User) {
    this.personComponent.setFormData(user);
  }

}
