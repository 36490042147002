import { ContractChange } from './contract-change.model';
import { BusinessIncentiveProjectInfo } from '../../../project/models/business-incentive-project-info.model';
import { FormType } from '../../../form/form.constants';
import { Location } from 'app/project/models/location.model';

export class ContractChangeTransfer extends ContractChange {
  companyName: string;
  fiscalYear: string;
  revenueId: string;
  insuranceId: string;
  fein: string;
  location: Location;
  naicsCode: string;
  reason: string;
  companyNameRetains: number; // only for partial transfer
  companyNameTransferred: number; // only for partial transfer
  childProjectGuid: string;
  oldCompanyName: string;
  oldFein: string;
  oldProjectDetails: BusinessIncentiveProjectInfo;
  applicationSnapshot = false;

  constructor(
    formIndex: number = null,
    formType: FormType = null,
    created?: boolean,
    form?: any
  ) {
    super(formIndex, form);

    if (formType) {
      this.type = formType;
    }
    if (created) {
      this.applicationSnapshot = true;
    }
    this.location = this.location
      ? new Location(this.location)
      : new Location();
  }

  init() {
    super.init();
  }
}
