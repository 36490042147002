<div class="d-flex flex-row flex-wrap">
  <h1 class="text-blue">Accessing FASTLANE Using Two-Factor Authentication</h1>
</div>
<div>
  <p>
    This site requires the use of two-factor authentication. This will require the installation of Google Authenticator on a mobile device.
  </p>
  <p>
    When logging in to FastLane for the first time you will be prompted to setup two-factor authentication AFTER entering your email and password and click the "Log In" button.
  </p>
  <p>
    A UNIQUE QR code will display which can be scanned by your mobile device using the Google Authenticator app.
  </p>
  <p>
    The Google Authenticator app will provide you with codes used to sign into the website. DO NOT DELETE THE GOOGLE AUTHENTICATOR APP AFTER SETUP.
  </p>
  <p>
    Instructions for Android and Apple devices are below.
  </p>
</div>
<div class="d-flex flex-row flex-wrap">
  <img src="/assets/two-factor/scan-watermark.png" class="mx-4" height="400">
  <img src="/assets/two-factor/validate.PNG" class="mx-4" height="400">
</div>

<div class="d-flex flex-row flex-wrap mt-4">
  <h3 class="text-blue">Android Setup</h3>
</div>
<div class="mt-4">
  <p>
    To obtain validation codes a mobile device is needed.
  </p>
  <p>For Android devices the google authenticator app can be found in the appstore.</p>
  <p>
    Once the app is installed you can press the "Scan QR Code" button and scan the QR code shown in the "Set Up
    Two-Factor Authentication" screen.
  </p>
  <p>
    After the QR code is scanned there will be an entry in the Google Authenticator app labeled "FastLane (email
    address)". Below it there is a code that is shown and changes periodically. This code is used to fill out the
    "Validation Code" field on the website.
  </p>
</div>
<div class="d-flex flex-row flex-wrap mt-4">
  <img src="/assets/two-factor/PlayStoreDownloadUpdated.jpg" class="mx-4" height="400">
  <img src="/assets/two-factor/Android-Authenticator.jpg" class="mx-4" height="400">
  <img src="/assets/two-factor/passcode.jpg" class="mx-4" height="400">
</div>



<div class="d-flex flex-row flex-wrap mt-4">
  <h3 class="text-blue">Apple Setup</h3>
</div>
<div class="mt-4">
  <p>
    To obtain validation codes a mobile device is needed.
  </p>
  <p>For Apple devices the google authenticator app can be found in the appstore.</p>
  <p>
    Once the app is installed you can press the "Scan QR Code" button and scan the QR code shown in the "Set Up
    Two-Factor Authentication" screen.
  </p>
  <p>
    After the QR code is scanned there will be an entry in the Google Authenticator app labeled "FastLane (email
    address)". Below it there is a code that is shown and changes periodically. This code is used to fill out the
    "Validation Code" field on the website.
  </p>
</div>
<div class="d-flex flex-row flex-wrap mt-4">
  <img src="/assets/two-factor/AppleDownloadUpdated.jpg" class="mx-4" height="400">
  <img src="/assets/two-factor/AppleAuthenticator.jpg" class="mx-4" height="400">
  <img src="/assets/two-factor/passcode.jpg" class="mx-4" height="400">
</div>

<div class="d-flex flex-row flex-wrap mt-4">
  <h3 class="text-blue">Google Chrome Setup</h3>
</div>
<div class="mt-4">
  <p>
    To obtain validation codes on browsers, an extension is needed.
  </p>
  <p><a href="https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai?hl=en-US" target="_blank"> Click here to get the extension</a></p>
  <p>Follow the link above and click "Add to Chrome".</p>
  <p>
    To access the authenticator extension, click the puzzle piece in the top corner and then the "Authenticator" extension.
    Once the authenticator is open, click the pencil in the corner to add your account. Choose "Manual Entry" and enter the code beneath the QR code or choose "Scan QR Code" and click and drag around
    the QR code to scan it.
  </p>
  <p>
    After the code is setup, there will be an entry in the authenticator labeled "FastLane", then a 6 digit code, and finally your email
    address. The code changes periodically and is used to fill out the
    "Validation Code" field on the website.
  </p>
</div>
<div class="d-flex flex-row flex-wrap mt-4">
  <img src="/assets/two-factor/AuthenticatorPage.jpg" class="mx-4" width="400" height="100">
  <img src="/assets/two-factor/Extensions.png" class="mx-4" height="300">
  <img src="/assets/two-factor/CodeExample.png" class="mx-4" height="400">
</div>
