<div class="mb-4" [formGroup]="depositForm">
  <!-- OVERFLOW-VISIBLE CLASS BELOW IS REQUIRED FOR POPOVER TO DISPLAY PROPERLY -->
  <div class="card overflow-visible">
    <div class="card-header">
      <b *ngIf="!isDM">AUDIT FEE</b>
      <b *ngIf="isDM">EXPENDITURE VERIFICATION REPORT FEE</b>
    </div>
    <div class="card-body px-5">
      <div id="auditDepositContainer">
        <ng-container>
          <!-- ESTIMATED EXPENDITURES -->
          <div class="row m-b" *ngIf="depositForm.get('auditCost').value === 0">
            <div class="col-12 col-md-6">Estimated Expenditures</div>
            <div class="col-12 col-md-6">
              {{
                depositForm.get('laExpenditure').value
                  | currency: 'USD':'symbol':'1.2-2'
              }}
            </div>
          </div>

          <!-- ASSESSED DEPOSIT -->
          <div class="row m-b" *ngIf="depositForm.get('auditCost').value === 0">
            <div class="col-12 col-md-6">Assessed Deposit</div>
            <div class="col-12 col-md-6">
              {{
                depositForm.get('assessedDeposit').value
                  | currency: 'USD':'symbol':'1.2-2'
              }}

              <i
                data-title="DEPOSIT DUE USING EXPENDITURES"
                data-trigger="hover"
                data-container="#auditDepositContainer"
                data-html="true"
                [attr.data-content]="depositLegend.innerHTML"
                data-toggle="popover"
                data-placement="top"
                data-boundary="window"
                [attr.data-template]="depositLegendTemplate.innerHTML"
                class="ml-2 fa fa-info-circle"
                aria-hidden="true"
              ></i>
              <span
                *ngIf="
                  depositForm.get('assessedDeposit').value > assessedDepositCap
                "
                class="text-warning ml-2"
              >
                Assessed deposit should not be greater than
                {{ assessedDepositCap }}</span
              >
            </div>
          </div>

          <!-- MANAGER ONLY -->
          <ng-container *ngIf="isManager()">
            <!-- DEPOSIT ADJUSTMENT -->
            <div
              class="row m-b"
              *ngIf="depositForm.get('auditCost').value === 0"
            >
              <div class="col-12 col-md-6">Deposit Adjustment</div>
              <div class="form-group col-12 col-md-3">
                <input
                  currencyMask
                  [options]="{ align: 'left', precision: 2 }"
                  type="text"
                  class="form-control"
                  formControlName="depositAdjustment"
                />
              </div>
            </div>

            <!-- AUDIT COST -->
            <div class="row m-b" *ngIf="shouldShowAuditCost()">
              <div class="col-12 col-md-6" *ngIf="!isDM">Audit Cost</div>
              <div class="col-12 col-md-6" *ngIf="isDM">Expenditure Verification Report Cost</div>
              <div class="form-group col-12 col-md-3">
                <input
                  type="text"
                  class="form-control"
                  formControlName="auditCost"
                  currencyMask
                  [options]="{ align: 'left', precision: 2 }"
                />
              </div>
            </div>
          </ng-container>

          <!-- AMOUNT PAID -->
          <div class="row m-b">
            <div class="col-12 col-md-6">Amount Paid</div>
            <div class="col-12 col-md-6">
              {{
                depositForm.get('amountPaid').value
                  | currency: 'USD':'symbol':'1.2-2'
              }}
            </div>
          </div>

          <!-- AMOUNT DUE -->
          <div class="row m-b">
            <div class="col-12 col-md-6">Amount Due</div>
            <div class="col-12 col-md-3">
              {{
                depositForm.get('amountDue').value
                  | currency: 'USD':'symbol':'1.2-2'
              }}
              <span
                *ngIf="depositForm.get('amountDue').invalid"
                class="text-warning ml-2"
              >
                Please pay the audit deposit before proceeding with the rest of
                the application process.</span
              >
            </div>
          </div>

          <div id="auditDepositActions" class="row px-2 justify-content-end">
            <!-- PAY DEPOSIT BUTTON -->
            <button
              *ngIf="!isAudit && depositForm.invalid"
              (click)="navigateToAudit()"
              class="btn btn-primary ml-2"
            >
              NAVIGATE TO DEPOSIT PAYMENT
            </button>
            <button
              class="btn btn-primary ml-2"
              data-toggle="collapse"
              data-target="#auditFeesHistory"
              *ngIf="shouldShowAuditCost()"
            >
              <i class="fa fa-history" aria-hidden="true"></i>
            </button>
            <button
              *ngIf="isAudit && isManager() && amountDue !== 0"
              (click)="requestPaymentorRefund()"
              class="btn btn-primary ml-2"
            >
              REQUEST
              {{ amountDue < 0 ? 'REFUND' : 'PAYMENT' }}
            </button>
          </div>
        </ng-container>

        <!-- CONTENT OF THE DEPOSIT BREAKDOWN POPOVER -->
        <div #depositLegend id="auditDepositLegend" class="d-none">
          <div class="d-flex flex-wrap mx-0" *ngFor="let info of popOverLegend">
            <span class="col-12 col-md-8 font-weight-bold"
              >{{ info.range[0] | currency }} -
              {{ (info.range[1] ? info.range[1] : null) | currency }}</span
            >
            <span class="col-12 col-md-4 text-danger">{{
              info.deposit | currency
            }}</span>
          </div>
        </div>

        <!-- TEMPLATE TO USE FOR DISPLAYING THE LEGEND -->
        <div #depositLegendTemplate class="d-none">
          <div style="z-index: 50000" class="popover" role="tooltip">
            <div class="arrow"></div>
            <h3 class="popover-header"></h3>
            <div class="popover-body"></div>
          </div>
        </div>

        <!-- AUDIT FEES HISTORY / SHOULD ALWAYS SHOW FOR MANAGERS -->
        <div
          id="auditFeesHistory"
          class="mt-2 col-md-4 col-12 collapse"
          *ngIf="shouldShowAuditCost()"
        >
          <div class="card">
            <div class="card-header font-weight-bold">FEE HISTORY</div>
            <div class="card-body">
              <div class="row font-weight-bold">
                <div class="col">FEE</div>
                <div class="col">TYPE</div>
                <div class="col">DUE</div>
              </div>
              <div class="row" *ngFor="let fee of getAuditFees()">
                <div class="col">{{ fee.assessedFee | currency }}</div>
                <div class="col">
                  {{ fee.isRefundable ? 'DEPOSIT' : 'FEE' }}
                </div>
                <div class="col">{{ fee.amountDue | currency }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
