export type AgreementStatus =
  | 'Queued'
  | 'Sent to Manager'
  | 'Sent to Company'
  | 'Sent to Deputy Secretary'
  | 'Sent to Governor'
  | 'Expired'
  | 'Cancelled'
  | 'Executed'
  | 'Sent to Manager for Approval'
  | 'Sent to Agreement Reviewer for Approval';

export const agreementStatus = {
  queued: 'Queued',
  sentToManager: 'Sent to Manager',
  sentToCompany: 'Sent to Company',
  sentToDeputySecretary: 'Sent to Deputy Secretary',
  sentToGovernor: 'Sent to Governor',
  expired: 'Expired',
  cancelled: 'Cancelled',
  executed: 'Executed',
  voided: 'Voided',
  sentToManagerForApproval: 'Sent to Manager for Approval',
  sentToAgreementReviewerForApproval: 'Sent to Agreement Reviewer for Approval'
};

export const agreementType = {
  contract: 'Contract',
  contractRenewal: 'Renewal Contract',
  contractAmendment: 'Contract Amendment',
  certificationInitial: 'Initial Certification Letter',
  certificationFinal: 'Final Certification Letter',
  other: 'Other'
};
