/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./auditor-assignment.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./auditor-assignment.component";
import * as i5 from "../../fastlane-common/services/lookup-http.service";
import * as i6 from "../../form/services/status.service";
import * as i7 from "../../form/services/validation.service";
var styles_AuditorAssignmentComponent = [i0.styles];
var RenderType_AuditorAssignmentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuditorAssignmentComponent, data: {} });
export { RenderType_AuditorAssignmentComponent as RenderType_AuditorAssignmentComponent };
function View_AuditorAssignmentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "col-12 col-lg-8"]], null, null, null, null, null))], null, null); }
function View_AuditorAssignmentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], [[8, "disabled", 0], [8, "className", 0]], null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.id; _ck(_v, 1, 0, currVal_2); var currVal_3 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = !_v.context.$implicit.isActive; var currVal_1 = (!_v.context.$implicit.isActive ? "gray-bg" : ""); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_4); }); }
function View_AuditorAssignmentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "col-12 col-lg-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "select", [["class", "form-control"], ["id", "activeCompanies"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("change" === en)) {
        var pd_2 = (_co.selectAuditor($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], { compareWith: [0, "compareWith"] }, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectControlValueAccessor]), i1.ɵdid(4, 540672, null, 0, i2.FormControlDirective, [[8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlDirective]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuditorAssignmentComponent_3)), i1.ɵdid(8, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.selectedCompany; _ck(_v, 2, 0, currVal_7); var currVal_8 = _co.activeCompany; _ck(_v, 4, 0, currVal_8); var currVal_9 = _co.activeCompanies; _ck(_v, 8, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_AuditorAssignmentComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "px-3 d-flex align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-spinner fa-spin fa-2x"]], null, null, null, null, null))], null, null); }
export function View_AuditorAssignmentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "d-flex flex-wrap py-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "col-12 col-lg-4 form-control-label"], ["for", "activeCompanies"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Auditing Company:"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuditorAssignmentComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfThen: [1, "ngIfThen"], ngIfElse: [2, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["companies", 2]], null, 0, null, View_AuditorAssignmentComponent_2)), (_l()(), i1.ɵand(0, [["spin", 2]], null, 0, null, View_AuditorAssignmentComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.activeCompanies && (_co.activeCompanies.length > 0)); var currVal_1 = i1.ɵnov(_v, 5); var currVal_2 = i1.ɵnov(_v, 6); _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AuditorAssignmentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fl-auditor-assignment", [], null, null, null, View_AuditorAssignmentComponent_0, RenderType_AuditorAssignmentComponent)), i1.ɵdid(1, 245760, null, 0, i4.AuditorAssignmentComponent, [i5.LookupHttpService, i6.FormStatusService, i7.FormValidationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuditorAssignmentComponentNgFactory = i1.ɵccf("fl-auditor-assignment", i4.AuditorAssignmentComponent, View_AuditorAssignmentComponent_Host_0, { form: "form", readOnly: "readOnly" }, {}, []);
export { AuditorAssignmentComponentNgFactory as AuditorAssignmentComponentNgFactory };
