import { Application } from './film-application.model';
import { formTypes } from '../../../form/form.constants';

export class AdditionalSeasonApplication extends Application {
  constructor(formIndex: number = null) {
    super();
    this.formIndex = formIndex;
  }
  init(): AdditionalSeasonApplication {
    super.init();
    this.type = formTypes.asa.abbrev;
    return this;
  }

  get qualifier() {
    if (this.productionDetails.season) {
      return `Season : ${this.productionDetails.season}`;
    }
    return null;
  }
}
