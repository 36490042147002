import { userType } from './user/user.constants';
const ɵ0 = {
    rolesAllowed: [userType.applicant.code]
}, ɵ1 = () => import("./applicant/applicant.module.ngfactory").then(m => m.ApplicantModuleNgFactory), ɵ2 = {
    rolesAllowed: [userType.assessor.code]
}, ɵ3 = () => import("./assessor/assessor.module.ngfactory").then(m => m.AssessorModuleNgFactory), ɵ4 = {
    rolesAllowed: [userType.auditor.code]
}, ɵ5 = () => import("./auditor/auditor.module.ngfactory").then(m => m.AuditorModuleNgFactory), ɵ6 = {
    rolesAllowed: [userType.board.code]
}, ɵ7 = () => import("./board/board.module.ngfactory").then(m => m.BoardModuleNgFactory), ɵ8 = {
    rolesAllowed: [userType.external.code]
}, ɵ9 = () => import("./external/external.module.ngfactory").then(m => m.ExternalModuleNgFactory), ɵ10 = () => import("./guest/guest.module.ngfactory").then(m => m.GuestModuleNgFactory), ɵ11 = {
    rolesAllowed: [userType.management.code]
}, ɵ12 = () => import("./management/management.module.ngfactory").then(m => m.ManagementModuleNgFactory);
export class AppModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12 };
