import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  AfterContentInit
} from '@angular/core';
import { Router } from '@angular/router';
import { UserContextService } from '../../../user/services/user-context.service';
import { UserPermissions } from '../../../user/models/user-permissions.model';

import { correctHeight, deepCopy } from '../../../shared/shared.functions';
import { navigationData } from '../../core.constants';

import { externalReviewAgencies } from '../../../project/project.constants';
import {userType} from '../../../user/user.constants';
import { AuthenticationService } from '../../../security/services/authentication.service';
declare var $: any;

@Component({
  selector: 'fl-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements AfterViewInit, AfterContentInit {
  navigationData: {
    headerImgAlt: string;
    headerImgSrc: string;
    headerRouterLink: string;
    items: {
      faIcon: string;
      label: string;
      name: string;
      routerLink?: string;
      submenu?: {
        id: string;
        items: {
          label: string;
          routerLink: string;
        }[];
        highlightRoute: string;
      };
    }[];
  };

  constructor(
    private router: Router,
    private userContext: UserContextService,
    private authenticationService: AuthenticationService
  ) { }

  activeRoute(routename: string) {
    return (
      this.router.url.indexOf(routename) === 0 &&
      this.router.url.length === routename.length
    );
  }

  activeRoutePrefix(routeprefix: string) {
    return this.router.url.indexOf(routeprefix) === 0;
  }

  ngAfterViewInit(): void {
    $('#side-menu')
      .metisMenu({
        activeClass: 'active',
        collapseInClass: 'show'
      })
      .on('shown.metisMenu', event => {
        correctHeight();
      })
      .on('hidden.metisMenu', event => {
        correctHeight();
      });

    $('.sidebar-collapse').slimscroll({
      height: '100vh'
    });
  }

  ngAfterContentInit(): void {
    const enabledItemNames: string[] = [];
    const navMenuName = this.userContext.currentUser.userType;
    let permissions: UserPermissions;
    const that = this;

    // get specific nav data
    // nav data includes nav labels, router links, icons, etc
    this.navigationData = deepCopy(navigationData[navMenuName]);

    // Project search is only available for LGA external reviewer
    if (
      navMenuName === userType.external.code &&
      this.userContext.currentUser.externalReviewAgency ===
      externalReviewAgencies.led.code
    ) {
      this.navigationData.items = navigationData[navMenuName].items.filter(
        value => value.routerLink !== 'projects'
      );
    }

    // get permissions for managers only
    if (!this.router.url.includes('management')) {
      return;
    }

    this.authenticationService.executeAfterUserPermissionsFetched((() => {
      permissions = this.userContext.currentUser.permissions;

      // use permissions data to pick and choose which nav items to load
      Object.keys(permissions).forEach((key, index) => {
        // get names of enabled permissions
        // do not store duplicate permission names
        if (permissions[key] && !enabledItemNames.includes(key)) {
          enabledItemNames.push(key);
        }
      });

      // remove links to features that the user does not have permission to access
      this.navigationData.items = this.navigationData.items.filter(
        value =>
          enabledItemNames.includes(value.name) || value.name === 'management'
      );
    }).bind(this));
  }

  toggleActiveClass(event: MouseEvent): void {
    if (!event.detail || event.detail === 1) {
      const element = event.srcElement as HTMLElement;
      let listId = element.parentElement.id as string;
      if (listId === 'open') {
        listId = element.parentElement.parentElement.id as string;
      }

      $('li.active:not(.selected):not(#' + listId + ') > a').click();
      $(
        'li.active:not(.selected):not(#' + listId + ') >>> .fa-caret-down'
      ).addClass('fa-caret-left');
      $(
        'li.active:not(.selected):not(#' + listId + ') >>> .fa-caret-down'
      ).removeClass('fa-caret-down');
      $('li.active:not(.selected):not(#' + listId + ')').removeClass('active');

      if ($('#' + listId).hasClass('active')) {
        $('#' + listId).removeClass('active');
        $('#' + listId + ' >>> .fa-caret-down').addClass('fa-caret-left');
        $('#' + listId + ' >>> .fa-caret-down').removeClass('fa-caret-down');
      } else {
        $('#' + listId).addClass('active');
        $('#' + listId + ' >>> .fa-caret-left').addClass('fa-caret-down');
        $('#' + listId + ' >>> .fa-caret-left').removeClass('fa-caret-left');
      }
    }
  }
}
