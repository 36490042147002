import { ProjectForm } from '../../../project/models/form.model';
import { formTypes } from 'app/form/form.constants';
import { RestorationTaxAbatementRenewal } from './restoration-tax-abatement-renewal.model';
import { IndustrialTaxExemptionRenewal } from './industrial-tax-exemption-renewal.model';
import { QualityJobsRenewal } from './quality-jobs-renewal.model';

export class Renewal extends ProjectForm {
  rta?: RestorationTaxAbatementRenewal;
  ite?: IndustrialTaxExemptionRenewal;
  qj?: QualityJobsRenewal;

  constructor() {
    super();
    this.init();
  }

  init() {
    super.init();

    this.ite = this.ite || new IndustrialTaxExemptionRenewal();
    this.qj = this.qj || new QualityJobsRenewal();
    this.rta = this.rta || new RestorationTaxAbatementRenewal();
    this.type = formTypes.renewalApplication.abbrev;
  }

  get qualifier() {
    return '';
  }
}
