import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CurrencyPipe, formatNumber } from '@angular/common';
import { ProjectDataService } from '../../project/services/project-data.service';
import { NavService } from '../../fastlane-common/services/nav.service';
import { incentiveProgram } from '../../project/project.constants';
import {
  DataTableColumnDefinition,
  publicColumnDefinitions
} from '../public-search-column-definitions';
import { formTypes } from '../../form/form.constants';
import { ProjectFormWrapper } from '../../project/models/project-form-wrapper.model';
declare var $: any;
@Component({
  selector: 'fl-public-ent-search',
  templateUrl: './public-ent-search.component.html',
  styleUrls: ['./public-ent-search.component.scss'],
  providers: [CurrencyPipe]
})
export class PublicEntSearchComponent implements OnInit, OnDestroy {
  dataSubscription: Subscription;
  // Filters
  filters: FormGroup;
  private table: any;
  private tableId = '#initial-cert-search-table';

  private formGroupNames = {
    incentiveProgram: {
      code: 'incentiveProgram',
      requestKey: 'incentivePrograms'
    },
    status: { code: 'status', requestKey: 'status' },
    appReceivedDateRange: {
      code: 'appReceivedDateRange',
      requestKey: 'applicationReceivedDateRange'
    },
    initialCertIssDateRange: {
      code: 'initialCertIssDateRange',
      requestKey: 'dateRange'
    }
  };

  private subs: Subscription[] = [];
  appReceivedDateRange: Date[] = [];
  initialCertIssDateRange: Date[] = [];
  constructor(
    private fb: FormBuilder,
    private dataService: ProjectDataService,
    private cp: CurrencyPipe,
    private navServ: NavService
  ) {}

  /**
   * @summary Adds a footer to the table. Only columns with the type property of either num or num-fmt will be computed
   * @param columnDefs Applicable Datatable Column Definition
   */
  addTableFooter(columnDefs: DataTableColumnDefinition[]) {
    try {
      // Get the html element that is the footer of the table
      let footerElement = document.querySelector(`${this.tableId} > tfoot`);

      // If no footer element found, create it
      if (!footerElement) {
        footerElement = document.createElement('tfoot');
        document.querySelector(this.tableId).appendChild(footerElement);
      }

      // Remove childrens from footer Element
      while (footerElement.firstChild) {
        footerElement.removeChild(footerElement.firstChild);
      }

      // New Table Row element
      const newRowElem = document.createElement('tr');

      // Make an element for each column as a placeholder, whether it has data or not
      for (let index = 0; index < columnDefs.length; index++) {
        const col = columnDefs[index];

        const elem = document.createElement('td');

        // Set the column name as a dataset attribute
        elem.dataset.columnName = col.title;

        // Add content if it has a render function
        if (col['type'] && col['type'].includes('num')) {
          // Sum all row values in this column
          const data = this.table.column(index).data();
          const sum = data.reduce((prev, next) => {
            return prev + (next || 0);
          }, 0);

          // Set text of table cell
          if (col['type'] === 'num-fmt') {
            elem.innerText = this.cp.transform(sum, 'USD');
          } else if (col['type'] === 'num') {
            elem.innerText = formatNumber(sum, 'en-US');
          } else {
            elem.innerText = sum;
          }
        }

        newRowElem.appendChild(elem);
      }

      // Ensure New Row has distinctive top border
      newRowElem.className = 'footer-top-border font-weight-bold';

      // Append the row to the footer
      footerElement.appendChild(newRowElem);
    } catch (e) {
      console.warn(e);
    }
  }

  //#region Event Handlers
  appReceivedDateSelected(range: Date[]) {
    this.filters
      .get(this.formGroupNames.appReceivedDateRange.code)
      .patchValue({ start: range[0], end: range[1] });
    console.log(range);
  }

  initialCertIssDateSelected(range: Date[]) {
    this.filters
      .get(this.formGroupNames.initialCertIssDateRange.code)
      .patchValue({ start: range[0], end: range[1] });
    console.log(range);
  }

  //#endregion

  ngOnDestroy() {
    // Ensure no subscription remains opened
    this.subs.forEach(s => s.unsubscribe());
  }

  ngOnInit() {
    const that = this;
    // Initialize Form Groups
    this.filters = this.fb.group({
      [this.formGroupNames.incentiveProgram.code]: [
        incentiveProgram.film.code,
        Validators.nullValidator
      ],
      [this.formGroupNames.status.code]: [
        'Review Complete',
        Validators.nullValidator
      ],
      [this.formGroupNames.appReceivedDateRange.code]: this.fb.group({
        start: null,
        end: null
      }),
      [this.formGroupNames.initialCertIssDateRange.code]: this.fb.group({
        start: null,
        end: null
      })
    });

    // Initialize Datatable
    this.initializeDatatable(
      publicColumnDefinitions[incentiveProgram.film.code][
        formTypes.initialCert.abbrev
      ]
    );

    // Subscribe to Program Changes
    const programSub = this.filters
      .get(this.formGroupNames.incentiveProgram.code)
      .valueChanges.subscribe(value => {
        // Destroy the current datatable
        that.table.destroy();

        // Ensure no table data remains: headers and rows
        $(that.tableId).empty();

        // Reinitialze the table
        that.initializeDatatable(
          publicColumnDefinitions[value][formTypes.initialCert.abbrev]
        );
      });

    this.subs.push(programSub);
  }

  initializeDatatable(columnDefs: DataTableColumnDefinition[]) {
    const that = this;

    that.table = $('#initial-cert-search-table').DataTable({
      dom:
        `<'col-xl-12'<'row d-flex justify-content-between'<'p-0'f><'ml-2 mr-2 p-0'l><'html5buttons'B>>` +
        `<'row'<'table-only-wrapper position-relative col-xl-12 p-0'tr>>` +
        `<'row d-flex justify-content-end'<'mr-auto p-0'i><'p-0'p>>>`,
      ordering: false,
      serverSide: true,
      initComplete: function(settings, json) {},
      drawCallback: settings => {
        // Redo the footer row
        that.addTableFooter(columnDefs);
        console.log('Callback Reached!!');
      },
      ajax: function(data, callback, settings) {
        Object.assign(data, that.translateFilters());
        if (that.dataSubscription && !that.dataSubscription.closed) {
          that.dataSubscription.unsubscribe();
          that.dataSubscription = null;
        }
        that.dataSubscription = that.dataService
          .getInitialCertsCancellable(data, callback)
          .subscribe(() => setTimeout(() => that.table.columns.adjust(), 1000));
      },
      columns: columnDefs,
      processing: true,
      fixedHeader: {
        header: true,
        footer: true
      },
      pageLength: 1000,
      lengthMenu: [50, 75, 100, 1000]
    });
  }

  search() {
    this.table.draw();
  }

  /**
   * @summary Converts filters form group into the proper datatables.net request object
   */
  translateFilters() {
    const request = {};

    // Set incentive program variable if applicable
    if (
      this.filters.get(this.formGroupNames.incentiveProgram.code) &&
      this.filters.get(this.formGroupNames.incentiveProgram.code).value !== ''
    ) {
      // Get the currently selected program
      const selectedProgram = this.filters.get(
        this.formGroupNames.incentiveProgram.code
      ).value;

      // Translate to an array as the request can usually take the form of many, but in this instance
      // all programs are mutually exclusive
      request[this.formGroupNames.incentiveProgram.requestKey] = [
        selectedProgram
      ];
    }

    // Set status variable if applicable
    if (
      this.filters.get(this.formGroupNames.status.code) &&
      this.filters.get(this.formGroupNames.status.code).value !== ''
    ) {
      request[this.formGroupNames.status.requestKey] = this.filters.get(
        this.formGroupNames.status.code
      ).value;
    }

    // Set Application Received Range if applicable
    if (
      this.filters.get(this.formGroupNames.appReceivedDateRange.code) &&
      this.filters.get(`${this.formGroupNames.appReceivedDateRange.code}.start`)
        .value
    ) {
      const range = [
        this.filters.get(
          `${this.formGroupNames.appReceivedDateRange.code}.start`
        ).value,
        this.filters.get(`${this.formGroupNames.appReceivedDateRange.code}.end`)
          .value
      ];
      request[this.formGroupNames.appReceivedDateRange.requestKey] = range;
    }

    // Set Initial Cert Issuance Range if applicable
    if (
      this.filters.get(this.formGroupNames.initialCertIssDateRange.code) &&
      this.filters.get(
        `${this.formGroupNames.initialCertIssDateRange.code}.start`
      ).value
    ) {
      const range = [
        this.filters.get(
          `${this.formGroupNames.initialCertIssDateRange.code}.start`
        ).value,
        this.filters.get(
          `${this.formGroupNames.initialCertIssDateRange.code}.end`
        ).value
      ];
      request[this.formGroupNames.initialCertIssDateRange.requestKey] = range;
    }

    return request;
  }
}
