/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./event-notification-preferences.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../event-notification-action-multiselect/event-notification-action-multiselect.component.ngfactory";
import * as i3 from "../event-notification-action-multiselect/event-notification-action-multiselect.component";
import * as i4 from "@angular/common";
import * as i5 from "./event-notification-preferences.component";
var styles_EventNotificationPreferencesComponent = [i0.styles];
var RenderType_EventNotificationPreferencesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventNotificationPreferencesComponent, data: {} });
export { RenderType_EventNotificationPreferencesComponent as RenderType_EventNotificationPreferencesComponent };
function View_EventNotificationPreferencesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "mr-2 col-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "fl-event-notification-action-multiselect", [["class", "w-100 py-2 col-8"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.updateEventActionPreferences(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_EventNotificationActionMultiselectComponent_0, i2.RenderType_EventNotificationActionMultiselectComponent)), i1.ɵdid(4, 638976, null, 0, i3.EventNotificationActionMultiselectComponent, [], { eventActions: [0, "eventActions"], selectedEventActions: [1, "selectedEventActions"] }, { change: "change" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getEventActions(_v.context.$implicit); var currVal_2 = _co.getSelectedActions(_v.context.$implicit); _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getEventDisplayName(_v.context.$implicit); _ck(_v, 2, 0, currVal_0); }); }
function View_EventNotificationPreferencesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "card m-t border border-primary"], ["style", "overflow:visible;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "card-header bg-primary text-white font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" NOTIFICATION PREFERENCES "])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "card-body p-4"], ["style", "overflow:visible;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "d-flex flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventNotificationPreferencesComponent_2)), i1.ɵdid(6, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.applicableEvents; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_EventNotificationPreferencesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventNotificationPreferencesComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.applicableEvents.length > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EventNotificationPreferencesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fl-event-notification-preferences", [], null, null, null, View_EventNotificationPreferencesComponent_0, RenderType_EventNotificationPreferencesComponent)), i1.ɵdid(1, 638976, null, 0, i5.EventNotificationPreferencesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventNotificationPreferencesComponentNgFactory = i1.ɵccf("fl-event-notification-preferences", i5.EventNotificationPreferencesComponent, View_EventNotificationPreferencesComponent_Host_0, { agency: "agency", user: "user" }, {}, []);
export { EventNotificationPreferencesComponentNgFactory as EventNotificationPreferencesComponentNgFactory };
