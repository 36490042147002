/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./assessor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/person/person.component.ngfactory";
import * as i3 from "../../../../shared/components/person/person.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../shared/components/parish/parish.component.ngfactory";
import * as i6 from "../../../../shared/components/parish/parish.component";
import * as i7 from "./assessor.component";
var styles_UserDetailAssessorComponent = [i0.styles];
var RenderType_UserDetailAssessorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserDetailAssessorComponent, data: {} });
export { RenderType_UserDetailAssessorComponent as RenderType_UserDetailAssessorComponent };
export function View_UserDetailAssessorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { personComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "shr-person", [], null, null, null, i2.View_PersonComponent_0, i2.RenderType_PersonComponent)), i1.ɵdid(2, 245760, [[1, 4]], 0, i3.PersonComponent, [i4.FormBuilder], { addressRequired: [0, "addressRequired"], form: [1, "form"], person: [2, "person"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "d-flex py-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "label", [["class", "col-4 form-control-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Governing Parish :"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "col-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "shr-parish", [], null, [[null, "parishChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("parishChange" === en)) {
        var pd_0 = ((_co.user.governingParish = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ParishComponent_0, i5.RenderType_ParishComponent)), i1.ɵdid(8, 245760, null, 0, i6.ParishComponent, [i4.FormBuilder], { readonly: [0, "readonly"], form: [1, "form"], parish: [2, "parish"] }, { parishChange: "parishChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.form; var currVal_2 = _co.user; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = false; var currVal_4 = _co.form; var currVal_5 = _co.user.governingParish; _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5); }, null); }
export function View_UserDetailAssessorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fl-user-detail-assessor", [], null, null, null, View_UserDetailAssessorComponent_0, RenderType_UserDetailAssessorComponent)), i1.ɵdid(1, 4308992, null, 0, i7.UserDetailAssessorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserDetailAssessorComponentNgFactory = i1.ɵccf("fl-user-detail-assessor", i7.UserDetailAssessorComponent, View_UserDetailAssessorComponent_Host_0, { form: "form", user: "user" }, {}, []);
export { UserDetailAssessorComponentNgFactory as UserDetailAssessorComponentNgFactory };
