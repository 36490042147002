import { Advance } from './advance.model';
import { Application } from './application.model';
import { Attachment } from './attachment.model';
import { Contact } from './project-contact.model';
import { ProjectInfo } from './project-info.model';
import { StatusLog } from './status-log.model';
import { ProjectForm } from './form.model';
import { contactType, incentiveProgram } from '../project.constants';
import { FormIdentifier } from './form-identifier.model';
import { AnnualCertificationReport } from '../../incentive/compliance/models/annual-certification-report.model';
import { ProjectCompletionReport } from '../../incentive/compliance/models/project-completion-report.model';
import { AffidavitFinalCost } from '../../incentive/compliance/models/affidavit-final-cost.model';
import { ComplianceForm } from '../../incentive/compliance/models/compliance-form.model';
import { ContractChangeName } from '../../incentive/contract-change/models/contract-change-name.model';
import { ContractChangeLocation } from '../../incentive/contract-change/models/contract-change-location.model';
import { ContractChangeTransfer } from '../../incentive/contract-change/models/contract-change-transfer.model';
import { ContractChangeOwnership } from '../../incentive/contract-change/models/contract-change-ownership.model';
import { ContractChangeClosure } from '../../incentive/contract-change/models/contract-change-closure.model';
import { EmployeeCertificationReport } from '../../incentive/compliance/models/employee-certification-report.model';
import { Renewal } from '../../incentive/renewal/models/renewal.model';
import { Comment } from './comments.model';
import { ExtensionRequest } from './extension-request.model';
import { SpecialRequest } from './special-request.model';
import { BalanceAdjustmentRequest } from './balance-adjustment-request.model';
import { ProjectBase } from './project-base.model';
import { CertificiateComplianceForm } from '../../incentive/compliance/models/certificate-of-compliance.model';
import { StepApplication } from '../../step/models/step/step-application.model';

export class Project extends ProjectBase {
  advance: Advance;
  application: Application;
  id: string;
  projectInfo: ProjectInfo;
  acr: AnnualCertificationReport[];
  ecr: EmployeeCertificationReport[];
  afc: AffidavitFinalCost[];
  pcr: ProjectCompletionReport[];
  ccc: ContractChangeClosure[];
  ccn: ContractChangeName[];
  ccl: ContractChangeLocation[];
  cpt: ContractChangeTransfer[];
  cft: ContractChangeTransfer[];
  cao: ContractChangeOwnership[];
  rnw: Renewal;
  ext: ExtensionRequest[];
  req: SpecialRequest[];
  coc: CertificiateComplianceForm[];
  stepApplication: StepApplication[];

  constructor() {
    super();
  }

  getComplianceFormGroups(): ComplianceForm[][] {
    return [
      this.acr ? this.acr : [],
      this.afc ? this.afc : [],
      this.ecr ? this.ecr : [],
      this.pcr ? this.pcr : [],
      this.coc ? this.coc : []
    ];
  }

  getContactChangesGroups(): ProjectForm[][] {
    return [
      this.ccc ? this.ccc : [],
      this.ccn ? this.ccn : [],
      this.ccl ? this.ccl : [],
      this.cpt ? this.cpt : [],
      this.cft ? this.cft : [],
      this.cao ? this.cao : []
    ];
  }

  init(): Project {
    // Initialize the base class ProjectBase.
    super.init();

    // ProjectInfo in ProjectBase is type ProjectInfoBase.
    // So, Super.init() takes care of initializing ProjectInfoBase in ProjectBase.
    // Here we just use that initialized projectInfoBase and construct BiProjectInfo.
    this.projectInfo = new ProjectInfo(this.projectInfo);

    //#region onetooneforms

    // the advance property exists, but to make it strongly typed and properly initialized, we perform the following
    this.advance = this.advance ? new Advance(this.advance) : null;

    if (this.application) {
      if (this.projectInfo.incentiveProgram === incentiveProgram.film.code) {
        this.application = Object.assign(new Application(), this.application);
      } else {
        this.application = Object.assign(new Application(), this.application);
      }
      this.application.init();
    }

    if (this.rnw) {
      this.rnw = Object.assign(new Renewal(), this.rnw);
      this.rnw.init();
    }
    //#endregion

    //#region onetomanyforms
    if (this.acr) {
      this.acr = this.acr.map(report => {
        report = Object.assign(new AnnualCertificationReport(), report);
        report.init();
        return report;
      });
    }

    if (this.bar) {
      this.bar = this.bar.map(report => {
        report = Object.assign(new BalanceAdjustmentRequest(), report);
        report.init();
        return report;
      });
    }

    if (this.pcr) {
      this.pcr = this.pcr.map(report => {
        report = Object.assign(new ProjectCompletionReport(), report);
        report.init();
        return report;
      });
    }

    if (this.afc) {
      this.afc = this.afc.map(report => {
        report = Object.assign(new AffidavitFinalCost(), report);
        report.init();
        return report;
      });
    }

    if (this.ecr) {
      this.ecr = this.ecr.map(report => {
        report = Object.assign(new EmployeeCertificationReport(), report);
        report.init();
        return report;
      });
    }

    if (this.ccn) {
      this.ccn = this.ccn.map(contractChanges => {
        contractChanges = Object.assign(
          new ContractChangeName(),
          contractChanges
        );
        contractChanges.init();
        return contractChanges;
      });
    }

    if (this.ccl) {
      this.ccl = this.ccl.map(contractChanges => {
        contractChanges = Object.assign(
          new ContractChangeLocation(),
          contractChanges
        );
        contractChanges.init();
        return contractChanges;
      });
    }

    if (this.coc) {
      this.coc = this.coc.map(contractChanges => {
        contractChanges = Object.assign(
          new CertificiateComplianceForm(),
          contractChanges
        );
        contractChanges.init();
        return contractChanges;
      });
    }

    if (this.cpt) {
      this.cpt = this.cpt.map(contractChanges => {
        contractChanges = Object.assign(
          new ContractChangeTransfer(),
          contractChanges
        );
        contractChanges.init();
        return contractChanges;
      });
    }

    if (this.cft) {
      this.cft = this.cft.map(contractChanges => {
        contractChanges = Object.assign(
          new ContractChangeTransfer(),
          contractChanges
        );
        contractChanges.init();
        return contractChanges;
      });
    }

    if (this.cao) {
      this.cao = this.cao.map(contractChanges => {
        contractChanges = Object.assign(
          new ContractChangeOwnership(),
          contractChanges
        );
        contractChanges.init();
        return contractChanges;
      });
    }

    if (this.ccc) {
      this.ccc = this.ccc.map(contractChanges => {
        contractChanges = Object.assign(
          new ContractChangeClosure(),
          contractChanges
        );
        contractChanges.init();
        return contractChanges;
      });
    }

    if (this.ext) {
      this.ext = this.ext.map(extensionRequest => {
        extensionRequest = Object.assign(
          new ExtensionRequest(),
          extensionRequest
        );
        extensionRequest.init();
        return extensionRequest;
      });
    }

    if (this.req) {
      this.req = this.req.map(specialRequest => {
        specialRequest = Object.assign(new SpecialRequest(), specialRequest);
        specialRequest.init();
        return specialRequest;
      });
    }

    if (this.stepApplication) {
      this.stepApplication = this.stepApplication.map(app => {
        app = Object.assign(new StepApplication(), app);
        app.init();
        return app;
      })
    }
    //#endregion

    return this;
  }
}
