export class AuditCriticalDates {
  auditDate: Date;
  finalCertIssuanceDate: Date;
  closureDate: Date;
  costReportReceivedDate: Date;
  verificationReportReceivedDate: Date;
  constructor(obj?: any) {
    obj = obj ? obj : {};
    this.auditDate = obj.auditDate ? new Date(obj.auditDate) : null;
    this.finalCertIssuanceDate = obj.finalCertIssuanceDate
      ? new Date(obj.finalCertIssuanceDate)
      : null;
    this.closureDate = obj.closureDate ? new Date(obj.closureDate) : null;
    this.costReportReceivedDate = obj.costReportReceivedDate
      ? new Date(obj.costReportReceivedDate)
      : null;
    this.verificationReportReceivedDate = obj.verificationReportReceivedDate
      ? new Date(obj.verificationReportReceivedDate)
      : null;
  }
}
