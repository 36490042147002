<shr-person
  [form]="userForm"
  [person]="user"
  [addressRequired]="false"
></shr-person>

<div class="card border-primary m-t">
  <div class="card-header bg-primary text-white font-weight-bold">
    AVAILABLE MENU OPTIONS
  </div>
  <div class="card-body">
    <div class="d-flex flex-wrap">
      <div
        *ngFor="let pair of permissionPropertyNameAndLabelPairs"
        class="d-flex col-xl-4 col-lg-6 col-12 py-1"
      >
        <label class="col-8 form-control-label px-0">{{ pair[1] }}</label>
        <div class="col-4">
          <button
            type="button"
            [ngClass]="{ active: user.permissions[pair[0]] }"
            (click)="changePermission(pair[0])"
            class="btn btn-toggle"
            aria-pressed="user.permissions[pair[0]]"
          >
            <div class="handle"></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
