import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AddressComponent } from './components/address/address.component';
import { DocumentTypeComboboxComponent } from './components/document-type-combobox/document-type-combobox.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ModalComponent } from './layouts/modal/modal.component';
import { ParishComponent } from './components/parish/parish.component';
import { BsDropdownModule } from 'ngx-bootstrap';
import { IboxtoolsComponent } from './components/iboxtools/iboxtools.component';
import { NgPipesModule } from 'angular-pipes';
import { TextMaskModule } from 'angular2-text-mask';
import { BsDatepickerModule, TimepickerModule } from 'ngx-bootstrap';
import { SelectListComponent } from './components/select-list/select-list.component';
import { PersonComponent } from './components/person/person.component';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { JsignComponent } from './components/jsign/jsign.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { DateComponent } from './components/date/date.component';
import { RegionComponent } from './components/region/region.component';
import { ParishMultiselectComponent } from './components/parish-multiselect/parish-multiselect.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ProjectStatusMultiselectComponent } from './components/project-status-multiselect/project-status-multiselect.component';
import { RegionMultiselectComponent } from './components/region-multiselect/region-multiselect.component';
import { BoardAgendaSummaryFormComponent } from './board/agenda/summary/form/form.component';
import { BoardAgendaSummaryComponent } from './board/agenda/summary/summary.component';
import { BoardAgendaSummarySelectorComponent } from './board/agenda/summary/selector/selector.component';
import { BoardAgendaSummaryOverviewComponent } from './board/agenda/summary/overview/overview.component';
import { BoardAgendaSummaryProgramComponent } from './board/agenda/summary/program/program.component';
import { BoardAgendaSummaryResolver } from './board/agenda/summary/summary.resolver';
import { LoginNotificationComponent } from './labels/loginNotification.component';

import { ProgramTypeCheckboxComponent } from './components/program-type-checkbox/program-type-checkbox.component';
import { FormTypeMultiselectComponent } from './components/form-type-multiselect/form-type-multiselect.component';;
import { FiscalYearMultiselectComponent } from './components/fiscal-year-multiselect/fiscal-year-multiselect.component';
import { RequiredWrapperComponent } from './components/required-wrapper/required-wrapper.component'

@NgModule({
  imports: [
    BsDatepickerModule.forRoot(),
    CommonModule,
    BsDropdownModule.forRoot(),
    FormsModule,
    NgPipesModule,
    ReactiveFormsModule,
    RouterModule,
    TextMaskModule,
    TimepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()
  ],
  declarations: [
    AddressComponent,
    CheckboxComponent,
    DocumentTypeComboboxComponent,
    IboxtoolsComponent,
    ParishComponent,
    ModalComponent,
    SelectListComponent,
    PersonComponent,
    DateTimePickerComponent,
    DateRangePickerComponent,
    JsignComponent,
    DateComponent,
    RegionComponent,
    ParishMultiselectComponent,
    ProjectStatusMultiselectComponent,
    RegionMultiselectComponent,
    BoardAgendaSummaryFormComponent,
    BoardAgendaSummaryComponent,
    BoardAgendaSummarySelectorComponent,
    BoardAgendaSummaryOverviewComponent,
    BoardAgendaSummaryProgramComponent,
    FormTypeMultiselectComponent,
    ProgramTypeCheckboxComponent
    ,
    LoginNotificationComponent,
    FiscalYearMultiselectComponent,
    RequiredWrapperComponent
  ],
  exports: [
    AddressComponent,
    CheckboxComponent,
    DocumentTypeComboboxComponent,
    IboxtoolsComponent,
    ModalComponent,
    NgPipesModule,
    ParishComponent,
    TextMaskModule,
    BsDatepickerModule,
    SelectListComponent,
    PersonComponent,
    DateTimePickerComponent,
    DateRangePickerComponent,
    JsignComponent,
    DateComponent,
    RegionComponent,
    ParishMultiselectComponent,
    ProjectStatusMultiselectComponent,
    RegionMultiselectComponent,
    BoardAgendaSummaryComponent,
    BoardAgendaSummaryProgramComponent,
    FormTypeMultiselectComponent,
    ProgramTypeCheckboxComponent,
    FiscalYearMultiselectComponent,
    LoginNotificationComponent,
    RequiredWrapperComponent,
  ],
  providers: [BoardAgendaSummaryResolver]
})
export class SharedModule {}
