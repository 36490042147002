import { ProjectInfoBase } from '../../../project/models/project-info-base.model';
import { EntertainmentId } from '../../interfaces/ent-id.interface';

export class ProjectInfo extends ProjectInfoBase implements EntertainmentId {
  entId: string;
  digitalId: string;
  constructor(obj?: any) {
    if (!obj) {
      obj = {};
    }
    super(obj);
    this.digitalId = obj.digitalId || null;
    this.entId = obj.digitalId;
  }
}
