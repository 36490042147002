import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { regions } from '../../shared.constants';
import { NgMultiSelectOption } from '../../interfaces/ng-multi-select-option.interface';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'shr-region-multiselect',
  templateUrl: './region-multiselect.component.html',
  styleUrls: ['./region-multiselect.component.scss']
})
export class RegionMultiselectComponent implements OnInit {
  //#region Settings
  dropdownSettings = {
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  //#endregion

  @Input()
  disabled = false;
  @Output()
  change: EventEmitter<NgMultiSelectOption[]> = new EventEmitter<NgMultiSelectOption[]>();

  // Get list of regions from constants
  regions: NgMultiSelectOption[] = regions.map(p => {
    return {
      id: p.name,
      text: p.name
    };
  });

  selectedRegions: string[] = [];
  selectedRegionObjects: NgMultiSelectOption[] = [];

  constructor() {}

  ngOnInit() {}
  onSelectionChange(region: NgMultiSelectOption, event: 'select' | 'deselect') {
    // Handle appropiate selection event to modify the selectedRegions array.
    if (event === 'select') {
      this.selectedRegions.push(region.text);
    } else {
      this.selectedRegions.splice(this.selectedRegions.indexOf(region.text), 1);
    }

    // Emit new regions
    this.change.emit(this.selectedRegionObjects);
  }

  onSelectAll(selectedRegions: NgMultiSelectOption[]) {
    this.selectedRegions = selectedRegions.map(sr => sr.text);
    this.selectedRegionObjects.splice(0);
    this.selectedRegionObjects.push(...selectedRegions);
    // Emit new regions
    this.change.emit(this.selectedRegionObjects);
  }

  parseSelectedRegions(selectedRegions: string[]) {
    // Parse Regions to comply with ng-multiselect ngModel
    if (selectedRegions && selectedRegions.length > 0) {
      this.selectedRegionObjects = selectedRegions.map(p => {
        return {
          id: p,
          text: p
        };
      });
    } else {
      this.selectedRegionObjects = [];
    }

    if (selectedRegions) {
      // Set selected regions property
      this.selectedRegions = selectedRegions;
    } else {
      this.selectedRegions = [];
    }
  }
}
