import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ApplicationService } from '../../../services/application.service';
import { ProjectDetailService } from '../../../../project/services/project-shared.service';
import { Project } from '../../../models/digital/project.model';
import { Application } from '../../../models/digital/application.model';
import { Subscription, Observable } from 'rxjs';
import { Expenditures } from '../../../models/digital/expenditures.model';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { LaborInfo } from '../../../models/digital/labor-info.model';
import {
  calculateMarkedLaborExpenditures,
  calculateMarkedNonLaborExpenditures,
  calculateJobCount,
  calUpdateAllTotal,
  calCreditExpenditure,
  calCreditAmount
} from '../expenditures/expenditures.function';
import { NonLaborInfo } from '../../../models/digital/non-labor-info.model';
import {
  ExpenditureStatuses,
  FilePath,
  paymentTypes,
  oldPaymentTypes
} from './expenditures.constants';
import { CreditTypes } from '../../../credit.constants';
import { translateCreditType } from '../../../credit.functions';
import {
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
  FormControl
} from '@angular/forms';
import { PublishSubscribeService } from '../../../../fastlane-common/services/publish-subscribe.service';
import {
  publishSubscribeEventStrings,
  events
} from '../../../../fastlane-common/event/event.constants';
import { FormValidationService } from '../../../../form/services/validation.service';
import { expenditureActions } from '../../../../project/project.constants';
import { ExpenditurePerCreditType } from '../../../credit.constants';
import { SwalService } from '../../../../fastlane-common/services/swal.service';
import { FormShareService } from '../../../../form/services/share.service';
import { formTypes } from '../../../../form/form.constants';
import { FormFeeService } from '../../../../form/services/fee.service';
import { deepCopy } from '../../../../shared/shared.functions';
import { AttachmentsService } from '../../../../project/services/attachments.service';
import { UserContextService } from '../../../../user/services/user-context.service';
declare var $: any;
@Component({
  selector: 'fl-dm-expenditures',
  templateUrl: './expenditures.component.html',
  styleUrls: ['./expenditures.component.scss'],
  providers: [CurrencyPipe, PercentPipe]
})
export class ExpendituresComponent implements OnInit, OnDestroy {
  // #region Function
  calCreditExpenditureFn = calCreditExpenditure;
  calCreditAmountFn = calCreditAmount;
  calculateJobCountFn = calculateJobCount;
  calculateMarkedLaborExpendituresFn = calculateMarkedLaborExpenditures;
  calculateMarkedNonLaborExpendituresFn = calculateMarkedNonLaborExpenditures;
  // #endregion
  // #region Constant
  FilePathConst = FilePath;
  creditTypesConst = CreditTypes;
  paymentTypes = deepCopy(paymentTypes);
  // #endregion
  digitalApplication: Application;
  project: Project;
  @Input() expenditures: Expenditures;
  @Input() editMode = false;
  expendituresUploadFormGroup: FormGroup;
  laborFormGroup: FormGroup;
  nonLaborFormGroup: FormGroup;
  estimatedJobsFormGroup: FormGroup;
  expendituresFormGroup: FormGroup;
  estimatedCreditsFormArray: FormArray;
  private subscriptions: Subscription[] = [];
  selectedLaborInfos: LaborInfo[] = [];
  selectedNonLaborInfos: NonLaborInfo[] = [];
  selectedIndex: number;
  invalidObservable: Observable<boolean>;
  get laborYearDetails(): FormArray {
    return this.laborFormGroup.get('laborYearDetails') as FormArray;
  }

  get nonLaborYearDetails(): FormArray {
    return this.nonLaborFormGroup.get('nonLaborYearDetails') as FormArray;
  }
  expenditureStatuses = ExpenditureStatuses;

  allExpenditureStatuses = [
    ExpenditureStatuses.eligible,
    ExpenditureStatuses.ineligible,
    ExpenditureStatuses.conditionallyEligible
  ];

  //#region Labor Template Variables
  personDetailHeaders = [
    { code: 'jobTitle', display: 'Title' },
    { code: 'jobCount', display: 'Job Count' },
    { code: 'employeeName', display: 'Employee' },
    {
      code: 'averageAllocation',
      display: 'Avg. Allocation'
    },
    { code: 'totalSalary', display: 'Total Salary' }
  ];

  personDetailAdditionalHeaders = [
    { code: 'existingNewHire', display: 'Existing or New Hire' },
    { code: 'jobCategory', display: 'Job Category' },
    { code: 'jobDescription', display: 'Job Description' },
    { code: 'laResident', display: 'LA Resident' },
    { code: 'paymentType', display: 'Payment Type' }
  ];

  yearDetailHeaders = [
    { code: 'year', display: 'Year', gridCol: 'year' },
    { code: 'percentAllocation', display: 'Percent', gridCol: 'percent' },
    { code: 'salaryAllocation', display: 'Salary', gridCol: 'salary' },
    {
      code: 'salaryByJobCount',
      display: 'Salary x Job Count',
      gridCol: 'count'
    }
  ];

  //#endregion

  //#region Non Labor Template Variables

  itemDetailsHeaders = [
    { code: 'item', display: 'Item' },
    { code: 'totalItemCost', display: 'Total Cost' }
  ];

  yearItemDetailHeaders = [
    { code: 'year', display: 'Year' },
    { code: 'unitCost', display: 'Unit Cost' },
    { code: 'itemCount', display: 'Count' },
    {
      code: 'totalCost',
      display: 'Total'
    }
  ];
  //#endregion

  //#region Estimated Credits
  estimatedCreditsHeaders = [
    { code: 'year', display: 'Calendar Year' },
    { code: 'dateRange', display: 'Period' },
    { code: 'expenditureAmount', display: 'Expenditure Total' },
    { code: 'creditType', display: 'Credit Type' },
    { code: 'rate', display: 'Rate %' },
    { code: 'creditTypeAmount', display: 'Credit Amount' }
  ];

  //#endregion

  constructor(
    private projectDetailService: ProjectDetailService,
    private applicationService: ApplicationService,
    private cp: CurrencyPipe,
    private percentpipe: PercentPipe,
    private fb: FormBuilder,
    private pubSubService: PublishSubscribeService,
    private validationService: FormValidationService,
    private swal: SwalService,
    private formShareService: FormShareService,
    private formFeeService: FormFeeService,
    private attachmentService: AttachmentsService,
    private userContext: UserContextService
  ) {
    this.estimatedCreditsFormArray = fb.array([]);
  }

  // #region "Custom Methods"
  /**
   * @summary Destroy the subscription after rendering the page to prevent leaks.
   * @param sub Subription that was register.
   */
  addSubscriberToBeDestroy(sub: Subscription) {
    // Add subscriberts to the Subscription array to be destroy later.
    this.subscriptions.push(sub);
  }
  /**
   * @summary
   */
  getKeys(obj) {
    if (obj !== null && obj !== undefined) {
      return Object.keys(obj);
    }
    return [];
  }
  /**
   * @summary Initialize the form controls
   * */
  initializeForm() {
    const that = this;

    this.expendituresUploadFormGroup = this.fb.group({
      expendituresUpload: ['', !this.expenditures ? Validators.required : null]
    })

    this.validationService.form.setControl(
      'expendituresUploadFormGroup',
      this.expendituresUploadFormGroup
    )
  }

  /**
   * @summary Get project info data
   */
  getProjectInfo() {
    this.project = this.projectDetailService.digitalProject;
  }
  getEligibleLabor() {
    this.expenditures.totalEligibleLabor = this.calculateMarkedLaborExpendituresFn(
      this.expenditures ? this.expenditures.laborInfo : null,
      null,
      [
        this.expenditureStatuses.eligible,
        this.expenditureStatuses.conditionallyEligible
      ]
    );
    return this.expenditures.totalEligibleLabor;
  }
  getEligibleNonLabor() {
this.expenditures.totalEligibleNonLabor =  this.calculateMarkedNonLaborExpendituresFn(
        this.expenditures ? this.expenditures.nonLaborInfo : null,
        null,
        [
          this.expenditureStatuses.eligible,
          this.expenditureStatuses.conditionallyEligible
        ]
      );
      return this.expenditures.totalEligibleNonLabor;
  }
  getEligibleTotalExpenditure(): number {
    this.expenditures.totalEligibleExpenditure =
      this.getEligibleLabor() +
      this.getEligibleNonLabor();
    return this.expenditures.totalEligibleExpenditure;
  }
  /**
   * @summary
   */
  renderValue(code: string, value: any) {
    if (
      [
        'totalSalary',
        'salaryAllocation',
        'totalItemCost',
        'unitCost',
        'totalCost',
        'salaryByJobCount',
        'expenditureAmount',
        'creditTypeAmount'
      ].includes(code)
    ) {
      return this.cp.transform(value);
    }
    if (code === 'dateRange') {
      if (value.length === 2) {
        return `${new Date(value[0]).toDateString()} - ${new Date(
          value[1]
        ).toDateString()}`;
      }
      return '';
    }
    if (code === 'creditType') {
      return translateCreditType(value);
    }
    if (['averageAllocation', 'percentAllocation'].includes(code)) {
      return this.percentpipe.transform(value, '1.2-2');
    }
    return value;
  }
  /**
   * @summary Translate the spreadsheet data into json
   */
  validateExpentureFileData(data) {
    let errors = [];
    // Spreadsheet Years:
    // there has to be at least one year in spreadsheet
    if (!data.yearsInSpreadsheet || data.yearsInSpreadsheet.length < 1) {
      errors.push('There must be at least one year in spreadsheet');
    }
    data.yearsInSpreadsheet.forEach(year => {
      if (!year.year || year.year > 4000 || year.year < 1900) {
        errors.push('Every year must be filled in');
      }
      if (!year.startDate || !year.endDate
        || new Date(year.startDate) > new Date(year.endDate)) {
        errors.push('Every year must have a start date, end date, and the end date must be after the start date');
      }
    });

    if ((data.laborInfo && data.laborInfo.length === 0) && (data.nonLaborInfo && data.nonLaborInfo.length === 0)) {
      errors.push('There must be at least one entry for either Labor Expenditures or Non Labor Expenditures');
    }

    // Labor Expenditures:
    if (data.laborInfo && data.laborInfo.length > 0) {
      data.laborInfo.forEach(labor => {
        if (!labor.employeeName) {
          errors.push('Labor Expenditure missing Employee Name');
        }
        if (!labor.existingNewHire) {
          errors.push('Labor Expenditure Existing or New Hire not selected');
        }
        if (!labor.jobCategory) {
          errors.push('Labor Expenditure Job Category not selected');
        }
        if (!labor.jobDescription) {
          errors.push('Labor Expenditure Job Description not filled in');
        }
        if (!labor.jobCount || labor.jobCount < 1) {
          errors.push('Labor Expenditure missing job count or job count is 0');
        }
        if (!labor.jobTitle) {
          errors.push('Labor Expenditure Job Title not filled in');
        }
        if (!labor.laResident) {
          errors.push('Labor Expenditure LA Resident not selected');
        }
        if (!labor.paymentType) {
          errors.push('Labor Expenditure Payment Type not selected');
        }
        if (!labor.totalSalary || labor.totalSalary < 1) {
          errors.push('Labor Expenditure total salary missing or value is 0');
        }

        if (!labor.yearDetails || labor.yearDetails.length < 1) {
          errors.push('Labor Expenditure must have at least one year filled out');
        }
      });
    }

    // Non-Labor Expenditures:
    if (data.nonLaborInfo && data.nonLaborInfo.length > 0) {
      if (data.nonLaborInfo.length === 1) {
        if (!data.nonLaborInfo[0].item || data.nonLaborInfo[0].item.length === 0) {
          if (data.nonLaborInfo[0].yearDetails.length === 0) {
            return errors;
          }
        }
      }
      data.nonLaborInfo.forEach(nonLabor => {
        if (!nonLabor.item) {
          errors.push('Non Labor Expenditure Item not filled in');
          return errors;
        }
        if (!nonLabor.yearDetails || nonLabor.yearDetails.length === 0) {
          errors.push('Non Labor Expenditure Unit Cost and/or Item Count not filled in');
        } else {
          nonLabor.yearDetails.forEach(nonLaborYear => {
            if (!nonLaborYear.year || nonLaborYear.year === 0 ) {
              errors.push('Non Labor Expenditure Year not filled in');
            }
            if (!nonLaborYear.unitCost || nonLaborYear.unitCost === 0 ) {
              errors.push('Non Labor Expenditure Unit Cost not filled in');
            }
            if (!nonLaborYear.itemCount || nonLaborYear.itemCount === 0 ) {
              errors.push('Non Labor Expenditure Item Count not filled in');
            }
            if (!nonLaborYear.totalCost || nonLaborYear.totalCost === 0 ) {
              errors.push('Non Labor Expenditure Total Cost not filled in');
            }
          });
          if (!nonLabor.totalItemCost || nonLabor.totalItemCost < 1) {
            errors.push('Non Labor Expenditure Total not filled in or 0');
          }
        }
      });
    }

    return errors;
  }


  uploadExpenditureFile(event) {
    const that = this;
    that.swal.load('Importing...', 'This will only take a second.');
    const fileSelected: File = event.target.files[0];
    const sub = that.applicationService
      .convertDigitalSpreadsheetToJson(
        fileSelected,
        that.project.projectInfo.legislation
      )
      .subscribe(
        expendituresJsonData => {
          const validationErrors = that.validateExpentureFileData(expendituresJsonData);
          if (validationErrors.length > 0) {
            Object.assign(that.expenditures, new Expenditures());
            that.swal.error({
              title: 'Import Failed.',
              html: `<div>The import of the preliminary schedule of expenditures failed. Please use the template
provided in this section and review data entered in template to ensure there is no blank data within required
fields in the excel file.</div></br><div><ul>${validationErrors.map(err => `<li>${err}</li>`)}</ul></div>`
            });
            return;
          }

          (that.expenditures = Object.assign(
            that.expenditures,
            new Expenditures(expendituresJsonData)
          )),
            calUpdateAllTotal(
              that.expenditures,
              that.project.projectInfo.legislation,
              'Application'
            );
          const expenditureChangedValue = <ExpenditurePerCreditType>{
            amount: that.expenditures.expenditureTotal
          };
          that.pubSubService.raise<ExpenditurePerCreditType>(
            events.expenditureChanged.code,
            expenditureActions.laExpenditure,
            expenditureChangedValue
          );
          that.swal.success({ title: 'Import Successful.' });
          this.emitValidityObservable();
          this.formFeeService.recalculateFees();
          
          let attachmentPrefix = `project/${this.project.id}/application`;
          this.attachmentService.uploadExpenditures(fileSelected, attachmentPrefix, this.userContext.currentUser.id);
        },
        error => {
          // Clear the object when the import gives an error
          Object.assign(that.expenditures, new Expenditures());
          that.swal.error({
            title: 'Import Failed.',
            text:
              // tslint:disable-next-line:max-line-length
              'The import of the preliminary schedule of expenditures failed. Please use the template provided in this section and review data entered in template to ensure there is no blank data within required fields in the excel file.'
          });
        }
      );
    // Allow to upload the same file name again.
    event.target.value = '';
    that.addSubscriberToBeDestroy(sub);
  }

  //#region Labor Edit Mode Helper Functions
  editLaborExpenditure(info: LaborInfo, index: number) {
    this.selectedIndex = index;
    setTimeout(() => {
      const that = this;
      // Initializing Labor Form Group
      this.laborFormGroup = this.fb.group({
        laborEmployeeName: [info.employeeName || '', Validators.required],
        laborTitle: [info.jobTitle || null, Validators.required],
        laborJobDescription: info.jobDescription || null,
        laborLaResident: info.laResident || null,
        laborTotalSalary: info.totalSalary || null,
        laborAverageAllocation:
          +(info.averageAllocation * 100).toFixed(2) || null,
        laborPaymentType: info.paymentType || null,
        laborEligibilityStatus: info.eligibilityStatus || null,
        laborExistingOrNewHire: [
          info.existingNewHire || null,
          Validators.required
        ],
        laborJobCategory: [info.jobCategory || null, Validators.required],
        laborCount: [info.jobCount || null, Validators.required],
        laborYearDetails: this.fb.array([])
      });

      // Initializing Year Details Form Array
      info.yearDetails.forEach(yd => {
        that.laborYearDetails.push(
          that.fb.group({
            year: yd.year || null,
            salary: [
              yd.salaryAllocation || null,
              [Validators.pattern('\\d+\\.?\\d*'), Validators.required]
            ],
            allocation: [
              +(yd.percentAllocation * 100).toFixed(2) || null,
              [Validators.pattern('\\d+\\.?\\d*'), Validators.required]
            ],
            total: yd.salaryByJobCount || null
          })
        );
      });

      // Responding to the changes in the Labor Details
      that.addSubscriberToBeDestroy(
        that.laborFormGroup.get('laborCount').valueChanges.subscribe(val => {
          that.laborYearDetails.controls.forEach(lyd => {
            const yearTotal =
              +lyd.get('salary').value *
              (+lyd.get('allocation').value / +100) *
              +val;
            lyd.get('total').setValue(yearTotal);
          });
          const totalSalary = that.laborYearDetails.controls.reduce(
            (pv, cv, i) => {
              return +cv.get('total').value + pv;
            },
            0
          );
          that.laborFormGroup.get('laborTotalSalary').setValue(totalSalary);
        })
      );
      // Monitoring value changes in the year details
      this.laborYearDetails.controls.forEach(fg => {
        that.addSubscriberToBeDestroy(
          fg.get('salary').valueChanges.subscribe(val => {
            if (!(+val >= 0)) {
              fg.get('salary').setValue(0, {
                onlySelf: true,
                emitEvent: false
              });
              fg.get('salary').setErrors(null);
              fg.get('salary').updateValueAndValidity();
            }

            fg.get('total').setValue(
              +fg.get('salary').value *
                (+fg.get('allocation').value / +100) *
                +that.laborFormGroup.get('laborCount').value,
              {
                emitEvent: false
              }
            );

            const totalSalary = that.laborYearDetails.controls.reduce(
              (pv, cv, i) => {
                return +cv.get('total').value + pv;
              },
              0
            );
            that.laborFormGroup.get('laborTotalSalary').setValue(totalSalary);
          })
        );
        that.addSubscriberToBeDestroy(
          fg.get('allocation').valueChanges.subscribe(val => {
            // Average Allocation
            const averageAllocation =
              that.laborYearDetails.controls.reduce((pv, cv, i) => {
                return +cv.get('allocation').value + pv;
              }, 0) / that.laborYearDetails.controls.length;
            that.laborFormGroup
              .get('laborAverageAllocation')
              .setValue(averageAllocation.toFixed(2));

            // Total Salary

            const yearTotal =
              +fg.get('salary').value *
              +that.laborFormGroup.get('laborCount').value *
              (+val / +100);
            fg.get('total').setValue(yearTotal);

            const totalSalary = that.laborYearDetails.controls.reduce(
              (pv, cv, i) => {
                return +cv.get('total').value + pv;
              },
              0
            );
            that.laborFormGroup.get('laborTotalSalary').setValue(totalSalary);
          })
        );
      });
      this.disableValidation(this.laborFormGroup);
    }, 0);
  }

  saveLaborInfo() {
    const selectedLaborInfo = this.expenditures.laborInfo[this.selectedIndex];
    // Save Expenditure Details
    selectedLaborInfo.eligibilityStatus = this.laborFormGroup.get(
      'laborEligibilityStatus'
    ).value;
    selectedLaborInfo.existingNewHire = this.laborFormGroup.get(
      'laborExistingOrNewHire'
    ).value;
    selectedLaborInfo.jobCategory = this.laborFormGroup.get(
      'laborJobCategory'
    ).value;
    selectedLaborInfo.jobTitle = this.laborFormGroup.get('laborTitle').value;
    selectedLaborInfo.jobDescription = this.laborFormGroup.get(
      'laborJobDescription'
    ).value;
    selectedLaborInfo.jobCount = this.laborFormGroup.get('laborCount').value;
    selectedLaborInfo.employeeName = this.laborFormGroup.get(
      'laborEmployeeName'
    ).value;
    selectedLaborInfo.laResident = this.laborFormGroup.get(
      'laborLaResident'
    ).value;
    selectedLaborInfo.paymentType = this.laborFormGroup.get(
      'laborPaymentType'
    ).value;
    const averageAllocation = this.laborFormGroup.get('laborAverageAllocation')
      .value;
    selectedLaborInfo.averageAllocation =
      averageAllocation > 0 ? +(averageAllocation / 100).toFixed(2) : 0;
    selectedLaborInfo.totalSalary = this.laborFormGroup.get(
      'laborTotalSalary'
    ).value;

    // Save Year Details
    this.laborYearDetails.controls.forEach((yfg, i) => {
      selectedLaborInfo.yearDetails[i].salaryAllocation = yfg.get(
        'salary'
      ).value;
      const allocation = yfg.get('allocation').value;
      selectedLaborInfo.yearDetails[i].percentAllocation =
        allocation > 0 ? +(allocation / 100).toFixed(2) : 0;
      selectedLaborInfo.yearDetails[i].salaryByJobCount = yfg.get(
        'total'
      ).value;
    });
    this.pubSubService.publish(
      publishSubscribeEventStrings.refreshAllExpenditureTotals
    );
  }
  //#endregion

  //#region Non Labor Edit Mode Helper Functions
  editNonLaborExpenditure(info: NonLaborInfo, index: number) {
    this.selectedIndex = index;
    setTimeout(() => {
      const that = this;
      // Initializing Non Labor Form Group
      this.nonLaborFormGroup = this.fb.group({
        nonLaborItemName: [info.item || '', Validators.required],
        nonLaborItemDescription: info.description || null,
        nonLaborTotalItemCost: info.totalItemCost || null,
        nonLaborEligibilityStatus: info.eligibilityStatus || null,
        nonLaborYearDetails: this.fb.array([])
      });

      // Initializing Year Details Form Array
      info.yearDetails.forEach(yd => {
        that.nonLaborYearDetails.push(
          that.fb.group({
            year: yd.year || null,
            unitCost: [
              yd.unitCost || null,
              [Validators.pattern('\\d+\\.?\\d*'), Validators.required]
            ],
            count: [
              yd.itemCount || null,
              [Validators.pattern('\\d+'), Validators.required]
            ],
            total: yd.totalCost || null
          })
        );
      });

      // Monitoring value changes in the year details
      this.nonLaborYearDetails.controls.forEach(fg => {
        that.addSubscriberToBeDestroy(
          fg.valueChanges.subscribe(val => {
            if (!(+val.unitCost >= 0)) {
              fg.get('unitCost').setValue(0, {
                onlySelf: true,
                emitEvent: false
              });
              fg.get('unitCost').setErrors(null);
              fg.get('unitCost').updateValueAndValidity();
            }
            fg.get('total').setValue(+fg.get('unitCost').value * +val.count, {
              emitEvent: false
            });

            const totalSalary = that.nonLaborYearDetails.controls.reduce(
              (pv, cv, i) => {
                return +cv.get('total').value + pv;
              },
              0
            );
            that.nonLaborFormGroup
              .get('nonLaborTotalItemCost')
              .setValue(totalSalary);
          })
        );
      });
      this.disableValidation(this.nonLaborFormGroup);
    }, 0);
  }

  saveNonLaborInfo() {
    const selectedNonLaborInfo = this.expenditures.nonLaborInfo[
      this.selectedIndex
    ];
    // Save Expenditure Details
    selectedNonLaborInfo.eligibilityStatus = this.nonLaborFormGroup.get(
      'nonLaborEligibilityStatus'
    ).value;
    selectedNonLaborInfo.item = this.nonLaborFormGroup.get(
      'nonLaborItemName'
    ).value;
    selectedNonLaborInfo.description = this.nonLaborFormGroup.get(
      'nonLaborItemDescription'
    ).value;
    selectedNonLaborInfo.totalItemCost = this.nonLaborFormGroup.get(
      'nonLaborTotalItemCost'
    ).value;

    // Save Year Details
    this.nonLaborYearDetails.controls.forEach((yfg, i) => {
      selectedNonLaborInfo.yearDetails[i].unitCost = yfg.get('unitCost').value;
      selectedNonLaborInfo.yearDetails[i].itemCount = yfg.get('count').value;
      selectedNonLaborInfo.yearDetails[i].totalCost = yfg.get('total').value;
    });
    this.pubSubService.publish(
      publishSubscribeEventStrings.refreshAllExpenditureTotals
    );
  }

  //#endregion

  checkValidity(rate: number, index: number) {
    if (rate < 0 || !rate) {
      this.estimatedCreditsFormArray.controls[index].setErrors({
        negativeRate: true
      });
    } else {
      this.estimatedCreditsFormArray.controls[index].setErrors(null);
      this.expenditures.estimatedCredits[index].rate = +(rate / 100).toFixed(4);
    }
    this.pubSubService.publish(
      publishSubscribeEventStrings.refreshAllExpenditureTotals
    );
  }
  laborInfoChecked(info: LaborInfo, value: boolean) {
    if (value) {
      this.selectedLaborInfos.push(info);
    } else {
      this.selectedLaborInfos.splice(this.selectedLaborInfos.indexOf(info), 1);
    }
  }
  nonLaborInfoChecked(info: NonLaborInfo, value: boolean) {
    if (value) {
      this.selectedNonLaborInfos.push(info);
    } else {
      this.selectedNonLaborInfos.splice(
        this.selectedNonLaborInfos.indexOf(info),
        1
      );
    }
  }

  changeExpenditureStatus(status: string) {
    this.selectedLaborInfos.forEach(li => (li.eligibilityStatus = status));
    this.selectedNonLaborInfos.forEach(li => (li.eligibilityStatus = status));
    this.pubSubService.publish(
      publishSubscribeEventStrings.refreshAllExpenditureTotals
    );
    this.uncheckCheckAll(false);
    $('.uncheckAllCheckbox').prop('checked', false);
  }
  uncheckCheckAll(value: boolean) {
    if (value) {
      $('.expenditureRowCheckbox').prop('checked', true);
      this.selectedLaborInfos.push(...this.expenditures.laborInfo);
      this.selectedNonLaborInfos.push(...this.expenditures.nonLaborInfo);
    } else {
      $('.expenditureRowCheckbox').prop('checked', false);
      this.selectedLaborInfos.splice(0);
      this.selectedNonLaborInfos.splice(0);
    }
  }
  disableValidation(formGroup: FormGroup) {
    if (
      this.formShareService.form.type === formTypes.initialCert.abbrev ||
      this.formShareService.form.type === formTypes.audit.abbrev
    ) {
      (<any>Object).keys(formGroup.controls).forEach(formGroupPropName => {
        if (<Object>formGroup.controls.hasOwnProperty(formGroupPropName)) {
          // Parent Form Group controls
          if (formGroup.get(formGroupPropName) instanceof FormControl) {
            formGroup.get(formGroupPropName).clearValidators();
            formGroup.get(formGroupPropName).updateValueAndValidity();
          }
          // Parent Form Array
          if (formGroup.get(formGroupPropName) instanceof FormArray) {
            // tslint:disable-next-line:forin
            for (const controlIndex in (<FormArray>(
              formGroup.get(formGroupPropName)
            )).controls) {
              // FormGroup within FormArray
              // tslint:disable-next-line:forin
              for (const childPropName in (<FormGroup>(
                (<FormArray>formGroup.get(formGroupPropName)).get(controlIndex)
              )).controls) {
                // Sub FormGroup for FormArray formcontrol
                (<FormGroup>(
                  (<FormArray>formGroup.get(formGroupPropName)).get(
                    controlIndex
                  )
                ))
                  .get(childPropName)
                  .clearValidators();
                // Sub FormGroup for FormArray formcontrol
                (<FormGroup>(
                  (<FormArray>formGroup.get(formGroupPropName)).get(
                    controlIndex
                  )
                ))
                  .get(childPropName)
                  .updateValueAndValidity();
              }
            }
          }
        }
      });
    }
  }

  emitValidityObservable() {
    const that = this;
    this.invalidObservable = new Observable<boolean>(subscriber => {
      setTimeout(() => {
        subscriber.next(
          (that.expenditures &&
            (that.expenditures.laborInfo.length > 0 ||
              that.expenditures.nonLaborInfo.length > 0)) ||
            (this.formShareService.form.type === formTypes.initialCert.abbrev ||
              this.formShareService.form.type === formTypes.audit.abbrev)
            ? false
            : true
        );
        subscriber.complete();
        subscriber.unsubscribe();
      });
    });
  }
  addEstimatedJobsFormGroup() {
    const that = this;
    this.estimatedJobsFormGroup = this.fb.group({
      totalEmployees: [this.expenditures.totalEmployees, [Validators.required]]
    });
    const subdigitalFormGroup = this.estimatedJobsFormGroup.valueChanges.subscribe(
      value => {
        // Add event handlers to form to update data model
        that.expenditures.totalEmployees = value.totalEmployees;
      }
    );
    // Add subscriber that needs to be destroy
    this.addSubscriberToBeDestroy(subdigitalFormGroup);
    this.disableValidation(this.estimatedJobsFormGroup);
  }
  //#endregion
  // #region "System Methods"
  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
  ngOnInit() {
    const that = this;
    this.getProjectInfo();
    // Update Payment Types
    if (this.project.projectInfo.legacyUid) {
      this.paymentTypes.push(...oldPaymentTypes);
    }
    const eligibilityStatusObj = {
      code: 'eligibilityStatus',
      display: 'Eligibility Status'
    };

    this.addEstimatedJobsFormGroup();

    var upload = this.expenditures.expenditureTotal !== null ? true : false;

    if (this.editMode) {
      this.itemDetailsHeaders.unshift(eligibilityStatusObj);
      this.personDetailHeaders.unshift(eligibilityStatusObj);
      if (
        this.expenditures.estimatedCredits &&
        this.expenditures.estimatedCredits.length > 0
      ) {
        this.expenditures.estimatedCredits.forEach(ec => {
          that.estimatedCreditsFormArray.push(
            that.fb.group({ rate: '', updateOn: 'blur' })
          );
        });
        this.validationService.form.addControl(
          'estimatedCreditsRate',
          this.estimatedCreditsFormArray
        );
      }
    }
    else {
      this.initializeForm();
    }

    this.emitValidityObservable();
  }
  // #endregion
}
