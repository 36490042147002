import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { Project } from '../../project/models/project.model';
import { ProjectInfo } from '../../project/models/project-info.model';
import { EventType, events } from '../event/event.constants';
import { ProjectFormWrapper } from '../../project/models/project-form-wrapper.model';
import { BoardMeeting } from '../../board/models/board-meeting.model';
import { BoardAgendaProgramSummary } from '../../board/models/board-agenda-summary.model';

@Injectable()
export class PublishSubscribeService implements OnDestroy {
  // Observable sources
  private projectInformationSubject: Subject<ProjectInfo> = new Subject<
    ProjectInfo
  >();
  private projectSubject: Subject<Project> = new Subject<Project>();
  private subjects: Subject<any>[] = [];

  private handlers: {
    [eventType: string]: { [eventAction: string]: Subscription[] };
  } = {};

  // This is a 2d dictionary of subjects belonging to each EventType & Action
  private eventSubjects: {
    [eventType: string]: { [eventAction: string]: Subject<any> };
  } = {};

  // Observable streams
  projectUpdated$ = this.projectSubject.asObservable();

  on(eventName: string): Observable<any> {
    // ensure a subject for the event name exists
    this.subjects[eventName] = this.subjects[eventName] || new Subject<any>();

    // return observabl
    return this.subjects[eventName];
  }

  /**
   * Use this function to raise events with their respective actions
   * @param eventType
   * @param eventAction
   * @param data
   */
  raise<T>(eventType: EventType, eventAction: string, data: T): Subject<any> {
    // Ensure that the eventType and action subject exists in the dictionary
    this.initializeEventActionSubjects(eventType, eventAction);

    // Initializing arrays for brand new eventTypes and eventActions
    this.initializeEventActionHandlers(eventType, eventAction);

    // Publish the data required to handle the event
    const subjectRaised = this.eventSubjects[eventType][eventAction];
    subjectRaised.next(data);
    return subjectRaised;
  }

  /**
   * Use this function to handle actions of certain events
   * @param eventType Event Type
   * @param eventAction Event Action
   * @param next Handler of Event Type and Action
   * @param error (optional) Error Handler
   */
  handle<T>(
    eventType: EventType,
    eventAction: string,
    next: (object: T) => void,
    error?: (error: any) => void
  ) {
    // Ensure that the eventType and action subject exists in the dictionary
    this.initializeEventActionSubjects(eventType, eventAction);

    // Initializing arrays for brand new eventTypes and eventActions
    this.initializeEventActionHandlers(eventType, eventAction);

    // Subscribe to subject with provided next and error handlers
    const subscription = this.eventSubjects[eventType][eventAction].subscribe(
      next,
      error
    );

    // Add the new subscription to the array of handlers for this service to maintain all handlers
    this.handlers[eventType][eventAction].push(subscription);

    // Return the subscription in case the calling object gets instantiated multiple times through out
    // the lifecycle of the application and it has to unsubscribe onDestroy()
    return subscription;
  }

  /**
   * Use this function to handle all actions for the events provided
   * @param eventTypes All Event Types to subscribe to all respective actions
   * @param next Handler of Event Type and Action
   * @param error (optional) Error Handler
   */
  handleAllActionsForEvents<T>(
    eventTypes: EventType[],
    next: (object: T) => void,
    error?: (error: any) => void
  ) {
    const subscriptions: Subscription[] = [];

    eventTypes.forEach(et => {
      events[et].actions.forEach(a => {
        // Ensure all necessary objects are initialized
        this.initializeEventActionSubjects(et, a);
        this.initializeEventActionHandlers(et, a);

        // Grab Subscription
        const subscription = this.eventSubjects[et][a].subscribe(next, error);

        // Add new handler
        this.handlers[et][a].push(subscription);

        // Add subscription to array which will be returned to calling object
        subscriptions.push(subscription);
      });
    });

    // Return the created subscriptions for optional handling
    return subscriptions;
  }

  initializeEventActionSubjects<T>(eventType: EventType, eventAction: string) {
    if (!this.eventSubjects[eventType]) {
      this.eventSubjects[eventType] = {
        [eventAction]: new Subject<T>()
      };
    } else if (!this.eventSubjects[eventType][eventAction]) {
      this.eventSubjects[eventType][eventAction] = new Subject<T>();
    }
  }

  initializeEventActionHandlers(eventType: EventType, eventAction: string) {
    if (!this.handlers[eventType]) {
      this.handlers[eventType] = {
        [eventAction]: []
      };
    } else if (!this.handlers[eventType][eventAction]) {
      this.handlers[eventType][eventAction] = [];
    }
  }

  publish(eventName: string) {
    // ensure a subject for the event name exists
    this.subjects[eventName] = this.subjects[eventName] || new Subject<any>();

    // publish event
    this.subjects[eventName].next();
  }

  ngOnDestroy() {
    // Unsubscribe to all the subscriptions in the handler.
    Object.keys(this.handlers).forEach(key => {
      Object.keys(this.handlers[key]).forEach(subkey => {
        this.handlers[key][subkey].forEach(subscription => {
          subscription.unsubscribe();
        });
      });
    });
  }
}
