import { StepApplication } from './step-application.model';
import { ProjectBase } from '../../../project/models/project-base.model';
import { ProjectInfo } from './project-info.model';
import { StepProject } from '../../interfaces/project.interface';

export class Project extends ProjectBase implements StepProject {
  stepApplication: StepApplication[];
  projectInfo: ProjectInfo;

  constructor() {
    super();
  }


  init(): Project {
    // Initialize the base class ProjectBase.
    super.init();

    if (this.stepApplication) {
      this.stepApplication = this.stepApplication.map(stepApplication => {
        stepApplication = Object.assign(new StepApplication(), stepApplication);
        stepApplication.init();
        return stepApplication;
      });
    }

    // ProjectInfo in ProjectBase is type ProjectInfoBase.
    // So, Super.init() takes care of initializing ProjectInfoBase in ProjectBase.
    // Here we just use that initialized projectInfoBase and construct FilmProjectInfo.
    this.projectInfo = new ProjectInfo(this.projectInfo);

    return this;
  }
}
