import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from '../security/services/authentication.service';
import { UserContextService } from '../user/services/user-context.service';
import { EmailService } from '../email/services/email.service';
import { GuestGuard } from '../security/guards/guest.guard';
import { PublishSubscribeService } from './services/publish-subscribe.service';
import { AuthorizationService } from '../security/services/authorization.service';
import { ServerSentEventService } from './services/server-sent-event.service';
import { DatatablesService } from './services/datatables.service';
import { SwalService } from './services/swal.service';
import { NavService } from './services/nav.service';
import { LookupHttpService } from './services/lookup-http.service';
import { DownloadService } from './services/download.service';
import { VersionService } from './services/version.service';
import { DateTimePickerComponent } from '../shared/components/date-time-picker/date-time-picker.component';
import { SharedModule } from '../shared/shared.module';
import { WebWorkerService } from './services/web-worker.service';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [],
  entryComponents: [DateTimePickerComponent],
  providers: [
    UserContextService,
    AuthorizationService,
    AuthenticationService,
    GuestGuard,
    PublishSubscribeService,
    DatatablesService,
    SwalService,
    NavService,
    LookupHttpService,
    DownloadService,
    VersionService,
    WebWorkerService,
    ServerSentEventService,
    EmailService
  ]
})
export class FastlaneCommonModule {}
