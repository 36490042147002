export class EzProjectDetails {
  blockGroup: string;
  censusTract: string;
  companyDescription: string;
  countofEmployeesReceivingPublicAssistance: number;
  establishmentYear: number;
  ezType: string;
  isProjectLocatedInEnterpriseZone: boolean;
  ownershipType: string;

  constructor(details?: any) {
    details = details || {};
    this.blockGroup = details.blockGroup || null;
    this.censusTract = details.censusTract || null;
    this.companyDescription = details.companyDescription || null;
    this.countofEmployeesReceivingPublicAssistance =
      details.isProjectLocatedInEnterpriseZone || null;
    this.establishmentYear = details.establishmentYear || null;
    this.ezType = details.ezType || null;
    this.isProjectLocatedInEnterpriseZone =
      details.isProjectLocatedInEnterpriseZone || null;
    this.ownershipType = details.ownershipType || null;
  }
}
