<div class="d-flex justify-content-center align-items-center animated fadeInUp">
  <div class="d-flex flex-column col-sm-12 col-md-10 col-lg-9 col-xl-6">
    <div class="text-center">
      <h2 class="text-primary">Applicant User Registration</h2>
      <p>If you are a Local Governing Authority or a Parish Assessor, please contact LED at <br>225-342-3000 for your account information. </p>
    </div>

    <div class="dropdown-divider my-2"></div>

    <form novalidate>
      <!-- DETAILS SECTION -->
      <shr-person *ngIf="user" [form]="registerForm" [person]="user" [addressRequired]="false"></shr-person>

      <div class="dropdown-divider my-2"></div>

      <!-- PASSWORD SECTION -->
      <div class='d-flex flex-wrap py-1'>
        <label class="col-12 col-lg-4 form-control-label">Password:</label>
        <div class="col-12 col-lg-8">
          <fl-new-password-input [parentFormGroup]="registerForm"></fl-new-password-input>
        </div>
      </div>

      <div class="dropdown-divider my-2"></div>

      <!-- BUTTON SECTION -->
      <div class="w-100 d-flex flex-wrap flex-row-reverse">
        <div class="col-md-12 col-lg-6 my-2 order-2">
          <a class="btn btn-light w-100 border border-primary" [routerLink]="['../login']">
            <span *ngIf="!registered">Already have an account?</span>
            <span class="font-weight-bold" [ngClass]="{'animated pulse': registered}">Log In</span>
          </a>
        </div>

        <div class="col-md-12 col-lg-6 my-2 order-1">
          <button type="submit" class="btn w-100 font-weight-bold" [ngClass]="{'btn-secondary': !registering && !registered, 'btn-warning': registering, 'btn-success animated pulse': registered}"
            [disabled]="registerForm.invalid || registering || registered" (click)="register()">
            <span *ngIf="!registering && !registered">Register</span>
            <span *ngIf="registering">Registering
              <i class="ml-2 fa fa-spinner fa-spin"></i>
            </span>
            <span *ngIf="registered">Registered
              <i class="ml-2 fa fa-check"></i>
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>