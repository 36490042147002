import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PendingPaymentsComponent } from './components/pending-payments/pending-payments.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { UnclassifiedPaymentsComponent } from './components/unclassified-payments/unclassified-payments.component';
import { RouterModule } from '@angular/router';
import { OutgoingPaymentsComponent } from './components/outgoing-payments/outgoing-payments.component';
import { ManagementPaymentsComponent } from './layouts/management-payments/management-payments.component';
import { OnlinePaymentsComponent } from './components/online-payments/online-payments.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ProjectModule } from '../project/project.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
    ProjectModule
  ],
  declarations: [
    PaymentsComponent,
    PendingPaymentsComponent,
    UnclassifiedPaymentsComponent,
    OutgoingPaymentsComponent,
    ManagementPaymentsComponent,
    OnlinePaymentsComponent
  ],
  exports: [
    PendingPaymentsComponent,
    OutgoingPaymentsComponent,
    PaymentsComponent
  ]
})
export class FiscalModule {}
