import { Contact } from '../../../project/models/project-contact.model';

export class FinalCertificationRecipient extends Contact {
  laTaxId: string;
  finalCertificationRecipientType: string;

  constructor() {
    super();
  }

  init() {
    super.init();
    this.laTaxId = this.laTaxId || null;
    this.finalCertificationRecipientType =
      this.finalCertificationRecipientType || null;
    return this;
  }
}
