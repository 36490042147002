import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  projectStatuses,
  IncentiveProgram,
  programChangedActions,
  incentiveCategory
} from '../../../project/project.constants';

import { getTypesArrayForPrograms } from '../../../project/project.functions';
import { PublishSubscribeService } from '../../../fastlane-common/services/publish-subscribe.service';
import { events } from '../../../fastlane-common/event/event.constants';
import { UserContextService } from '../../../user/services/user-context.service';
import { NgMultiSelectOption } from '../../interfaces/ng-multi-select-option.interface';

@Component({
  selector: 'fl-shr-project-status-multiselect',
  templateUrl: './project-status-multiselect.component.html',
  styleUrls: ['./project-status-multiselect.component.scss']
})
export class ProjectStatusMultiselectComponent implements OnInit {
  //#region Settings
  dropdownSettings = {
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  //#endregion

  @Input()
  disabled = false;
  @Output()
  change: EventEmitter<NgMultiSelectOption[]> = new EventEmitter<NgMultiSelectOption[]>();
  @Input()
  defaults: string[] = [];

  programs: IncentiveProgram[];

  // Get list of projectStatus from constants
  localProjectStatuses: NgMultiSelectOption[] = projectStatuses.map(p => {
    return {
      id: p.name,
      text: p.name
    };
  });

  selectedProjectStatuses: string[] = [];
  selectedProjectStatusObjects: NgMultiSelectOption[] = [];

  constructor(
    private pubSubService: PublishSubscribeService,
    private userContext: UserContextService
  ) {}

  ngOnInit() {
    const that = this;
    // populate the drop down options based on the context.
    that.populateDropDownOptions(
      that.programs
        ? that.programs
        : ((that.userContext.currentUser.incentivePrograms
            ? that.userContext.currentUser.incentivePrograms
            : incentiveCategory.business
                .incentivePrograms) as IncentiveProgram[])
    );
    this.pubSubService.handle<IncentiveProgram[]>(
      events.selectedProgramsChanged.code,
      programChangedActions.programChanged,
      programs => {
        that.programs = programs;
        that.populateDropDownOptions(programs);
      }
    );
  }

  populateDropDownOptions(programsArray: IncentiveProgram[]) {
    const that = this;
    // use the map below to get the project status map based on the incentiveprograms.
    // also use map to create the dropdownoptionsObjects for the multiselect.
    const projectStatusesByProgram = getTypesArrayForPrograms(
      programsArray,
      'projectStatus'
    ).map((p: string) => {
      return <NgMultiSelectOption>{ id: p, text: p };
    });
    // set local projectstatuses based on the programs.
    if (projectStatusesByProgram.length > 0) {
      this.localProjectStatuses = projectStatusesByProgram;
    }
    if (that.defaults.length !== 0) {
      this.selectedProjectStatusObjects = this.localProjectStatuses.filter(ft =>
        that.defaults.includes(ft.id)
      );
      this.selectedProjectStatuses = this.selectedProjectStatusObjects.map(
        s => s.id
      );
    }
  }

  onSelectionChange(
    selectedProjectStatus: NgMultiSelectOption,
    event: 'select' | 'deselect'
  ) {
    // Handle appropiate selection event to modify the selectedParishes array.
    if (event === 'select') {
      this.selectedProjectStatuses.push(selectedProjectStatus.text);
    } else {
      this.selectedProjectStatuses.splice(
        this.selectedProjectStatuses.indexOf(selectedProjectStatus.text),
        1
      );
    }

    // Emit new parishes
    this.change.emit(this.selectedProjectStatusObjects);
  }

  onSelectAll(selectedProjectStatuses: any[]) {
    this.selectedProjectStatuses = selectedProjectStatuses.map(sps => sps.text);
    this.selectedProjectStatusObjects.splice(0);
    this.selectedProjectStatusObjects.push(...selectedProjectStatuses);
    // Emit new project statuses
    this.change.emit(this.selectedProjectStatusObjects);
  }

  parseSelectedProjectStatuses(selectedProjectStatuses: string[]) {
    // Parse Parishes to comply with ng-multiselect ngModel
    if (selectedProjectStatuses && selectedProjectStatuses.length > 0) {
      this.selectedProjectStatusObjects = selectedProjectStatuses.map(p => {
        return {
          id: p,
          text: p
        };
      });
    } else {
      this.selectedProjectStatusObjects = [];
    }

    if (selectedProjectStatuses) {
      // Set selected parishes property
      this.selectedProjectStatuses = selectedProjectStatuses;
    } else {
      this.selectedProjectStatuses = [];
    }
  }
}
