import swal from 'sweetalert2';
import { extend } from 'underscore';
import * as i0 from "@angular/core";
export class ToastService {
    constructor() { }
    get defaults() {
        const options = {
            cancelButtonColor: '#046993',
            confirmButtonColor: '#1AB394',
            toast: true,
            timer: 3000,
            position: 'top-end'
        };
        return options;
    }
    error(options) {
        options = extend(this.defaults, options, {
            type: 'error'
        });
        return swal(options);
    }
    queue(options) {
        const that = this;
        options = extend(this.defaults, options, {
            type: 'success'
        });
        // if no existing promise, create a new one and capture it
        if (that.lastToastPromise === null || that.lastToastPromise === undefined) {
            that.lastToastPromise = swal(options);
        }
        else {
            // Configure Promise to get next in queue
            that.lastToastPromise = that.lastToastPromise.then(value => {
                // Make this new toast, the last one
                return swal(options);
            });
        }
    }
    // Shows a sticky toast, must provide title and additional configuration options
    sticky(options) {
        options = extend(this.defaults, options, {
            timer: 0
        });
        return swal(options);
    }
    success(options) {
        options = extend(this.defaults, options, {
            type: 'success'
        });
        return swal(options);
    }
}
ToastService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToastService_Factory() { return new ToastService(); }, token: ToastService, providedIn: "root" });
