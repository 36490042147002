import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { Attachment } from '../models/attachment.model';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../security/services/authentication.service';
import { Project } from '../models/project.model';
import { UserDataService } from '../../user/services/user-data.service';
import { LookupHttpService } from '../../fastlane-common/services/lookup-http.service';
import { EmailService } from '../../email/services/email.service';

@Injectable({ providedIn: 'root' })
export class AttachmentsService {

  needRefresh: Subject<void> = new Subject<void>();
  addExpenditure: Subject<void> = new Subject<void>();
  newAttachments: string[] = [];
  newAttachmentsGeneration: number[] = [];
  newExpenditure: Attachment;

 
  constructor(
    private authService: AuthenticationService,
    private http: HttpClient,
    private userDataService: UserDataService,
    private lookupService: LookupHttpService,
    private emailService: EmailService
  ) { }

  addToNewAttachments(attachment: Attachment) {
    this.newAttachments.push(attachment.documentName.toString());
    this.newAttachmentsGeneration.push(attachment.generation);
  }  

  removeFromNewAttachments() {
    this.newAttachments = [];
    this.newAttachmentsGeneration = [];
  }

  callRefresh() {
    this.needRefresh.next();
  }


  emailNewAttachments(project: Project) {
    const that = this;
    var recipients = [];

    return new Observable<any>(subscriber => {
      this.userDataService.getUserByEmail("amanda.hafford@la.gov").subscribe(user => {
        recipients.push(user);
        this.lookupService.getProgramManager(project.projectInfo.incentiveProgram).subscribe(programManager => {
          recipients.push(programManager);
          that.emailService.sendDMattachmentsUploadedEmail(project.projectInfo.projectId, recipients, this.newAttachments, project.id)
            .subscribe(
              successfulEmail => {
                this.removeFromNewAttachments();
                subscriber.next();
                subscriber.complete();
              },
              errorEmailing => {
                subscriber.error(errorEmailing);
              }
            );
        });
      });      
    });
    
  }

  uploadExpenditures(attachment: File, attachmentPrefix: string, userContext: string) {
    const uploadFileUrl = `${environment.apiUrl}/file/upload/${userContext}?filenamePrefix=${encodeURIComponent(attachmentPrefix)}`;
    const headers = { 'Authorization': this.authService.getAuthHeaderValue() };
    let fileData: FormData = new FormData();
    fileData.append('files', attachment);
    this.http.post(uploadFileUrl, fileData, { headers })
      .subscribe(response => {
        this.newExpenditure = response[0];
        this.newExpenditure.documentName = attachment.name;
        this.newExpenditure.documentType = 'Preliminary Schedule of Expenditures';
        this.addExpenditure.next();
      });
  }
}
