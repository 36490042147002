/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./parish-multiselect.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ng-multiselect-dropdown/ng-multiselect-dropdown.ngfactory";
import * as i3 from "ng-multiselect-dropdown";
import * as i4 from "@angular/forms";
import * as i5 from "./parish-multiselect.component";
var styles_ParishMultiselectComponent = [i0.styles];
var RenderType_ParishMultiselectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ParishMultiselectComponent, data: {} });
export { RenderType_ParishMultiselectComponent as RenderType_ParishMultiselectComponent };
export function View_ParishMultiselectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "ng-multiselect-dropdown", [["name", "city"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "onDeSelectAll"], [null, "onSelectAll"], [null, "onSelect"], [null, "onDeSelect"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = ((_co.selectedParishObjects = $event) !== false);
        ad = (pd_1 && ad);
    } if (("onDeSelectAll" === en)) {
        var pd_2 = (_co.onSelectAll($event) !== false);
        ad = (pd_2 && ad);
    } if (("onSelectAll" === en)) {
        var pd_3 = (_co.onSelectAll($event) !== false);
        ad = (pd_3 && ad);
    } if (("onSelect" === en)) {
        var pd_4 = (_co.onSelectionChange($event, "select") !== false);
        ad = (pd_4 && ad);
    } if (("onDeSelect" === en)) {
        var pd_5 = (_co.onSelectionChange($event, "deselect") !== false);
        ad = (pd_5 && ad);
    } return ad; }, i2.View_MultiSelectComponent_0, i2.RenderType_MultiSelectComponent)), i1.ɵdid(2, 49152, null, 0, i3.MultiSelectComponent, [i1.ChangeDetectorRef], { placeholder: [0, "placeholder"], disabled: [1, "disabled"], settings: [2, "settings"], data: [3, "data"] }, { onSelect: "onSelect", onDeSelect: "onDeSelect", onSelectAll: "onSelectAll", onDeSelectAll: "onDeSelectAll" }), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MultiSelectComponent]), i1.ɵdid(4, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], isDisabled: [1, "isDisabled"], model: [2, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(6, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "Filter by Parish"; var currVal_8 = _co.disabled; var currVal_9 = _co.dropdownSettings; var currVal_10 = _co.parishes; _ck(_v, 2, 0, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = "city"; var currVal_12 = _co.disabled; var currVal_13 = _co.selectedParishObjects; _ck(_v, 4, 0, currVal_11, currVal_12, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ParishMultiselectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shr-parish-multiselect", [], null, null, null, View_ParishMultiselectComponent_0, RenderType_ParishMultiselectComponent)), i1.ɵdid(1, 114688, null, 0, i5.ParishMultiselectComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ParishMultiselectComponentNgFactory = i1.ɵccf("shr-parish-multiselect", i5.ParishMultiselectComponent, View_ParishMultiselectComponent_Host_0, { disabled: "disabled" }, { change: "change" }, []);
export { ParishMultiselectComponentNgFactory as ParishMultiselectComponentNgFactory };
