import { ProjectInfoBase } from '../../../project/models/project-info-base.model';
import { Address } from '../../../shared/models/address.model';
import { EntertainmentId } from '../../interfaces/ent-id.interface';

export class ProjectInfo extends ProjectInfoBase implements EntertainmentId {
    filmId: string;
    permanentMailingAddress: Address;
    entId: string;

    constructor(info?: any) {
        info = info || {};

        super(info);

        this.filmId = info.filmId || null;
        this.permanentMailingAddress = new Address(info.permanentMailingAddress) || new Address();
        this.entId = this.filmId;
      }
}