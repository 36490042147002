import { Component, OnInit, Input } from '@angular/core';
import { Credit } from '../../models/film/credit.model';
import { ProjectForm } from '../../../project/models/form.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formTypes } from '../../../form/form.constants';
import { Application } from '../../models/film/film-application.model';
import { InitialCertification } from '../../models/film/initial-certification.model';
import {
  logoRequirements,
  careerLearningTypes,
  creditTypes,
  baseCreditTypes
} from '../../../entertainment/entertainment.constants';
import { FormIdentifier } from '../../../project/models/form-identifier.model';
import { FormValidationService } from '../../../form/services/validation.service';
import { ExpenditurePerCreditType } from '../../../entertainment/credit.constants';
import { PublishSubscribeService } from '../../../fastlane-common/services/publish-subscribe.service';
import { events } from '../../../fastlane-common/event/event.constants';
import {
  getCalculatedCreditAmount,
  updateCreditTypesAndCreditsOnExpenditureChanged
} from '../../../entertainment/credit.functions';
import * as _ from 'underscore';
import {
  vfxActions,
  legislationRuleNames
} from '../../../project/project.constants';
import { ProjectDetailService } from '../../../project/services/project-shared.service';
import { VisualEffectsCompany } from '../../../fastlane-common/models/lookup/visual-effects-company.model';
import { LookupHttpService } from '../../../fastlane-common/services/lookup-http.service';
declare var $: any;

@Component({
  selector: 'fl-film-application',
  templateUrl: './film.component.html',
  styleUrls: ['./film.component.css']
})
export class FilmApplicationComponent implements OnInit {
  @Input() form: ProjectForm;
  @Input() hideCredits = false;
  hideDeposit = false;
  application: Application;
  logoRequirements = logoRequirements;
  careerLearningTypes = careerLearningTypes;
  currentFormId: FormIdentifier;
  isOtherLogoReq = false;
  isOtherCareerLearning = false;
  minimum3PaidPosition = false;
  minimum8HoursClassroomWorkshop = false;
  minimum8HoursStudioEmployment = false;
  minimum8HoursContinuingEducation = false;
  financialContribution = false;
  filmApplicationForm: FormGroup;
  qualifiedEntertainmentCompanies: VisualEffectsCompany[];
  showTextBox = false;
  constructor(
    private formBuilder: FormBuilder,
    private pubsubService: PublishSubscribeService,
    private validationService: FormValidationService,
    private projectDetailService: ProjectDetailService,
    private lookupService: LookupHttpService
  ) {
    const that = this;
    // Go get active companies
    lookupService.getActiveVisualEffectsCompanies().subscribe(vfxCompanies => {
      that.qualifiedEntertainmentCompanies = vfxCompanies;
    });
  }

  ngOnInit() {
    // Check if legislation rule is 'Pre July 2015 to always hide *audit deposit*'
    if (
      this.projectDetailService.projectBase.projectInfo.legislation ===
      legislationRuleNames.film.preJuly2015.name
    ) {
      this.hideDeposit = true;
    } else {
      this.hideDeposit = this.hideCredits;
    }

    const that = this;
    // Enable Legend Popover
    this.enableLegendPopover();
    if (
      this.form.type === formTypes.application.abbrev ||
      this.form.type === formTypes.asa.abbrev
    ) {
      this.application = <Application>this.form;
    } else if (this.form.type === formTypes.initialCert.abbrev) {
      this.application = <InitialCertification>this.form;
    }
    this.isOtherLogoReq = this.application.otherLogoReqDescription
      ? true
      : false;
    this.isOtherCareerLearning = this.application.otherCareerLearningDescription
      ? true
      : false;
    this.currentFormId = new FormIdentifier(<FormIdentifier>{
      formType: this.application.type,
      formIndex: this.application.formIndex,
      projectGuid: null
    });

    this.filmApplicationForm = this.formBuilder.group({
      parentCompanyName: [
        this.application.parentCompanyName,
        Validators.required
      ],
      isVisualEffectsinLA: [
        this.application.isVisualEffectsinLA,
        Validators.required
      ],
      isVisualEffectsQualifiedCompany: [
        this.application.isVisualEffectsQualifiedCompany,
        Validators.nullValidator
      ],
      visualEffectsCompanyName: [
        this.application.visualEffectsCompanyName,
        Validators.nullValidator
      ],
      logoRequirementType: [
        this.application.logoRequirementType,
        Validators.required
      ],
      careerLearningType: [
        this.application.careerLearningType,
        Validators.required
      ],
      otherLogoReqDescription: [this.application.otherLogoReqDescription],
      otherCareerLearningDescription: [
        this.application.otherCareerLearningDescription
      ]
    });
    this.filmApplicationForm.valueChanges.subscribe(value => {
      this.application.parentCompanyName = value.parentCompanyName;
      this.application.isVisualEffectsinLA = value.isVisualEffectsinLA;
      this.application.isVisualEffectsQualifiedCompany =
        value.isVisualEffectsQualifiedCompany;
      this.application.visualEffectsCompanyName =
        value.visualEffectsCompanyName;
      this.application.otherLogoReqDescription = value.otherLogoReqDescription;
      this.application.otherCareerLearningDescription =
        value.otherCareerLearningDescription;
    });

    this.validationService.form.setControl(
      'filmApplicationFormGroup',
      this.filmApplicationForm
    );

    // When ever expenditure is changed, this will update the creditTypes.
    this.pubsubService.handleAllActionsForEvents<ExpenditurePerCreditType>(
      [events.expenditureChanged.code],
      expenditureChangedValue => {
        // Update creditTypes only if this is the application or asa.
        // If this is initial cert then expenditure changes are handled differently
        // in initialcert Component.
        if (
          that.form.type === formTypes.application.abbrev ||
          that.form.type === formTypes.asa.abbrev
        ) {
          updateCreditTypesAndCreditsOnExpenditureChanged(
            that.application.estimatedCreditTypes,
            expenditureChangedValue
          );
        }
      }
    );

    if (this.application.isVisualEffectsinLA) {
      this.filmApplicationForm
        .get('visualEffectsCompanyName')
        .setValidators([Validators.required]);
      this.filmApplicationForm
        .get('visualEffectsCompanyName')
        .updateValueAndValidity();
      if (!this.application.isVisualEffectsQualifiedCompany) {
        this.showTextBox = true;
      }
    }
  }

  changeLogoType(type: any) {
    this.application.logoRequirementType = type;
    if (type === 'Other') {
      this.isOtherLogoReq = true;
    } else {
      this.isOtherLogoReq = false;
    }
  }

  changeCareerLearningType(type: any) {
    this.application.careerLearningType = type;
    if (type === 'Other') {
      this.isOtherCareerLearning = true;
    } else {
      this.isOtherCareerLearning = false;
    }
  }

  isVisualEffectsQualifiedCompanyChanged(value: boolean) {
    this.showTextBox = !value;
    this.filmApplicationForm.get('visualEffectsCompanyName').setValue(null);
    this.filmApplicationForm.get('visualEffectsCompanyName').updateValueAndValidity();
  }

  enableLegendPopover() {
    $(function() {
      $('[data-toggle="popover"]').popover();
    });
  }

  radioChanged(value: boolean) {
    const that = this;
    if (value) {
      // Setting required validators for the form controls
      // when vfx is based in LA.
      that.filmApplicationForm
        .get('isVisualEffectsQualifiedCompany')
        .setValidators([Validators.required]);
      that.filmApplicationForm
        .get('visualEffectsCompanyName')
        .setValidators([Validators.required]);
      that.filmApplicationForm
        .get('isVisualEffectsQualifiedCompany')
        .updateValueAndValidity();
      that.filmApplicationForm
        .get('visualEffectsCompanyName')
        .updateValueAndValidity();

      // Raise the event to refresh the expenditure.
      that.pubsubService.raise<boolean>(
        events.vfxDetailsChanged.code,
        vfxActions.vfxinLAChaged,
        true
      );
    } else {
      // Resetting the values when vfxinLA is false.
      that.filmApplicationForm
        .get('isVisualEffectsQualifiedCompany')
        .setValue(null);
      that.filmApplicationForm.get('visualEffectsCompanyName').setValue(null);
      // Clearing the validators.
      that.filmApplicationForm
        .get('isVisualEffectsQualifiedCompany')
        .clearValidators();
      that.filmApplicationForm
        .get('visualEffectsCompanyName')
        .clearValidators();

      // Updating the value and validity.
      that.filmApplicationForm
        .get('isVisualEffectsQualifiedCompany')
        .updateValueAndValidity();
      that.filmApplicationForm
        .get('visualEffectsCompanyName')
        .updateValueAndValidity();

      // raising the event to publish and refresh expenditures.
      that.pubsubService.raise<boolean>(
        events.vfxDetailsChanged.code,
        vfxActions.vfxinLAChaged,
        false
      );
    }
  }
}
