export class Signature {
  public attestationStatement: boolean;
  public accuratelyAnsweredQuestions: boolean;
  public meetsStateCertifiedProd: boolean;
  public doNotMaintainRecords: boolean;
  public digitalSignature: string;
  public signatureDate: Date;
  public signeeName: string;

  constructor() {}

  init(
    attestationStatement?: boolean,
    digitalSignature?: string,
    signatureDate?: Date,
    accuratelyAnsweredQuestions?: boolean,
    meetsStateCertifiedProd?: boolean,
    doNotMaintainRecords?: boolean
  ): Signature {
    this.attestationStatement =
      attestationStatement || this.attestationStatement || false;
    this.accuratelyAnsweredQuestions =
      accuratelyAnsweredQuestions || this.accuratelyAnsweredQuestions || false;
    this.meetsStateCertifiedProd =
      meetsStateCertifiedProd || this.meetsStateCertifiedProd || false;
    this.doNotMaintainRecords =
      doNotMaintainRecords || this.doNotMaintainRecords || false;
    this.digitalSignature = digitalSignature || this.digitalSignature || null;
    this.signeeName = this.signeeName || null;
    this.signatureDate = signatureDate
      ? new Date(signatureDate)
      : this.signatureDate
      ? new Date(this.signatureDate)
      : null;

    return this;
  }
}
