import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectorRef
} from '@angular/core';
import { Days } from '../../../models/film/days.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormValidationService } from '../../../../form/services/validation.service';
import { events } from '../../../../fastlane-common/event/event.constants';
import { PublishSubscribeService } from '../../../../fastlane-common/services/publish-subscribe.service';
import { FormShareService } from '../../../../form/services/share.service';
import { formTypes } from '../../../../form/form.constants';

@Component({
  selector: 'fl-principal-photography-days',
  templateUrl: './principal-photography-days.component.html',
  styleUrls: ['./principal-photography-days.component.css']
})
export class PrincipalPhotographyDaysComponent implements OnInit {
  @Input() days: Days;
  daysForm: FormGroup;
  private formGroupSubscription: Subscription;
  isAudit: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private validationService: FormValidationService,
    private pubSubService: PublishSubscribeService,
    private formService: FormShareService
  ) {}

  ngOnInit() {
    this.isAudit =
      this.formService.formId.formType === formTypes.audit.abbrev
        ? true
        : false;
    this.daysForm = this.formBuilder.group({
      totalDaysInLA: [this.days.totalDaysInLA, Validators.required],
      totalPrincipalPhotographyDaysInLA: [
        this.days.totalPrincipalPhotographyDaysInLA,
        Validators.required
      ],
      totalPhotographyDaysOutside: [
        this.days.totalPhotographyDaysOutside,
        Validators.required
      ]
    });
    const that = this;
    this.formGroupSubscription = this.daysForm.valueChanges.subscribe(value => {
      Object.assign(that.days, value);
      that.days.percentPhotographyDaysOutside =
        that.days.totalPrincipalPhotographyDaysInLA === 0 ||
        !that.days.totalPrincipalPhotographyDaysInLA
          ? 0
          : that.days.totalPhotographyDaysOutside /
            that.days.totalPrincipalPhotographyDaysInLA;
      that.pubSubService.raise<any>(
        events.creditEligibilityChanged.code,
        'percentPhotographyDaysOutside',
        that.days.percentPhotographyDaysOutside
      );
    });

    this.validationService.form.setControl('days', this.daysForm);

    this.cdr.detectChanges();
  }
}
