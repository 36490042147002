<div class="card h-100 border">
  <div class="card-header">
    <b>ESTIMATED EXPENDITURES</b>
  </div>

  <div
    class="card-body form-group px-3 mb-0 d-flex flex-wrap flex-column justify-content-between no-borders"
    [formGroup]="expendituresForm"
  >
    <div class="row py-1 w-100 mx-0">
      <label class="col-12 col-xl-6 form-control-label pr-0"
        >Estimated Total Budget:</label
      >
      <div class="col-12 col-xl-6 float-right">
        <input
          id="totalBudget"
          formControlName="totalBudget"
          class="form-control"
          currencyMask
          [options]="{ align: 'left', precision: 2 }"
          placeholder="$"
          pattern="\$? ?(\d{0,3},?)+([.](\d)+)?"
        />
      </div>
    </div>

    <div class="row py-1 w-100 mx-0">
      <label class="col-12 col-xl-6 form-control-label pr-0"
        >Estimated Louisiana Above the Line Salaries:</label
      >
      <div class="col-12 col-xl-6 float-right">
        <input
          id="aboveLineSalaries"
          formControlName="aboveLineSalaries"
          class="form-control"
          placeholder="$"
          pattern="\$? ?(\d{0,3},?)+([.](\d)+)?"
          currencyMask
          [options]="{ align: 'left', precision: 2 }"
        />
      </div>
    </div>

    <div class="row py-1 w-100 mx-0">
      <label class="col-12 col-xl-6 form-control-label pr-0"
        >Estimated Louisiana Resident Payroll:</label
      >
      <div class="col-12 col-xl-6 float-right">
        <input
          id="residentPayroll"
          formControlName="residentPayroll"
          class="form-control"
          placeholder="$"
          pattern="\$? ?(\d{0,3},?)+([.](\d)+)?"
          currencyMask
          [options]="{ align: 'left', precision: 2 }"
        />
      </div>
    </div>

    <div class="row py-1 w-100 mx-0">
      <label class="col-12 col-xl-6 form-control-label pr-0"
        >Estimated Louisiana Visual Effects Expenditures:</label
      >
      <div class="col-12 col-xl-6 float-right">
        <input
          id="laVisualEffectsExpenditure"
          formControlName="laVisualEffectsExpenditure"
          class="form-control"
          placeholder="$"
          pattern="\$? ?(\d{0,3},?)+([.](\d)+)?"
          currencyMask
          [options]="{ align: 'left', precision: 2 }"
        />
      </div>
    </div>

    <div class="row py-1 w-100 mx-0">
      <label class="col-12 col-xl-6 form-control-label pr-0"
        >Estimated Total Visual Effects Expenditures:</label
      >
      <div class="col-12 col-xl-6 float-right">
        <input
          id="totalVisualEffectsExpenditure"
          formControlName="totalVisualEffectsExpenditure"
          class="form-control"
          placeholder="$"
          pattern="\$? ?(\d{0,3},?)+([.](\d)+)?"
          currencyMask
          [options]="{ align: 'left', precision: 2 }"
        />
      </div>
    </div>

    <div class="row py-1 w-100 mx-0">
      <label class="col-12 col-xl-6 form-control-label pr-0"
        >Estimated Louisiana Expenditures:<i [ngClass]="{'d-none': laExpenditureAbstractControl.valid || !(laExpenditureAbstractControl && laExpenditureAbstractControl.errors && laExpenditureAbstractControl.errors.errorMessage)}" class="fa fa-exclamation-circle text-danger ml-2 fa-2x animated infinite rubberBand"
        [title]="errorMessage" data-toggle="tooltip" data-placement="top"></i>
    </label>
      <div class="col-12 col-xl-6 float-right">
        <input
          id="laExpenditure"
          formControlName="laExpenditure"
          class="form-control"
          placeholder="$"
          pattern="\$? ?(\d{0,3},?)+([.](\d)+)?"
          currencyMask
          [options]="{ align: 'left', precision: 2 }"
        />
      </div>
    </div>

    <div class="row py-1 w-100 mx-0">
      <label class="col-12 col-xl-6 form-control-label pr-0"
        >Percentage of Louisiana Visual Effects Expenditure:</label
      >
      <div class="col-12 col-xl-6 float-right">
        {{
          expenditures.totalVisualEffectsExpenditure === 0 ||
          !expenditures.totalVisualEffectsExpenditure
            ? (0 | percent: '1.0-2')
            : (expenditures.percentVfxinLA | percent: '1.0-2')
        }}
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <p class="mr-auto">
        Does your LA Spend include airfare, finance fees, insurance fees or
        goods from a procurement company? Airfare is not eligible - please
        remove airfare expenditures from Estimated Louisiana Expenditures.
        Finance fees, insurance fees, or goods from a procurement company may
        require additional documentation.
      </p>
      <div class=" has-error">
        <label class="form-check-inline">
          <input
            type="radio"
            [value]="true"
            formControlName="doesLaSpendIncludeAirfareAndOthers"
            name="doesLaSpendIncludeAirfareAndOthers"
            id="inlineCheckbox1"
          />Yes
        </label>
        <label class="form-check-inline">
          <input
            type="radio"
            [value]="false"
            formControlName="doesLaSpendIncludeAirfareAndOthers"
            name="doesLaSpendIncludeAirfareAndOthers"
            id="inlineCheckbox2"
          />No
        </label>
      </div>
    </div>
  </div>
</div>
