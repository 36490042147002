import { of } from 'rxjs';
import * as i0 from "@angular/core";
export class BoardAgendaSummaryResolver {
    constructor() { }
    resolve(route, state) {
        const program = route.paramMap.get('ip').toLocaleUpperCase();
        return of(program);
    }
}
BoardAgendaSummaryResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BoardAgendaSummaryResolver_Factory() { return new BoardAgendaSummaryResolver(); }, token: BoardAgendaSummaryResolver, providedIn: "root" });
