import { Injectable } from '@angular/core';
import { PublishSubscribeService } from '../../fastlane-common/services/publish-subscribe.service';
import { UserContextService } from '../../user/services/user-context.service';
import { AuthenticationService } from '../../security/services/authentication.service';
import {
  publishSubscribeEvents,
  serverSentTypes
} from '../../event/event.constants';
import { environment } from '../../../environments/environment';

@Injectable()
export class ServerSentEventService {
  eventSource: EventSource;

  constructor(
    private pubsubService: PublishSubscribeService,
    private userContextService: UserContextService,
    private authService: AuthenticationService
  ) {}

  /**
   * @summary Starts listening for server side events related to project data changes
   */
  listenForProjectDataChanges(projectGuid: string) {
    const that = this;

    try {
      // If not null, close existing event source
      this.stopListening();

      // Get token
      const headers = this.authService.getAuthOptionsForHttpClient().headers;
      const token = headers['Authorization']
        .replace('Bearer ', '');

      // Create new event source
      this.eventSource = new EventSource(
        environment.apiUrl +
          `/project-change-notification-receiver?token=${token}`
      );

      //#region Use for troubleshooting

      // Begin listening for messages only for current project
      // this.eventSource.onmessage = e => {
      //   if (projectGuid === e.data) {
      //     console.log(`Project was changed`);
      //     console.log(e.data);
      //   }
      // };

      // this.eventSource.onerror = err => {
      //   console.log('EventSource error: ', err);
      // };

      // this.eventSource.onopen = err => {
      //   console.log('EventSource Open!!: ', err);
      // };

      //#endregion

      // Listen specifically for projectChange event
      this.eventSource.addEventListener(
        serverSentTypes.outdatedProject,
        (e: MessageEvent) => {
          const data = JSON.parse(e.data);
          if (
            projectGuid === data.projectGuid &&
            that.userContextService.currentUser.id !== data.userId
          ) {
            that.pubsubService.publish(publishSubscribeEvents.outdatedData);
          }
        }
      );
    } catch (e) {
      console.log('Unable to start listening to server events!');
    }
  }

  /**
   * @summary Stops listening for server side events related to project data changes
   */
  stopListening() {
    if (this.eventSource && this.eventSource.close) {
      this.eventSource.close();
      this.eventSource = null;
    }
  }
}
