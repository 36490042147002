<div *ngIf="applicableEvents.length > 0" class="card m-t border border-primary" style="overflow:visible;">
  <div class="card-header bg-primary text-white font-weight-bold">
    NOTIFICATION PREFERENCES
  </div>
  <div class="card-body p-4" style="overflow:visible;">
    <div class="d-flex flex-column">
      <div class="d-flex align-items-center" *ngFor="let event of applicableEvents">
        <div class="mr-2 col-4">{{getEventDisplayName(event)}}</div>
        <fl-event-notification-action-multiselect class="w-100 py-2 col-8"
          [eventActions]="getEventActions(event)" 
          [selectedEventActions]="getSelectedActions(event)"
          (change)="updateEventActionPreferences(event, $event)">
        </fl-event-notification-action-multiselect>
      </div>
    </div>
  </div>
</div>