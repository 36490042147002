import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PersonComponent } from '../../../../shared/components/person/person.component';
import { User } from '../../../models/user.model';
import { Subscription } from 'rxjs';
import { externalReviewAgencies } from '../../../../project/project.constants';
import { Programs } from 'app/project/models/programs.model';
declare var $;

@Component({
  selector: 'fl-user-detail-external',
  templateUrl: './external.component.html',
  styleUrls: ['./external.component.scss']
})
export class UserDetailExternalComponent implements OnDestroy, OnInit {
  @Input()
  form: FormGroup;
  @Input()
  user: User;
  @ViewChild(PersonComponent, { static: true })
  personComponent: PersonComponent;
  led = externalReviewAgencies.led;
  ldr = externalReviewAgencies.ldr;
  lga = externalReviewAgencies.lga;
  lwc = externalReviewAgencies.lwc;
  userForm: FormGroup;
  restrictedPrograms: Programs = new Programs(
    false,
    false,
    false,
    false,
    true,
    true
  );
  selectedPrograms: Programs = new Programs();
  get hasProgramChecked() {
    return (
      this.selectedPrograms.ez ||
      this.selectedPrograms.qj ||
      this.selectedPrograms.ite ||
      this.selectedPrograms.rta ||
      this.selectedPrograms.film ||
      this.selectedPrograms.dm
    );
  }

  incentiveProgramsForm: FormGroup;

  selectedAgency = '';
  private subs: Subscription[] = [];

  constructor(private fb: FormBuilder) {}

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());

    // Remove the form from the parent form
    this.form.removeControl('externalUser');
  }

  ngOnInit() {
    const that = this;

    // Initialize the form group
    this.userForm = this.fb.group({
      agency: [this.user.externalReviewAgency, Validators.required],
      hasProgramChecked: [this.hasProgramChecked, Validators.required]
    });

    if (this.user.externalReviewAgency) {
      this.selectedAgency = this.user.externalReviewAgency;
    }
    if (this.user.incentivePrograms && this.user.incentivePrograms.length > 0) {
      this.setPrograms();
      this.userForm.get('hasProgramChecked').patchValue(this.hasProgramChecked);
    }
    // Add an event handler to observe when values in this component's form changes so that we
    // can properly update the data model behind it.
    const sub = this.userForm.valueChanges.subscribe(value => {
      this.user.externalReviewAgency = value.agency;
    });
    this.subs.push(sub);

    // Add this component's form to the parent form
    if (this.form) {
      this.form.addControl('externalUser', this.userForm);
    }

    if (this.selectedAgency === this.lga.code) {
      this.userForm.addControl(
        'incentiveProgramsForm',
        this.incentiveProgramsForm
      );
    }

    // Enable bootstrap tooltips
    $('[data-toggle="tooltip"]').tooltip();
  }

  parseCheckboxes() {
    this.userForm.get('hasProgramChecked').patchValue(this.hasProgramChecked);
    this.pushAndPopIncentiveProgram('EZ');
    this.pushAndPopIncentiveProgram('QJ');
    this.pushAndPopIncentiveProgram('RTA');
    this.pushAndPopIncentiveProgram('ITE');
    this.setPrograms();
  }

  pushAndPopIncentiveProgram(incentiveProgram: string) {
    const bool = this.selectedPrograms[incentiveProgram.toLowerCase()];
    if (bool) {
      if (this.user.incentivePrograms.indexOf(incentiveProgram) < 0) {
        this.user.incentivePrograms.push(incentiveProgram);
      }
    } else {
      this.user.incentivePrograms = this.user.incentivePrograms.filter(
        ip => ip !== incentiveProgram
      );
    }
  }

  setFormData(user: User) {
    this.userForm.patchValue({
      agency: user.externalReviewAgency
    });

    this.setPrograms();
    this.personComponent.setFormData(user);
  }

  setPrograms() {
    this.user.incentivePrograms.forEach(program => {
      this.selectedPrograms[program.toLowerCase()] = true;
    });

    const hasProgramChecked =
      this.selectedPrograms.ez ||
      this.selectedPrograms.qj ||
      this.selectedPrograms.rta ||
      this.selectedPrograms.ite;

    if (this.incentiveProgramsForm) {
      this.incentiveProgramsForm.patchValue({
        hasProgramChecked: hasProgramChecked
      });
    } else {
      this.incentiveProgramsForm = this.fb.group({
        hasProgramChecked: [hasProgramChecked, Validators.requiredTrue]
      });
    }
  }

  selectedAgecyChange(value: any) {
    this.selectedAgency = value.currentTarget.selectedOptions[0].value;
    if (this.selectedAgency !== this.lga.code) {
      // LGA not selected
      this.user.incentivePrograms = [];
      this.selectedPrograms = new Programs();
      this.user.governingParish = null;
      this.setPrograms();

      if (this.userForm.contains('incentiveProgramsForm')) {
        this.userForm.removeControl('incentiveProgramsForm');
      }
    } else {
      // LGA is selected
      if (
        !this.userForm.contains('incentiveProgramsForm') &&
        this.incentiveProgramsForm
      ) {
        this.userForm.addControl(
          'incentiveProgramsForm',
          this.incentiveProgramsForm
        );
      }

      // Additionally we have to display the bootstrap tooltip
      setTimeout(() => $('[data-toggle="tooltip"]').tooltip(), 0);
    }
  }
}
