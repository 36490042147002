import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { nullUser } from '../models/nullUser.model';
import { userType } from '../user.constants';
import { programActionPermissions } from '../../security/security.constants';

@Injectable()
export class UserContextService {
  // By default, the current user is the null user.
  // This should update to a real user when a user logs into the application
  // or the application is loaded from an access token.
  currentUser: User = nullUser;
  guestTokenGuid: string;

  constructor() {}

  isUserGuest() {
    return (
      this.currentUser.userType.toLowerCase() ===
      userType.guest.code.toLowerCase()
    );
  }

  isUserManager() {
    return (
      this.currentUser.userType.toLowerCase() ===
      userType.management.code.toLowerCase()
    );
  }

  resetContext() {
    this.currentUser = nullUser;
  }

  hasPermission(permission: string) {
    return this.currentUser.permissions &&
      this.currentUser.permissions[permission]
      ? this.currentUser.permissions[permission]
      : false;
  }

  hasPermissionToEditProgram(incentiveProgram: string) {
    return this.hasPermission(programActionPermissions[incentiveProgram]);
  }
  hasAvailablePrograms(incentiveProgram: string) {
    return (
      incentiveProgram ===
      this.currentUser.incentivePrograms.find(ip => ip === incentiveProgram)
    );
  }
}
