
export class ProjectDetail  {
    anticipatedProjectStartDate: Date;
    anticipatedProjectEndDate: Date;
    projectType: string;
    projectDescription: string;
    userInterfaceDescription: string;
    distributionPlan: string;
    productWebsite: string;

    constructor(obj?: any) {
      if (!obj) {
        obj = {};
      }
      this.anticipatedProjectStartDate = obj.anticipatedProjectStartDate
      ? new Date(obj.anticipatedProjectStartDate)
      : null;
      this.anticipatedProjectEndDate = obj.anticipatedProjectEndDate
      ? new Date(obj.anticipatedProjectEndDate)
      : null;
      this.projectType = obj.projectType || null;
      this.projectDescription = obj.projectDescription || null;
      this.userInterfaceDescription = obj.userInterfaceDescription || null;
      this.distributionPlan = obj.distributionPlan || null;
      this.productWebsite = obj.productWebsite || null;
    }
}
