/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logged-in.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/navigation/navigation.component.ngfactory";
import * as i3 from "../../components/navigation/navigation.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../user/services/user-context.service";
import * as i6 from "../../../security/services/authentication.service";
import * as i7 from "../../../shared/labels/loginNotification.component.ngfactory";
import * as i8 from "../../../shared/labels/loginNotification.component";
import * as i9 from "../../components/header/header.component.ngfactory";
import * as i10 from "../../components/header/header.component";
import * as i11 from "../../components/footer/footer.component.ngfactory";
import * as i12 from "../../components/footer/footer.component";
import * as i13 from "./logged-in.component";
var styles_LoggedInComponent = [i0.styles];
var RenderType_LoggedInComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoggedInComponent, data: {} });
export { RenderType_LoggedInComponent as RenderType_LoggedInComponent };
export function View_LoggedInComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "d-flex flex-column"], ["id", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "side-nav-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "fl-navigation", [], null, null, null, i2.View_NavigationComponent_0, i2.RenderType_NavigationComponent)), i1.ɵdid(3, 5292032, null, 0, i3.NavigationComponent, [i4.Router, i5.UserContextService, i6.AuthenticationService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "gray-bg"], ["id", "page-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "shr-loginNotification", [], null, null, null, i7.View_LoginNotificationComponent_0, i7.RenderType_LoginNotificationComponent)), i1.ɵdid(6, 49152, null, 0, i8.LoginNotificationComponent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "fl-header", [], null, null, null, i9.View_HeaderComponent_0, i9.RenderType_HeaderComponent)), i1.ɵdid(8, 49152, null, 0, i10.HeaderComponent, [i5.UserContextService], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "wrapper mt-3"], ["style", "padding-bottom: 100px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(11, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "fl-footer", [], null, null, null, i11.View_FooterComponent_0, i11.RenderType_FooterComponent)), i1.ɵdid(13, 114688, null, 0, i12.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 11, 0); _ck(_v, 13, 0); }, null); }
export function View_LoggedInComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fl-logged-in", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_LoggedInComponent_0, RenderType_LoggedInComponent)), i1.ɵdid(1, 114688, null, 0, i13.LoggedInComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoggedInComponentNgFactory = i1.ɵccf("fl-logged-in", i13.LoggedInComponent, View_LoggedInComponent_Host_0, {}, {}, []);
export { LoggedInComponentNgFactory as LoggedInComponentNgFactory };
