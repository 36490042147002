import { ContractChange } from './contract-change.model';
import { BusinessLegalStructure } from '../../../project/models/business-legal-structure.model';
import { formTypes } from 'app/form/form.constants';
import { QualityJobsCao } from '../ownership/models/quality-jobs-cao.model';
import { EnterpriseZoneCao } from '../ownership/models/enterprise-zone-cao.model';

export class ContractChangeOwnership extends ContractChange {
  qj: QualityJobsCao;
  ez: EnterpriseZoneCao;
  applicationSnapshot = false;
  constructor(formIndex: number = null, created?: boolean, form?: any) {
    super(formIndex, form);
    this.init();
    if (created) {
      this.applicationSnapshot = true;
    }
  }

  init() {
    super.init();
    this.qj = this.qj || new QualityJobsCao(this.qj);
    this.ez = this.ez || new EnterpriseZoneCao(this.ez);
    this.type = formTypes.changeAffiliatesOwners.abbrev;
  }
}
