import { Project } from './project.model';

export class HybridProject {
    biProject: Project;
    filmProject: Project;
    digitalProject: Project;
    stepProject: Project;

    constructor(hybridProject?: any) {
        hybridProject = hybridProject || {};
        this.biProject = hybridProject.biProject || null;
        this.filmProject = hybridProject.filmProject || null;
        this.digitalProject = hybridProject.digitalProject || null;
        this.stepProject = hybridProject.stepProject || null;
    }
}
