import { Credit } from '../../../models/digital/credit.model';
import { LaborInfo } from '../../../models/digital/labor-info.model';
import { NonLaborInfo } from '../../../models/digital/non-labor-info.model';
import { benefitParameterSchedule } from '../../../../fiscal/fiscal.constants';
import { incentiveProgram } from '../../../../project/project.constants';
import { BenefitParameters } from '../../../../fiscal/models/benefit-parameters.model';
import { ExpenditureStatuses } from './expenditures.constants';
import { CreditTypes } from '../../../credit.constants';
import { YearsInSpreadsheet } from '../../../models/digital/yearsInSpreadsheet.model';
import * as _ from 'underscore';
import { Expenditures } from '../../../models/digital/expenditures.model';

// #region "CREDITS"
/**
 * @summary Calculate for the Total CreditExpenditure Amount
 */
export function calCreditExpenditure(
  estimatedCredits: Credit[],
  year: number,
  creditTypes: string[]
): number {
  if (estimatedCredits && estimatedCredits.length > 0) {
    return estimatedCredits
      .filter(
        i =>
          creditTypes.includes(i.creditType) &&
          (i.year === year || year === null)
      )
      .map(i => i.expenditureAmount || 0)
      .reduce((sum, current) => sum + current, 0);
  }
  return 0;
}

/**
 * @summary Calculate for the Total CreditType Amount
 */
export function calCreditAmount(
  estimatedCredits: Credit[],
  year: number,
  creditTypes: string[]
): number {
  if (estimatedCredits && estimatedCredits.length > 0) {
    return estimatedCredits
      .filter(
        i =>
          creditTypes.includes(i.creditType) &&
          (i.year === year || year === null)
      )
      .map(i => i.creditTypeAmount || 0)
      .reduce((sum, current) => sum + current, 0);
  }
  return 0;
}
export function calQualifiedExpenditureAmount(
  estimatedCredits: Credit[],
  year: number,
  creditTypes: string[]
): number {
  if (estimatedCredits && estimatedCredits.length > 0) {
    return estimatedCredits
      .filter(
        i =>
          creditTypes.includes(i.creditType) &&
          (i.year === year || year === null)
      )
      .map(i => i.expenditureAmount || 0)
      .reduce((sum, current) => sum + current, 0);
  }
  return 0;
}

/**
 * @summary Calculate for the Overall Credits that include the base percentages
 */
function calCumulativeCredits(
  legislationRule: string,
  formType: string,
  laborInfo: LaborInfo[],
  nonLaborInfo: NonLaborInfo[]
): number {
  let benefitParams: BenefitParameters;

  // Retreive benefit
  benefitParams = getBenefitParameters(legislationRule, formType);

  return (
    laborInfo.reduce((sum, current) => {
      const existing: number = sum || 0;
      return (sum =
        current.laResident && current.laResident.toLowerCase() === 'yes'
          ? existing + current.totalSalary * benefitParams.laborInState
          : existing + current.totalSalary * benefitParams.laborOutOfState);
    }, 0) +
    nonLaborInfo
      .map(i => i.totalItemCost || 0)
      .reduce((sum, current) => sum + current * benefitParams.nonLabor, 0)
  );
}
/**
 * @summary Calculation for Estimated Credits for Initial Certificate
 */
function calEstimatedCreditsForInitialCert(
  legislationRule: string,
  formType: string,
  estimatedCredits: Credit[],
  yearsInSpreadsheet: YearsInSpreadsheet[],
  laborInfo: LaborInfo[],
  nonLaborInfo: NonLaborInfo[]
) {
  let benefitParams: BenefitParameters;
  const localEstimatedCredits: Credit[] = [];
  const localLaResident: string[] = ['yes', 'no'];

  // Retreive benefit
  benefitParams = getBenefitParameters(legislationRule, formType);

  let actualRate: number[] = [];
  for (const yearDetail of yearsInSpreadsheet) {
    for (const laResidentDetail of localLaResident) {
      // Retrieve existing rate for calculation. If existing rate doesn't
      // exist in the estimatedCredits then retrieve the benefitParams rate.
      actualRate = getEstimatedCreditRate(
        estimatedCredits,
        yearDetail.year,
        laResidentDetail.toLowerCase() === 'yes'
          ? CreditTypes.inState
          : CreditTypes.outOfState,
        laResidentDetail.toLowerCase() === 'yes'
          ? benefitParams.laborInState
          : benefitParams.laborOutOfState
      );
      // Labor Credits calculation
      // Calculate only with data that has an "eligible" status
      if (laborInfo.length > 0) {
        localEstimatedCredits.push(
          laborInfo
            .filter(
              i =>
                typeof i.eligibilityStatus !== 'undefined' &&
                i.eligibilityStatus !== ExpenditureStatuses.ineligible &&
                i.eligibilityStatus !== '' &&
                ((i.laResident &&
                  i.laResident.toLowerCase() === laResidentDetail &&
                  laResidentDetail.toLowerCase() === 'yes') ||
                  (((i.laResident &&
                    i.laResident.toLowerCase() === laResidentDetail) ||
                    (i.laResident && i.laResident.toLowerCase() === '')) &&
                    laResidentDetail.toLowerCase() === 'no')) &&
                _.any(i.yearDetails, y => y.year === yearDetail.year)
            )
            .reduce(
              (tempObject: Credit, current) => {
                // Get only the year that belongs to the current one in the loop
                const selectedYear = current.yearDetails.filter(
                  yd => yd.year === yearDetail.year
                );

                // If the year is found then add it up to any existing one
                if (selectedYear && selectedYear.length > 0) {
                  // Sum the salary by job count and create the credit object.
                  const existingAmt: number =
                    tempObject['expenditureAmount'] || 0;

                  // Expenditure Amount
                  tempObject['expenditureAmount'] =
                    existingAmt + selectedYear[0].salaryByJobCount;
                  tempObject['creditTypeAmount'] =
                    (existingAmt + selectedYear[0].salaryByJobCount) *
                    actualRate[0];
                }
                return tempObject;
              },
              <Credit>{
                creditType:
                  laResidentDetail.toLowerCase() === 'yes'
                    ? CreditTypes.inState
                    : CreditTypes.outOfState,
                expenditureAmount: 0,
                rate: actualRate[0],
                creditTypeAmount: 0,
                year: yearDetail.year,
                dateRange: [yearDetail.startDate, yearDetail.endDate]
              }
            )
        );
      }
    }

    // NonLaborInfo Credits calculation
    if (nonLaborInfo.length > 0) {
      // Retrieve existing rate for calculation. If existing rate doesn't
      // exist in the estimatedCredits then retrieve the benefitParams rate.
      actualRate = getEstimatedCreditRate(
        estimatedCredits,
        yearDetail.year,
        CreditTypes.nonLabor,
        benefitParams.nonLabor
      );

      localEstimatedCredits.push(
        nonLaborInfo
          .filter(
            i =>
              typeof i.eligibilityStatus !== 'undefined' &&
              i.eligibilityStatus !== ExpenditureStatuses.ineligible &&
              i.eligibilityStatus !== '' &&
              _.any(i.yearDetails, y => y.year === yearDetail.year)
          )
          .reduce(
            (tempObject: Credit, current) => {
              // Get only the year that belongs to the current one in the loop
              const selectedYear = current.yearDetails.filter(
                yd => yd.year === yearDetail.year
              );

              // If the year is found then add it up to any existing one
              if (selectedYear && selectedYear.length > 0) {
                // Sum the salary by job count and create the credit object.
                const existingAmt: number =
                  tempObject['expenditureAmount'] || 0;

                // Expenditure Amount
                tempObject['expenditureAmount'] =
                  existingAmt + selectedYear[0].totalCost;
                // Credit Type Amount
                tempObject['creditTypeAmount'] =
                  (existingAmt + selectedYear[0].totalCost) * actualRate[0];
              }
              return tempObject;
            },
            <Credit>{
              creditType: CreditTypes.nonLabor,
              expenditureAmount: 0,
              rate: actualRate[0],
              creditTypeAmount: 0,
              year: yearDetail.year,
              dateRange: [yearDetail.startDate, yearDetail.endDate]
            }
          )
      );
    }
  }

  // Delete all data from array then reinsert using object.assign
  if (estimatedCredits.length >= 0) {
    estimatedCredits.splice(0, estimatedCredits.length);
  }

  Object.assign(estimatedCredits, localEstimatedCredits);
}
/**
 * @summary Calculation for Estimated Credits for Labor and NonLabor data For Application
 */
function calEstimatedCreditsForApplication(
  legislationRule: string,
  formType: string,
  estimatedCredits: Credit[],
  yearsInSpreadsheet: YearsInSpreadsheet[],
  laborInfo: LaborInfo[],
  nonLaborInfo: NonLaborInfo[]
) {
  let benefitParams: BenefitParameters;
  const localEstimatedCredits: Credit[] = [];
  const localLaResident: string[] = ['yes', 'no'];

  // Retreive benefit
  benefitParams = getBenefitParameters(legislationRule, formType);

  for (const yearDetail of yearsInSpreadsheet) {
    for (const laResidentDetail of localLaResident) {
      // Labor Credits calculation
      if (laborInfo.length > 0) {
        localEstimatedCredits.push(
          laborInfo
            .filter(
              i =>
                ((i.laResident &&
                  i.laResident.toLowerCase() === laResidentDetail &&
                  laResidentDetail.toLowerCase() === 'yes') ||
                  (((i.laResident &&
                    i.laResident.toLowerCase() === laResidentDetail) ||
                    (i.laResident && i.laResident.toLowerCase() === '')) &&
                    laResidentDetail.toLowerCase() === 'no')) &&
                _.any(i.yearDetails, y => y.year === yearDetail.year)
            )
            .reduce(
              (tempObject: Credit, current, i, LaborTemp) => {
                // Get only the year that belongs to the current one in the loop
                const selectedYear = current.yearDetails.filter(
                  yd => yd.year === yearDetail.year
                );

                // If the year is found then add it up to any existing one
                if (selectedYear && selectedYear.length > 0) {
                  const existingAmt: number =
                    tempObject['expenditureAmount'] || 0;

                  // Sum the salary by job count and create the credit object.
                  // Credit Type
                  tempObject['creditType'] =
                    current.laResident &&
                    current.laResident.toLowerCase() === 'yes'
                      ? CreditTypes.inState
                      : CreditTypes.outOfState;
                  // Expenditure Amount
                  tempObject['expenditureAmount'] =
                    existingAmt + selectedYear[0].salaryByJobCount;
                  // Rate
                  tempObject['rate'] =
                    current.laResident &&
                    current.laResident.toLowerCase() === 'yes'
                      ? benefitParams.laborInState
                      : benefitParams.laborOutOfState;
                  // Credit Type Amount
                  tempObject['creditTypeAmount'] =
                    current.laResident &&
                    current.laResident.toLowerCase() === 'yes'
                      ? (existingAmt + selectedYear[0].salaryByJobCount) *
                        benefitParams.laborInState
                      : (existingAmt + selectedYear[0].salaryByJobCount) *
                        benefitParams.laborOutOfState;
                  // Year
                  tempObject['year'] = yearDetail.year;
                  // Project Dates
                  tempObject['dateRange'] = [
                    yearDetail.startDate,
                    yearDetail.endDate
                  ];
                }
                return tempObject;
              },
              // Initialize array row to filter during the object assign,
              // if nothing is return in the reduce.
              <Credit>{ creditType: '' }
            )
        );
      }
    }

    // NonLaborInfo Credits calculation
    if (nonLaborInfo.length > 0) {
      localEstimatedCredits.push(
        nonLaborInfo
          .filter(i => _.any(i.yearDetails, y => y.year === yearDetail.year))
          .reduce(
            (tempObject: Credit, current) => {
              // Get only the year that belongs to the current one in the loop
              const selectedYear = current.yearDetails.filter(
                yd => yd.year === yearDetail.year
              );

              // If the year is found then add it up to any existing one
              if (selectedYear && selectedYear.length > 0) {
                const existingAmt: number =
                  tempObject['expenditureAmount'] || 0;

                // Sum the salary by job count and create the credit object.
                // Credit Type
                tempObject['creditType'] = CreditTypes.nonLabor;
                // Expenditure Amount
                tempObject['expenditureAmount'] =
                  existingAmt + selectedYear[0].totalCost;
                // Rate
                tempObject['rate'] = benefitParams.laborOutOfState;
                // Credit Type Amount
                tempObject['creditTypeAmount'] =
                  (existingAmt + selectedYear[0].totalCost) *
                  benefitParams.laborOutOfState;
                // Year
                tempObject['year'] = yearDetail.year;
                // Project Dates
                tempObject['dateRange'] = [
                  yearDetail.startDate,
                  yearDetail.endDate
                ];
              }
              return tempObject;
            },
            // Initialize array row to filter during the object assign,
            // if nothing is return in the reduce.
            <Credit>{ creditType: '' }
          )
      );
    }
  }

  // Delete all data from array then reinsert using object.assign
  if (estimatedCredits.length >= 0) {
    estimatedCredits.splice(0, estimatedCredits.length);
  }
  // Merge data. Remove any array with creditType is blank because it is an initial value.W
  Object.assign(
    estimatedCredits,
    localEstimatedCredits.filter(i => i.creditType !== '')
  );
}
//#endregion

// #region "TOTALS OF LABOR AND NON LABOR"
/**
 * @summary Calculate total labor salary
 */
function calLaborTotal(laborInfo: LaborInfo[], laResident: string): number {
  return laborInfo
    .filter(
      i =>
        (i.laResident &&
          i.laResident.toLowerCase() === laResident &&
          laResident.toLowerCase() === 'yes') ||
        (((i.laResident && i.laResident.toLowerCase() === laResident) ||
          (i.laResident && i.laResident.toLowerCase() === '')) &&
          laResident.toLowerCase() === 'no')
    )
    .reduce((sum, current) => {
      const existing: number = sum || 0;
      return (sum = existing + current.totalSalary);
    }, 0);
}

/**
 * @summary Calculate total nonlabor salary
 */
function calNonLaborTotal(nonLaborInfo: NonLaborInfo[]): number {
  return nonLaborInfo.reduce((sum, current) => {
    const existing: number = sum || 0;
    return (sum = existing + current.totalItemCost);
  }, 0);
}
/**
 * @summary  Update all of the total that is read access only.
 */
export function calUpdateAllTotal(
  expenditures: Expenditures,
  legislationRule: string,
  formType: string
) {
  const localRateFormType = 'application';
  // Calculate for Labor InState Total
  expenditures.laborInStateTotal = calLaborTotal(expenditures.laborInfo, 'yes');
  // Calculate for Labor OutOfState Total
  expenditures.laborOutOfStateTotal = calLaborTotal(
    expenditures.laborInfo,
    'no'
  );
  // Calculate Job Count
  expenditures.totalEstimatedJobs = calculateJobCount(
    expenditures.laborInfo,
    null
  );
  if (formType.toLowerCase() === 'initialcert') {
    expenditures.totalEstimatedEligibleJobs = calculateJobCount(
      expenditures.laborInfo,
      'eligible'
    );
  }
  // Calculate for NonLabor Total
  expenditures.nonLaborTotal = calNonLaborTotal(expenditures.nonLaborInfo);
  // Calculate for Expenditure Total
  expenditures.expenditureTotal = calExpenditureTotal(
    expenditures.laborInfo,
    expenditures.nonLaborInfo
  );
  // Calculate for Cumulative Credits
  expenditures.cumulativeCredits = calCumulativeCredits(
    legislationRule,
    localRateFormType,
    expenditures.laborInfo,
    expenditures.nonLaborInfo
  );
  // Calculate for the Estimate credit with base percentages
  if (formType.toLowerCase() === localRateFormType) {
    calEstimatedCreditsForApplication(
      legislationRule,
      localRateFormType,
      expenditures.estimatedCredits,
      expenditures.yearsInSpreadsheet,
      expenditures.laborInfo,
      expenditures.nonLaborInfo
    );
  }
  if (formType.toLowerCase() === 'initialcert') {
    calEstimatedCreditsForInitialCert(
      legislationRule,
      localRateFormType,
      expenditures.estimatedCredits,
      expenditures.yearsInSpreadsheet,
      expenditures.laborInfo,
      expenditures.nonLaborInfo
    );
  }
}
/**
 * @summary  Estimated Louisiana Expenditure Total
 */
function calExpenditureTotal(
  laborInfo: LaborInfo[],
  nonLaborInfo: NonLaborInfo[]
): number {
  return (
    laborInfo
      .map(i => i.totalSalary || 0)
      .reduce((sum, current) => sum + current, 0) +
    nonLaborInfo
      .map(i => i.totalItemCost || 0)
      .reduce((sum, current) => sum + current, 0)
  );
}
// #endregion

// #region "Marked Expenditure Totals"

export function calculateMarkedLaborExpenditures(
  laborExpenditures: LaborInfo[],
  year?: number,
  eligibilityStatuses?: string[]
): number {
  let aggregate = 0;
  laborExpenditures
    .filter(
      fe =>
        !eligibilityStatuses ||
        eligibilityStatuses.length === 0 ||
        eligibilityStatuses.includes(fe.eligibilityStatus)
    )
    .forEach(le => {
      aggregate += le.yearDetails
        .filter(yd => yd.year === year || !year)
        .map(ye => ye.salaryByJobCount || 0)
        .reduce((sum, current) => sum + current, 0);
    });
  return aggregate;
}

export function calculateMarkedNonLaborExpenditures(
  nonLaborExpenditures: NonLaborInfo[],
  year?: number,
  eligibilityStatuses?: string[]
): number {
  let aggregate = 0;
  nonLaborExpenditures
    .filter(
      fe =>
        !eligibilityStatuses ||
        eligibilityStatuses.length === 0 ||
        eligibilityStatuses.includes(fe.eligibilityStatus)
    )
    .forEach(le => {
      aggregate += le.yearDetails
        .filter(yd => yd.year === year || !year)
        .map(ye => ye.totalCost || 0)
        .reduce((sum, current) => sum + current, 0);
    });
  return aggregate;
}

export function calculateJobCount(
  laborInfo: LaborInfo[],
  eligibilityStatus: string
) {
  return laborInfo
    .filter(
      li =>
        ((li.eligibilityStatus === ExpenditureStatuses.eligible ||
          li.eligibilityStatus === ExpenditureStatuses.conditionallyEligible) &&
          li.eligibilityStatus.toLowerCase() === 'eligible') ||
        eligibilityStatus === null
    )
    .reduce((sum, curr) => (sum = sum + curr.jobCount), 0);
}
// #endregion

// #region "Share function"
// /**
//  * @summary  Get existing credits if the rates was change from the manager
//  * @param baseRate If year doesn't exist in estimatedCredits
//  */
function getEstimatedCreditRate(
  estimatedCredits: Credit[],
  year: number,
  creditType: string,
  benefitRate: number
) {
  let rate: number[] = [];
  rate = estimatedCredits
    .filter(ec => ec.year === year && ec.creditType.includes(creditType))
    .map(ec => {
      return ec.rate;
    });
  if (!rate[0]) {
    rate[0] = benefitRate;
  }
  return rate;
}

export function getBenefitParameters(
  legislationRule: string,
  formType: string
): BenefitParameters {
  let benefitParams: BenefitParameters;
  const specificBenefitParameterSchedule =
    benefitParameterSchedule[incentiveProgram.dm.code.toLowerCase()][formType];
  if (specificBenefitParameterSchedule) {
    for (let i = 0; i < specificBenefitParameterSchedule.length; i++) {
      // check if the legislation rule exists in the array
      if (
        specificBenefitParameterSchedule[i].legislationRule.includes(
          legislationRule
        )
      ) {
        benefitParams = specificBenefitParameterSchedule[i].benefitParams;
        return benefitParams;
      }
    }
  }
  return benefitParams;
}
// #endregion
