import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ConsultantDisclosureComponent } from './components/consultant-disclosure/consultant-disclosure.component';
import { DisclosureConfirmationComponent } from '../print/components/disclosure-confirmation/disclosure-confirmation.component';
import { FormListComponent } from './components/form-list/form-list.component';
import { CreateComponent } from './components/create/create.component';
import { ListComponent } from './components/list/list.component';
import { ListManagementComponent } from './components/list-management/list-management.component';
import { ProjectDataService } from './services/project-data.service';
import { RequestAccessComponent } from './components/request-access/request-access.component';
import { SharedModule } from '../shared/shared.module';

import { ImportantDatesComponent } from './components/important-dates/important-dates.component';
import { ComplianceFormsComponent } from './components/compliance-forms/compliance-forms.component';
import { ContractChangeFormsComponent } from './components/contract-change-forms/contract-change-forms.component';
import { ProjectInfoComponent } from './info/info.component';
import { ProjectContactsComponent } from './contacts/contacts.component';
import { ProjectEditorComponent } from './components/editor/editor.component';
import { ProjectFormManagerComponent } from './components/form-manager/form-manager.component';
import { ProjectHeaderComponent } from './header/header.component';
import { AttachmentsComponent } from './components/attachments/attachments.component';
// tslint:disable-next-line:max-line-length
import { ProjectAccessConsultantConfirmationComponent } from './components/project-access-consultant-confirmation/project-access-consultant-confirmation.component';
import { AssignProjectAccessComponent } from './components/assign-project-access/assign-project-access.component';
import { CriticalDatesComponent } from './critical-dates/critical-dates.component';
import { PhaseCreatorComponent } from './components/phase-creator/phase-creator.component';
import { ProjectCommentsComponent } from './comments/comments.component';
import { ProjectSummaryComponent } from './summary/summary.component';
import { ContractSignatoryComponent } from './components/contract-signatory/contract-signatory.component';
import { LateFilingComponent } from './components/late-filing/late-filing.component';
import { AcrCertificationComponent } from './certification/acr/acr.component';
import { EcrCertificationComponent } from './certification/ecr/ecr.component';
import { ProjectStatusesComponent } from './statuses/statuses.component';
import { RequestAccessProjectSearchComponent } from './components/request-access/project-search/project-search.component';
import { RequestAccessFormComponent } from './components/request-access/form/form.component';
import { DetailSearchComponent } from './detail-search/detail-search.component';
import { AdvaceNotificationSearchComponent } from './advace-notification-search/advace-notification-search.component';
import { BoardApprovedTableComponent } from './detail-search/board-approved-table/board-approved-table.component';
import { StatusHistoryComponent } from './components/status-history/status-history.component';
import { ConfidentialComponent } from './components/confidential/confidential.component';
import { ProjectResolver } from './resolvers/project.resolver';;
import { SwitchProgramComponent } from './components/switch-program/switch-program.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule
  ],
  declarations: [
    AttachmentsComponent,
    ConsultantDisclosureComponent,
    CreateComponent,
    DisclosureConfirmationComponent,
    FormListComponent,
    ListComponent,
    ListManagementComponent,
    RequestAccessComponent,
    ProjectAccessConsultantConfirmationComponent,
    ImportantDatesComponent,
    ComplianceFormsComponent,
    ContractChangeFormsComponent,
    ProjectInfoComponent,
    ProjectContactsComponent,
    ProjectEditorComponent,
    ProjectFormManagerComponent,
    ProjectHeaderComponent,
    AssignProjectAccessComponent,
    CriticalDatesComponent,
    PhaseCreatorComponent,
    ProjectCommentsComponent,
    ProjectSummaryComponent,
    ContractSignatoryComponent,
    LateFilingComponent,
    AcrCertificationComponent,
    EcrCertificationComponent,
    ProjectStatusesComponent,
    RequestAccessProjectSearchComponent,
    RequestAccessFormComponent,
    DetailSearchComponent,
    AdvaceNotificationSearchComponent,
    BoardApprovedTableComponent,
    StatusHistoryComponent,
    ConfidentialComponent,
    SwitchProgramComponent
  ],
  exports: [
    AttachmentsComponent,
    CreateComponent,
    CriticalDatesComponent,
    DisclosureConfirmationComponent,
    FormListComponent,
    ListComponent,
    ListManagementComponent,
    RequestAccessComponent,
    ProjectAccessConsultantConfirmationComponent,
    ImportantDatesComponent,
    ConsultantDisclosureComponent,
    ProjectInfoComponent,
    ProjectContactsComponent,
    ProjectEditorComponent,
    ProjectFormManagerComponent,
    ProjectHeaderComponent,
    ProjectCommentsComponent,
    ProjectSummaryComponent,
    ContractSignatoryComponent,
    AcrCertificationComponent,
    EcrCertificationComponent,
    StatusHistoryComponent
  ],
  providers: [ProjectResolver]
})
export class ProjectModule {}
