import { PropertyUsage } from './property-usage.model';
import { PropertyTax } from './property-tax.model';

export class RestorationTaxAbatementApplication {
  propertyUsage: PropertyUsage;
  propertyTax: PropertyTax;
  annualAdvalorem: number;

  constructor() {}
}
