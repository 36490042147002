<div
  class="form-group px-3 mb-0 d-flex flex-wrap justify-content-between"
  [formGroup]="formCriticalDatesFormGroup"
>
  <div class="col-12 col-md-6  py-1 d-flex align-items-center">
    <span class="col-12 col-xl-5 form-control-label"
      >Related Application Received Date:</span
    >
    <div class="col-12 col-xl-6">
      <fl-date-time-picker
        [form]="
          formCriticalDatesFormGroup.get('relatedApplicationReceivedDateGroup')
        "
        [showTime]="false"
        [selectedDate]="initialCertCriticalDate.relatedApplicationReceivedDate"
        [IsRequired]="true"
        (dateTimeChanged)="
          estimatedDateChanged('relatedApplicationReceivedDate', $event)
        "
      ></fl-date-time-picker>
    </div>
  </div>

  <div class="col-12 col-md-6  py-1 d-flex align-items-center">
    <span class="col-12 col-xl-5 form-control-label"
      >Initial Certification Issuance Date:</span
    >
    <div class="col-12 col-xl-6">
      <fl-date-time-picker
        [form]="formCriticalDatesFormGroup.get('initialCertIssuanceDateGroup')"
        [showTime]="false"
        [selectedDate]="initialCertCriticalDate.initialCertIssuanceDate"
        [IsRequired]="true"
        (dateTimeChanged)="
          estimatedDateChanged('initialCertIssuanceDate', $event)
        "
      ></fl-date-time-picker>
    </div>
  </div>

  <div class="col-12 col-md-6  py-1 d-flex align-items-center">
    <span class="col-12 col-xl-5 form-control-label"
      >Eligible Expenditure Start Date:</span
    >
    <div class="col-12 col-xl-6">
      <fl-date-time-picker
        [form]="
          formCriticalDatesFormGroup.get('eligibleExpenditureStartDateGroup')
        "
        [showTime]="false"
        [selectedDate]="initialCertCriticalDate.eligibleExpenditureStartDate"
        [IsRequired]="true"
        (dateTimeChanged)="
          estimatedDateChanged('eligibleExpenditureStartDate', $event)
        "
      ></fl-date-time-picker>
    </div>
  </div>

  <div class="col-12 col-md-6  py-1 d-flex align-items-center">
    <span class="col-12 col-xl-5 form-control-label"
      >Eligible Expenditure End Date:</span
    >
    <div class="col-12 col-xl-6">
      <fl-date-time-picker
        [form]="
          formCriticalDatesFormGroup.get('eligibleExpenditureEndDateGroup')
        "
        [showTime]="false"
        [selectedDate]="initialCertCriticalDate.eligibleExpenditureEndDate"
        [IsRequired]="true"
        (dateTimeChanged)="
          estimatedDateChanged('eligibleExpenditureEndDate', $event)
        "
      ></fl-date-time-picker>
    </div>
  </div>

  <div class="col-12 col-md-6  py-1 d-flex align-items-center">
    <span class="col-12 col-xl-5 form-control-label"
      >Cost Report Final Eligible Date:</span
    >
    <div class="col-12 col-xl-6">
      <fl-date-time-picker
        [form]="
          formCriticalDatesFormGroup.get('costReportFinalEligibleDateGroup')
        "
        [showTime]="false"
        [selectedDate]="initialCertCriticalDate.costReportFinalEligibleDate"
        [IsRequired]="true"
        (dateTimeChanged)="
          estimatedDateChanged('costReportFinalEligibleDate', $event)
        "
      ></fl-date-time-picker>
    </div>
  </div>

  <div class="col-12 col-md-6  py-1 d-flex align-items-center">
    <span class="col-12 col-xl-5 form-control-label"
      >Post Production Cost Report Submission Date:</span
    >
    <div class="col-12 col-xl-6">
      <fl-date-time-picker
        [form]="
          formCriticalDatesFormGroup.get('postProductionCostReportDateGroup')
        "
        [showTime]="false"
        [selectedDate]="initialCertCriticalDate.postProductionCostReportDate"
        [IsRequired]="false"
        (dateTimeChanged)="
          estimatedDateChanged('postProductionCostReportDate', $event)
        "
      ></fl-date-time-picker>
    </div>
  </div>
  <div class="col-12 col-md-6  py-1 d-flex align-items-center">
    <span class="col-12 col-xl-5 form-control-label"
      >Principal Photography Start Due Date:</span
    >
    <div class="col-12 col-xl-6">
      <fl-date-time-picker
        [form]="
          formCriticalDatesFormGroup.get(
            'principalPhotographyStartDueDateGroup'
          )
        "
        [showTime]="false"
        [selectedDate]="
          initialCertCriticalDate.principalPhotographyStartDueDate
        "
        [IsRequired]="false"
        (dateTimeChanged)="
          estimatedDateChanged('principalPhotographyStartDueDate', $event)
        "
      ></fl-date-time-picker>
    </div>
  </div>
  <div class="col-12 col-md-6  py-1 d-flex align-items-center">
    <span class="col-12 col-xl-5 form-control-label"
      >Proof of Principal Photography Received Date:</span
    >
    <div class="col-12 col-xl-6">
      <fl-date-time-picker
        [form]="
          formCriticalDatesFormGroup.get(
            'proofofprincipalPhotographyReceivedDateGroup'
          )
        "
        [showTime]="false"
        [selectedDate]="
          initialCertCriticalDate.proofofPrincipalPhotographyReceivedDate
        "
        [IsRequired]="false"
        (dateTimeChanged)="
          estimatedDateChanged('proofofPrincipalPhotographyReceivedDate', $event)
        "
      ></fl-date-time-picker>
    </div>
  </div>
</div>
