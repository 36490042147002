import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ICanComponentDeactivate } from '../models/canComponentDeactivate.model';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ICanComponentDeactivate> {

  canDeactivate(
    component: ICanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot) {
    return component.canDeactivate ? component.canDeactivate(currentRoute, currentState, nextState) : true;
  }

}