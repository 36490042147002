import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { DownloadService } from '../../fastlane-common/services/download.service';

@Component({
  selector: 'fl-public-reports',
  templateUrl: './public-reports.component.html',
  styleUrls: ['./public-reports.component.scss']
})
export class PublicReportsComponent implements OnInit {
  apiUrl = environment.apiUrl;
  publicFilesUrl = environment.cloudPublicFilesPrefix;
  retrievePublicReportFromApi: (fileName: string) => void;
  constructor(private downloadService: DownloadService) {
    const that = this;
    this.retrievePublicReportFromApi = (fileName: string) =>
      that.downloadService.retrievePublicReportFromApi(fileName);
  }

  ngOnInit() {}
}
