import { environment } from '../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../security/services/authentication.service';
import { UserDataService } from '../../user/services/user-data.service';
import { LookupHttpService } from '../../fastlane-common/services/lookup-http.service';
import { EmailService } from '../../email/services/email.service';
import * as i0 from "@angular/core";
import * as i1 from "../../security/services/authentication.service";
import * as i2 from "@angular/common/http";
import * as i3 from "../../user/services/user-data.service";
import * as i4 from "../../fastlane-common/services/lookup-http.service";
import * as i5 from "../../email/services/email.service";
export class AttachmentsService {
    constructor(authService, http, userDataService, lookupService, emailService) {
        this.authService = authService;
        this.http = http;
        this.userDataService = userDataService;
        this.lookupService = lookupService;
        this.emailService = emailService;
        this.needRefresh = new Subject();
        this.addExpenditure = new Subject();
        this.newAttachments = [];
        this.newAttachmentsGeneration = [];
    }
    addToNewAttachments(attachment) {
        this.newAttachments.push(attachment.documentName.toString());
        this.newAttachmentsGeneration.push(attachment.generation);
    }
    removeFromNewAttachments() {
        this.newAttachments = [];
        this.newAttachmentsGeneration = [];
    }
    callRefresh() {
        this.needRefresh.next();
    }
    emailNewAttachments(project) {
        const that = this;
        var recipients = [];
        return new Observable(subscriber => {
            this.userDataService.getUserByEmail("amanda.hafford@la.gov").subscribe(user => {
                recipients.push(user);
                this.lookupService.getProgramManager(project.projectInfo.incentiveProgram).subscribe(programManager => {
                    recipients.push(programManager);
                    that.emailService.sendDMattachmentsUploadedEmail(project.projectInfo.projectId, recipients, this.newAttachments, project.id)
                        .subscribe(successfulEmail => {
                        this.removeFromNewAttachments();
                        subscriber.next();
                        subscriber.complete();
                    }, errorEmailing => {
                        subscriber.error(errorEmailing);
                    });
                });
            });
        });
    }
    uploadExpenditures(attachment, attachmentPrefix, userContext) {
        const uploadFileUrl = `${environment.apiUrl}/file/upload/${userContext}?filenamePrefix=${encodeURIComponent(attachmentPrefix)}`;
        const headers = { 'Authorization': this.authService.getAuthHeaderValue() };
        let fileData = new FormData();
        fileData.append('files', attachment);
        this.http.post(uploadFileUrl, fileData, { headers })
            .subscribe(response => {
            this.newExpenditure = response[0];
            this.newExpenditure.documentName = attachment.name;
            this.newExpenditure.documentType = 'Preliminary Schedule of Expenditures';
            this.addExpenditure.next();
        });
    }
}
AttachmentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AttachmentsService_Factory() { return new AttachmentsService(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.UserDataService), i0.ɵɵinject(i4.LookupHttpService), i0.ɵɵinject(i5.EmailService)); }, token: AttachmentsService, providedIn: "root" });
