import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { PersonComponent } from '../../../../shared/components/person/person.component';
import { User } from '../../../models/user.model';
import { managementPermissionLabels } from '../../../user.constants';

import * as _ from 'underscore';
import { UserPermissions } from '../../../models/user-permissions.model';
import { Programs } from 'app/project/models/programs.model';

@Component({
  selector: 'fl-user-detail-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss']
})
export class UserDetailManagerComponent implements OnDestroy, OnInit {
  @Input() form: FormGroup;
  @Input() user: User;
  @ViewChild(PersonComponent, { static: true }) personComponent: PersonComponent;
  hasProgramChecked = false;
  permissionPropertyNameAndLabelPairs;
  selectedPrograms: Programs = new Programs();
  userForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  changePermission(value) {
    if (this.user) {
      this.user.permissions[value] = !this.user.permissions[value];
    }
  }

  ngOnDestroy() {
    // Remove the form from the parent form
    this.form.removeControl('managementUser');

    // Remove any managerial permissions from this user
    // this.user.permissions = null;
  }

  ngOnInit() {
    // Create this object so that we can easily do an ngFor loop in the template for displaying permission switches
    this.permissionPropertyNameAndLabelPairs = _.pairs(
      managementPermissionLabels
    );

    // This component doesn't neeed a group for itself, but rather simple as a container for its child forms
    this.userForm = this.fb.group({});

    // Add this form group to the parent form
    this.form.addControl('managementUser', this.userForm);
  }

  setFormData(user: User) {
    this.personComponent.setFormData(user);
  }
}
