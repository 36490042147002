import { BoardMeeting } from '../../board/models/board-meeting.model';
import { User } from '../../user/models/user.model';
import { ProjectFormWrapper } from '../../project/models/project-form-wrapper.model';
import { ExpenditurePerCreditType } from '../../entertainment/credit.constants';
import {
  externalReviewStatuses,
  updateFormDateActions,
  programChangedActions,
  IncentiveProgram,
  expenditureActions,
  vfxActions,
  auditDepositTransactionActions,
  boardStatus,
  companyLlcActions
} from '../../project/project.constants';
import {
  formStatuses,
  formCertificationStatus
} from '../../form/form.constants';
import { agreementStatus } from '../../management/signatures/signatures.constants';
import { userType } from '../../user/user.constants';
import { formStatus } from '../../form/form.constants';
//#region NotificationTypes

export type EventType =
  | 'formStatusChanges'
  | 'formCertificationChanges'
  | 'agreementStatusChanges'
  | 'externalReviewChanges'
  | 'boardStatusChanges'
  | 'boardAgendaChanges'
  | 'expenditureChanged'
  | 'creditEligibilityChanged'
  | 'scheduleEmailReminder'
  | 'selectedProgramsChanged'
  | 'requestFormPayment'
  | 'applicantReminders'
  | 'auditManagementChange';

//#endregion

//#region NotificationInterfaces
export interface Event {
  display: string;
  code: EventType;
  actions: string[];
  contextDataType:
    | { boardMeeting: BoardMeeting; boardMembers: User[] }
    | ProjectFormWrapper[]
    | ProjectFormWrapper
    | ExpenditurePerCreditType
    | IncentiveProgram[]
    | boolean;
}

export interface Notifications {
  [key: string]: Event;
}

export interface UserTypeToNotificationMap {
  [key: string]: EventType[];
}

export interface NotificationPreferences {
  userId: string;
}
//#endregion

//#region NotificationConstants

export const applicantReminders = {
  costReportSubmission: 'Cost Report',
  photographyStartDateSubmission: 'Photography Start',
  additionalApplicationNeeded: 'Additional Application'
};

// These are the actions which will change the dates.

// This constant is used to populate multi-select dropdowns for the events in the user-details screen.
export const events = {
  formStatusChanges: <Event>{
    display: 'Form Status Changes',
    code: <EventType>'formStatusChanges',
    actions: [
      formStatuses.informationRequired.name,
      formStatuses.reviewComplete.name,
      formStatuses.onHold.name,
      formStatuses.cancelled.name,
      formStatuses.auditorAssigned.name,
      formStatuses.received.name
    ]
  },
  formCertificationChanges: <Event>{
    display: 'Form Certification Changes',
    code: <EventType>'formCertificationChanges',
    actions: [formCertificationStatus.certified.display]
  },
  externalReviewStatusChanges: <Event>{
    display: 'External Review Status Changes',
    code: <EventType>'externalReviewStatusChanges',
    actions: [
      externalReviewStatuses.pendingReview.displayName,
      externalReviewStatuses.objection.displayName,
      externalReviewStatuses.objectionLifted.displayName
    ]
  },
  agreementStatusChanges: <Event>{
    display: 'Agreement Status Changes',
    code: <EventType>'agreementStatusChanges',
    actions: [agreementStatus.executed]
  },
  boardStatusChanges: <Event>{
    display: 'Board Status Changes',
    code: <EventType>'boardStatusChanges',
    actions: [boardStatus.approved, boardStatus.denied, 'Publish']
  },
  boardAgendaChanges: <Event>{
    display: 'Board Agenda Changes',
    code: <EventType>'boardAgendaChanges',
    actions: ['Publish'],
    contextDataType: <{ boardMeeting: BoardMeeting; boardMembers: User[] }>{}
  },
  updateBalanceRequest: <Event>{
    display: 'Balance Adjusted',
    code: <EventType>'updateBalanceRequest',
    actions: ['Refund', 'Request'],
    contextDataType: <ProjectFormWrapper>{}
  },
  expenditureChanged: <Event>{
    display: 'Expenditure Changed',
    code: <EventType>'expenditureChanged',
    actions: [
      expenditureActions.laExpenditure,
      expenditureActions.laVfx,
      expenditureActions.laResidentPayroll
    ],
    contextDataType: <ExpenditurePerCreditType>{}
  },
  creditEligibilityChanged: <Event>{
    display: 'Credit Eligibility Changed',
    code: <EventType>'creditEligibilityChanged',
    actions: [
      'percentPhotographyDaysOutside',
      'isScreenPlayCreatedByResident',
      'percentVfxinLA',
      'parishChanged'
    ],
    contextDataType: <any>{}
  },
  scheduleEmailReminder: {
    display: 'Schedule Email Reminder',
    code: <EventType>'scheduleEmailReminder',
    actions: []
  },
  updateFormDate: <Event>{
    display: 'Updated Form Date',
    code: <EventType>'updateFormDate',
    actions: [
      updateFormDateActions.initCertTemplateChanged,
      updateFormDateActions.costReportSubmissionDateChanged,
      updateFormDateActions.principalPhotographyStartDateChanged,
      updateFormDateActions.auditPeriodChanged,
      updateFormDateActions.relatedApplicationReceivedDateChanged,
      updateFormDateActions.initialCertIssuanceDateChanged,
      updateFormDateActions.costReportFinalEligibleDateChanged,
      updateFormDateActions.principalPhotographyStartDueDateChanged,
      updateFormDateActions.eligibleExpenditureStartDateChanged,
      updateFormDateActions.eligibleExpenditureEndDateChanged,
      updateFormDateActions.postProductionCostReportDateChanged,
      updateFormDateActions.actualExpenditureStartDateChanged,
      updateFormDateActions.actualExpenditureEndDateChanged,
      updateFormDateActions.auditDateChanged,
      updateFormDateActions.finalCertIssuanceDateChanged,
      updateFormDateActions.closureDateChanged,
      updateFormDateActions.costReportReceivedDateChanged,
      updateFormDateActions.verificationReportReceivedDateChanged,
      updateFormDateActions.proofofPrincipalPhotographyReceivedDateChanged,
      updateFormDateActions.cocExtensionRequested
    ]
  },
  companyIsLlc : <Event>{
    display : 'Company is LLC',
    code: <EventType>'companyIsLlc',
    actions: [companyLlcActions.isLlc]

  },
  selectedProgramsChanged: <Event>{
    display: 'Selected Programs Changed',
    code: <EventType>'selectedProgramsChanged',
    actions: [programChangedActions.programChanged],
    contextDataType: <IncentiveProgram[]>{}
  },
  vfxDetailsChanged: <Event>{
    display: 'VFX company Details Changed',
    code: <EventType>'vfxDetailsChanged',
    actions: [vfxActions.vfxinLAChaged],
    contextDataType: <boolean>{}
  },
  auditDepositTransaction: <Event>{
    display: 'Audit Deposit Transactions',
    code: <EventType>'auditDepositTransaction',
    actions: [
      auditDepositTransactionActions.requestPayment,
      auditDepositTransactionActions.requestRefund,
      auditDepositTransactionActions.OutgoingpaymentRequest,
      auditDepositTransactionActions.OutgoingpaymentCancelled
    ]
  },
  applicantReminders: <Event>{
    display: 'Applicant Reminders',
    code: <EventType>'applicantReminders',
    actions: [
      applicantReminders.photographyStartDateSubmission,
      applicantReminders.costReportSubmission,
      applicantReminders.additionalApplicationNeeded
    ]
  },
  auditManagementChange: <Event>{
    display: 'Audit Management Change',
    code: <EventType>'auditManagementChange',
    actions: [
      formStatus.auditorAssigned,
      formStatus.awaitingAudit,
      formStatus.received
    ]
  }
};

export const userTypeToNotificationsMap: UserTypeToNotificationMap = {
  [userType.management.code]: [
    events.formStatusChanges.code,
    events.boardStatusChanges.code,
    events.agreementStatusChanges.code,
    events.externalReviewStatusChanges.code,
    events.formCertificationChanges.code,
    events.updateBalanceRequest.code,
    events.auditManagementChange.code,
    events.auditDepositTransaction.code
  ],
  [userType.applicant.code]: [],
  [userType.assessor.code]: [
    events.agreementStatusChanges.code,
    events.boardStatusChanges.code,
    events.formStatusChanges.code
  ],
  [userType.board.code]: [],
  lga: [
    events.formStatusChanges.code,
    events.externalReviewStatusChanges.code,
    events.agreementStatusChanges.code,
    events.boardStatusChanges.code
  ],
  ldr: [
    events.agreementStatusChanges.code,
    events.externalReviewStatusChanges.code,
    events.formCertificationChanges.code
  ],
  lwc: [events.externalReviewStatusChanges.code],
  led: [events.externalReviewStatusChanges.code]
};

export const publishSubscribeEventStrings = {
  user_saved: 'user-saved',
  project_created: 'project-created',
  refreshAttachmentValidity: 'refreshAttachmentValidity',
  attachmentsUpdated: 'attachmentsUpdated',
  affiliate_saved: 'affiliate-saved',
  llc_saved: 'llc-saved-',
  amountDueChanged: 'amountDueChanged',
  schedule1_saved: 'schedule1-saved',
  schedule2_saved: 'schedule2-saved',
  schedule3_saved: 'schedule3-saved',
  update_formDate: 'update-formDate',
  formDateChanged: 'formDateChanged',
  principal_photography_uploaded: 'principalPhotographyUploaded',
  refreshAllExpenditureTotals: 'refreshAllExpenditureTotals',
  creditsRefreshed: 'creditsRefreshed'
};
//#endregion
