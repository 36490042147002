import { FeeRange } from './fee-range.model';

export class FeeParameters {
  public capAmount: number; // EZ
  public feeRange: FeeRange;
  public feeRate: number;

  constructor(params?: any) {
    if (!params) {
      params = {};
    }
    this.capAmount = params.capAmount || 0;
    this.feeRange = params.feeRange || new FeeRange();
    this.feeRate = params.feeRate || this.feeRate || 0;
  }
}
