export class SalesTaxInvestmentTax {
  anticipatedBenefit?: string;
  estimatedLocalRebate: number;
  estimatedStateRebateOrCredit: number;
  localSalesUseTaxPaid?: number;
  projectFacilityExpenseRebate?: number;
  seekingRebateOrInvestmentCredit: boolean;
  stateSalesUseTaxPaid?: number;

  constructor(taxes?: any) {
    taxes = taxes || {};
    this.seekingRebateOrInvestmentCredit =
      taxes.seekingRebateOrInvestmentCredit === undefined ||
      taxes.seekingRebateOrInvestmentCredit == null
        ? null
        : taxes.seekingRebateOrInvestmentCredit;
    this.estimatedStateRebateOrCredit =
      taxes.estimatedStateRebateOrCredit || null;
    this.estimatedLocalRebate = taxes.estimatedLocalRebate || null;
    this.stateSalesUseTaxPaid = taxes.stateSalesUseTaxPaid || 0;
    this.localSalesUseTaxPaid = taxes.localSalesUseTaxPaid || 0;
    this.projectFacilityExpenseRebate = taxes.projectFacilityExpenseRebate || 0;
    this.anticipatedBenefit = taxes.anticipatedBenefit || '';
  }
}
