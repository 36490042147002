import { ComplianceForm } from './compliance-form.model';
import { formTypes } from 'app/form/form.constants';

export class ProjectCompletionReport extends ComplianceForm {
  operationStartDate: Date;
  benefit: string;

  constructor(formIndex: number = null) {
    super(formIndex);
    this.init();
  }

  init() {
    super.init();
    if (this.operationStartDate) {
      this.operationStartDate = new Date(this.operationStartDate);
    }
    this.type = formTypes.pcr.abbrev;
  }

  get qualifier() {
    return '';
  }
}
