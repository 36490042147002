import { ProjectForm } from '../../../project/models/form.model';

export abstract class ComplianceForm extends ProjectForm {
  startDate: Date;
  endDate: Date;
  comments?: string;

  constructor(formIndex: number = null) {
    super(formIndex);
  }

  init() {
    super.init();
    if (this.startDate) {
      this.startDate = new Date(this.startDate);
    }
    if (this.endDate) {
      this.endDate = new Date(this.endDate);
    }
  }
}
