import { CreditType } from './models/creditType.model';
import { creditTypes, baseCreditTypes } from './entertainment.constants';
import { chain } from 'underscore';
import { Credit } from './models/film/credit.model';
import {
  creditTypeFormulaDictionary,
  ExpenditurePerCreditType,
  CreditTypes
} from './credit.constants';
import { ProjectForm } from '../project/models/form.model';
import { legistlationRules } from '../project/project.constants';
import { Project as FilmProject } from './models/film/project.model';
import { FormIdentifier } from '../project/models/form-identifier.model';
import { ProjectInfo } from './models/film/project-info.model';
import { formTypes } from '../form/form.constants';
/**
 * @summary Change credit type on the fly.
 */
export function translateCreditType(creditType: string) {
  if (!creditType) {
    return '';
  }
  return creditType.toLowerCase() === CreditTypes.inState.toLowerCase()
    ? CreditTypes.laResident
    : creditType.toLowerCase() === CreditTypes.outOfState.toLowerCase()
    ? CreditTypes.nonLaResident
    : CreditTypes.nonLabor;
}
export function getAllCreditTypes(
  form: ProjectForm,
  incentiveProgram: string,
  effectiveDate?: Date,
  legislationRule?: string
): CreditType[] {
  if (!legislationRule) {
    legislationRule = getLegislationRule(incentiveProgram, effectiveDate);
  }
  const creditTypeArray =
    creditTypeFormulaDictionary[legislationRule].creditTypes;
  return creditTypeArray.map(cd => {
    const func = cd.creditTypeDetails;
    return func(form);
  });
}

export function getBaseInvestmentCap(
  incentiveProgram: string,
  legislationRule?: string,
  effectiveDate?: Date
) {
  if (!legislationRule) {
    legislationRule = getLegislationRule(incentiveProgram, effectiveDate);
  }
  return creditTypeFormulaDictionary[legislationRule].baseInvestmentCap;
}

export function getCapPerSeason(
  incentiveProgram: string,
  legislationRule?: string,
  effectiveDate?: Date
): number {
  if (!legislationRule) {
    legislationRule = getLegislationRule(incentiveProgram, effectiveDate);
  }
  return creditTypeFormulaDictionary[legislationRule].capPerSeason;
}

export function getDefaultCredits(
  form: ProjectForm,
  incentiveProgram: string,
  formId: FormIdentifier,
  creditTypeArray?: CreditType[],
  effectiveDate?: Date,
  legislationRule?: string
): Credit[] {
  if (!legislationRule) {
    legislationRule = getLegislationRule(incentiveProgram, effectiveDate);
  }
  if (!creditTypeArray) {
    creditTypeArray = getAllCreditTypes(
      form,
      incentiveProgram,
      effectiveDate,
      legislationRule
    );
  }
  return creditTypeArray.map(cd => {
    const credit = Object.assign(new Credit({ formIdentifier: formId }), cd);
    return credit;
  });
}

export function getCreditTypeName(abbrevCreditType: string): string {
  return chain(creditTypes)
    .pairs()
    .map(pair => {
      return {
        abbrev: pair[1].abbrev,
        name: pair[1].name
      };
    })
    .value()
    .filter(ct => ct.abbrev === abbrevCreditType)[0].name;
}

export function getLegislationRule(
  incentiveProgram: string,
  effectiveDate?: Date
): string {
  if (!effectiveDate) {
    effectiveDate = new Date();
  }
  const specificLegislationRule =
    legistlationRules[incentiveProgram.toLowerCase()];
  for (let i = 0; i < specificLegislationRule.length; i++) {
    if (
      !specificLegislationRule[i].effectiveDate ||
      effectiveDate.getTime() >
        specificLegislationRule[i].effectiveDate.getTime()
    ) {
      return specificLegislationRule[i].ruleName;
    }
  }
}

export function getCalculatedCreditAmount(creditType: CreditType): CreditType {
  creditType.creditTypeAmount =
    creditType.expenditureAmount >= 0
      ? creditType.maxExpenditureAmount >= 0 &&
        creditType.maxExpenditureAmount != null
        ? creditType.expenditureAmount > creditType.maxExpenditureAmount
          ? creditType.maxExpenditureAmount * creditType.rate
          : creditType.expenditureAmount * creditType.rate
        : creditType.expenditureAmount * creditType.rate
      : null;
  return creditType;
}

export function updateEligibility(
  creditTypeArray: CreditType[],
  projectInfo: ProjectInfo,
  form: ProjectForm
): CreditType[] {
  let legislationRule = projectInfo.legislation;
  if (!legislationRule) {
    legislationRule = getLegislationRule(projectInfo.incentiveProgram);
  }
  creditTypeArray.map(cdType => {
    const ctArray = creditTypeFormulaDictionary[
      legislationRule
    ].creditTypes.find(cd => cd.abbrev === cdType.creditType);
    cdType.isEligible = ctArray.eligibility(projectInfo, form);
    // if the applicant already applies for this creditType
    // then uncheck this creditType if this creditType is not eligible.
    if (
      cdType.isApplied &&
      [formTypes.application.abbrev, formTypes.asa.abbrev].includes(form.type)
    ) {
      cdType.isApplied = cdType.isEligible;
    }
    // if the manager approves the credit type and it is not eligible
    // then disapprove the creditType.
    if (cdType.isReserved) {
      cdType.isReserved = cdType.isEligible;
    }
  });
  return creditTypeArray;
}

/**
 * When Expenditure Changed Event is raised this function is called.
 * This will updated expediture amounts in the CreditTypes and Credits.
 * @param estimatedCreditTypes credit Types in the current form.
 * @param credits all the credits that belong to the current form.
 * @param value updated expenditure amount for that credit Type
 */
export function updateCreditTypesAndCreditsOnExpenditureChanged(
  estimatedCreditTypes: CreditType[],
  value: ExpenditurePerCreditType,
  credits?: Credit[]
) {
  estimatedCreditTypes.forEach(ct => {
    if (value.creditType === ct.creditType) {
      ct.expenditureAmount = value.amount;
      getCalculatedCreditAmount(ct);
      // Credits are provided only for initial Cert and audit.
      updateCredits(credits, ct);
    } else {
      // making sure when base credit Type is updated screen play and out of MSA is also updated.
      if (
        value.creditType === creditTypes.base.abbrev &&
        baseCreditTypes.includes(ct.creditType)
      ) {
        ct.expenditureAmount = value.amount;
        getCalculatedCreditAmount(ct);
        // Credits are provided only for initial Cert and audit.
        updateCredits(credits, ct);
      }
    }
  });
}

/**
 * This function will update the credits related to this credityType when
 * Expenses changes or anything else pertaining to CredityType Changes.
 * @param credits credits that needs to be updated.
 * @param updatedCreditType helps update credits only with this credit type
 */
export function updateCredits(
  credits: Credit[],
  updatedCreditType: CreditType
) {
  if (credits && credits.length > 0) {
    credits.map(cd => {
      if (cd.creditType === updatedCreditType.creditType) {
        Object.assign(cd, updatedCreditType);
      }
    });
  }
}
