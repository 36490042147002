import { Injectable } from '@angular/core';
import { FormShareService } from './share.service';
import { FeeCalculations } from '../../fiscal/models/fee-calculations.model';
import {
  feeTypeSchedule,
  feeParameterSchedule,
  feeFormulaSchedule,
  benefitParameterSchedule,
  benefitFormulaSchedule
} from '../../fiscal/fiscal.constants';
import { FeeParameters } from '../../fiscal/models/fee-parameters.model';
import { FormPayment } from '../models/form-payment.model';
import { FormDataService } from './data.service';
import { Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { UserContextService } from '../../user/services/user-context.service';
import { userType } from '../../user/user.constants';
import { BenefitParameters } from '../../fiscal/models/benefit-parameters.model';
import { round, deepCopy } from '../../shared/shared.functions';
import { AffidavitFinalCost } from '../../incentive/compliance/models/affidavit-final-cost.model';
import {
  legislationRuleNames,
  incentiveCategory,
  legistlationRules
} from '../../project/project.constants';
import { BalanceAdjustmentRequest } from '../../project/models/balance-adjustment-request.model';
import { programTypes } from '../../shared/shared.constants';
import {formStatuses, formTypes} from '../form.constants';

@Injectable()
export class FormFeeService {
  newFeeSubject: Subject<FeeCalculations>;

  // This property is set to true when there is a new fee calculation that has not been committed to the database yet
  private feesChanged: boolean;
  private formSavedSub: Subscription;

  constructor(
    private dataService: FormDataService,
    private userContext: UserContextService,
    private shareService: FormShareService
  ) {
    const that = this;
    this.feesChanged = false;

    // Every time the form is saved, we reset this property back to it's default
    // (this is part of the implementation for fees to be saved at most ONCE per 'save' action)
    this.formSavedSub = dataService.formSavedSubject.subscribe(
      success => (that.feesChanged = false)
    );

    // Instantiate the subject that will be next'd whenever fees change
    this.newFeeSubject = new Subject<FeeCalculations>();
  }

  calculateAdValorem() {
    // Get variables for the upcoming operations
    const form = this.shareService.form;
    const project = this.shareService.project;
    const incentiveProgram = project.projectInfo.incentiveProgram;

    // ad valorem calculations are only for ITE and RTA as of now
    if (form.type.toLowerCase() === 'application') {
      // for RTA
      if (incentiveProgram.toLowerCase() === 'rta') {
        const adValorem: number =
          ((project.application.estimatedInvestments.buildingAndMaterials
            ? project.application.estimatedInvestments.buildingAndMaterials
            : 0) +
            (project.application.estimatedInvestments.laborAndEngineering
              ? project.application.estimatedInvestments.laborAndEngineering
              : 0) +
            (project.application.estimatedInvestments.machineryAndEquipment
              ? project.application.estimatedInvestments.machineryAndEquipment
              : 0)) *
          (project.application.fees[0].benefitParameters.benefitRate
            ? project.application.fees[0].benefitParameters.benefitRate
            : 0) *
          (project.application.millageRate
            ? project.application.millageRate
            : 0);

        project.application.rta.annualAdvalorem = round(adValorem, 2);
      }

      // For ITE
      if (incentiveProgram.toLowerCase() === 'ite') {
        const adValorem: number =
          ((project.application.estimatedInvestments.buildingAndMaterials
            ? project.application.estimatedInvestments.buildingAndMaterials
            : 0) +
            (project.application.estimatedInvestments.laborAndEngineering
              ? project.application.estimatedInvestments.laborAndEngineering
              : 0) +
            (project.application.estimatedInvestments.machineryAndEquipment
              ? project.application.estimatedInvestments.machineryAndEquipment
              : 0) -
            (project.application.estimatedInvestments.restrictedAmount
              ? project.application.estimatedInvestments.restrictedAmount
              : 0)) *
          project.application.fees[0].benefitParameters.assessmentPercentage *
          (project.application.millageRate
            ? project.application.millageRate
            : 0);

        if (
          project.projectInfo.legislation ===
          legislationRuleNames.ite.postExeOrd_2018.name
        ) {
          project.application.ite.annualAdValorem = round(adValorem * 0.8, 2);
        } else {
          project.application.ite.annualAdValorem = round(adValorem, 2);
        }
      }
    }

    if (form.type.toLowerCase() === 'rnw') {
      // for ITE
      let affidavitFinalCost: AffidavitFinalCost;
      if (incentiveProgram.toLowerCase() === 'ite') {
        // get the latest afc from the AFC array
        if (project.afc.length > 0) {
          const approvedAfcs = project.afc.filter(x => x.statuses[0].status === formStatuses.reviewComplete.name);
          if (approvedAfcs.length > 0) {
            const itemOfArray = approvedAfcs.length - 1;
            affidavitFinalCost = approvedAfcs[itemOfArray];
          }
        }

        const advalorem =
          (project.rnw.ite.annualAdValorem =
            (affidavitFinalCost.equipmentTotal
              ? affidavitFinalCost.equipmentTotal
              : 0) +
            (affidavitFinalCost.buildingTotal
              ? affidavitFinalCost.buildingTotal
              : 0) -
            (affidavitFinalCost.restrictedAmount
              ? affidavitFinalCost.restrictedAmount
              : 0)) *
          0.15 *
          project.rnw.ite.millageRate;

        if (
          project.projectInfo.legislation ===
          legislationRuleNames.ite.postExeOrd_2018.name
        ) {
          project.rnw.ite.annualAdValorem = round(advalorem * 0.8, 2);
          project.rnw.ite.annualAdValoremDepreciation = round(
            advalorem * 0.73 * 0.8,
            2
          );
        } else {
          project.rnw.ite.annualAdValorem = round(advalorem, 2);
          project.rnw.ite.annualAdValoremDepreciation = round(
            advalorem * 0.73,
            2
          );
        }
      }
    }
  }

  recalculateFees() {
    // Get variables for the upcoming operations
    const form = this.shareService.form;
    const formId = this.shareService.formId;
    const project = this.shareService.project;
    const projectId = project.projectInfo.projectId;
    const incentiveProgram = project.projectInfo.incentiveProgram;

    // Recalculate new fee
    const newFeeCalculations = this.getFeeCalculations(
      projectId,
      incentiveProgram,
      formId.formType,
      form,
      this.shareService.payments
    );

    // Update fees list with latest fee calculation (if necessary)
    if (!form.fees || !form.fees[0]) {
      // Sometimes the fees array is empty (new forms case)
      this.feesChanged = true;
      this.shareService.form.fees.push(newFeeCalculations);
    } else if (
      JSON.stringify(newFeeCalculations) !== JSON.stringify(form.fees[0])
    ) {
      // When array has values prior to calculation, we only want to add to the fees array if the new calculation
      // is different than the last calculation

      if (this.feesChanged) {
        // Within a form, sometimes the fee changes multiple times based on some of the fields
        // If it changes multiple times before saving, we replace the latest calculation with the new calculation
        this.shareService.form.fees[0] = newFeeCalculations;
      } else {
        this.feesChanged = true;
        this.shareService.form.fees.unshift(newFeeCalculations);
      }
    } else {
      return;
    }

    this.newFeeSubject.next(newFeeCalculations);
  }

  getAmountPaid(payments: FormPayment[]) {
    // Aggregate payments (if available)
    return !payments || payments.length === 0
      ? 0
      : payments.reduce((sum, payment) => sum + payment.amountPaid, 0);
  }

  private getFeeCalculations(
    projectId: string,
    incentiveProgram: string,
    formType: string,
    form: any,
    payments: FormPayment[]
  ): FeeCalculations {
    // Force strings to be lowercase
    incentiveProgram = incentiveProgram.toLowerCase();
    formType = formType.toLowerCase();

    // returning empty fee Calculations when the manager creates a ccc.
    // TODO: Similar implementation for manager for the initcert.
    if (
      formType === 'ccc' &&
      this.userContext.currentUser.userType.toLowerCase() ===
        userType.management.code.toLowerCase()
    ) {
      return new FeeCalculations();
    }

    // Use the first payment received date (if applicable) or today's date for determining which algorithm to use
    const feeEffectiveDate: Date = this.shareService.determineFeeEffectiveDate();

    // new - get the legislation rule instead of advance received date
    const legislationRule: string = this.shareService.getLegislationRule();

    // With payment date, do lookup for the algorithm to use

    const benefitParams = this.getBenefitParams(
      feeEffectiveDate,
      legislationRule,
      incentiveProgram,
      formType
    );
    const feeParams = this.getFeeParams(
      feeEffectiveDate,
      incentiveProgram,
      formType
    );

    // benefit formula calculated based on new Legislation Rules
    const benefitFormula = this.getBenefitFormula(
      legislationRule,
      incentiveProgram,
      formType
    );
    const estimatedBenefits = benefitFormula(projectId, form, benefitParams);

    const feeFormula = this.getFeeFormula(
      feeEffectiveDate,
      incentiveProgram,
      formType
    );
    // Exception for ez application.
    if (
      legislationRule !== legislationRuleNames.ez.postAct18_2016.name &&
      incentiveProgram.toLowerCase() === programTypes.ez.name &&
      formType === formTypes.application.abbrev
    ) {
      feeParams.capAmount = null;
    }

    // Execute algorithm
    const amountPaid = this.getAmountPaid(payments);
    const feeCalculations = feeFormula(
      projectId,
      form,
      amountPaid,
      feeParams,
      estimatedBenefits
    );

    // add the benefit params to the feeCalculations object
    feeCalculations.benefitParameters = benefitParams; // used in html

    // Round values and return
    return feeCalculations.roundValues();
  }

  private getFeeFormula(
    paymentDate: Date,
    incentiveProgram: string,
    formType: string
  ): (
    projectId?: string,
    form?: any,
    amountPaid?: number,
    params?: FeeParameters,
    estimatedBenefits?: number
  ) => FeeCalculations {
    // Get fee formula schedule
    const specificFeeFormulaSchedule =
      feeFormulaSchedule[incentiveProgram][formType];

    for (let i = 0; i < specificFeeFormulaSchedule.length; i++) {
      if (
        !specificFeeFormulaSchedule[i].effectiveDate ||
        paymentDate.getTime() >
          specificFeeFormulaSchedule[i].effectiveDate.getTime()
      ) {
        return specificFeeFormulaSchedule[i].feeFormula;
      }
    }
  }

  //  new legislation rule - get the benefit formula based on legislation rules
  private getBenefitFormula(
    legislationRule: string,
    incentiveProgram: string,
    formType: string
  ): (projectId?: string, form?: any, params?: BenefitParameters) => number {
    const specificFeeFormulaSchedule =
      benefitFormulaSchedule[incentiveProgram][formType];

    if (specificFeeFormulaSchedule) {
      for (let i = 0; i < specificFeeFormulaSchedule.length; i++) {
        if (
          specificFeeFormulaSchedule[i].legislationRule.includes(
            legislationRule
          ) // check if the legislation rule exists in the array
        ) {
          if (specificFeeFormulaSchedule[i].benefitFormula) {
            return specificFeeFormulaSchedule[i].benefitFormula;
          } else {
            break;
          }
        }
      }
    }

    return (projectId?: string, form?: any, params?: BenefitParameters) => 0;
  }

  private getFeeParams(
    paymentDate: Date,
    incentiveProgram: string,
    formType: string
  ): FeeParameters {
    if (!this.isFlatFee(paymentDate, incentiveProgram, formType)) {
      let specificFeeParameterSchedule =
        feeParameterSchedule[incentiveProgram][formType];
      if (specificFeeParameterSchedule) {
        specificFeeParameterSchedule = deepCopy(
          feeParameterSchedule[incentiveProgram][formType]
        );
        for (let i = 0; i < specificFeeParameterSchedule.length; i++) {
          if (
            !specificFeeParameterSchedule[i].effectiveDate ||
            paymentDate.getTime() >
              new Date(specificFeeParameterSchedule[i].effectiveDate).getTime()
          ) {
            return specificFeeParameterSchedule[i].feeParams;
          }
        }
      }
    }
  }

  // new leg rule based on legislation
  private getBenefitParams(
    paymentDate: Date,
    legislationRule: string,
    incentiveProgram: string,
    formType: string
  ): BenefitParameters {
    // if its a flat fee no need for benefit params
    if (!this.isFlatFee(paymentDate, incentiveProgram, formType)) {
      const specificBenefitParameterSchedule =
        benefitParameterSchedule[incentiveProgram][formType];
      if (specificBenefitParameterSchedule) {
        for (let i = 0; i < specificBenefitParameterSchedule.length; i++) {
          // check if the legislation rule exists in the array
          if (
            specificBenefitParameterSchedule[i].legislationRule.includes(
              legislationRule
            )
          ) {
            return specificBenefitParameterSchedule[i].benefitParams;
          }
        }
      }
    }
  }

  private isFlatFee(
    paymentDate: Date,
    incentiveProgram: string,
    formType: string
  ) {
    const specificFeeTypeSchedule = feeTypeSchedule[incentiveProgram][formType];
    for (let i = 0; i < specificFeeTypeSchedule.length; i++) {
      if (
        !specificFeeTypeSchedule[i].effectiveDate ||
        paymentDate.getTime() >
          specificFeeTypeSchedule[i].effectiveDate.getTime()
      ) {
        return specificFeeTypeSchedule[i].isFlatFee;
      }
    }
  }
}
