import { Person } from '../../shared/models/person.model';
import { UserPermissions } from './user-permissions.model';
import { EventNotificationPreferences } from '../../shared/models/event-notification-preference.model';
import { Programs } from '../../project/models/programs.model';
import { AuditInfo } from '../../entertainment/interfaces/audit-info.interface';

export class User extends Person {
  auditingCompanyId: string;
  public boards: string[] = null;
  public externalReviewAgency = null;
  public id: string = null;
  public incentivePrograms: string[] = [];
  public isActive: boolean = null;
  public legacyId: number = null;
  //  This is a property of a user for external reviewer and assessors not a property of address.
  public governingParish: string = null;
  public password: string = null;
  public permissions: UserPermissions = null;
  public userType: string = null;
  public eventNotificationPreferences: EventNotificationPreferences = null;

  init(): User {
    super.init();
    this.boards = this.boards || null;
    this.externalReviewAgency = this.externalReviewAgency || null;
    this.id = this.id || null;
    this.incentivePrograms = this.incentivePrograms || null;
    this.isActive = this.isActive || false;
    this.legacyId = this.legacyId || null;
    this.governingParish = this.governingParish || null;
    this.password = this.password || null;
    this.permissions = new UserPermissions(this.permissions);
    this.userType = this.userType || null;
    this.eventNotificationPreferences = this.eventNotificationPreferences || new EventNotificationPreferences();
    return this;
  }

  getPrograms(): Programs {
    return this.incentivePrograms.reduce((seed, next) => {
      seed[next.toLowerCase()] = true;
      return seed;
    }, new Programs());
  }
}