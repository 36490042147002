/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./permission-settings.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./permission-settings.component";
var styles_PermissionSettingsComponent = [i0.styles];
var RenderType_PermissionSettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PermissionSettingsComponent, data: {} });
export { RenderType_PermissionSettingsComponent as RenderType_PermissionSettingsComponent };
function View_PermissionSettingsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "d-flex col-xl-4 col-lg-6 col-12 py-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "col-8 form-control-label px-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "col-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "button", [["aria-pressed", "user.permissions[permission.value]"], ["class", "btn btn-toggle"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.togglePermission(_v.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "active": 0 }), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "handle"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "btn btn-toggle"; var currVal_2 = _ck(_v, 7, 0, _co.user.permissions[_v.context.$implicit.value]); _ck(_v, 6, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.display; _ck(_v, 2, 0, currVal_0); }); }
export function View_PermissionSettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "card m-t border border-primary"], ["style", "overflow:visible;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "card-header bg-primary text-white font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" PERMISSION SETTINGS "])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "card-body p-4"], ["style", "overflow:visible;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "d-flex flex-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PermissionSettingsComponent_1)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.availablePermissions; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_PermissionSettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fl-permission-settings", [], null, null, null, View_PermissionSettingsComponent_0, RenderType_PermissionSettingsComponent)), i1.ɵdid(1, 638976, null, 0, i3.PermissionSettingsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PermissionSettingsComponentNgFactory = i1.ɵccf("fl-permission-settings", i3.PermissionSettingsComponent, View_PermissionSettingsComponent_Host_0, { user: "user" }, {}, []);
export { PermissionSettingsComponentNgFactory as PermissionSettingsComponentNgFactory };
