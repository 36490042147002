import { BusinessLegalStructure } from './business-legal-structure.model';
import { PropertyTax } from './property-tax.model';

export class IndustrialTaxExemptionApplication {
  productManufactured: string;
  manufacturingProcess: string;
  adValorem: number;
  annualAdValorem: number;
  llcMembers: BusinessLegalStructure[];
  propertyTax: PropertyTax;
  isThisCompanyLlc: boolean;

  constructor(ite?: any) {
    if (!ite) ite = {};
    this.productManufactured = ite.productManufactured || null;
    this.manufacturingProcess = ite.manufacturingProcess || null;
    this.adValorem = ite.adValorem || null;
    this.annualAdValorem = ite.annualAdValorem || null;
    this.llcMembers = ite.llcMembers || [];
    this.propertyTax = ite.propertyTax || null;
    this.isThisCompanyLlc = ite.isThisCompanyLlc || false;
  }
}
