import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { User } from '../../../models/user.model';
import { PersonComponent } from '../../../../shared/components/person/person.component';
declare var $;

@Component({
  selector: 'fl-user-detail-assessor',
  templateUrl: './assessor.component.html',
  styleUrls: ['./assessor.component.scss']
})
export class UserDetailAssessorComponent implements AfterViewInit, OnInit {
  @Input() form: FormGroup;
  @Input() user: User;
  @ViewChild(PersonComponent, { static: true }) personComponent: PersonComponent;
  assessorForm = new FormGroup({});

  constructor() {}

  ngAfterViewInit() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  ngOnInit() {}

  setFormData(user: User) {
    this.personComponent.setFormData(user);
  }
}
