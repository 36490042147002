import { BusinessLegalStructure } from '../../../../project/models/business-legal-structure.model';
import { ContractChangeOwnership } from '../../models/contract-change-ownership.model';
export class QualityJobsCao {
  affiliates: BusinessLegalStructure[];
  llcMembers: BusinessLegalStructure[];
  oldAffiliates: BusinessLegalStructure[];
  oldLlcMembers: BusinessLegalStructure[];
  constructor(qj?: any) {
    if (!qj) qj = {};
    if (!this.affiliates) {
      this.affiliates = [];
    }
    if (!this.llcMembers) {
      this.llcMembers = [];
    }
    if (!this.oldAffiliates) {
      this.oldAffiliates = [];
    }
    if (!this.oldLlcMembers) {
      this.oldLlcMembers = [];
    }
  }
}
