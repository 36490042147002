import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectorRef
} from '@angular/core';
import { Expenditures } from '../../../models/film/expenditures.model';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  AbstractControlOptions,
  ValidatorFn,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { creditTypes } from '../../../entertainment.constants';
import { FormValidationService } from '../../../../form/services/validation.service';
import { PublishSubscribeService } from '../../../../fastlane-common/services/publish-subscribe.service';
import { ExpenditurePerCreditType } from '../../../credit.constants';
import { events } from '../../../../fastlane-common/event/event.constants';
import {
  expenditureActions,
  vfxActions
} from '../../../../project/project.constants';

@Component({
  selector: 'fl-film-expenditures',
  templateUrl: './expenditures.component.html',
  styleUrls: ['./expenditures.component.css']
})
export class ExpendituresComponent implements OnInit, OnDestroy {
  @Input() expenditures: Expenditures;
  @Input() isVfxinLa = false;
  @Input() readOnly = false;
  expendituresForm: FormGroup;
  private formGroupSubscription: Subscription;
  private subs: Subscription[] = [];
  laExpenditureValidator: ValidatorFn | ValidatorFn[];
  errorMessage: string;
  laExpenditureAbstractControl: AbstractControl;

  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private pubSubService: PublishSubscribeService,
    private validationService: FormValidationService
  ) {}

  ngOnDestroy() {
    // Destroy all subscriptions
    this.subs.forEach(sub => sub.unsubscribe());
    this.validationService.form.removeControl('expenditures');
  }

  ngOnInit() {
    // tslint:disable-next-line:max-line-length
    this.errorMessage = 'Estimated Louisiana Expenditures can not be less than sum of Estimated Louisiana Residential Payroll and Estimated Louisiana Visual Effects.';
    this.expendituresForm = this.formBuilder.group({
      totalBudget: [this.expenditures.totalBudget, Validators.required],
      aboveLineSalaries: [
        this.expenditures.aboveLineSalaries,
        Validators.required
      ],
      residentPayroll: [this.expenditures.residentPayroll, Validators.required],
      laVisualEffectsExpenditure: new FormControl(
        this.expenditures.laVisualEffectsExpenditure,
        <AbstractControlOptions>{
          updateOn: 'blur',
          validators: Validators.required
        }
      ),
      totalVisualEffectsExpenditure: [
        this.expenditures.totalVisualEffectsExpenditure,
        <AbstractControlOptions>{
          updateOn: 'blur',
          validators: Validators.required
        }
      ],
      laExpenditure: [this.expenditures.laExpenditure],
      doesLaSpendIncludeAirfareAndOthers: [
        this.expenditures.doesLaSpendIncludeAirfareAndOthers
      ]
    });
    const that = this;
    that.laExpenditureAbstractControl = that.expendituresForm.get('laExpenditure');
    that.laExpenditureValidator = [(control: AbstractControl): ValidationErrors => {
      const laExpenditureAmount = control.value ? control.value : null;

      // tslint:disable-next-line:max-line-length
      if (laExpenditureAmount < that.expendituresForm.get('residentPayroll').value
      || laExpenditureAmount < that.expendituresForm.get('laVisualEffectsExpenditure').value ) {
        return {
          errorMessage:
            this.errorMessage
        };
      }

      return null;
    }, Validators.required];

    this.formGroupSubscription = this.expendituresForm.valueChanges.subscribe(
      (value: Expenditures) => {
        that.expenditures.totalBudget = value.totalBudget;
        that.expenditures.aboveLineSalaries = value.aboveLineSalaries;
        that.expenditures.residentPayroll = value.residentPayroll;
        that.expenditures.laVisualEffectsExpenditure =
          value.laVisualEffectsExpenditure;
        that.expenditures.totalVisualEffectsExpenditure =
          value.totalVisualEffectsExpenditure;
        that.expenditures.laExpenditure = value.laExpenditure;
        that.expenditures.percentVfxinLA =
          that.expenditures.totalVisualEffectsExpenditure === 0 ||
          !that.expenditures.totalVisualEffectsExpenditure
            ? 0
            : that.expenditures.laVisualEffectsExpenditure /
              that.expenditures.totalVisualEffectsExpenditure;
        that.expenditures.doesLaSpendIncludeAirfareAndOthers =
          value.doesLaSpendIncludeAirfareAndOthers;
        that.pubSubService.raise<any>(
          events.creditEligibilityChanged.code,
          'percentVfxinLA',
          that.expenditures.percentVfxinLA
        );
      }
    );
    //#region Expenditure_Subscriptions
    

    this.expendituresForm.get('laExpenditure').setValidators(this.laExpenditureValidator);

    this.expendituresForm.get('residentPayroll').valueChanges.subscribe(value => {
      if(this.expendituresForm.get('laExpenditure').value > value)
      {
        //that.laExpenditureAbstractControl.errors.errorMessage = null ;
        
      }
    });

    this.expendituresForm.get('laVisualEffectsExpenditure').valueChanges.subscribe(value => {
      if(this.expendituresForm.get('laExpenditure').value > value)
      {
       // that.laExpenditureAbstractControl.errors.errorMessage = null ;
      
      }
    });

    this.expendituresForm.get('laExpenditure').valueChanges.subscribe(value => {
      // set the credit type which will be affected by change in this expenditure amount.
      const expenditureChangedValue = <ExpenditurePerCreditType>{
        amount: value,
        creditType: creditTypes.base.abbrev
      };
      that.expenditures.laExpenditure = value;
      // Broadcast the la expenditure change that needs to be emitted to all listeners
      that.pubSubService.raise<ExpenditurePerCreditType>(
        events.expenditureChanged.code,
        expenditureActions.laExpenditure,
        expenditureChangedValue
      );
    });

    this.expendituresForm
      .get('laVisualEffectsExpenditure')
      .valueChanges.subscribe(value => {
        // set the credit type which will be affected by change in this expenditure amount.
        const expenditureChangedValue = <ExpenditurePerCreditType>{
          amount: value,
          creditType: creditTypes.vfx.abbrev
        };
        that.pubSubService.raise<ExpenditurePerCreditType>(
          events.expenditureChanged.code,
          expenditureActions.laVfx,
          expenditureChangedValue
        );
      });

    this.expendituresForm
      .get('residentPayroll')
      .valueChanges.subscribe(value => {
        // set the credit type which will be affected by change in this expenditure amount.
        const expenditureChangedValue = <ExpenditurePerCreditType>{
          amount: value,
          creditType: creditTypes.payroll.abbrev
        };
        that.pubSubService.raise<ExpenditurePerCreditType>(
          events.expenditureChanged.code,
          expenditureActions.laResidentPayroll,
          expenditureChangedValue
        );
      });

    // Updates the vfx expenditures based on LA visual effects company details.
    that.pubSubService.handle<boolean>(
      events.vfxDetailsChanged.code,
      vfxActions.vfxinLAChaged,
      value => {
        that.updateVFXExpendituresFormGroup(value);
      }
    );
    //#endregion

    this.validationService.form.setControl(
      'expenditures',
      this.expendituresForm
    );
    setTimeout(() => {
      if (that.isVfxinLa === false) {
        that.updateVFXExpendituresFormGroup(that.isVfxinLa);
      }
    });
    this.cdr.detectChanges();

    if (this.readOnly) {
      this.expendituresForm.disable();
    }
  }

  updateVFXExpendituresFormGroup(value: boolean) {
    const that = this;
    if (value) {
      // Enable the form controls
      that.expendituresForm.get('laVisualEffectsExpenditure').enable();
      that.expendituresForm.get('totalVisualEffectsExpenditure').enable();

      // Set the required validator and number pattern validator
      that.expendituresForm
        .get('laVisualEffectsExpenditure')
        .setValidators([
          Validators.required,
          Validators.pattern(new RegExp('\\d+'))
        ]);

      that.expendituresForm
        .get('totalVisualEffectsExpenditure')
        .setValidators([
          Validators.required,
          Validators.pattern(new RegExp('\\d+'))
        ]);

      // Refresh the values and validity of the form controls
      that.expendituresForm
        .get('laVisualEffectsExpenditure')
        .updateValueAndValidity();
      that.expendituresForm
        .get('totalVisualEffectsExpenditure')
        .updateValueAndValidity();
    } else {
      // When vfx is not in LA then set vfx expenditures to 0.
      that.expendituresForm.get('laVisualEffectsExpenditure').setValue(0);
      that.expendituresForm.get('totalVisualEffectsExpenditure').setValue(0);

      // Then disable the formcontrols.
      that.expendituresForm
        .get('laVisualEffectsExpenditure')
        .disable({ emitEvent: false });
      that.expendituresForm
        .get('totalVisualEffectsExpenditure')
        .disable({ emitEvent: false });

      // Remove the validators
      that.expendituresForm.get('laVisualEffectsExpenditure').clearValidators();
      that.expendituresForm
        .get('totalVisualEffectsExpenditure')
        .clearValidators();

      // Update the values and validity of the form control.
      that.expendituresForm
        .get('laVisualEffectsExpenditure')
        .updateValueAndValidity({ emitEvent: false });
      that.expendituresForm
        .get('totalVisualEffectsExpenditure')
        .updateValueAndValidity();
    }
  }
}
