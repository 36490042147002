export class PrimaryQualification {

  public isManufacturer?: boolean;

  public isOilAndGasServiceBusiness?: boolean;

  public hasHalfSalesOfOutOfStateCustomers?: boolean;

  public isLocatedInDistressedRegion: boolean;

  public isParishWithLowPerCapita?: boolean;

  public lowPerCapitaParish: string;

  public isCensusTractBlockGroup?: boolean;

  public censusTractBlockGroup: string;

  public medianPerCapitaIncome: number;

  public medianPerCapitaParish: string;

  public businessIdentifierBioTechnology?: boolean;

  public businessIdentifierSoftware?: boolean;

  public businessIdentifierMicroManufacturing?: boolean;

  public businessIdentifierEnvironmentalTechnology?: boolean;

  public businessIdentifierFoodTechnology?: boolean;

  public businessIdentifierAdvancedMaterials?: boolean;

  public businessIdentifierMedicalIndustries?: boolean;

  public businessIdentifierCleanEnergyTech?: boolean;

  public businessIdentifierMultiStateBusiness?: boolean;

  public businessIdentifierSpendingOutOfState?: boolean;

  public businessIdentifierTransportAircraft?: boolean;

  public businessDescription: string;

  public isCovidImpacted?: boolean;

  constructor(qualify?: any) {
    if (!qualify) qualify = {};
    this.isManufacturer = qualify.isManufacturer || false;
    this.isOilAndGasServiceBusiness = qualify.isOilAndGasServiceBusiness || false;
    this.hasHalfSalesOfOutOfStateCustomers = qualify.hasHalfSalesOfOutOfStateCustomers || false;
    this.isLocatedInDistressedRegion = qualify.isLocatedInDistressedRegio || false;
    this.isParishWithLowPerCapita = qualify.isParishWithLowPerCapita || false;
    this.isCensusTractBlockGroup = qualify.isCensusTractBlockGroup || false;
    this.medianPerCapitaIncome = qualify.medianPerCapitaIncome || 0;
    this.businessIdentifierBioTechnology = qualify.businessIdentifierBioTechnology || false;
    this.businessIdentifierSoftware = qualify.businessIdentifierSoftware || false;
    this.businessIdentifierMicroManufacturing = qualify.businessIdentifierMicroManufacturing || false;
    this.businessIdentifierEnvironmentalTechnology = qualify.businessIdentifierEnvironmentalTechnology || false;
    this.businessIdentifierFoodTechnology = qualify.businessIdentifierFoodTechnology || false;
    this.businessIdentifierAdvancedMaterials = qualify.businessIdentifierAdvancedMaterials || false;
    this.businessIdentifierMedicalIndustries = qualify.businessIdentifierMedicalIndustries || false;
    this.businessIdentifierCleanEnergyTech = qualify.businessIdentifierCleanEnergyTech || false;
    this.businessIdentifierMultiStateBusiness = qualify.businessIdentifierMultiStateBusiness || false;
    this.businessIdentifierSpendingOutOfState = qualify.businessIdentifierSpendingOutOfState || false;
    this.businessIdentifierTransportAircraft = qualify.businessIdentifierTransportAircraft || false;
    this.isCovidImpacted = qualify.isCovidImpacted || false;
  }

}
