import { ComplianceForm } from './compliance-form.model';
import { JobBenefitDetail } from './job-benefit-detail.model';
import { formTypes } from 'app/form/form.constants';

export class AnnualCertificationReport extends ComplianceForm {
  year: number;
  estimatedNewJobs: number;
  actualNewJobs: number;
  estimatedGrossPayroll: number;
  actualGrossPayroll: number;
  processedDate: Date;
  certifiedDate: Date;
  previousNewJobs: number;
  activeJobs: number;
  endOfYearNewJobs: number;
  newJobHiresCurrentPeriod: number;
  newJobHiresCurrentPeriodLouisianaDriversLicense: number;
  eligibleForRebate: number;
  electingCoverage: number;
  coverageValue: number;
  grossPayroll: number;
  jobBenefitDetails: JobBenefitDetail[];
  totalCreditOrRebate: number;
  benefitCap: number;

  constructor(formIndex: number = null) {
    super(formIndex);
    this.init();
  }

  init() {
    super.init();
    this.type = formTypes.acr.abbrev;
    this.processedDate = this.processedDate ? new Date(this.processedDate) : null;
    this.certifiedDate = this.certifiedDate ? new Date(this.certifiedDate) : null;
    this.comments = this.comments || null;
  }

  get qualifier() {
    return `Year: ${this.year}`;
  }
}
