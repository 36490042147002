import { ProjectForm } from '../../project/models/form.model';
import { AuditDepositCreator } from '../interfaces/audit-deposit-creator.interface';
import { Expenditures as DigitalExpenditures } from './digital/expenditures.model';
import { Expenditures as FilmExpenditures } from './film/expenditures.model';
import { FormIdentifier } from '../../project/models/form-identifier.model';
export abstract class EntertainmentForm extends ProjectForm
  implements AuditDepositCreator {
  abstract auditFormId: FormIdentifier;
  expenditures: DigitalExpenditures | FilmExpenditures;
  sourceFormId: FormIdentifier;
  abstract laExpenditure: number;
}
