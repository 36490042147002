export class MailAddress {
  emailAddress: string;
  displayName: string;
  phoneNumber?: string;

  constructor(email: string, name: string, phoneNumber?: string) {
    this.emailAddress = email;
    this.displayName = name;
    this.phoneNumber = phoneNumber;
  }
}
