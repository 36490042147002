import { Component, OnInit, Input } from '@angular/core';
import { InitialCertCriticalDates } from '../../../models/digital/initialCert-critical-dates.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EventContext } from '../../../../fastlane-common/event/interfaces/event-context.interface';
import { ProjectForm } from '../../../../project/models/form.model';
import { events } from '../../../../fastlane-common/event/event.constants';
import { updateFormDateActions } from '../../../../project/project.constants';
import { FormShareService } from '../../../../form/services/share.service';
import { PublishSubscribeService } from '../../../../fastlane-common/services/publish-subscribe.service';
import * as _ from 'underscore';
import { deepCopy } from '../../../../shared/shared.functions';
import { ProjectDetailService } from '../../../../project/services/project-shared.service';
import { Application } from '../../../models/digital/application.model';
import { InitialCertification } from '../../../models/digital/initial-certification.model';
import { formTypes } from '../../../../form/form.constants';
import { FormValidationService } from '../../../../form/services/validation.service';

@Component({
  selector: 'fl-dm-initialcert-form-critical-dates',
  templateUrl: './form-critical-dates.component.html',
  styleUrls: ['./form-critical-dates.component.scss']
})
export class DigitalInitialCertFormCriticalDatesComponent implements OnInit {
  @Input() initialCertCriticalDate: InitialCertCriticalDates;
  formCriticalDatesFormGroup: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private formShareService: FormShareService,
    private projectShareService: ProjectDetailService,
    private pubSubService: PublishSubscribeService,
    private validationService: FormValidationService
  ) {}

  ngOnInit() {
    const that = this;
    const initialCert = <InitialCertification>this.formShareService.form;

    const applicationSnapshot = _.extend(
      new Application(),
      deepCopy(
        this.projectShareService.digitalProject[formTypes.application.abbrev]
      )
    );
    this.initialCertCriticalDate.relatedApplicationReceivedDate = new Date(
      applicationSnapshot.receivedDate
    );
    this.raiseRelatedApplicationReceivedDateChanged();

    this.formCriticalDatesFormGroup = that.formBuilder.group({
      relatedApplicationReceivedDateGroup: that.formBuilder.group({}),
      initialCertIssuanceDateGroup: that.formBuilder.group({})
    });

    this.validationService.form.setControl(
      'initialCertCriticalDates',
      this.formCriticalDatesFormGroup
    );
  }
  estimatedDateChanged(property: string, date: Date) {
    this.initialCertCriticalDate[property] = date;
    if (property === 'relatedApplicationReceivedDate') {
      this.raiseRelatedApplicationReceivedDateChanged();
    }
    if (property === 'initialCertIssuanceDate') {
      this.raiseInitialCertIssuanceDateChanged();
    }
  }

  //#region Raise Event Handlers
  raiseRelatedApplicationReceivedDateChanged() {
    const that = this;
    const eventContext = <EventContext<ProjectForm>>{
      data: that.formShareService.form,
      eventType: events.updateFormDate.code,
      eventAction: updateFormDateActions.relatedApplicationReceivedDateChanged
    };
    this.pubSubService.raise<EventContext<ProjectForm>>(
      eventContext.eventType,
      eventContext.eventAction,
      eventContext
    );
  }
  raiseInitialCertIssuanceDateChanged() {
    const that = this;
    const eventContext = <EventContext<ProjectForm>>{
      data: that.formShareService.form,
      eventType: events.updateFormDate.code,
      eventAction: updateFormDateActions.initialCertIssuanceDateChanged
    };
    this.pubSubService.raise<EventContext<ProjectForm>>(
      eventContext.eventType,
      eventContext.eventAction,
      eventContext
    );
  }
  //#endregion
}
