import { formTypes } from '../form/form.constants';

export const managementPageTitles = {
  board: {
    agenda: 'Manage Board Agenda',
    meetings: 'Board Meetings',
    results: 'Manage Board Results',
    send: 'Send Forms to Board Agenda'
  },
  external: {
    send: 'Send Forms to External Review',
    manage: 'Manage Forms in External Review'
  },
  payments: {
    incoming: 'Incoming Payments',
    outgoing: 'Outgoing Payments'
  }
};

export const boardMeetingType = {
  CommerceIndustry: {
    name: 'Commerce and Industry Board',
    abbrev: 'C&I'
  }
};

export const defaultFilters = {
  formTypes: [
    formTypes.application.abbrev,
    formTypes.renewalApplication.abbrev,
    formTypes.changeAffiliatesOwners.abbrev,
    formTypes.changeCompanyName.abbrev,
    formTypes.changeLocation.abbrev,
    formTypes.partialTransferOwnership.abbrev,
    formTypes.fullTransferOwnership.abbrev
  ]
};

export const boardStatus = {
  Ready: {
    name: 'Ready',
    abbrev: 'ready'
  },
  Approved: {
    name: 'Approved',
    abbrev: 'approve'
  },
  Deferred: {
    name: 'Deferred',
    abbrev: 'defer'
  },
  Denied: {
    name: 'Denied',
    abbrev: 'deny'
  }
};
