export class ExpenditureNotes {
  totalBudget: string;
  aboveLineSalaries: string;
  residentPayroll: string;
  laVisualEffectsExpenditure: string;
  totalVisualEffectsExpenditure: string;
  laExpenditure: string;
  constructor(obj?: any) {
    obj = obj ? obj : {};
    this.totalBudget = obj.totalBudget ? obj.totalBudget : '';
    this.aboveLineSalaries = obj.aboveLineSalaries ? obj.aboveLineSalaries : '';
    this.residentPayroll = obj.residentPayroll ? obj.residentPayroll : '';
    this.laVisualEffectsExpenditure = obj.laVisualEffectsExpenditure
      ? obj.laVisualEffectsExpenditure
      : '';
    this.totalVisualEffectsExpenditure = obj.totalVisualEffectsExpenditure
      ? obj.totalVisualEffectsExpenditure
      : '';
    this.laExpenditure = obj.laExpenditure ? obj.laExpenditure : '';
  }
}
