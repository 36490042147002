import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import { Principal } from '../../../models/film/principal.model';
import {
  FormGroup,
  FormBuilder,
  AbstractControl,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { Subscription ,  Subject } from 'rxjs';
declare var $;
declare type ModalMode = 'Add' | 'Edit';

export interface PrincipalChangeEvent {
  action: 'add' | 'edit' | 'delete';
  principal: Principal;
  index: number;
}

@Component({
  selector: 'fl-principals',
  templateUrl: './principals.component.html',
  styleUrls: ['./principals.component.css']
})
export class PrincipalsComponent implements OnInit {
  @Input() principals: Principal[];
  roles: string[];
  principalForm: FormGroup;
  @Input() readonly: boolean;
  private formGroupSubscription: Subscription;
  mode: ModalMode;
  selectedPrincipal: Principal;
  selectedPrincipalIndex: number;
  principalChanged: Subject<PrincipalChangeEvent>;

  constructor(private fb: FormBuilder) {
    this.principalForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      middleInitial: [''],
      role: ['', Validators.required]
    });
  }

  delete(index: number) {
    // Remove principal at particular index
    const removedPrincipals = this.principals.splice(index, 1);

    // Notify listeners that a contact was deleted
    this.principalChanged.next({
      action: 'delete',
      principal: removedPrincipals[0],
      index: index
    });
  }

  ngOnInit() {
    // Register modal events
    $('#principalModal').on('shown.bs.modal', e =>
      $('[data-toggle="tooltip"]').tooltip()
    );

    // Register modal events
    $('#principalModal').on('hidden.bs.modal', e => $('button:focus').blur());
  }
  save() {
    // Transfer data from form model to data model
    Object.assign(this.selectedPrincipal, this.principalForm.value);

    if (this.mode === 'Add') {
      // Add new principal
      this.principals.push(this.selectedPrincipal);

      //   // Notify listeners that a principal was added
      //   this.principalChanged.next({
      //     action: 'add',
      //     principal: this.principalChanged,
      //     index: this.contacts.length - 1
      //   });
      // } else {
      //   // Notify listeners that a contact was edited
      //   this.principalChanged.next({
      //     action: 'edit',
      //     principal: this.principalChanged,
      //     index: this.selectedPrincipalIndex
      //   });
    }
  }
  select(modalMode: ModalMode, index?: number) {
    const that = this;
    const principal =
      index !== undefined && index != null
        ? this.principals[index]
        : new Principal().init();
    this.mode = modalMode;
    this.selectedPrincipal = principal;
    this.selectedPrincipalIndex = index;
  }
}
