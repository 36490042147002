import { Component } from '@angular/core';
import { smoothlyMenu } from '../../../shared/shared.functions';
import { UserContextService } from '../../../user/services/user-context.service';
import {getUserModule} from '../../../user/user.functions';
declare var $;

@Component({
  selector: 'fl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  // This is the current users base url route
  routingRoot: string;

  constructor(
    public userContext: UserContextService,
  ) {
    // Set routing root for links
    this.routingRoot = getUserModule(this.userContext.currentUser.userType);
  }

  toggleNavigation(): void {
    $('body').toggleClass('mini-navbar');

    $('a.nav-link.has-arrow[aria-expanded="true"]').click();

    smoothlyMenu();
  }
}
