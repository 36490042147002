import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of, asyncScheduler } from 'rxjs';
import { map, catchError, subscribeOn } from 'rxjs/operators';
import { UserPermissions } from '../../user/models/user-permissions.model';

@Injectable()
export class AuthorizationService {
  private readonly getPermissionsUrl = environment.apiUrl + '/user/permissions';
  private readonly getUserProgramsUrl = `${environment.apiUrl}/user/programs`;

  constructor(private http: HttpClient) {}

  getUserPrograms(authOptions: { headers: any }): Observable<string[]> {
    return this.http
      .get(this.getUserProgramsUrl, authOptions)
      .pipe(
        subscribeOn(asyncScheduler),
        map((res: string[]) => {
          if (res) {
            return res;
          }
          return null;
        })
      )
      .pipe(catchError(this.handleError));
  }

  getUserPermissions(authOptions: {
    headers: any;
  }): Observable<UserPermissions> {
    return this.http
      .get(this.getPermissionsUrl, authOptions)
      .pipe(
        subscribeOn(asyncScheduler),
        map((response: UserPermissions) => new UserPermissions(response))
      )
      .pipe(catchError(this.handleError));
  }

  hasUserPermission(
    permission: string,
    authOptions: {
      headers: HttpHeaders;
    }
  ): Observable<boolean> {
    return this.http
      .get(this.getPermissionsUrl, authOptions)
      .pipe(
        subscribeOn(asyncScheduler),
        map((response: boolean) => {
          if (response) {
            const permissions = new UserPermissions(response);
            if (permissions[permission] === true) {
              return true;
            }
          }
          return false;
        })
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse | any) {
    // In a real world app, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      errMsg = `${error.status} - ${error.statusText || ''} ${error.message}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return of(error);
  }
}
