import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { Credit } from '../../models/film/credit.model';
import { CreditType } from '../../models/creditType.model';
import {
  baseCreditTypes,
  additionalCreditTypes,
  creditTypes
} from '../../entertainment.constants';
import { subCategories, CreditStatuses } from '../../credit.constants';
import * as _ from 'underscore';
import {
  getCreditTypeName,
  getBaseInvestmentCap,
  getLegislationRule,
  updateEligibility,
  getCapPerSeason
} from '../../credit.functions';
import { Expenditures } from '../../models/film/expenditures.model';
import { incentiveProgram } from '../../../project/project.constants';
import { formTypes } from '../../../form/form.constants';
import { PublishSubscribeService } from '../../../fastlane-common/services/publish-subscribe.service';
import { events } from '../../../fastlane-common/event/event.constants';
import { ProjectForm } from '../../../project/models/form.model';
import { ProjectDetailService } from '../../../project/services/project-shared.service';
import { FormIdentifier } from '../../../project/models/form-identifier.model';
import { Application } from '../../models/film/film-application.model';
import { InitialCertification } from '../../models/film/initial-certification.model';
import { FormFeeService } from '../../../form/services/fee.service';
import { Subscription } from 'rxjs';
import { UserFormPermissions } from '../../../form/models/permissions.model';
import { FormShareService } from '../../../form/services/share.service';
import { Audit } from '../../models/film/audit.model';
import { doFormIdsMatch } from '../../../form/form.functions';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CreditTrackingData } from '../../../management/credit-tracking/credit-tracking.component';
import { LookupDataService } from '../../../management/services/lookup-data.service';
import { WebWorkerService } from '../../../fastlane-common/services/web-worker.service';
import { AuthenticationService } from '../../../security/services/authentication.service';
import { environment } from '../../../../environments/environment';

declare var $: any;

@Component({
  selector: 'fl-film-credit-management',
  templateUrl: './credit-management.component.html',
  styleUrls: ['./credit-management.component.scss'],
  providers: [LookupDataService]
})
export class FilmCreditManagementComponent implements OnInit, OnDestroy {
  allCreditTypes = _.pairs(creditTypes).map(pair => {
    return { name: pair[1].name, abbrev: pair[1].abbrev };
  });
  dropdownOptions: Array<{ name: any; abbrev: any }> = [];
  allSubCategories = _.pairs(subCategories).map(pair => {
    return { name: pair[1].name, abbrev: pair[1].abbrev };
  });
  @Input() credits: Credit[] = []; // Provided credits are already filtered on formtype, formindex and credit status.
  @Input() creditTypes: CreditType[]; // These are credit Types loaded from form.
  @Input() expenditures: Expenditures; // The expenditures from the current form.
  @Input() form: ProjectForm;
  @Input() readOnly = false;
  @Output() onRefreshCredit: EventEmitter<any> = new EventEmitter<any>();
  currentCreditReservations: Credit[];
  selectedCreditType: CreditType;
  baseCredits: CreditType[];
  formTypes = formTypes;
  additionalCredits: CreditType[];
  getCreditTypeName = getCreditTypeName;
  baseInvestmentCap: number;
  totalAppliedCreditTypes = 0;
  totalReservedCreditTypes = 0;
  totalIssuedCreditTypes = 0;
  subscriptions: Subscription[] = [];
  permissions: UserFormPermissions;
  currentFormId: FormIdentifier;

  validationWarnings: { type: string; message: string }[] = [];
  creditTrackingData: CreditTrackingData[] = [];
  creditsToBeTracked: CreditTrackingData[] = [];
  years: number[] = [];
  private defaultYearOffsets = [-4, -3, -2, -1, 0, 1, 2, 3, 4, 5];

  // This group will contain a form array for each credit type and a formControl for the explanation.
  // @Baaji will come back later to implement the form array
  creditFormGroup: FormGroup;

  //#region CalculatedProperties
  get maximumCredits(): number {
    const baseCap = this.baseCreditCap;
    if (this.isScriptedEpisodic) {
      const seasonCap = this.seasonCap;
      return baseCap <= seasonCap ? baseCap : seasonCap;
    }
    return baseCap;
  }
  get isScriptedEpisodic(): boolean {
    if (
      (<Application>this.form).productionDetails.productionType ===
      'Scripted Episodic Content'
    ) {
      return true;
    }
    return false;
  }
  get seasonCap(): number {
    if (this.isScriptedEpisodic) {
      const seasonCap = getCapPerSeason(
        incentiveProgram.film.code,
        this.projectDetailsService.filmProject.projectInfo.legislation
      );
      return seasonCap;
    }
  }
  get baseCreditCap(): number {
    return this.expenditures.laExpenditure * this.baseInvestmentCap;
  }

  get totalCreditsAllowed(): number {
    if (
      this.form.type === formTypes.application.abbrev ||
      this.form.type === formTypes.asa.abbrev
    ) {
      return this.totalAppliedCreditTypes >= this.maximumCredits
        ? this.maximumCredits
        : this.totalAppliedCreditTypes;
    }
    if (this.form.type === formTypes.initialCert.abbrev) {
      return this.totalReservedCreditTypes >= this.maximumCredits
        ? this.maximumCredits
        : this.totalReservedCreditTypes;
    }
    if (this.form.type === formTypes.audit.abbrev) {
      const creditsThreshold =
        this.maximumCredits >= this.audit.totalCreditsReserved
          ? this.audit.totalCreditsReserved
          : this.maximumCredits;

      return this.totalIssuedCreditTypes >= creditsThreshold
        ? creditsThreshold
        : this.totalIssuedCreditTypes;
    }
  }

  get totalFundingAmount(): number {
    // Clear all CreditsToBeTracked so that every iteration has fresh array.
    this.creditsToBeTracked.splice(0);
    // Clear all tracking related warnings.
    const requiredWarnings = this.validationWarnings.filter(
      vw => vw.type !== 'tracking'
    );
    this.validationWarnings.splice(0);
    this.validationWarnings.push(...requiredWarnings);
    const totalFunding = this.credits.reduce(
      (prv, curr) => {
        if (curr.fundingAmount >= 0) {
          if (this.form.type === formTypes.initialCert.abbrev) {
            const index = this.creditsToBeTracked.findIndex(
              ctt =>
                ctt.year === curr.fiscalYear &&
                ctt.category === curr.subCategory
            );
            if (index !== -1) {
              this.creditsToBeTracked[index]['Pending Reservation'] +=
                curr.fundingAmount;
              this.checkTrackingCap(this.creditsToBeTracked[index]);
            } else {
              const newCreditTracking = <CreditTrackingData>{
                year: curr.fiscalYear,
                category: curr.subCategory,
                ['Pending Reservation']: curr.fundingAmount
              };
              this.creditsToBeTracked.push(newCreditTracking);
              this.checkTrackingCap(newCreditTracking);
            }
          }
          const updatedFund = curr.fundingAmount + prv.fundingAmount;
          return <Credit>{ fundingAmount: updatedFund };
        }
        return prv;
      },
      <Credit>{ fundingAmount: 0 }
    ).fundingAmount;

    const warning = {
      type: 'overReserve',
      message:
        this.form.type === formTypes.initialCert.abbrev
          ? 'The Total Amount in credit reservation section does not equal Total Reserved Amount from Credit Types.'
          : 'The Total Amount in credit reservation section does not equal Final Total Credits Granted ' +
            '(includes deferred credits granted) from Credit Types.'
    };
    if (totalFunding !== this.totalCreditsAllowed) {
      this.warningManagment(warning, true);
    } else {
      this.warningManagment(warning, false);
    }
    return totalFunding;
  }

  get initialCert() {
    return this.form as InitialCertification;
  }

  get application() {
    return <Application>this.form;
  }

  get audit() {
    return <Audit>this.form;
  }

  get unUsedReservedCredits() {
    return this.audit.totalCreditsReserved - this.totalCreditsAllowed > 0
      ? this.audit.totalCreditsReserved - this.totalCreditsAllowed
      : 0;
  }

  get deferredCredits() {
    const creditsThreshold =
      this.maximumCredits >= this.totalIssuedCreditTypes
        ? this.totalIssuedCreditTypes
        : this.maximumCredits;
    return creditsThreshold - this.totalCreditsAllowed > 0
      ? creditsThreshold - this.totalCreditsAllowed
      : 0;
  }

  get deferredFundingAmount() {
    return this.credits.reduce(
      (prv, curr) => {
        if (curr.fundingAmount >= 0 && curr.isDeferred) {
          const updatedFund = curr.fundingAmount + prv.fundingAmount;
          return <Credit>{ fundingAmount: updatedFund };
        }
        return prv;
      },
      <Credit>{ fundingAmount: 0 }
    ).fundingAmount;
  }

  get qualifiedRate(): { rate: number; amount: number } {
    if (
      this.form.type === formTypes.application.abbrev ||
      this.form.type === formTypes.asa.abbrev
    ) {
      return this.baseCredits.reduce(
        (pv, cv) => {
          const rate = cv.isApplied ? pv.rate + cv.rate : pv.rate + 0;
          const amount = cv.isApplied
            ? pv.amount + cv.creditTypeAmount
            : pv.amount + 0;
          return { rate: rate, amount: amount };
        },
        { rate: 0, amount: 0 }
      );
    }
    if (this.form.type === formTypes.initialCert.abbrev) {
      return this.baseCredits.reduce(
        (pv, cv) => {
          const rate = cv.isReserved ? pv.rate + cv.rate : pv.rate + 0;
          const amount = cv.isReserved
            ? pv.amount + cv.creditTypeAmount
            : pv.amount + 0;
          return { rate: rate, amount: amount };
        },
        { rate: 0, amount: 0 }
      );
    }
    if (this.form.type === formTypes.audit.abbrev) {
      return this.baseCredits.reduce(
        (pv, cv) => {
          const rate = cv.isIssued ? pv.rate + cv.rate : pv.rate + 0;
          const amount = cv.isIssued
            ? pv.amount + cv.creditTypeAmount
            : pv.amount + 0;
          return { rate: rate, amount: amount };
        },
        { rate: 0, amount: 0 }
      );
    }
  }
  //#endregion

  //#region Lifecycle
  constructor(
    private pubsubService: PublishSubscribeService,
    private feeService: FormFeeService,
    private formShareService: FormShareService,
    private projectDetailsService: ProjectDetailService,
    private fb: FormBuilder,
    private lookupDataService: LookupDataService,
    private webWorkerService: WebWorkerService,
    private authService: AuthenticationService
  ) {
    // Patch credit form group with values
    this.creditFormGroup = fb.group({
      reservationTotalExplanation: ['', Validators.nullValidator]
    });
  }

  ngOnInit() {
    const that = this;
    this.permissions = this.formShareService.permissions;
    this.currentFormId = <FormIdentifier>{
      formType: that.form.type,
      formIndex: that.form.formIndex,
      projectGuid: that.projectDetailsService.project.id
    };
    this.initializeCredits();
    //#region intializations

    // Sets the drop down menu in the credits area for all the approved credit Types.
    this.dropdownOptions = [];
    this.refreshDropDownOptions();
    if (this.dropdownOptions.length > 0) {
      // This is to preselect the credits table with expenditure credit type.
      this.creditTypeChanged(this.dropdownOptions[0].abbrev);
    }
    // Enforces Eligibility when loading this component.
    updateEligibility(
      this.creditTypes,
      this.projectDetailsService.filmProject.projectInfo,
      this.form
    );
    //#endregion

    //#region subscriptions

    // Looks for changes in the Expenditures and recalculates the totals.
    // Also enforces eligibility on the creditTypes.
    this.subscriptions = this.pubsubService.handleAllActionsForEvents(
      [events.expenditureChanged.code],
      expenditureChangedValue => {
        updateEligibility(
          that.creditTypes,
          that.projectDetailsService.filmProject.projectInfo,
          that.form
        );

        that.refreshAllCalculations();
      }
    );
    // This will listen to any changes to the values which will effect credit Eligibility.
    const eligibilitySubs = this.pubsubService.handleAllActionsForEvents(
      [events.creditEligibilityChanged.code],
      value => {
        updateEligibility(
          that.creditTypes,
          that.projectDetailsService.filmProject.projectInfo,
          that.form
        );
        that.refreshAllCalculations();
      }
    );
    this.subscriptions.push(...eligibilitySubs);
    //#endregion

    //#region PopOver
    $(function() {
      $('[data-toggle="popover"]').popover();
    });
    //#endregion

    //#region FormGroup Initialization

    // Patch the formGroup with contents of initial cert
    this.creditFormGroup.patchValue(
      _.pick(this.initialCert, Object.keys(this.creditFormGroup.controls))
    );

    //#endregion

    //#region Form Group Actions

    const sub = this.creditFormGroup
      .get('reservationTotalExplanation')
      .valueChanges.subscribe(value => {
        that.initialCert.reservationTotalExplanation = value;
      });
    this.subscriptions.push(sub);

    //#endregion
  }
  initializeCredits() {
    //#region credits-setup

    // Separate baseCredits and Additional Credits
    // All Base Credits pull from one Expenditure field,
    // Where as Additional credits has their own fields to pull from.
    this.baseCredits = this.creditTypes.filter(ct =>
      baseCreditTypes.includes(ct.creditType)
    );
    this.additionalCredits = this.creditTypes.filter(ct =>
      additionalCreditTypes.includes(ct.creditType)
    );

    // baseInvestmentCap based on the Legislation rule.
    this.baseInvestmentCap = getBaseInvestmentCap(
      incentiveProgram.film.code,
      this.projectDetailsService.filmProject.projectInfo.legislation
    );
    //#endregion
    //#region creditTracking

    if (formTypes.initialCert.abbrev === this.form.type) {
      this.years = this.getDefaultYears();
      // Query with given year
      this.fetchData({
        endpoint: environment.apiUrl,
        authHeader: this.authService.getAuthOptions().headers['Authorization'],
        statuses: {
          reserved: CreditStatuses.reserved,
          issued: CreditStatuses.issued,
          pendingReservation: CreditStatuses.pendingReservation
        },
        years: this.years
      });
    }
    //#endregion
  }
  ngOnDestroy() {
    this.subscriptions.map(sub => sub.unsubscribe());
  }
  //#endregion

  //#region EventHandlers

  // Adds a new credit to the table based on creditType selected in the
  // Drop down menu.
  add() {
    const that = this;
    const newCredit = new Credit({
      creditType: that.currentCreditReservations[0].creditType,
      expenditureAmount: that.currentCreditReservations[0].expenditureAmount,
      rate: that.currentCreditReservations[0].rate,
      creditTypeAmount: that.currentCreditReservations[0].creditTypeAmount,
      isApplied: that.currentCreditReservations[0].isApplied,
      isReserved: that.currentCreditReservations[0].isReserved,
      isEligible: that.currentCreditReservations[0].isEligible,
      formIdentifier: that.currentFormId,
      season: that.application.productionDetails.season
    });
    // Also add to the current reservation to update calculations.
    this.currentCreditReservations.push(newCredit);
    this.credits.push(newCredit);
    // Add to the project Details Service to save these credits to collection.
    this.projectDetailsService.filmCredits.push(newCredit);
  }

  // Triggered when dropdown changes.
  creditTypeChanged(creditType: string) {
    const that = this;
    // Whenever there is a change in the dropdown box to select the credit Type.
    this.selectedCreditType = this.creditTypes.find(
      ct => ct.creditType === creditType
    );
    if (this.credits) {
      this.currentCreditReservations = this.credits.filter(
        cd => cd.creditType === creditType
      );
      this.currentCreditReservations.map(
        ccr => (ccr.season = that.application.productionDetails.season)
      );
    }
  }

  // Looks for changes in the funding textbox.
  fundingAmountChanged(creditTypeAbbrev: string) {
    this.updateTotalFunding(creditTypeAbbrev);
  }

  // This function recalculates and updates the Total appplied and approved whenever
  // a check box is checked or unchecked for any credit Type.
  creditTypeChecked(creditType: CreditType) {
    const that = this;
    // update credits for this form and creditType in project DetailService.
    this.projectDetailsService.filmCredits.map(fc => {
      if (
        doFormIdsMatch(fc.formIdentifier, that.currentFormId) &&
        fc.creditType === creditType.creditType
      ) {
        Object.assign(fc, creditType);
      }
    });
    this.refreshAllCalculations();
  }

  //#endregion

  //#region HelperFunctions

  refreshDropDownOptions() {
    const that = this;
    this.creditTypes.map(cdtype => that.updateDropdownOptions(cdtype));
  }

  // Updates the Dropdown menu base on checked options.
  updateDropdownOptions(creditType: CreditType) {
    if (
      (creditType.isReserved &&
        this.form.type === formTypes.initialCert.abbrev) ||
      (creditType.isIssued && this.form.type === formTypes.audit.abbrev)
    ) {
      const index = this.dropdownOptions.findIndex(
        dp => dp.abbrev === creditType.creditType
      );
      if (
        index === -1 &&
        ![creditTypes.outMSA.abbrev, creditTypes.screenplay.abbrev].includes(
          creditType.creditType
        )
      ) {
        this.dropdownOptions.push(
          this.allCreditTypes.filter(
            act => act.abbrev === creditType.creditType
          )[0]
        );
      }
      if (this.dropdownOptions.length === 1) {
        this.creditTypeChanged(this.dropdownOptions[0].abbrev);
      }
    } else {
      if (
        (creditType.isReserved === false &&
          this.form.type === formTypes.initialCert.abbrev) ||
        (creditType.isIssued === false &&
          this.form.type === formTypes.audit.abbrev)
      ) {
        const index = this.dropdownOptions.findIndex(
          dp => dp.abbrev === creditType.creditType
        );
        if (
          index !== -1 &&
          ![creditTypes.outMSA.abbrev, creditTypes.screenplay.abbrev].includes(
            creditType.creditType
          )
        ) {
          this.dropdownOptions.splice(index, 1);
          // Clear the issuance or reseravation data for this credit type.
          this.clearUncheckedCredits(creditType.creditType);
          // Clear warnings for unchecked creditTypes
          const warning = {
            type: 'missingReservation',
            message:
              this.allCreditTypes.filter(
                al => al.abbrev === creditType.creditType
              )[0].name + ' is reserved but missing reservation amounts.'
          };
          this.warningManagment(warning, false);
          // change selectedCreditype and corresponding selected credits when dropdown options change i.e if removed selected creditType.
          if (this.dropdownOptions[0]) {
            this.creditTypeChanged(this.dropdownOptions[0].abbrev);
          }
        }
      }
    }
  }
  // Update the Total allocated funds for this current CreditType.
  updateTotalFunding(creditTypeAbbrev: string): number {
    let currentTotalFunding = 0;
    currentTotalFunding = this.credits.reduce(
      (prv, curr) => {
        if (curr.fundingAmount >= 0 && curr.creditType === creditTypeAbbrev) {
          const updatedFund = curr.fundingAmount + prv.fundingAmount;
          return <Credit>{ fundingAmount: updatedFund };
        }
        return prv;
      },
      <Credit>{ fundingAmount: 0 }
    ).fundingAmount;
    const warning = {
      type: 'missingReservation',
      message:
        this.allCreditTypes.filter(al => al.abbrev === creditTypeAbbrev)[0]
          .name + ' is reserved but missing reservation amounts.'
    };
    if (currentTotalFunding <= 0) {
      this.warningManagment(warning, true);
    } else {
      this.warningManagment(warning, false);
    }
    return currentTotalFunding;
  }

  // Recalculates the Applied credits when the a check box is changed.
  recalculateAppliedCreditTypes() {
    const that = this;
    // Now Update the total applied credits in the application for fees purposes.
    if (
      this.form.type === formTypes.application.abbrev ||
      this.form.type === formTypes.asa.abbrev
    ) {
      // Aggregation over all the credit types' credit amount
      // Which are applied and also eligible.
      this.totalAppliedCreditTypes = this.creditTypes.reduce(
        (prvs, curr, i, array) => {
          return curr.isApplied && curr.isEligible
            ? <CreditType>{
                creditTypeAmount: prvs.creditTypeAmount + curr.creditTypeAmount
              }
            : <CreditType>{ creditTypeAmount: prvs.creditTypeAmount };
        },
        <CreditType>{ creditTypeAmount: 0 }
      ).creditTypeAmount;

      this.application.totalCreditsApplied = this.totalCreditsAllowed;
      // Recaculate fees.
      // This function is called when there is change in the expenditure, check box and credit Eligibility
      // All these factors should trigger recalculation of the fees.
      that.feeService.recalculateFees();
    }
  }

  // Recalculates the Approved credits when the a check box is changed InitialCert.
  recalculateReservedCreditTypes() {
    // Now Update the total approved credits in the initialCert.
    if (this.form.type === formTypes.initialCert.abbrev) {
      this.totalReservedCreditTypes = this.creditTypes.reduce(
        (prvs, curr, i, array) => {
          return curr.isReserved && curr.isEligible
            ? <CreditType>{
                creditTypeAmount: prvs.creditTypeAmount + curr.creditTypeAmount
              }
            : <CreditType>{ creditTypeAmount: prvs.creditTypeAmount };
        },
        <CreditType>{ creditTypeAmount: 0 }
      ).creditTypeAmount;

      this.initialCert.totalCreditsReserved = this.totalCreditsAllowed;
    }
  }

  // Recalculates the Issued credits when the a check box is changed in audit.
  recalculateIssuedCreditTypes() {
    // Now Update the total issued credits in the audit.
    if (this.form.type === formTypes.audit.abbrev) {
      this.totalIssuedCreditTypes = +this.creditTypes
        .reduce(
          (prvs, curr, i, array) => {
            return curr.isIssued
              ? <CreditType>{
                  creditTypeAmount:
                    prvs.creditTypeAmount + curr.creditTypeAmount
                }
              : <CreditType>{ creditTypeAmount: prvs.creditTypeAmount };
          },
          <CreditType>{ creditTypeAmount: 0 }
        )
        .creditTypeAmount.toFixed(2);

      this.audit.totalCreditsIssued = this.totalCreditsAllowed;
    }
  }

  // Deletes a Credit which is added to the credits table.
  delete(credit: Credit) {
    this.credits.splice(this.credits.indexOf(credit), 1);
    // Also remove it from the projectDetailsservice to reflect the changes when saving the credits.
    this.projectDetailsService.filmCredits.splice(
      this.credits.indexOf(credit),
      1
    );
    this.currentCreditReservations.splice(
      this.currentCreditReservations.indexOf(credit),
      1
    );
    if (
      this.credits.findIndex(c => c.creditType === credit.creditType) === -1
    ) {
      this.currentCreditReservations.push(
        this.addInitializedCredit(credit.creditType)
      );
    }
  }

  addInitializedCredit(creditTypeName: string) {
    const creditType = this.creditTypes.find(
      ct => ct.creditType === creditTypeName
    );
    const credit = Object.assign(new Credit(), creditType);
    credit.formIdentifier = this.currentFormId;
    this.credits.push(credit);
    this.projectDetailsService.filmCredits.push(credit);
    return credit;
  }

  clearUncheckedCredits(creditTypeName: string) {
    // do not clear credits for outofMSA and screenplay.
    if (
      ![creditTypes.outMSA.abbrev, creditTypes.screenplay.abbrev].includes(
        creditTypeName
      )
    ) {
      const that = this;
      this.credits
        .filter(c => c.creditType === creditTypeName)
        .forEach(cc => {
          that.credits.splice(this.credits.indexOf(cc), 1);
          // Also remove it from the projectDetailsservice to reflect the changes when saving the credits.
          that.projectDetailsService.filmCredits.splice(
            that.projectDetailsService.filmCredits.indexOf(cc),
            1
          );
          if (
            that.credits.findIndex(c => c.creditType === cc.creditType) === -1
          ) {
            this.addInitializedCredit(cc.creditType);
          }
        });
    }
  }

  refreshAllCalculations() {
    const that = this;

    setTimeout(() => {
      that.recalculateAppliedCreditTypes();
      that.recalculateReservedCreditTypes();
      that.recalculateIssuedCreditTypes();
      that.refreshDropDownOptions();
    });
  }
  importFromInitialCert() {
    this.onRefreshCredit.emit();
    this.initializeCredits();
    this.refreshDropDownOptions();
    if (this.dropdownOptions.length > 0) {
      // This is to preselect the credits table with expenditure credit type.
      this.creditTypeChanged(this.dropdownOptions[0].abbrev);
    }
    this.refreshAllCalculations();
  }

  warningManagment(warning: { type: string; message: string }, add: boolean) {
    if (add) {
      if (
        this.validationWarnings.findIndex(
          warn => warn.type === warning.type && warn.message === warning.message
        ) === -1
      ) {
        this.validationWarnings.push(warning);
      }
    } else {
      const index = this.validationWarnings.findIndex(
        warn => warn.type === warning.type && warn.message === warning.message
      );
      if (index !== -1) {
        this.validationWarnings.splice(index, 1);
      }
    }
  }

  /**
   * @summary Queues a worker to fetch credit tracking data from api and prepare for UI.
   * @param details Details to be passed to the worker to be able to query the api.
   */
  fetchData(details: any) {
    const that = this;

    const promise = this.webWorkerService.run(() => {
      // Create the onmessage event handler to handle posted messages from the application
      onmessage = ev => {
        const authorizationHeader = ev.data.authHeader;
        const data = ev.data.payload;
        const url = ev.data.endpoint;
        const authHeader = 'Authorization';
        const statuses = ev.data.statuses;
        const years: number[] = ev.data.years;

        // Create query string of years
        const yearsQuery = years.reduce((prev, next) => {
          prev += `years=${next}&`;
          return prev;
        }, '');

        // Get Credit Summaries
        const completeUrl = `${url}/credit/summaries?${yearsQuery}`;
        const xhr = new XMLHttpRequest();
        xhr.open('GET', completeUrl, true);

        // Set authorization header
        xhr.setRequestHeader(authHeader, authorizationHeader);

        xhr.onload = function() {
          const count = JSON.parse(xhr.responseText);
          if (xhr.readyState === 4 && xhr.status === 200) {
            // Convert to display in same row
            const creditSummaries: {
              subCategory: string;
              fiscalYear: number;
              creditStatusTotals: { creditStatus: string; total: number }[];
              cap: number;
            }[] = JSON.parse(xhr.responseText);

            // Organize for row display
            const rows = creditSummaries.map(yearSubCapDetails => {
              const reducedTotals = yearSubCapDetails.creditStatusTotals.reduce(
                (prev, next, index) => {
                  prev[next.creditStatus] =
                    prev[next.creditStatus] + next.total;
                  return prev;
                },
                {
                  [statuses.reserved]: 0,
                  [statuses.issued]: 0,
                  [statuses.pendingReservation]: 0
                }
              );

              return {
                year: yearSubCapDetails.fiscalYear,
                category: yearSubCapDetails.subCategory,
                reserved: reducedTotals[statuses.reserved],
                issued: reducedTotals[statuses.issued],
                pendingReservation: reducedTotals[statuses.pendingReservation],
                cap: yearSubCapDetails.cap
              };
            });

            self.postMessage(rows, null);
          } else {
            self.postMessage(count, null);
          }
        };
        xhr.send(null);
      };

      onerror = ev => {
        self.postMessage(ev, null);
      };
    });
    const worker = this.webWorkerService.getWorker(promise);

    // Ask the worker to get the data!!
    worker.postMessage(details);

    worker.onmessage = ev => {
      that.creditTrackingData = ev.data;
      // Terminate the worker thread
      worker.terminate();
    };

    worker.onerror = ev => {
      // Terminate this worker
      worker.terminate();
    };
  }
  getDefaultYears(): number[] {
    const thisYear = new Date().getFullYear();
    return this.defaultYearOffsets.map(offset => thisYear + offset);
  }

  checkTrackingCap(currentTracking: CreditTrackingData) {
    const that = this;
    const creditTracking = that.creditTrackingData.filter(
      ctd =>
        ctd.year === currentTracking.year &&
        ctd.category === currentTracking.category
    )[0];
    if (!creditTracking) {
      return;
    }
    const categoryName = this.allSubCategories.filter(
      asc => asc.abbrev === currentTracking.category
    )[0].name;
    const availableAmount =
      creditTracking.cap -
      creditTracking['reserved'] -
      creditTracking['issued'] -
      creditTracking['pendingReservation'];
    const warning = {
      type: 'tracking',
      message:
        'The sum of the reservation amount for FY ' +
        currentTracking.year +
        ' ' +
        categoryName +
        ' exceeds $' +
        availableAmount +
        ' (available amount including pending reservations).'
    };
    if (currentTracking['Pending Reservation'] > availableAmount) {
      this.warningManagment(warning, true);
    } else {
      this.warningManagment(warning, false);
    }
  }
  //#endregion
}
