import { Injectable } from '@angular/core';
import swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { extend } from 'underscore';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private get defaults() {
    const options = <SweetAlertOptions>{
      cancelButtonColor: '#046993',
      confirmButtonColor: '#1AB394',
      toast: true,
      timer: 3000,
      position: 'top-end'
    };

    return options;
  }
  private lastToastPromise: Promise<SweetAlertResult | void>;

  constructor() {}

  error(options: SweetAlertOptions) {
    options = extend(this.defaults, options, <SweetAlertOptions>{
      type: 'error'
    });

    return swal(options);
  }

  queue(options: SweetAlertOptions) {
    const that = this;
    options = extend(this.defaults, options, <SweetAlertOptions>{
      type: 'success'
    });

    // if no existing promise, create a new one and capture it
    if (that.lastToastPromise === null || that.lastToastPromise === undefined) {
      that.lastToastPromise = swal(options);
    } else {
      // Configure Promise to get next in queue
      that.lastToastPromise = that.lastToastPromise.then(value => {
        // Make this new toast, the last one
        return swal(options);
      });
    }
  }

  // Shows a sticky toast, must provide title and additional configuration options
  sticky(options: SweetAlertOptions) {
    options = extend(this.defaults, options, <SweetAlertOptions>{
      timer: 0
    });

    return swal(options);
  }

  success(options: SweetAlertOptions) {
    options = extend(this.defaults, options, <SweetAlertOptions>{
      type: 'success'
    });

    return swal(options);
  }
}
