<div class="d-flex justify-content-center p-0">
    <div class="card col-sm-12 col-md-7 col-lg-5 p-0">
        <div class="card-header">
            <div class="card-title">
                <h5>Reset Password</h5>
            </div>
        </div>

        <div class="card-body">
            <label for="passwordResetGroup">Enter a new password.</label>
            <fl-new-password-input [parentFormGroup]="resetFormGroup" id="passwordResetGroup"></fl-new-password-input>
        </div>

        <div class="card-footer d-flex justify-content-center">
            <button class="btn btn-primary col-xs-9 col-sm-8 col-md-7 col-xl-5" (click)="submitResetRequest()" [disabled]="resetFormGroup.invalid">Reset</button>
        </div>
    </div>
</div>