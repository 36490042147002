export class Jobs {
  aboveTheLineJobs: number;
  belowTheLineJobs: number;
  extras: number;
  totalJobs: number;

  constructor(obj?: any) {
    if (!obj) {
      obj = {};
    }
    this.aboveTheLineJobs =
      obj.aboveTheLineJobs >= 0 ? obj.aboveTheLineJobs : null;
    this.belowTheLineJobs =
      obj.belowTheLineJobs >= 0 ? obj.belowTheLineJobs : null;
    this.extras = obj.extras >= 0 ? obj.extras : null;
    this.totalJobs = obj.totalJobs >= 0 ? obj.totalJobs : null;
  }
}
