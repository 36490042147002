<div class="loginColumns animated fadeInDown">

  <div class="d-flex flex-row flex-wrap justify-content-center">
    <img class="" alt="image" src="/assets/images/fng_app.svg" class="m-b">
    <div class="col-8 position-relative" *ngIf="activeAuthentication">
      <p><a href="/auth-documentation">Two-Factor Authentication Instructions</a></p>
    </div>
    <div class="col-8 position-relative" *ngIf="!activeAuthentication">
      <div *ngIf="showSetupQR" class="d-flex flex-row flex-wrap">
        <h5>Set Up Two-Factor Authentication</h5>
        <p>Two-Factor Authentication Powered By Google Authenticator.</p>
      </div>
      <div>
        <p><a href="/auth-documentation">Two-Factor Authentication Instructions</a></p>
      </div>
      <div *ngIf="showSetupQR" class="d-flex flex-row flex-wrap justify-content-center">

        <img alt="image" [src]="setUpQRUrl" class="m-b">
        <p>Manual Entry Code: {{manualEntryKey}}</p>
      </div>
    </div>
    <div class="col-8 position-relative" *ngIf="activeAuthentication || (!activeAuthentication && showSetupQR)">
      <div id="login" class="animated">
        <form class="bg-white p-4 no-validation-border" [formGroup]="validateForm" novalidate>
          <div class="form-group">
            <div class="mb-3">
              <input type="text" class="form-control" id="passcode" formControlName='passcode' placeholder="Validation Code" required>
            </div>
            <button type="submit" (click)="validate()" class="btn btn-secondary block w-100 m-b">Validate</button>
          </div>
          <div *ngIf="invalidAuthCode">
            <p class="text-danger"><strong>Invalid Authenticator Code</strong></p>
          </div>
          <div>
            <shr-checkbox [controlId]="'remember-device-checkbox'" [label]="'Remember This Device'" [(checked)]="twoFactorRememberMe"></shr-checkbox>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
