import { Injectable, OnDestroy } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable ,  Subscription } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { AuthorizationToken } from '../models/authorization-token.model';

@Injectable()
export class GuestGuard implements OnDestroy, CanActivate {
  private subs: Subscription[] = [];
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {
    localStorage.removeItem('token');
  }
  private consumeToken(token: AuthorizationToken, tokenGuid: string) {
    this.authService.setToken(token);
    this.authService.setUserContext();
    localStorage.setItem('guestToken', tokenGuid);
  }

  checkTokenisExpired(token: AuthorizationToken) {
    if (!token.payload) {
      return true;
    }
    const expirationDate = new Date(token.payload.expiration * 1000);
    if (expirationDate < new Date()) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const that = this;
    const tokenGuid =
      next.queryParams['tok'] || localStorage.getItem('guestToken');
    return new Observable<boolean>(observer => {
      if (next.firstChild && next.firstChild.url[0].path === 'tokenexpired') {
        observer.next(true);
      }
      const tokenSub = that.authService
        .getTokenFromGuid(tokenGuid)
        .subscribe(tok => {
          if (that.checkTokenisExpired(tok)) {
            that.router.navigate(['/guest/tokenexpired']);
            observer.next(false);
          } else {
            that.consumeToken(tok, tokenGuid);
            observer.next(true);
          }
          observer.complete();
        });
      that.subs.push(tokenSub);
    });
  }
}
