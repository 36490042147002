import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { PublishSubscribeService } from '../../../fastlane-common/services/publish-subscribe.service';
import { User } from '../../models/user.model';
import { UserDataService } from '../../services/user-data.service';
import { UserPermissions } from '../../models/user-permissions.model';
import { Subscription } from 'rxjs';

import { UserDetailApplicantComponent } from './applicant/applicant.component';
import { UserDetailExternalComponent } from './external/external.component';
import { UserDetailManagerComponent } from './management/management.component';
import { UserDetailBoardComponent } from './board/board.component';
import { userType } from '../../user.constants';
import { SwalService } from '../../../fastlane-common/services/swal.service';
import { EmailService } from '../../../email/services/email.service';
import { UserDetailAssessorComponent } from './assessor/assessor.component';
import { userTypeToPermissionsMap } from '../../../security/security.constants';
import {
  userTypeToNotificationsMap,
  publishSubscribeEventStrings
} from '../../../fastlane-common/event/event.constants';
import { chain } from 'underscore';
import { UserContextService } from '../../services/user-context.service';
import { AuthenticationService } from '../../../security/services/authentication.service';
declare var $: any;

@Component({
  selector: 'fl-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnDestroy, OnInit {
  @ViewChild('auditor', { static: true })
  auditorBlock: TemplateRef<any>;
  @ViewChild('assessor', { static: true })
  assessorBlock: TemplateRef<any>;
  @ViewChild('common', { static: true })
  commonBlock: TemplateRef<any>;
  @ViewChild('brd', { static: true })
  brdBlock: TemplateRef<any>;
  @ViewChild('ext', { static: true })
  extBlock: TemplateRef<any>;
  @ViewChild('mgr', { static: true })
  mgrBlock: TemplateRef<any>;
  @ViewChild('usr', { static: true })
  usrBlock: TemplateRef<any>;
  @ViewChild(UserDetailApplicantComponent, { static: false })
  applicantComponent: UserDetailApplicantComponent;
  @ViewChild(UserDetailExternalComponent, { static: false })
  assessorComponent: UserDetailAssessorComponent;
  @ViewChild(UserDetailAssessorComponent, { static: false })
  externalReviewerComponent: UserDetailExternalComponent;
  @ViewChild(UserDetailManagerComponent, { static: false })
  managerComponent: UserDetailManagerComponent;
  @ViewChild(UserDetailBoardComponent, { static: false })
  boardComponent: UserDetailBoardComponent;
  createMode = false;
  form: FormGroup;
  pageTitle: string;
  thenBlock: TemplateRef<any>;
  user: User;
  currentUser: User;
  sendResetEmail = false;
  canResetTwoFactor: boolean;
  userPermissionMap = userTypeToPermissionsMap;
  userNotificationMap = userTypeToNotificationsMap;
  userTypeOptions = chain(userType)
    .pairs()
    .map(pair => {
      return {
        key: pair[1].code,
        value: pair[1].name
      };
    })
    .filter(kvp => kvp.key !== userType.guest.code)
    .value();

  private subs: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataService: UserDataService,
    private formBuilder: FormBuilder,
    private pubSubService: PublishSubscribeService,
    private route: ActivatedRoute,
    private router: Router,
    private emailService: EmailService,
    private swal: SwalService,
    private userContext: UserContextService,
    private userData: UserDataService,
    private authService: AuthenticationService
  ) {}

  cancel() {
    $('#app_modal').modal('hide');
  }

  ngOnDestroy() {
    // Unsubscribe from all subs
    this.subs.forEach(sub => sub.unsubscribe());

    // Unset any modal event handlers that were added on init
    $('#app_modal').on('hidden.bs.modal', function(e) {});
  }

  ngOnInit() {
    const that = this;
    this.currentUser = this.userContext.currentUser;
    this.userData.getMyUser().subscribe(user => {
      this.currentUser.firstName = user.firstName;
      this.currentUser.lastName = user.lastName;
    });
    // initialize the form for validations
    this.form = this.formBuilder.group({
      isActive: 'true',
      userType: ''
    });

    // Determine if we are creating a new user or editing an existing user

    // Modal title changes based on createMode
    if (this.createMode) {
      this.pageTitle = 'Create User Account';
      this.user = new User().init();
    } else {
      this.pageTitle = 'Manage User Account';

      // Additionally, if we're in edit mode, we need to fetch the user's data from the api
      // this.subs.push(
      //   this.activatedRoute.params.subscribe((params: Params) => {
      //     that.userId = params['id'];
      //     that.getUser(userId);
      //   })
      // );

      // Since we now should have a userType defined, we can disabled children forms we don't need
      that.switchChildForm();
    }

    // on dismissal, navigate back to the user list
    $('#app_modal').on('hidden.bs.modal', function(e) {
      // that.router.navigate(['../../'], { relativeTo: that.route });
    });

    // add css to make this a large modal
    $('.modal-dialog').addClass('modal-lg');

    // automatically show the modal on load
    $('#app_modal').modal({ backdrop: 'static', keyboard: false });

    // Monitor changes to form model
    const sub = this.form.valueChanges.subscribe(value => {
      const allValues = that.form.getRawValue();
      // Based on current user type, we conditionally have to scrap some values back to their defaults
      if (that.user.userType !== allValues.userType) {
        if (that.user.userType === userType.external.code) {
          // When setting user from an external reviewer account to a non-reviewer account, deselect the external review agency
          that.user.externalReviewAgency = null;
        }
      }

      that.user.isActive = allValues.isActive;
      that.user.userType = allValues.userType;
    });
    this.subs.push(sub);
    this.setReset();
  }

  sendEmailsChanged(value: any) {
    value = value === 'true' ? true : false;
    this.sendResetEmail = this.sendResetEmail === value ? !value : value;
  }

  setReset() {
    this.authService.isTwoFactorActive(this.user.emailAddress).subscribe(value => {
      this.canResetTwoFactor = value;
    });
  }

  resetTwoFactor() {
    const that = this;
    that.swal.load('Resetting two factor....');
    this.authService.resetTwoFactor(this.user.emailAddress).subscribe(
      () => {
        that.swal.success({ title: 'Two Factor Successfully Reset!' })
          .then(() => {
            this.setReset();
            that.swal.load('Sending confirmation email...');
            that.emailService.sendTwoFactorResetEmail(this.user, this.currentUser, true, null)
              .subscribe(
                success => {
                  that.swal
                    .success({
                      title: 'Email Sent'
                    })
                    .then(() => {
                      $('#app_modal').modal('hide');
                    })
                    .catch(() => { });
                },
                error => {
                  that.swal.error({
                    title: 'Oops!',
                    text: 'An error occurred while sending Email.'
                  });
                }
              );;
          });
      },
      (error) => {
        that.swal.error({
          title: 'Oops!',
          text: 'An error occurred while updating account'
        })
        .then(() => {
          that.swal.load('Sending error email...');
          that.emailService.sendTwoFactorResetEmail(this.user, this.currentUser, false, error)
            .subscribe(
              success => {
                that.swal
                  .success({
                    title: 'Email Sent'
                  })
                  .then(() => {
                    $('#app_modal').modal('hide');
                  })
                  .catch(() => { });
              },
              error => {
                that.swal.error({
                  title: 'Oops!',
                  text: 'An error occurred while sending Email.'
                });
              }
            );;
        });
      }
    );
  }

  save() {
    const that = this;

    // Show loader swal
    that.swal.load('Creating user account...');

    // Commit to DB
    this.subs.push(
      this.dataService.createUser(this.user).subscribe(
        () => {
          // Raise event for parent to listen on
          that.pubSubService.publish(publishSubscribeEventStrings.user_saved);

          // show a friendly alert, then hide user modal after alert is dismissed
          that.swal
            .success({ title: 'User data updated successfully!' })
            .then(() => {
              that.swal.load('Sending password reset email to the user...');
              const emailSub = that.emailService
                .sendUserAccountCreatedEmail(
                  that.user,
                  that.dataService.getResetRedirectionUrl(
                    that.user.emailAddress
                  )
                )
                .subscribe(
                  success => {
                    that.swal
                      .success({
                        title: 'Email Sent'
                      })
                      .then(() => {
                        $('#app_modal').modal('hide');
                      })
                      .catch(() => {});
                  },
                  error => {
                    that.swal.error({
                      title: 'Oops!',
                      text: 'An error occurred while sending Email.'
                    });
                  }
                );
              that.subs.push(emailSub);
            })
            .catch(() => $('#app_modal').modal('hide'));
        },
        () =>
          that.swal.error({
            title: 'Oops!',
            text: 'An error occurred while saving user data.'
          })
      )
    );
  }

  setFormValue() {
    if (this.user) {
      this.form.patchValue({
        isActive: this.user.isActive,
        userType: this.user.userType
      });
      if (this.applicantComponent) {
        this.applicantComponent.setFormData(this.user);
      }
      if (this.assessorComponent) {
        this.assessorComponent.setFormData(this.user);
      }
      if (this.boardComponent) {
        this.boardComponent.setFormData(this.user);
      }

      if (this.externalReviewerComponent) {
        this.externalReviewerComponent.setFormData(this.user);
      }

      if (this.managerComponent) {
        this.managerComponent.setFormData(this.user);
      }
    }
  }

  switchChildForm() {
    const that = this;
    // When user is governor, the 'Account Status' is not modifiable by the user.
    if (this.user.userType === userType.governor.code) {
      // When creating governor accounts, they must always default to inactive because there can only be one active governor at a time.

      if (this.createMode) {
        // To set this governor to active, you have to go to the special accounts page and reassign the current governor there.
        this.form.get('isActive').patchValue('false');
      } else {
        // This is the case for a governor account that has already been created.
        // It should never be repurposed to any other account type.
        this.form.get('userType').disable();
      }

      this.form.get('isActive').disable();
      this.form.updateValueAndValidity();
    } else {
      if (this.createMode) {
        const controlNamesToRemove = chain(this.form.controls)
          .keys()
          .filter(key => key !== 'isActive' && key !== 'userType');

        const that = this;
        controlNamesToRemove.forEach(name => that.form.removeControl(name));

        this.form.setValue({
          isActive: true,
          userType: this.form.get('userType').value
        });
      }

      // Simply enable anything that could have been disabled
      this.form.get('isActive').enable();
      this.form.get('userType').enable();
    }

    switch (this.user.userType) {
      case userType.applicant.code:
        this.thenBlock = this.commonBlock;
        break;
      case userType.auditor.code:
        this.thenBlock = this.auditorBlock;
        break;
      case userType.governor.code:
        this.thenBlock = this.commonBlock;
        break;
      case userType.assessor.code:
        this.thenBlock = this.assessorBlock;
        break;
      case userType.board.code:
        this.thenBlock = this.brdBlock;
        break;
      case userType.external.code:
        this.thenBlock = this.extBlock;
        break;
      case userType.management.code:
        this.thenBlock = this.mgrBlock;
        break;
      default:
        this.thenBlock = null;
        break;
    }
    // Set form model values
    that.setFormValue();
  }

  resetPermissionsandPrograms() {
    this.user.permissions = new UserPermissions();
    this.user.incentivePrograms.splice(0);
    // Nullify the boards value -- so that if this user's user type is switched, he is no longer part of any board
    this.user.boards = null;
    this.user.externalReviewAgency = null;
  }
  update() {
    const that = this;

    // Show loader swal
    that.swal.load('Updating user account...');

    // Commit to DB
    this.subs.push(
      this.dataService.updateUser(this.user).subscribe(
        () => {
          // Raise event for parent to listen on
          that.pubSubService.publish(publishSubscribeEventStrings.user_saved);

          // show a friendly alert, then hide user modal after alert is dismissed
          that.swal
            .success({ title: 'User data updated successfully!' })
            .then(() => {
              // Send Password Reset Email only
              if (that.sendResetEmail) {
                that.swal.load('Sending password reset email to the user...');
                const resetEmailSub = that.emailService
                  .sendUserAccountUpdatedEmail(
                    that.user,
                    that.dataService.getResetRedirectionUrl(
                      that.user.emailAddress
                    )
                  )
                  .subscribe(
                    success => {
                      that.swal
                        .success({
                          title: 'Email Sent'
                        })
                        .then(() => {
                          $('#app_modal').modal('hide');
                        })
                        .catch(() => {});
                    },
                    error => {
                      that.swal.error({
                        title: 'Oops!',
                        text: 'An error occurred while sending Email.'
                      });
                    }
                  );
                that.subs.push(resetEmailSub);
              }
            });
        },
        () =>
          that.swal.error({
            title: 'Oops!',
            text: 'An error occurred while saving user data.'
          })
      )
    );
  }
}
