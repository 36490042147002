import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { Route } from '@angular/router';
import { SwalService } from '../../fastlane-common/services/swal.service';

@Injectable()
export class RoleGuard implements CanLoad, CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.isUserLoggedIn) {
      // Get URL to redirect.
      this.authService.redirectUrl = state.url;
      // If the user is not logged in redirect to Login page.
      this.router.navigate(['/login']);
      return false;
    } else if (this.authService.isUserLoggedIn) {
      const rolesAllowed: string[] = route.data['rolesAllowed'];
      const userRole = this.authService.getUserRole();

      // Check whether user matches any of the roles allowed
      if (rolesAllowed.includes(userRole)) {
        return true;
      }

      // User is not allowed
      console.log('The current route cannot be activated: ' + route.url);

      // Clear the token
      this.authService.logout();

      // Ensure that any previous path is cleared
      // This would avoid a perceived loop
      this.authService.redirectUrl = null;

      this.swalService.error({
        title: 'Access Denied',
        text: 'You attempted to navigate to an area to which you do not have access!'
      });

      this.router.navigate(['/login']);
      return false;
    }
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.isUserLoggedIn) {
      return false;
    } else if (this.authService.isUserLoggedIn) {
      const rolesAllowed: string[] = route.data['rolesAllowed'];
      const userRole = this.authService.getUserRole();

      // Check whether user matches any of the roles allowed
      if (rolesAllowed.includes(userRole)) {
        return true;
      }

      // User is not allowed
      console.log('The current module cannot be loaded: ' + route.path);
      return false;
    }
  }

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private swalService: SwalService
  ) {}
}
