import { formTypes } from '../../../form/form.constants';
import { ComplianceForm } from './compliance-form.model';

export class CertificiateComplianceForm extends ComplianceForm {
  reportingYear: string;

  isFirstYear?: boolean;

  projectOrPhaseFullyExecuted?: boolean;
  projectOrPhaseFullyExecutedCompiledWithPCR?: boolean;
  projectOrPhaseFullyExecutedCompiledWithAFC?: boolean;
  projectOrPhaseFullyExecutedNoDescription: string;

  operationOfRelatedAssetsCommenced?: boolean;
  operationOfRelatedAssetsCommencedDate?: Date;
  operationOfRelatedAssetsCommencedDescription: string;

  capitalExpenditureDate?: Date;
  capitalExpenditureAmount?: number;

  requiredAnnualJobs?: number;
  actualAnnualJobs?: number;

  requiredAnnualPayroll?: number;
  actualAnnualPayroll?: number;

  offeredBasicHealthBenefits?: boolean;

  manufacturingJobsAttributableInCompany?: boolean;
  manufacturingJobsAttributableOutsideCompany?: boolean;
  manufacturingJobsNeutralJobCountWithinState?: boolean;
  manufacturingJobsNeutralJobCountWithinStateDescription: string;

  additionalNonComplianceAttached?: boolean;

  complianceDueDate?: Date;
  checklistReportingPeriodStart?: Date;
  checklistReportingPeriodEnd?: Date;

  checklistNewAnnualJobs?: number;
  checklistRetainedAnnualJobs?: number;
  checklistRequiredAnnualPayroll?: number;

  mustSatisfyPercentage?: number;
  siteBaselineNumber: string;
  statewideBaselineNumber: string;

  pSEBSRetainedJobs?: number;
  pSEBSMeetsBaselineCriteria?: boolean;
  pSEBSMeetsPayrollRequirements?: boolean;
  pSEBSES4SUTA?: boolean;
  psebsRetainedJobs?: number;
  psebsMeetsBaselineCriteria?: boolean;
  psebsMeetsPayrollRequirements?: boolean;
  psebseS4SUTA?: boolean;

  mWSRMultipleSitesInState?: boolean;
  mWSRSitesListedWithJobAndPayrollNumbers?: boolean;
  mwsrMultipleSitesInState?: boolean;
  mwsrSitesListedWithJobAndPayrollNumbers?: boolean;

  nJCSNewJobs?: number;
  nJCSEmployeesLiveInLa?: boolean;
  nJCSAverage30HoursWeekly?: boolean;
  nJCSMeetsPayrollRequirements?: boolean;
  nJCSES4SUTA?: boolean;
  njcsNewJobs?: number;
  njcsEmployeesLiveInLa?: boolean;
  njcsAverage30HoursWeekly?: boolean;
  njcsMeetsPayrollRequirements?: boolean;
  njcseS4SUTA?: boolean;

  notes: string;
  compliant?: boolean;
  extensionRequested?: boolean;


  constructor(formIndex: number = null) {
    super(formIndex);
    this.init();
  }

  init() {
    super.init();
    this.type = formTypes.coc.abbrev;
  }

  get qualifier() {
    if (!this.reportingYear) {
      return '';
    }
    return `Year: ${this.reportingYear}`;
  }
}
