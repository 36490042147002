export class Days {
  totalDaysInLA: number;
  totalPrincipalPhotographyDaysInLA: number;
  totalPhotographyDaysOutside: number;
  percentPhotographyDaysOutside: number;

  constructor(obj?: any) {
    if (!obj) {
      obj = {};
    }
    this.totalDaysInLA = obj.totalDaysInLA >= 0 ? obj.totalDaysInLA : null;
    this.totalPrincipalPhotographyDaysInLA =
      obj.totalPrincipalPhotographyDaysInLA >= 0
        ? obj.totalPrincipalPhotographyDaysInLA
        : null;
    this.totalPhotographyDaysOutside =
      obj.totalPhotographyDaysOutside >= 0
        ? obj.totalPhotographyDaysOutside
        : null;
    this.percentPhotographyDaysOutside =
      obj.percentPhotographyDaysOutside >= 0
        ? obj.percentPhotographyDaysOutside
        : null;
  }
}
