import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  CanActivateChild
} from '@angular/router';
import { SwalService } from '../../fastlane-common/services/swal.service';
import { Observable } from 'rxjs';
import { UserContextService } from '../../user/services/user-context.service';
import { AuthenticationService } from '../services/authentication.service';
import { ManagerialPermission } from '../security.constants';

@Injectable()
export class PermissionsGuard implements CanActivate, CanActivateChild {
  hasUserPermission(
    route: ActivatedRouteSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return new Observable<boolean>(subscribeOn => {
      this.authenticationService.executeAfterUserPermissionsFetched(() => {
        // Ensure permissions are fetched
        const permissions = this.userContextService.currentUser.permissions;

        // Read the required permission from the route as provided in routing.ts
        const permission = route.data['permission'];

        // If the user has the permission, broadcast to all listeners TRUE
        if (permissions[permission] === true) {
          subscribeOn.next(true);
        } else {
          const componentName = ManagerialPermission[permission].name;
          // A swal should be shown to the user to notify of lack of permissions
          setTimeout(x => {
            this.swal
              .warn({
                title: 'No Permissions',
                confirmButtonText: 'Ok',
                html:
                  '<p class="text-left">Sorry, you do not have permissions to access ' + componentName + '.</p>',
                showCloseButton: true
              })
              .catch(() => {});
          }, 0);

          // If User does not have permission to defualt dashboard page, then redirect to unrestricted document checklist page.
          if (ManagerialPermission[permission].code === ManagerialPermission.biDashBoard.code) {
            this.router.navigate(['/management/document-checklist']);
          } else {
            this.router.navigate([]);
          }

          subscribeOn.next(false);
        }

        // Ensure this one time subscription is complete
        subscribeOn.complete();

        // No teardown logic from this perspective required
        return () => {};
      });
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.hasUserPermission(route);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.hasUserPermission(route);
  }

  constructor(
    private router: Router,
    private userContextService: UserContextService,
    private swal: SwalService,
    private authenticationService: AuthenticationService
  ) {}
}
