export class Expenditures {
  totalBudget: number;
  aboveLineSalaries: number;
  residentPayroll: number;
  laVisualEffectsExpenditure: number;
  totalVisualEffectsExpenditure: number;
  laExpenditure: number;
  doesLaSpendIncludeAirfareAndOthers: boolean;
  percentVfxinLA: number;

  constructor(obj?: any) {
    if (!obj) {
      obj = {};
    }
    this.totalBudget = obj.totalBudget >= 0 ? obj.totalBudget : null;
    this.aboveLineSalaries =
      obj.aboveLineSalaries >= 0 ? obj.aboveLineSalaries : null;
    this.residentPayroll =
      obj.residentPayroll >= 0 ? obj.residentPayroll : null;
    this.laVisualEffectsExpenditure =
      obj.laVisualEffectsExpenditure >= 0
        ? obj.laVisualEffectsExpenditure
        : null;
    this.totalVisualEffectsExpenditure =
      obj.totalVisualEffectsExpenditure >= 0
        ? obj.totalVisualEffectsExpenditure
        : null;
    this.laExpenditure = obj.laExpenditure >= 0 ? obj.laExpenditure : null;
    this.percentVfxinLA = obj.percentVfxinLA >= 0 ? obj.percentVfxinLA : null;
  }
}
