import { ProjectForm } from './form.model';
import { formTypes } from 'app/form/form.constants';

export class ExtensionRequest extends ProjectForm {
  newEndDate: Date;
  newStartDate: Date;
  priorEndDate: Date;
  priorStartDate: Date;

  constructor(formIndex: number = null) {
    super(formIndex);
    this.init();
  }

  init(): ExtensionRequest {
    super.init();

    this.type = formTypes.extensionRequest.abbrev;

    this.newEndDate = this.newEndDate ? new Date(this.newEndDate) : null;
    this.newStartDate = this.newStartDate ? new Date(this.newStartDate) : null;
    this.priorEndDate = this.priorEndDate ? new Date(this.priorEndDate) : null;
    this.priorStartDate = this.priorStartDate
      ? new Date(this.priorStartDate)
      : null;

    return this;
  }

  get qualifier() {
    return '';
  }
}
