import { AuthenticationService } from './security/services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { EmailService } from './email/services/email.service';
import { VersionService } from './fastlane-common/services/version.service';
import { SwalService } from './fastlane-common/services/swal.service';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { NavService } from './fastlane-common/services/nav.service';

declare var $: any;

/**
 * This component has no presentation to it. The purpose of this module is to manage automatic access token refreshing and for
 * redirecting users to specific pages based on their role in the application.
 */
@Component({
  selector: 'fl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthenticationService,
    private emailService: EmailService,
    private versionService: VersionService,
    private googleAnalytics: Angulartics2GoogleAnalytics, // initializes the service and starts event subscriptions
    private navService: NavService,
    private swalService: SwalService
  ) {
    Date['getLocalDate'] = (dateString: string) => {
      const gmtDate = new Date(dateString);
      return new Date(+gmtDate + gmtDate.getTimezoneOffset() * 60000);
    };
  }

  ngOnInit() {
    // Ensure browser is compatible with application
    this.checkBrowserCompatibility();

    // Hijack anchor clicks
    this.setupNavigation();
    this.navService.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  checkBrowserCompatibility() {
    const that = this;
    const isIE = /msie\s|trident\//i.test(window.navigator.userAgent);
    if (isIE) {
      that.swalService.warn({
        title: 'Incompatible Browser',
        text:
          'This site is incompatible with Internet Explorer. Please use Google Chrome, Mozilla Firefox, Edge, or Safari.'
      });
    }
  }

  checkLocalStorageEnabled() {
    const sampleLocalStorageString = 'Local Storage Enabled?';
    try {
      localStorage.setItem(sampleLocalStorageString, sampleLocalStorageString);
      localStorage.removeItem(sampleLocalStorageString);

      // Local storage or Site Data is enabled
      return true;
    } catch (error) {
      // Local storage or Site Data is disabled
      return false;
    }
  }

  setupNavigation() {
    const that = this;
    const interceptLinkClick = e => {
      try {
        const target = e.target || e.srcElement;

        let path: string;
        let externalLink: boolean;

        if (target.tagName === 'A') {
          path = target
            .getAttribute('href')
            .replace(window.location.origin, '');
          externalLink = target.getAttribute('target') === '_blank';
        } else if (target.parentElement.tagName === 'A') {
          path = target.parentElement
            .getAttribute('href')
            .replace(window.location.origin, '');
          externalLink =
            target.parentElement.getAttribute('target') === '_blank';
        }

        if (
          path &&
          path.trim() !== '' &&
          !path.includes('#') &&
          path.trim() !== 'javascript:;' &&
          !path.startsWith('blob:/') &&
          externalLink === false
        ) {
          e.preventDefault();
          const route = path.split('?')[0];
          const paramsString = path.split('?')[1];
          if (paramsString) {
            const params = paramsString.split('&').reduce((seed, next) => {
              const kvp = next.split('=');
              seed[kvp[0]] = decodeURI(kvp[1]);
              return seed;
            }, {});
            that.navService.nav(route, 'absolute', params);
          } else {
            that.navService.nav(route, 'absolute');
          }
        }
      } catch {}
    };

    if (document.addEventListener) {
      document.addEventListener('click', interceptLinkClick);
    } else if ((<any>document).attachEvent) {
      (<any>document).attachEvent('onclick', interceptLinkClick);
    }
  }
}
