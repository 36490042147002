export class CreditType {
  // Credit Type
  creditType: string;
  // Expenditure amount used to calculate CreditType Amount
  expenditureAmount?: number;
  rate?: number;
  creditTypeAmount: number;
  // Tracks if the credit Type is applied by the applicant
  isApplied?: boolean;
  // Tracks if the credit Type is reserved by the manager in the initial Cert.
  isReserved?: boolean;
  // Tracks if the credit Type amount is issued by the manager in the audit.
  isIssued?: boolean;
  // Tracks if the credit Type is eligible based on Eligiblity rules in application and IntialCert.
  isEligible?: boolean;
  maxExpenditureAmount?: number;

  constructor(obj?: any) {
    if (!obj) {
      obj = {};
    }
    this.creditType = obj.creditType || null;
    this.expenditureAmount =
      obj.expenditureAmount >= 0 ? obj.expenditureAmount : null;
    this.rate = obj.rate >= 0 ? obj.rate : null;
    this.creditTypeAmount =
      obj.creditTypeAmount >= 0 ? obj.creditTypeAmount : null;
    this.isApplied =
      obj.isApplied === null || obj.isApplied === undefined
        ? null
        : obj.isApplied;
    this.isReserved =
      obj.isReserved === null || obj.isReserved === undefined
        ? null
        : obj.isReserved;
    this.isIssued =
      obj.isIssued === null || obj.isIssued === undefined ? null : obj.isIssued;
    this.isEligible =
      obj.isEligible === null || obj.isEligible === undefined
        ? null
        : obj.isEligible;
    this.maxExpenditureAmount =
      obj.maxExpenditureAmount >= 0 ? obj.maxExpenditureAmount : null;
  }
}
