import { creditTypes } from './entertainment.constants';

import { CreditType } from './models/creditType.model';
import { ProjectForm } from '../project/models/form.model';
import { Project as FilmProject } from './models/film/project.model';
import { FormIdentifier } from '../project/models/form-identifier.model';
import { formTypes } from '../form/form.constants';
import { Application } from './models/film/film-application.model';
import { outofMSAParishes as notOutofMSAParishes } from '../shared/shared.constants';
import { ProjectInfo } from './models/film/project-info.model';
import { legislationRuleNames } from '../project/project.constants';

type CreditTypes =
  | 'InState'
  | 'OutOfState'
  | 'NonLabor'
  | 'LA Resident' /** Same as InState. Only use for displaying, not saving. */
  | 'Non LA Resident' /** Same as OutOfState. Only use for displaying, not saving. */;

export const CreditTypes = {
  inState: <CreditTypes>'InState',
  outOfState: <CreditTypes>'OutOfState',
  nonLabor: <CreditTypes>'NonLabor',
  laResident: <CreditTypes>'LA Resident' /** Same as InState. Only use for displaying, not saving. */,
  nonLaResident: <CreditTypes>'Non LA Resident' /** Same as OutOfState. Only use for displaying, not saving. */
};

export const creditTypeFormulaDictionary = {
  [legislationRuleNames.film.preJuly2015.name]: {
    creditTypes: [
      {
        name: creditTypes.base.name,
        abbrev: creditTypes.base.abbrev,
        creditTypeDetails: (form: ProjectForm) => {
          return new CreditType(<CreditType>{
            creditType: creditTypes.base.abbrev,
            expenditureAmount: 0,
            rate: 0.25,
            maxExpenditureAmount: null,
            creditTypeAmount: 0
          });
        },
        eligibility: (projectInfo: ProjectInfo, form: ProjectForm): boolean => {
          if (
            form.type === formTypes.application.abbrev ||
            form.type === formTypes.asa.abbrev ||
            form.type === formTypes.initialCert.abbrev
          ) {
            const application = <Application>form;
            const baseCreditType = application.estimatedCreditTypes.find(
              ct => ct.creditType === creditTypes.base.abbrev
            );
            const eligibile =
              (application.productionDetails.isScreenPlayCreatedByResident &&
                application.expenditures.laExpenditure >= 50000) ||
              (!application.productionDetails.isScreenPlayCreatedByResident &&
                application.expenditures.laExpenditure >= 300000);
            baseCreditType.isApplied = eligibile;
            baseCreditType.isReserved = eligibile;
            return eligibile;
          }
          return true;
        }
      },
      {
        name: creditTypes.outMSA.name,
        abbrev: creditTypes.outMSA.abbrev,
        creditTypeDetails: (form: ProjectForm) => {
          return new CreditType(<CreditType>{
            creditType: creditTypes.outMSA.abbrev,
            expenditureAmount: 0,
            rate: 0.05,
            maxExpenditureAmount: null,
            creditTypeAmount: 0
          });
        },
        eligibility: (projectInfo: ProjectInfo, form: ProjectForm): boolean => {
          const isOutsideMSA = !notOutofMSAParishes.includes(
            projectInfo.location.parish
          );
          if (
            form.type === formTypes.application.abbrev ||
            form.type === formTypes.asa.abbrev ||
            form.type === formTypes.initialCert.abbrev
          ) {
            const application = <Application>form;
            const baseCreditType = application.estimatedCreditTypes.find(
              ct => ct.creditType === creditTypes.base.abbrev
            );
            return (
              isOutsideMSA &&
              application.estimatedDays.percentPhotographyDaysOutside >= 0.6 &&
              baseCreditType.isEligible
            );
          }
          return true;
        }
      },
      {
        name: creditTypes.screenplay.name,
        abbrev: creditTypes.screenplay.abbrev,
        creditTypeDetails: (form: ProjectForm) => {
          return new CreditType(<CreditType>{
            creditType: creditTypes.screenplay.abbrev,
            expenditureAmount: 0,
            rate: 0.1,
            maxExpenditureAmount: 5000000,
            creditTypeAmount: 0
          });
        },
        eligibility: (projectInfo: ProjectInfo, form: ProjectForm): boolean => {
          if (
            form.type === formTypes.application.abbrev ||
            form.type === formTypes.asa.abbrev ||
            form.type === formTypes.initialCert.abbrev
          ) {
            const application = <Application>form;
            const baseCreditType = application.estimatedCreditTypes.find(
              ct => ct.creditType === creditTypes.base.abbrev
            );
            return (
              application.productionDetails.isScreenPlayCreatedByResident &&
              application.expenditures.laExpenditure >= 50000 &&
              baseCreditType.isEligible
            );
          }
          return true;
        }
      },
      {
        name: creditTypes.payroll.name,
        abbrev: creditTypes.payroll.abbrev,
        creditTypeDetails: (form: ProjectForm) => {
          return new CreditType(<CreditType>{
            creditType: creditTypes.payroll.abbrev,
            expenditureAmount: 0,
            rate: 0.15,
            maxExpenditureAmount: null,
            creditTypeAmount: 0
          });
        },
        eligibility: (projectInfo: ProjectInfo, form: ProjectForm): boolean => {
          if (
            form.type === formTypes.application.abbrev ||
            form.type === formTypes.asa.abbrev ||
            form.type === formTypes.initialCert.abbrev
          ) {
            const application = <Application>form;
            const baseCreditType = application.estimatedCreditTypes.find(
              ct => ct.creditType === creditTypes.base.abbrev
            );
            return baseCreditType.isEligible;
          }
          return true;
        }
      },
      {
        name: creditTypes.vfx.name,
        abbrev: creditTypes.vfx.abbrev,
        creditTypeDetails: (form: ProjectForm) => {
          return new CreditType(<CreditType>{
            creditType: creditTypes.vfx.abbrev,
            expenditureAmount: 0,
            rate: 0.05,
            maxExpenditureAmount: null,
            creditTypeAmount: 0
          });
        },
        eligibility: (projectInfo: ProjectInfo, form: ProjectForm): boolean => {
          if (
            form.type === formTypes.application.abbrev ||
            form.type === formTypes.asa.abbrev ||
            form.type === formTypes.initialCert.abbrev
          ) {
            const application = <Application>form;
            const baseCreditType = application.estimatedCreditTypes.find(
              ct => ct.creditType === creditTypes.base.abbrev
            );
            return (
              ((application.isVisualEffectsQualifiedCompany &&
                application.expenditures.percentVfxinLA >= 0.5) ||
                application.expenditures.laVisualEffectsExpenditure >=
                  1_000_000) &&
              baseCreditType.isEligible
            );
          }
          return true;
        }
      }
    ],
    baseInvestmentCap: 0.3,
    capPerSeason: 25_000_000,
    effectiveDate: null
  },
  [legislationRuleNames.film.july2015.name]: {
    creditTypes: [
      {
        name: creditTypes.base.name,
        abbrev: creditTypes.base.abbrev,
        creditTypeDetails: (form: ProjectForm) => {
          return new CreditType(<CreditType>{
            creditType: creditTypes.base.abbrev,
            expenditureAmount: 0,
            rate: 0.25,
            maxExpenditureAmount: null,
            creditTypeAmount: 0
          });
        },
        eligibility: (projectInfo: ProjectInfo, form: ProjectForm): boolean => {
          if (
            form.type === formTypes.application.abbrev ||
            form.type === formTypes.asa.abbrev ||
            form.type === formTypes.initialCert.abbrev
          ) {
            const application = <Application>form;
            const baseCreditType = application.estimatedCreditTypes.find(
              ct => ct.creditType === creditTypes.base.abbrev
            );
            const eligibile =
              (application.productionDetails.isScreenPlayCreatedByResident &&
                application.expenditures.laExpenditure >= 50000) ||
              (!application.productionDetails.isScreenPlayCreatedByResident &&
                application.expenditures.laExpenditure >= 300000);
            baseCreditType.isApplied = eligibile;
            baseCreditType.isReserved = eligibile;
            return eligibile;
          }
          return true;
        }
      },
      {
        name: creditTypes.outMSA.name,
        abbrev: creditTypes.outMSA.abbrev,
        creditTypeDetails: (form: ProjectForm) => {
          return new CreditType(<CreditType>{
            creditType: creditTypes.outMSA.abbrev,
            expenditureAmount: 0,
            rate: 0.05,
            maxExpenditureAmount: null,
            creditTypeAmount: 0
          });
        },
        eligibility: (projectInfo: ProjectInfo, form: ProjectForm): boolean => {
          const isOutsideMSA = !notOutofMSAParishes.includes(
            projectInfo.location.parish
          );
          if (
            form.type === formTypes.application.abbrev ||
            form.type === formTypes.asa.abbrev ||
            form.type === formTypes.initialCert.abbrev
          ) {
            const application = <Application>form;
            const baseCreditType = application.estimatedCreditTypes.find(
              ct => ct.creditType === creditTypes.base.abbrev
            );
            return (
              isOutsideMSA &&
              application.estimatedDays.percentPhotographyDaysOutside >= 0.6 &&
              baseCreditType.isEligible
            );
          }
          return true;
        }
      },
      {
        name: creditTypes.screenplay.name,
        abbrev: creditTypes.screenplay.abbrev,
        creditTypeDetails: (form: ProjectForm) => {
          return new CreditType(<CreditType>{
            creditType: creditTypes.screenplay.abbrev,
            expenditureAmount: 0,
            rate: 0.1,
            maxExpenditureAmount: 5000000,
            creditTypeAmount: 0
          });
        },
        eligibility: (projectInfo: ProjectInfo, form: ProjectForm): boolean => {
          if (
            form.type === formTypes.application.abbrev ||
            form.type === formTypes.asa.abbrev ||
            form.type === formTypes.initialCert.abbrev
          ) {
            const application = <Application>form;
            const baseCreditType = application.estimatedCreditTypes.find(
              ct => ct.creditType === creditTypes.base.abbrev
            );
            return (
              application.productionDetails.isScreenPlayCreatedByResident &&
              application.expenditures.laExpenditure >= 50000 &&
              baseCreditType.isEligible
            );
          }
          return true;
        }
      },
      {
        name: creditTypes.payroll.name,
        abbrev: creditTypes.payroll.abbrev,
        creditTypeDetails: (form: ProjectForm) => {
          return new CreditType(<CreditType>{
            creditType: creditTypes.payroll.abbrev,
            expenditureAmount: 0,
            rate: 0.15,
            maxExpenditureAmount: null,
            creditTypeAmount: 0
          });
        },
        eligibility: (projectInfo: ProjectInfo, form: ProjectForm): boolean => {
          if (
            form.type === formTypes.application.abbrev ||
            form.type === formTypes.asa.abbrev ||
            form.type === formTypes.initialCert.abbrev
          ) {
            const application = <Application>form;
            const baseCreditType = application.estimatedCreditTypes.find(
              ct => ct.creditType === creditTypes.base.abbrev
            );
            return baseCreditType.isEligible;
          }
          return true;
        }
      },
      {
        name: creditTypes.vfx.name,
        abbrev: creditTypes.vfx.abbrev,
        creditTypeDetails: (form: ProjectForm) => {
          return new CreditType(<CreditType>{
            creditType: creditTypes.vfx.abbrev,
            expenditureAmount: 0,
            rate: 0.05,
            maxExpenditureAmount: null,
            creditTypeAmount: 0
          });
        },
        eligibility: (projectInfo: ProjectInfo, form: ProjectForm): boolean => {
          if (
            form.type === formTypes.application.abbrev ||
            form.type === formTypes.asa.abbrev ||
            form.type === formTypes.initialCert.abbrev
          ) {
            const application = <Application>form;
            const baseCreditType = application.estimatedCreditTypes.find(
              ct => ct.creditType === creditTypes.base.abbrev
            );
            return (
              ((application.isVisualEffectsQualifiedCompany &&
                application.expenditures.percentVfxinLA >= 0.5) ||
                application.expenditures.laVisualEffectsExpenditure >=
                  1_000_000) &&
              baseCreditType.isEligible
            );
          }
          return true;
        }
      }
    ],
    baseInvestmentCap: 0.3,
    capPerSeason: 25_000_000,
    effectiveDate: null
  },
  [legislationRuleNames.film.july2017.name]: {
    creditTypes: [
      {
        name: creditTypes.base.name,
        abbrev: creditTypes.base.abbrev,
        creditTypeDetails: (form: ProjectForm) => {
          return new CreditType(<CreditType>{
            creditType: creditTypes.base.abbrev,
            expenditureAmount: 0,
            rate: 0.25,
            maxExpenditureAmount: null,
            creditTypeAmount: 0
          });
        },
        eligibility: (projectInfo: ProjectInfo, form: ProjectForm): boolean => {
          if (
            form.type === formTypes.application.abbrev ||
            form.type === formTypes.asa.abbrev ||
            form.type === formTypes.initialCert.abbrev
          ) {
            const application = <Application>form;
            const baseCreditType = application.estimatedCreditTypes.find(
              ct => ct.creditType === creditTypes.base.abbrev
            );
            const eligibile =
              (application.productionDetails.isScreenPlayCreatedByResident &&
                application.expenditures.laExpenditure >= 50000) ||
              (!application.productionDetails.isScreenPlayCreatedByResident &&
                application.expenditures.laExpenditure >= 300000);
            baseCreditType.isApplied = eligibile;
            baseCreditType.isReserved = eligibile;
            return eligibile;
          }
          return true;
        }
      },
      {
        name: creditTypes.outMSA.name,
        abbrev: creditTypes.outMSA.abbrev,
        creditTypeDetails: (form: ProjectForm) => {
          return new CreditType(<CreditType>{
            creditType: creditTypes.outMSA.abbrev,
            expenditureAmount: 0,
            rate: 0.05,
            maxExpenditureAmount: null,
            creditTypeAmount: 0
          });
        },
        eligibility: (projectInfo: ProjectInfo, form: ProjectForm): boolean => {
          const isOutsideMSA = !notOutofMSAParishes.includes(
            projectInfo.location.parish
          );
          if (
            form.type === formTypes.application.abbrev ||
            form.type === formTypes.asa.abbrev ||
            form.type === formTypes.initialCert.abbrev
          ) {
            const application = <Application>form;
            const baseCreditType = application.estimatedCreditTypes.find(
              ct => ct.creditType === creditTypes.base.abbrev
            );
            return (
              isOutsideMSA &&
              application.estimatedDays.percentPhotographyDaysOutside >= 0.6 &&
              baseCreditType.isEligible
            );
          }
          return true;
        }
      },
      {
        name: creditTypes.screenplay.name,
        abbrev: creditTypes.screenplay.abbrev,
        creditTypeDetails: (form: ProjectForm) => {
          return new CreditType(<CreditType>{
            creditType: creditTypes.screenplay.abbrev,
            expenditureAmount: 0,
            rate: 0.1,
            maxExpenditureAmount: 5000000,
            creditTypeAmount: 0
          });
        },
        eligibility: (projectInfo: ProjectInfo, form: ProjectForm): boolean => {
          if (
            form.type === formTypes.application.abbrev ||
            form.type === formTypes.asa.abbrev ||
            form.type === formTypes.initialCert.abbrev
          ) {
            const application = <Application>form;
            const baseCreditType = application.estimatedCreditTypes.find(
              ct => ct.creditType === creditTypes.base.abbrev
            );
            return (
              application.productionDetails.isScreenPlayCreatedByResident &&
              application.expenditures.laExpenditure >= 50000 &&
              baseCreditType.isEligible
            );
          }
          return true;
        }
      },
      {
        name: creditTypes.payroll.name,
        abbrev: creditTypes.payroll.abbrev,
        creditTypeDetails: (form: ProjectForm) => {
          return new CreditType(<CreditType>{
            creditType: creditTypes.payroll.abbrev,
            expenditureAmount: 0,
            rate: 0.15,
            maxExpenditureAmount: null,
            creditTypeAmount: 0
          });
        },
        eligibility: (projectInfo: ProjectInfo, form: ProjectForm): boolean => {
          if (
            form.type === formTypes.application.abbrev ||
            form.type === formTypes.asa.abbrev ||
            form.type === formTypes.initialCert.abbrev
          ) {
            const application = <Application>form;
            const baseCreditType = application.estimatedCreditTypes.find(
              ct => ct.creditType === creditTypes.base.abbrev
            );
            return baseCreditType.isEligible;
          }
          return true;
        }
      },
      {
        name: creditTypes.vfx.name,
        abbrev: creditTypes.vfx.abbrev,
        creditTypeDetails: (form: ProjectForm) => {
          return new CreditType(<CreditType>{
            creditType: creditTypes.vfx.abbrev,
            expenditureAmount: 0,
            rate: 0.05,
            maxExpenditureAmount: null,
            creditTypeAmount: 0
          });
        },
        eligibility: (projectInfo: ProjectInfo, form: ProjectForm): boolean => {
          if (
            form.type === formTypes.application.abbrev ||
            form.type === formTypes.asa.abbrev ||
            form.type === formTypes.initialCert.abbrev
          ) {
            const application = <Application>form;
            const baseCreditType = application.estimatedCreditTypes.find(
              ct => ct.creditType === creditTypes.base.abbrev
            );
            return (
              ((application.isVisualEffectsQualifiedCompany &&
                application.expenditures.percentVfxinLA >= 0.5) ||
                application.expenditures.laVisualEffectsExpenditure >=
                  1_000_000) &&
              baseCreditType.isEligible
            );
          }
          return true;
        }
      }
    ],
    baseInvestmentCap: 0.4,
    capPerSeason: 25_000_000,
    effectiveDate: new Date('2017-07-01')
  }
};

export interface ExpenditurePerCreditType {
  amount: number;
  creditType: string;
}
export type CreditStatus =
  | 'Pending Reservation'
  | 'Reserved'
  | 'Pending Issuance'
  | 'Issued';

export const CreditStatuses = {
  pendingReservation: <CreditStatus>'Pending Reservation',
  reserved: <CreditStatus>'Reserved',
  pendingIssuance: <CreditStatus>'Pending Issuance',
  issued: <CreditStatus>'Issued'
};

export const subCategories = {
  generalProd: {
    name: 'General Production Cap',
    abbrev: 'generalProd'
  },
  qualifiedEntComp: {
    name: 'Qualified Entertainment Company',
    abbrev: 'qualifiedEntComp'
  },
  laScreenplay: {
    name: 'Louisiana Screeenplay',
    abbrev: 'laScreenplay'
  },
  independent: {
    name: 'Independent',
    abbrev: 'independent'
  }
};
