import { ContractChange } from './contract-change.model';
import { formTypes } from 'app/form/form.constants';
export class ContractChangeName extends ContractChange {
    name: string;
    currentName: string;

    constructor(formIndex: number = null, form?: any) {
        super(formIndex, form);
        this.init();
    }

    init() {
        super.init();
        this.type = formTypes.changeCompanyName.abbrev;
    }
}