<div class="card h-100 border">
  <div class="card-header"><b>ESTIMATED DATES</b></div>

  <div
    class="card-body form-group px-3 mb-0 d-flex flex-wrap flex-column justify-content-between no-borders"
    [formGroup]="estimatedDatesFormGroup"
  >
    <div class="row py-1 d-flex align-items-center">
      <span class="col-12 col-xl-5 form-control-label"
        >Estimated Cost Report Submission Date:</span
      >
      <div class="col-12 col-xl-6">
        <fl-date-time-picker
          [form]="estimatedDatesFormGroup.get('costReportSubmissionDateGroup')"
          [showTime]="false"
          [selectedDate]="estimatedDates.costReportSubmissionDate"
          [IsRequired]="true"
          (dateTimeChanged)="
            estimatedDateChanged('costReportSubmissionDate', $event)
          "
        ></fl-date-time-picker>
      </div>
    </div>

    <div class="row py-1 d-flex align-items-center">
      <span class="col-12 col-xl-5 form-control-label"
        >Principal Photography Start Date:</span
      >
      <div class="col-12 col-xl-6">
        <fl-date-time-picker
          [form]="estimatedDatesFormGroup.get('photographyStartDateGroup')"
          [showTime]="false"
          [selectedDate]="estimatedDates.photographyStartDate"
          [IsRequired]="true"
          (dateTimeChanged)="
            estimatedDateChanged('photographyStartDate', $event)
          "
        ></fl-date-time-picker>
      </div>
    </div>

    <div class="row py-1 d-flex align-items-center">
      <span class="col-12 col-xl-5 form-control-label"
        >Principal Photography End Date:</span
      >
      <div class="col-12 col-xl-6">
        <fl-date-time-picker
          [form]="estimatedDatesFormGroup.get('photographyEndDateGroup')"
          [showTime]="false"
          [IsRequired]="true"
          [selectedDate]="estimatedDates.photographyEndDate"
          (dateTimeChanged)="estimatedDateChanged('photographyEndDate', $event)"
        ></fl-date-time-picker>
      </div>
    </div>

    <div class="row py-1 d-flex align-items-center">
      <label class="col-12 col-md-5"
        >Will Post Production be performed in Louisiana?</label
      >
      <div class="col-12 col-xl-6">
        <label class="form-check-inline">
          <input
            type="radio"
            [value]="true"
            (change)="radioChanged(true)"
            formControlName="isPostProductionInLA"
            name="isPostProductionInLA"
            id="inlineCheckbox1"
          />Yes
        </label>
        <label class="form-check-inline">
          <input
            type="radio"
            [value]="false"
            (change)="radioChanged(false)"
            formControlName="isPostProductionInLA"
            name="isPostProductionInLA"
            id="inlineCheckbox2"
          />No
        </label>
      </div>
    </div>

    <ng-container *ngIf="estimatedDates.isPostProductionInLA">
      <div class="row py-1 d-flex align-items-center">
        <span class="col-12 col-xl-5 form-control-label"
          >Post-Production Start Date:</span
        >
        <div class="col-12 col-xl-6">
          <fl-date-time-picker
            [form]="estimatedDatesFormGroup.get('postProductionStartDateGroup')"
            [showTime]="false"
            [IsRequired]="true"
            [selectedDate]="estimatedDates.postProductionStartDate"
            (dateTimeChanged)="
              estimatedDateChanged('postProductionStartDate', $event)
            "
          ></fl-date-time-picker>
        </div>
      </div>

      <div class="row py-1 d-flex align-items-center">
        <span class="col-12 col-xl-5 form-control-label"
          >Post-Production End Date:</span
        >
        <div class="col-12 col-xl-6">
          <fl-date-time-picker
            [form]="estimatedDatesFormGroup.get('postProductionEndDateGroup')"
            [showTime]="false"
            [IsRequired]="true"
            [selectedDate]="estimatedDates.postProductionEndDate"
            (dateTimeChanged)="
              estimatedDateChanged('postProductionEndDate', $event)
            "
          ></fl-date-time-picker>
        </div>
      </div>
    </ng-container>
  </div>
</div>
