import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthorizationPayload } from './authorization-payload.model';

export class AuthorizationToken {
  public access_token: string;
  public expires_in: number;
  public payload: AuthorizationPayload;
  public token_type: string;

  constructor(token?: any) {
    // Decode the jsonwebtoken
    const helper = new JwtHelperService();

    if (!token) {
      token = {};
    }
    this.access_token = token.access_token || null;
    this.expires_in = token.expires_in || null;
    this.payload =
      token.payload || this.access_token
        ? new AuthorizationPayload(helper.decodeToken(this.access_token))
        : null;
    this.token_type = token.token_type || null;
  }
}
