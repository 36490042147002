import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { EstimatedDates } from '../../../../entertainment/models/film/estimatedDates.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormValidationService } from '../../../../form/services/validation.service';
import { PublishSubscribeService } from '../../../../fastlane-common/services/publish-subscribe.service';
import { EventContext } from '../../../../fastlane-common/event/interfaces/event-context.interface';
import { ProjectForm } from '../../../../project/models/form.model';
import { events } from '../../../../fastlane-common/event/event.constants';
import { updateFormDateActions } from '../../../../project/project.constants';
import { FormShareService } from '../../../../form/services/share.service';

@Component({
  selector: 'fl-dates',
  templateUrl: './dates.component.html',
  styleUrls: ['./dates.component.scss']
})
export class DatesComponent implements OnInit {
  @Input() estimatedDates: EstimatedDates;
  estimatedDatesFormGroup: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private pubSubService: PublishSubscribeService,
    private formShareService: FormShareService,
    private validationService: FormValidationService
  ) {}

  ngOnInit() {
    const that = this;
    this.estimatedDatesFormGroup = this.formBuilder.group({
      costReportSubmissionDateGroup: this.formBuilder.group({}),
      photographyStartDateGroup: this.formBuilder.group({}),
      photographyEndDateGroup: this.formBuilder.group({}),
      isPostProductionInLA: [
        that.estimatedDates.isPostProductionInLA,
        Validators.required
      ],
      postProductionStartDateGroup: this.formBuilder.group({}),
      postProductionEndDateGroup: this.formBuilder.group({})
    });

    this.estimatedDatesFormGroup
      .get('isPostProductionInLA')
      .valueChanges.subscribe(value => {
        that.estimatedDates.isPostProductionInLA = value;
      });

    this.validationService.form.setControl(
      'estimatedDates',
      this.estimatedDatesFormGroup
    );

    this.raisecostReportDateChange();
    this.raisePhotographyStartDateChange();
  }

  estimatedDateChanged(property: string, date: Date) {
    this.estimatedDates[property] = date;
    if (property === 'costReportSubmissionDate') {
      this.raisecostReportDateChange();
    }
    if (property === 'photographyStartDate') {
      this.raisePhotographyStartDateChange();
    }
  }

  raisecostReportDateChange() {
    const that = this;
    const eventContext = <EventContext<ProjectForm>>{
      data: that.formShareService.form,
      eventType: events.updateFormDate.code,
      eventAction: updateFormDateActions.costReportSubmissionDateChanged
    };
    this.pubSubService.raise<EventContext<ProjectForm>>(
      eventContext.eventType,
      eventContext.eventAction,
      eventContext
    );
  }

  raisePhotographyStartDateChange() {
    const that = this;
    const eventContext = <EventContext<ProjectForm>>{
      data: that.formShareService.form,
      eventType: events.updateFormDate.code,
      eventAction: updateFormDateActions.principalPhotographyStartDateChanged
    };
    this.pubSubService.raise<EventContext<ProjectForm>>(
      eventContext.eventType,
      eventContext.eventAction,
      eventContext
    );
  }

  radioChanged(value: boolean) {
    const that = this;
    if (value) {
      that.estimatedDatesFormGroup.addControl(
        'postProductionStartDateGroup',
        this.formBuilder.group({})
      );
      that.estimatedDatesFormGroup.addControl(
        'postProductionEndDateGroup',
        this.formBuilder.group({})
      );
    } else {
      that.estimatedDatesFormGroup.removeControl(
        'postProductionStartDateGroup'
      );
      that.estimatedDates.postProductionStartDate = null;
      that.estimatedDatesFormGroup.removeControl('postProductionEndDateGroup');
      that.estimatedDates.postProductionEndDate = null;
    }
  }
}
