import {FormType, oneToManyFormTypes} from '../../form/form.constants';

export class FormIdentifier {
  formIndex: number;
  formType: FormType;
  projectGuid: string;

  constructor(formId?: any) {
    if (!formId) {
      formId = {};
    }

    this.formIndex = formId.formIndex >= 0 ? formId.formIndex : null;
    this.formType = formId.formType || null;
    this.projectGuid = formId.projectGuid || null;
  }

  getAttachmentPrefix() {
    let attachmentPrefix: string;

    attachmentPrefix = `project/${this.projectGuid}/${
      this.formType
      }`;

    if (!oneToManyFormTypes.includes(this.formType)) {
      attachmentPrefix += `/${this.formIndex}`;
    }

    return attachmentPrefix;
  }
}
