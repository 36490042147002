import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  AbstractControl,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { RelatedTransaction } from '../../../models/film/related-transactions.model';
import { Subscription ,  Subject } from 'rxjs';
import { FormValidationService } from '../../../../form/services/validation.service';
import { Application } from '../../../models/film/film-application.model';


declare var $;
declare type ModalMode = 'Add' | 'Edit';

export interface TransactionChangeEvent {
  action: 'add' | 'edit' | 'delete';
  relatedTransaction: RelatedTransaction;
  index: number;
}

@Component({
  selector: 'fl-related-transactions',
  templateUrl: './related-transactions.component.html',
  styleUrls: ['./related-transactions.component.css']
})
export class RelatedTransactionsComponent implements OnInit {
  @Input() relatedTransactions: RelatedTransaction[];
  @Input() application: Application;
  readonly: boolean;
  relatedTransactionForm: FormGroup;
  transactionRequirementsForm: FormGroup;
  mode: ModalMode;
  selectedTransaction: RelatedTransaction;
  selectedRelatedTransactionIndex: number;
  relatedTransactionChanged: Subject<TransactionChangeEvent>;
  hasParty= false;
  noPartyTransactions= false;
  isManager = false;

  constructor(private fb: FormBuilder,
    private validationService: FormValidationService) {
    
  }

  delete(index: number) {
    // Remove related transaction at particular index
    const removedTransactions = this.relatedTransactions.splice(index, 1);

    // Notify listeners that a transaction was deleted
    this.relatedTransactionChanged.next({
      action: 'delete',
      relatedTransaction: removedTransactions[0],
      index: index
    });

    this.validateRequirements();
  }

  ngOnInit() {
    this.noPartyTransactions = this.application.noPartyTransactions;
    this.relatedTransactionForm = this.fb.group({
      name: ['', Validators.required],
      relationship: ['', Validators.required],
      transactionAmount: ['', Validators.required]
    });

    this.transactionRequirementsForm = this.fb.group({
      hasParty: [null, Validators.pattern('true')],
      noPartyTransactions: [this.application.noPartyTransactions]
    });

    this.validationService.form.setControl(
      'relatedTransactionRequirementsForm',
      this.transactionRequirementsForm
    );

    this.validateRequirements();
  }

  ngOnDestroy() {
    this.validationService.form.removeControl('relatedTransactionRequirementsForm');
  }

  save() {
    // Transfer data from form model to data model
    Object.assign(this.selectedTransaction, this.relatedTransactionForm.value);

    if (this.mode === 'Add') {
      // Add new Related Transaction
      this.relatedTransactions.push(this.selectedTransaction);
    }

    this.validateRequirements();
  }
  select(modalMode: ModalMode, index?: number) {
    const that = this;
    const transaction =
      index !== undefined && index != null
        ? this.relatedTransactions[index]
        : new RelatedTransaction().init();
    this.mode = modalMode;
    this.selectedTransaction = transaction;
    this.selectedRelatedTransactionIndex = index;

    this.validateRequirements();
  }
  validateRequirements() {
    this.hasParty = this.relatedTransactions.length > 0 || this.noPartyTransactions;

    this.transactionRequirementsForm.get('hasParty').setValue(this.hasParty);
    this.transactionRequirementsForm.get('noPartyTransactions').setValue(this.noPartyTransactions);
  }
  noTransactionChecked(value: boolean) {
    this.noPartyTransactions = value;
    this.application.noPartyTransactions = this.noPartyTransactions;
    this.validateRequirements();
  }
}
