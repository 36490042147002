/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./advance-public-search.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./advance-public-search.component";
import * as i3 from "../../../project/services/project-data.service";
import * as i4 from "../../../fastlane-common/services/swal.service";
var styles_AdvancePublicSearchComponent = [i0.styles];
var RenderType_AdvancePublicSearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdvancePublicSearchComponent, data: {} });
export { RenderType_AdvancePublicSearchComponent as RenderType_AdvancePublicSearchComponent };
export function View_AdvancePublicSearchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "pb-2 font-weight-bold text-blue bg-white px-4 pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Advance Notification Results"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "white-bg pt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "table", [["cellspacing", "0"], ["class", "table table-striped table-bordered table-hover"], ["id", "advance-table"], ["width", "100%"]], null, null, null, null, null))], null, null); }
export function View_AdvancePublicSearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fl-advance-public-search", [], null, null, null, View_AdvancePublicSearchComponent_0, RenderType_AdvancePublicSearchComponent)), i1.ɵdid(1, 245760, null, 0, i2.AdvancePublicSearchComponent, [i3.ProjectDataService, i4.SwalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdvancePublicSearchComponentNgFactory = i1.ɵccf("fl-advance-public-search", i2.AdvancePublicSearchComponent, View_AdvancePublicSearchComponent_Host_0, { parentSubject: "parentSubject", parentSearchFilters: "parentSearchFilters" }, {}, []);
export { AdvancePublicSearchComponentNgFactory as AdvancePublicSearchComponentNgFactory };
