
import { Renewal } from "./renewal.model";
import { PrimaryQualification } from "../../../project/models/primary-qualification.model";


export class QualityJobsRenewal   {
    complianceConfirmation : boolean;
    hasCompanyComplied : boolean;
    hasAllReports : boolean;
    hasSalesAndUseTaxIntention : boolean;
    hasHourlyWageIncreased : boolean;
    primaryQualification: PrimaryQualification;

    constructor() {
      if (!this.primaryQualification) {
        this.primaryQualification = new PrimaryQualification();
      }
      if(!this.hasCompanyComplied){
        this.hasCompanyComplied = false;
      }
      if(!this.hasAllReports){
        this.hasAllReports = false;
      }
      if(!this.hasSalesAndUseTaxIntention){
        this.hasSalesAndUseTaxIntention = false;
      }
      if(!this.hasHourlyWageIncreased){
        this.hasHourlyWageIncreased = false;
      }
    }


  }