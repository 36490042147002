import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Programs } from '../../../project/models/programs.model';
import { keys } from 'underscore';

@Component({
  selector: 'shr-program-type-checkbox',
  templateUrl: './program-type-checkbox.component.html',
  styleUrls: ['./program-type-checkbox.component.scss']
})
export class ProgramTypeCheckboxComponent implements OnInit {
  // send the object from the parent always
  @Input()
  selectedPrograms: Programs = new Programs(true, true, true, true, true, true, true);

  @Input()
  programRestrictions: Programs = new Programs();

  @Output()
  selectionChanged: EventEmitter<any> = new EventEmitter();

  programsToDisplay: string[];


  constructor() {
    this.programsToDisplay = keys(this.selectedPrograms);

  }

  ngOnInit() {
    const that = this;
    // Hide programs that are restricted from the user
    this.programsToDisplay = this.programsToDisplay
      .filter(program => !that.programRestrictions[program]);
  }

  emit() {
    this.selectionChanged.emit();
  }
}
