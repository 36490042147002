export class EstimatedInvestments {
  buildingAndMaterials: number;
  laborAndEngineering: number;
  machineryAndEquipment: number;
  restrictedAmount?: number;
  totalInvestments?: number;
  totalInvestmentsIte?: number;

  constructor(calculations?: any) {
    calculations = calculations || {};
    this.buildingAndMaterials = calculations.buildingAndMaterials === 0 ? calculations.buildingAndMaterials :
      calculations.buildingAndMaterials ? calculations.buildingAndMaterials : null;

    this.laborAndEngineering = calculations.laborAndEngineering === 0 ? calculations.laborAndEngineering :
      calculations.laborAndEngineering ? calculations.laborAndEngineering : null;

    this.machineryAndEquipment = calculations.machineryAndEquipment === 0 ? calculations.machineryAndEquipment :
      calculations.machineryAndEquipment ? calculations.machineryAndEquipment : null;

    this.restrictedAmount = calculations.restrictedAmount === 0 ? calculations.restrictedAmount :
      calculations.restrictedAmount ? calculations.restrictedAmount : null;

    this.totalInvestments = calculations.totalInvestments || null;

    this.totalInvestmentsIte = calculations.totalInvestmentsIte || null;
  }
}
