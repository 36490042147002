<div class="mb-4">
  <div class="card border">
    <div class="card-header"> 
      <div class="d-flex justify-content-between">
          <b>CREDIT TYPES</b>
          <button *ngIf="permissions.canUserEdit && form.type === formTypes.audit.abbrev"
            class="btn btn-primary pt-0 pb-0"
            (click)="importFromInitialCert()"
          >
            IMPORT FROM INITIAL CERT
          </button>
        </div>
      </div>
    <div class="card-body px-5 form-group mb-0 no-borders">
      <div class="row d-flex flex-wrap">
        <div class="col-12">
          <div class="row py-1">
            <table
              id="credit-reservation-table"
              class="w-100 table table-striped table-bordered table-hover"
            >
              <thead>
                <tr>
                  <th>
                    Base Investment Credits (applicable to entire eligible
                    spend)
                  </th>
                  <th>Expenditure Amount</th>
                  <th>Rate</th>
                  <th>Credit Amount</th>
                  <th>Apply</th>
                  <th
                    *ngIf="
                      [
                        formTypes.initialCert.abbrev,
                        formTypes.audit.abbrev
                      ].includes(form.type)
                    "
                  >
                    Reserve
                  </th>
                  <th *ngIf="form.type === formTypes.audit.abbrev">Approve</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  *ngFor="let credit of baseCredits"
                  [ngClass]="{
                    'text-muted': !credit.isEligible || !permissions.canUserEdit
                  }"
                >
                  <td>{{ getCreditTypeName(credit.creditType) }}</td>
                  <td>
                    {{
                      credit.expenditureAmount
                        | currency: 'USD':'symbol':'1.2-2'
                    }}
                  </td>
                  <td>{{ credit.rate | percent }}</td>
                  <td class="font-weight-bold">
                    {{
                      credit.creditTypeAmount | currency: 'USD':'symbol':'1.2-2'
                    }}
                  </td>
                  <td>
                    <input
                      *ngIf="credit.isEligible"
                      type="checkbox"
                      (change)="
                        credit.isApplied = !credit.isApplied;
                        creditTypeChecked(credit)
                      "
                      [checked]="credit.isApplied"
                      [disabled]="
                        form.type === formTypes.initialCert.abbrev ||
                        form.type === formTypes.audit.abbrev ||
                        !permissions.canUserEdit ||
                        credit.creditType === 'base'
                      "
                      class="m-2"
                    />
                    <span
                      *ngIf="!credit.isEligible"
                      title="Credit is not Eligible"
                    >
                      <i
                        class="fal fa-exclamation-circle fa-2x"
                        style="color:orangered"
                      ></i>
                    </span>
                  </td>
                  <td
                    *ngIf="
                      [
                        formTypes.initialCert.abbrev,
                        formTypes.audit.abbrev
                      ].includes(form.type)
                    "
                  >
                    <input
                      *ngIf="credit.isEligible"
                      type="checkbox"
                      (change)="
                        credit.isReserved = !credit.isReserved;
                        creditTypeChecked(credit)
                      "
                      [checked]="credit.isReserved"
                      [disabled]="
                        form.type === formTypes.audit.abbrev ||
                        !permissions.canUserEdit ||
                        credit.creditType === 'base'
                      "
                      class="m-2"
                    />
                    <span
                      *ngIf="!credit.isEligible"
                      title="Credit is not Eligible"
                      ><i
                        class="fal fa-exclamation-circle fa-2x"
                        style="color:orangered"
                      ></i>
                    </span>
                  </td>
                  <td *ngIf="form.type === formTypes.audit.abbrev">
                    <input
                      type="checkbox"
                      (change)="
                        credit.isIssued = !credit.isIssued;
                        creditTypeChecked(credit)
                      "
                      [checked]="credit.isIssued"
                      [disabled]="!permissions.canUserEdit"
                      class="m-2"
                    />
                  </td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                  <td>{{ qualifiedRate.rate | percent }}</td>
                  <td class="font-weight-bold">{{ qualifiedRate.amount | currency: 'USD':'symbol':'1.2-2' }}</td>
                  <td *ngIf="form.type === formTypes.application.abbrev || form.type === formTypes.asa.abbrev " colspan="1"></td>
                  <td *ngIf="form.type === formTypes.initialCert.abbrev" colspan="2"></td>
                  <td *ngIf="form.type === formTypes.audit.abbrev" colspan="3"></td>
                </tr>
                <tr *ngIf="baseCredits.length === 0" class="text-center">
                  <td colspan="6">No Credit Reservations records available</td>
                </tr>
                <tr>
                  <th class="text-center">
                    Additional Credits (applicable to a particular spend)
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th
                    *ngIf="
                      [
                        formTypes.initialCert.abbrev,
                        formTypes.audit.abbrev
                      ].includes(form.type)
                    "
                  ></th>
                  <th *ngIf="form.type === formTypes.audit.abbrev"></th>
                </tr>
                <tr
                  *ngFor="let additionalCredit of additionalCredits"
                  [ngClass]="{
                    'text-muted':
                      !additionalCredit.isEligible || !permissions.canUserEdit
                  }"
                >
                  <td>{{ getCreditTypeName(additionalCredit.creditType) }}</td>
                  <td>
                    {{
                      additionalCredit.expenditureAmount
                        | currency: 'USD':'symbol':'1.2-2'
                    }}
                  </td>
                  <td>{{ additionalCredit.rate | percent }}</td>
                  <td class="font-weight-bold">
                    {{
                      additionalCredit.creditTypeAmount
                        | currency: 'USD':'symbol':'1.2-2'
                    }}
                  </td>
                  <td>
                    <input
                      *ngIf="additionalCredit.isEligible"
                      type="checkbox"
                      (change)="
                        additionalCredit.isApplied = !additionalCredit.isApplied;
                        creditTypeChecked(additionalCredit)
                      "
                      [checked]="additionalCredit.isApplied"
                      [disabled]="
                        form.type === formTypes.initialCert.abbrev ||
                        form.type === formTypes.audit.abbrev ||
                        !permissions.canUserEdit
                      "
                      class="m-2"
                    />
                    <span
                      *ngIf="!additionalCredit.isEligible"
                      title="Credit is not Eligible"
                      ><i
                        class="fal fa-exclamation-circle fa-2x"
                        style="color:orangered"
                      ></i>
                    </span>
                  </td>
                  <td
                    *ngIf="
                      [
                        formTypes.initialCert.abbrev,
                        formTypes.audit.abbrev
                      ].includes(form.type)
                    "
                  >
                    <input
                      *ngIf="additionalCredit.isEligible"
                      type="checkbox"
                      (change)="
                        additionalCredit.isReserved = !additionalCredit.isReserved;
                        creditTypeChecked(additionalCredit)
                      "
                      [checked]="additionalCredit.isReserved"
                      [disabled]="
                        form.type === formTypes.audit.abbrev ||
                        !permissions.canUserEdit
                      "
                      class="m-2"
                    />
                    <span
                      *ngIf="!additionalCredit.isEligible"
                      title="Credit is not Eligible"
                      ><i
                        class="fal fa-exclamation-circle fa-2x"
                        style="color:orangered"
                      ></i>
                    </span>
                  </td>
                  <td *ngIf="form.type === formTypes.audit.abbrev">
                    <input
                      type="checkbox"
                      (change)="
                        additionalCredit.isIssued = !additionalCredit.isIssued;
                        creditTypeChecked(additionalCredit)
                      "
                      [checked]="additionalCredit.isIssued"
                      [disabled]="!permissions.canUserEdit"
                      class="m-2"
                    />
                  </td>
                </tr>
                <tr *ngIf="additionalCredits.length === 0" class="text-center">
                  <td colspan="6">No Credit Reservations records available</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="row py-1" [ngSwitch]="true">
            <ng-template
              [ngSwitchCase]="
                (form.type === formTypes.application.abbrev ||
                form.type === formTypes.asa.abbrev)"
            >
              <div class="col-12 col-lg-8">
                <div class="row font-weight-bold">
                  <label class="col-12 col-md-4">Total Credits Selected:</label>
                  <label class="col-12 col-md-4"></label>
                  <label class="col-12 col-md-4">{{
                    totalAppliedCreditTypes | currency: 'USD':'symbol':'1.2-2'
                  }}</label>
                </div>
                <div class="row font-weight-bold">
                  <label class="col-12 col-md-4">Total Credits Maximum:</label>
                  <label class="col-12 col-md-4">{{
                    baseInvestmentCap | percent: '1.0-2'
                  }}</label>
                  <label class="col-12 col-md-4">{{
                    baseCreditCap | currency: 'USD':'symbol':'1.2-2'
                  }}</label>
                </div>
                <div *ngIf="isScriptedEpisodic" class="row font-weight-bold">
                  <label class="col-12 col-md-4"
                    >Total Credits Maximum Per Season:</label
                  >
                  <label class="col-12 col-md-4"></label>
                  <label class="col-12 col-md-4">{{
                    seasonCap | currency: 'USD':'symbol':'1.2-2'
                  }}</label>
                </div>
                <div class="row font-weight-bold">
                  <label class="col-12 col-md-4">Total Credits Allowed:</label>
                  <label class="col-12 col-md-4"></label>
                  <label class="col-12 col-md-4">{{
                    totalCreditsAllowed | currency: 'USD':'symbol':'1.2-2'
                  }}</label>
                </div>
              </div>
            </ng-template>
            <ng-template
              [ngSwitchCase]="form.type === formTypes.initialCert.abbrev"
            >
              <div class="col-12 col-lg-8">
                <div class="row font-weight-bold">
                  <label class="col-12 col-md-4">Total Credits Applied:</label>
                  <label class="col-12 col-md-4"></label>
                  <label class="col-12 col-md-4">{{
                    initialCert.totalCreditsApplied
                      | currency: 'USD':'symbol':'1.2-2'
                  }}</label>
                </div>
                <div class="row font-weight-bold">
                  <label class="col-12 col-md-4"
                    >Total Credits Reserved (unaltered):</label
                  >
                  <label class="col-12 col-md-4"></label>
                  <label class="col-12 col-md-4">{{
                    totalReservedCreditTypes | currency: 'USD':'symbol':'1.2-2'
                  }}</label>
                </div>
                <div class="row font-weight-bold">
                  <label class="col-12 col-md-4">Total Credits Maximum:</label>
                  <label class="col-12 col-md-4">{{
                    baseInvestmentCap | percent: '1.0-2'
                  }}</label>
                  <label class="col-12 col-md-4">{{
                    maximumCredits | currency: 'USD':'symbol':'1.2-2'
                  }}</label>
                </div>
                <div *ngIf="isScriptedEpisodic" class="row font-weight-bold">
                  <label class="col-12 col-md-4"
                    >Total Credits Maximum Per Season:</label
                  >
                  <label class="col-12 col-md-4"></label>
                  <label class="col-12 col-md-4">{{
                    seasonCap | currency: 'USD':'symbol':'1.2-2'
                  }}</label>
                </div>
                <div class="row font-weight-bold">
                  <label class="col-12 col-md-4">Total Reserved Amount:</label>
                  <label class="col-12 col-md-4"></label>
                  <label class="col-12 col-md-4">{{
                    totalCreditsAllowed | currency: 'USD':'symbol':'1.2-2'
                  }}</label>
                </div>
                <!-- RESERVATION TOTAL EXPLANATION -->
                <div class="form-group mt-4">
                  <label
                    class="font-weight-bold"
                    for="reservationTotalExplanation"
                    >Reservation Total Explanation<span
                      class="pl-2"
                      data-toggle="popover"
                      data-placement="top"
                      data-html="true"
                      data-title="Reasons for providing an explanation"
                      [attr.data-content]="reservationPopOver.innerHTML"
                      data-trigger="click focus"
                      ><i class="fas fa-info-circle"></i></span
                  ></label>
                  <textarea [formControl]="creditFormGroup.get('reservationTotalExplanation')"
                    class="form-control"
                    id="reservationTotalExplanation"
                    rows="3"
                  ></textarea>
                </div>
                <!-- RESERVATION TOTAL EXPLANATION POPOVER TEMPLATE -->
                <div
                  #reservationPopOver
                  id="reservationTotalPopover"
                  class="d-none"
                >
                  Total Credits Reserved is the lesser of reservation based on
                  application estimates or reservation based on 40% of the base
                  investment.<br/>Total Credits Reserved does not include
                  increased or additional tax credits applied for but currently
                  unsubstantiated.
                </div>
              </div>
            </ng-template>
            <ng-template [ngSwitchCase]="form.type === formTypes.audit.abbrev && credits.length > 0">
              <div class="col-12 col-lg-8">
                <div class="row font-weight-bold">
                  <label class="col-12 col-md-4"
                    >Total Credits Approved (unaltered):</label
                  >
                  <label class="col-12 col-md-4"></label>
                  <label class="col-12 col-md-4">{{
                    totalIssuedCreditTypes | currency: 'USD':'symbol':'1.2-2'
                  }}</label>
                </div>
                <div class="row font-weight-bold">
                  <label class="col-12 col-md-4">Total Credits Maximum:</label>
                  <label class="col-12 col-md-4">{{
                    baseInvestmentCap | percent: '1.0-2'
                  }}</label>
                  <label class="col-12 col-md-4">{{
                    maximumCredits | currency: 'USD':'symbol':'1.2-2'
                  }}</label>
                </div>
                <div class="row font-weight-bold">
                  <label class="col-12 col-md-4">Total Credits Reserved:</label>
                  <label class="col-12 col-md-4"></label>
                  <label class="col-12 col-md-4">{{
                    audit.totalCreditsReserved
                      | currency: 'USD':'symbol':'1.2-2'
                  }}</label>
                </div>
                <div class="row font-weight-bold">
                  <label class="col-12 col-md-4">Total Credits Granted:</label>
                  <label class="col-12 col-md-4"></label>
                  <label class="col-12 col-md-4">{{
                    totalCreditsAllowed | currency: 'USD':'symbol':'1.2-2'
                  }}</label>
                </div>
                <div class="row font-weight-bold">
                  <label class="col-12 col-md-4"
                    >Unused Reserved Credits:</label
                  >
                  <label class="col-12 col-md-4"></label>
                  <label class="col-12 col-md-4">{{
                    unUsedReservedCredits | currency: 'USD':'symbol':'1.2-2'
                  }}</label>
                </div>
                <div class="row font-weight-bold">
                  <label class="col-12 col-md-4">Deferred Credits:</label>
                  <label class="col-12 col-md-4"></label>
                  <label class="col-12 col-md-4">{{
                    deferredCredits | currency: 'USD':'symbol':'1.2-2'
                  }}</label>
                </div>
                <div class="row font-weight-bold">
                  <label class="col-12 col-md-4"
                    >Deferred Credits Granted:</label
                  >
                  <label class="col-12 col-md-4"></label>
                  <label class="col-12 col-md-4">{{
                    deferredFundingAmount | currency: 'USD':'symbol':'1.2-2'
                  }}</label>
                </div>
                <div class="row font-weight-bold">
                  <label class="col-12 col-md-4"
                    >Final Total Credits Granted (includes deferred credits
                    granted):</label
                  >
                  <label class="col-12 col-md-4"></label>
                  <label class="col-12 col-md-4">{{
                    totalCreditsAllowed + deferredFundingAmount
                      | currency: 'USD':'symbol':'1.2-2'
                  }}</label>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="credits.length > 0" class="mb-4">
  <div class="card border">
    <div *ngIf="form.type === formTypes.initialCert.abbrev" class="card-header"><b>CREDIT RESERVATION</b></div>
    <div *ngIf="form.type === formTypes.audit.abbrev" class="card-header"><b>CREDIT ISSUANCE</b></div>
    <div class="card-body px-5 form-group mb-0 no-borders">
      <div class="row d-flex flex-wrap">
        <div class="col-12">
          <div class="row py-1">
            <div class="col-12 col-md-6">
              <div class="row d-flex flex-wrap align-content-center">
                <label class="col-3">Credit Type:</label>
                <select
                  class="form-control col-6"
                  [ngModel]="
                    selectedCreditType ? selectedCreditType.creditType : null
                  "
                  (change)="creditTypeChanged($event.target.value)"
                >
                  <option
                    *ngFor="let ct of dropdownOptions"
                    [value]="ct.abbrev"
                    >{{ ct.name }}</option
                  >
                </select>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <button class="btn btn-primary" [disabled]="!permissions.canUserEdit" (click)="add()">
                ADD
              </button>
            </div>
          </div>
          <div class="row py-1">
            <table
              id="credit-reservation-table"
              class="w-100 table table-striped table-bordered table-hover"
            >
              <thead>
                <tr>
                  <th>Fiscal Year</th>
                  <th>Cap SubCategory</th>
                  <th>Amount</th>
                  <th *ngIf="form.type === formTypes.audit.abbrev">Deferred</th>
                  <th>Season</th>
                  <th>Note</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody *ngIf="dropdownOptions.length > 0">
                <tr
                  *ngFor="let credit of currentCreditReservations"
                  [ngClass]="{ 'text-muted': !permissions.canUserEdit }"
                >
                  <td>
                    <input
                      type="number"
                      class="form-control"
                      min="1900"
                      max="2099"
                      step="1"
                      maxlength="4"
                      required
                      pattern="[0-9]{4}"
                      [disabled]="!permissions.canUserEdit"
                      [(ngModel)]="credit.fiscalYear"
                    />
                  </td>
                  <td>
                    <select
                      class="form-control"
                      [disabled]="!permissions.canUserEdit"
                      [(ngModel)]="credit.subCategory"
                    >
                      <option
                        *ngFor="let ct of allSubCategories"
                        [value]="ct.abbrev"
                        >{{ ct.name }}</option
                      >
                    </select>
                  </td>
                  <td>
                    <input
                      class="form-control"
                      required
                      [disabled]="!permissions.canUserEdit"
                      (ngModelChange)="
                        credit.fundingAmount = $event;
                        fundingAmountChanged(credit.creditType)
                      "
                      placeholder="$"
                      pattern="\$? ?(\d{0,3},?)+([.](\d)+)?"
                      currencyMask
                      [options]="{ align: 'left', precision: 2 }"
                      [ngModel]="credit.fundingAmount"
                    />
                  </td>
                  <td *ngIf="form.type === formTypes.audit.abbrev">
                    <input
                      type="checkbox"
                      (change)="credit.isDeferred = !credit.isDeferred"
                      [checked]="credit.isDeferred"
                      [disabled]="!permissions.canUserEdit"
                      class="m-2"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      required
                      [disabled]="true"
                      [(ngModel)]="credit.season"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      [disabled]="!permissions.canUserEdit"
                      required
                      [(ngModel)]="credit.note"
                    />
                  </td>
                  <td>
                    <button
                      [disabled]="!permissions.canUserEdit || currentCreditReservations.length === 1"
                      class="btn btn-link"
                      (click)="delete(credit)"
                    ><i class="fa fa-trash-o fa-2x pr-1"></i></button>
                  </td>
                </tr>

                <tr *ngIf="credits.length === 0" class="text-center">
                  <td colspan="5">No Credit Reservations records available</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row py-1" [ngSwitch]="true">
            <ng-template
              [ngSwitchCase]="
                selectedCreditType &&
                selectedCreditType.creditType &&
                [formTypes.initialCert.abbrev, formTypes.audit.abbrev].includes(
                  form.type
                )
              "
            >
              <div class="col-12 col-lg-8">
                <div
                  class="row font-weight-bold"
                  *ngFor="let ddo of dropdownOptions"
                >
                  <label class="col-12 col-md-8"
                    >Total Credits
                    {{
                      form.type === formTypes.initialCert.abbrev
                        ? 'Reserved'
                        : 'Issued'
                    }}
                    for {{ ddo.name }}:</label
                  >
                  <label class="col-12 col-md-4">{{
                    updateTotalFunding(ddo.abbrev)
                      | currency: 'USD':'symbol':'1.2-2'
                  }}</label>
                </div>
                <div class="row font-weight-bold">
                  <label class="col-12 col-md-8">Total Amount:</label>
                  <label class="col-12 col-md-4">{{
                    totalFundingAmount | currency: 'USD':'symbol':'1.2-2'
                  }}</label>
                </div>
              </div>
               <!-- Validation Messages -->
               <div class="flex-column d-flex">
                <ng-container *ngFor="let warning of validationWarnings">
                  <div class="row font-weight-bold">
                    <div class="col-12">
                     <span  title="{{warning.message}}">
                      <i
                        class="fas fa-exclamation-triangle animated infinite rubberBand"
                        style="color:orange"
                      ></i>
                    </span>
                     <label class="pl-2">{{warning.message}}</label>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
