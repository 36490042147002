import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fl-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss']
})
export class DocsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
