import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'app/security/services/authentication.service';
import { map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { CreditSubCategoryCap } from '../lookup-editor/lookup-editor.component';
import { AuditingCompany } from 'app/fastlane-common/models/lookup/auditing-company.model';

@Injectable()
export class LookupDataService {
  //#region URLs
  private filmCreditCapCreateUrl =
    environment.apiUrl + '/lookup/saveSubCategoryCaps';
  private filmCreditCapQueryUrl =
    environment.apiUrl + '/lookup/fiscalYearSubcategoryCaps?year=:year';
  private filmMultiCreditCapQueryUrl =
    environment.apiUrl + '/lookup/fiscalYearSubcategoryCaps?:yearsQuery';
  private auditCompanyQueryUrl =
    environment.apiUrl + '/lookup/auditingCompanyById?id=:id';
  //#endregion
  constructor(
    private authService: AuthenticationService,
    private http: HttpClient
  ) {}

  saveNewSubCategoryCap(data: CreditSubCategoryCap[]) {
    return this.http
      .post(
        this.filmCreditCapCreateUrl,
        data,
        this.authService.getAuthOptionsForHttpClient()
      )
      .pipe(
        map(res => res),
        catchError(err => of(err))
      );
  }

  getAuditCompanyById(
    id: string
  ): Observable<AuditingCompany> {
    return this.http
      .get(
        this.auditCompanyQueryUrl.replace(':id', `${id}`),
        this.authService.getAuthOptionsForHttpClient('application/gzip')
      )
      .pipe(
        map(res => res as AuditingCompany),
        catchError(err => of(err))
      );
  }

  getSubCategoryCapsForYear(
    fiscalYear: number
  ): Observable<CreditSubCategoryCap[]> {
    return this.http
      .get(
        this.filmCreditCapQueryUrl.replace(':year', `${fiscalYear}`),
        this.authService.getAuthOptionsForHttpClient('application/gzip')
      )
      .pipe(
        map(res => res as CreditSubCategoryCap[]),
        catchError(err => of(err))
      );
  }

  getSubCategoryCapsForYears(
    fiscalYears: number[]
  ): Observable<CreditSubCategoryCap[]> {
    const yearsQueryString: string[] = fiscalYears.map(y => `year=${y}`);
    return this.http
      .get(
        this.filmMultiCreditCapQueryUrl.replace(
          ':yearsQuery',
          `${yearsQueryString.join('&')}`
        ),
        this.authService.getAuthOptionsForHttpClient('application/gzip')
      )
      .pipe(
        map(res => res as CreditSubCategoryCap[]),
        catchError(err => of(err))
      );
  }
}
