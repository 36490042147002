import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Programs } from '../../project/models/programs.model';
import { parishes } from '../../shared/shared.constants';
import {
  publicReportTypes,
  publicBoardApprovedReports
} from '../../project/project.constants';
import { pairs, omit } from 'underscore';
import { ParishMultiselectComponent } from '../../shared/components/parish-multiselect/parish-multiselect.component';
import { ProjectStatusMultiselectComponent } from '../../shared/components/project-status-multiselect/project-status-multiselect.component';
import { RegionMultiselectComponent } from '../../shared/components/region-multiselect/region-multiselect.component';
import { NgMultiSelectOption } from '../../shared/interfaces/ng-multi-select-option.interface';
declare var $;

@Component({
  selector: 'fl-public-search',
  templateUrl: './public-search.component.html',
  styleUrls: ['./public-search.component.scss']
})
export class PublicSearchComponent implements AfterViewInit, OnInit, OnDestroy {
  publicReportTypesLocal = publicReportTypes;
  publicBoardApprovedReportsLocal = publicBoardApprovedReports;
  reportTypes = pairs(publicReportTypes).map(pair => {
    return { name: pair[1].reportName, abbrev: pair[1].abbrev };
  });
  publicBoardApprovedReports = pairs(publicBoardApprovedReports).map(pair => {
    return { name: pair[1].reportName, abbrev: pair[1].abbrev };
  });
  selectedBoardReportType = publicBoardApprovedReports.ez.abbrev;
  parishes = parishes;
  programs = new Programs(true, true, true, true, false, false);

  filtersSubject: Subject<any> = new Subject();

  searchFilters = {
    reportType: 'advance',
    incentivePrograms: [],
    projectStatuses: [],
    parishes: [],
    regions: [],
    advanceReceivedDateRange: [],
    applicationReceivedDateRange: [],
    boardApprovalDateRange: [],
    contractEffectiveDateRange: [],
    contractExpirationDateRange: [],
    renewalEffectiveDateRange: [],
    renewalExpirationDateRange: [],
    renewalReceivedDateRange: []
  };

  projectStatuses: {
    projectInReview: boolean;
    projectWithdrawn: boolean;
    renewalContractComplete: boolean;
    contractComplete: boolean;
    projectPending: boolean;
    projectCertified: boolean;
    projectInitiallyCertified: boolean;
    contractPending: boolean;
    renewalContractPending: boolean;
    contractActive: boolean;
    renewalContractActive: boolean;
    contractCancelled: boolean;
    renewalContractCancelled: boolean;
  } = {
    projectInReview: false,
    projectWithdrawn: false,
    renewalContractComplete: false,
    contractComplete: false,
    projectPending: false,
    projectCertified: false,
    projectInitiallyCertified: false,
    contractPending: false,
    renewalContractPending: false,
    contractActive: false,
    renewalContractActive: false,
    contractCancelled: false,
    renewalContractCancelled: false
  };

  @ViewChild(ParishMultiselectComponent, { static: true })
  private parishMultiSelectComponent: ParishMultiselectComponent;

  @ViewChild(RegionMultiselectComponent, { static: true })
  private regionMultiSelectComponent: RegionMultiselectComponent;

  @ViewChild(ProjectStatusMultiselectComponent, { static: true })
  private projectStatusMultiSelectComponent: ProjectStatusMultiselectComponent;

  clearArray(arrayToClear: any[]) {
    if (arrayToClear && arrayToClear.length > 0) {
      arrayToClear.splice(0);
    }
  }

  clearFilters() {
    this.clearArray(this.searchFilters.projectStatuses);
    this.clearArray(this.searchFilters.parishes);
    this.clearArray(this.searchFilters.regions);
    this.searchFilters.advanceReceivedDateRange = [];
    this.searchFilters.applicationReceivedDateRange = [];
    this.searchFilters.boardApprovalDateRange = [];
    this.searchFilters.contractEffectiveDateRange = [];
    this.searchFilters.contractExpirationDateRange = [];
    this.searchFilters.renewalEffectiveDateRange = [];
    this.searchFilters.renewalExpirationDateRange = [];
    this.searchFilters.renewalReceivedDateRange = [];

    // This is the programs for the advance notifications report only, not to be confused with one above.
    this.programs = new Programs(true, true, true, true, false, false);

    // Parse Parish, Region, Project Status multi-select components
    this.parishMultiSelectComponent.parseSelectedParishes(null);
    this.regionMultiSelectComponent.parseSelectedRegions(null);
    this.projectStatusMultiSelectComponent.parseSelectedProjectStatuses(null);
  }

  filterDataTableByParish(selectedParishes: NgMultiSelectOption[]) {
    this.searchFilters.parishes.splice(0);
    this.searchFilters.parishes.push(...selectedParishes.map(ps => ps.text));
  }

  filterDataTableByProjectStatus(projectStatuses: NgMultiSelectOption[]) {
    this.searchFilters.projectStatuses.splice(0);
    this.searchFilters.projectStatuses.push(...projectStatuses.map(ps => ps.text));
  }

  filterDataTableByRegion(regions: NgMultiSelectOption[]) {
    this.searchFilters.regions.splice(0);
    this.searchFilters.regions.push(...regions.map(ps => ps.text));
  }

  ngAfterViewInit() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  ngOnDestroy() {
    // needed if child gets re-created (eg on some model changes)
    // note that subsequent subscriptions on the same subject will fail
    // so the parent has to re-create parentSubject on changes
  }

  ngOnInit() {}

  sendFilters() {
    // UPDATE SEARCH FILTERS BEFORE SENDING THEM OFF TO SUBSCRIBERS

    // Change incentive programs to filter by based on which report type is selected
    const programsToFilterBy = this.searchFilters.reportType === publicReportTypes.advance.abbrev
      ? this.programs.getSelectedPrograms()
      : [this.selectedBoardReportType];
    this.searchFilters.incentivePrograms.splice(0);
    this.searchFilters.incentivePrograms.push(...programsToFilterBy);

    // Omit certain filters based on which report type is selected
    const filters = this.searchFilters.reportType === publicReportTypes.advance.abbrev
    ? omit(this.searchFilters, [
      'applicationReceivedDateRange',
      'boardApprovalDateRange',
      'contractEffectiveDateRange',
      'contractExpirationDateRange',
      'renewalEffectiveDateRange',
      'renewalExpirationDateRange',
      'renewalReceivedDateRange'])
    : omit(this.searchFilters, 'advanceReceivedDateRange');

    // Publish resulting filters
    this.filtersSubject.next(filters);
  }
}
