<div class="mb-4" [formGroup]="eligibilityQuestionsFormGroup">
  <div class="card">
    <div class="card-header"><b>PROGRAM ELIGIBILITY QUESTIONS</b></div>
    <div class="card-body px-5">
      <div class="col-xl-12">
        <div class="d-flex justify-content-end">
          <p class="mr-auto">
            1. Are any project(s) claimed primarily designed and developed for
            institutional, private or internal purposes?
          </p>
          <div class=" has-error">
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="true"
                formControlName="isProjectForInstitutionalPrivateInternal"
                name="isProjectForInstitutionalPrivateInternal"
                [readOnly]="readOnly"
                id="inlineCheckbox1"
              />Yes
            </label>
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="false"
                formControlName="isProjectForInstitutionalPrivateInternal"
                name="isProjectForInstitutionalPrivateInternal"
                [readOnly]="readOnly"
                id="inlineCheckbox2"
              />No
            </label>
          </div>
        </div>
        <div
          *ngIf="
            eligibilityQuestionsFormGroup.controls[
              'isProjectForInstitutionalPrivateInternal'
            ].value
          "
        >
          <p class="col-md-12 text-danger bold-font">
            Please contact OEID for eligibility. Projects that are primarily
            designed and developed for institutional, private or internal
            purposes are ineligible.
          </p>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="d-flex justify-content-end">
          <p class="mr-auto">
            2. Is this project being developed for a state government agency?
          </p>
          <div class=" has-error">
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="true"
                formControlName="isProjectForStateAgency"
                name="isProjectForStateAgency"
                [readOnly]="readOnly"
                id="inlineCheckbox1"
              />Yes
            </label>
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="false"
                formControlName="isProjectForStateAgency"
                name="isProjectForStateAgency"
                [readOnly]="readOnly"
                id="inlineCheckbox2"
              />No
            </label>
          </div>
        </div>
        <div
          *ngIf="
            eligibilityQuestionsFormGroup.controls['isProjectForStateAgency']
              .value
          "
        >
          <p class="col-md-12 text-danger bold-font">
            Please contact OEID for eligibility. Projects that are being
            developed for a state government agency are ineligible.
          </p>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="d-flex justify-content-end">
          <p class="mr-auto">
            3. Will this project be financed through funding or grants from a
            Louisiana state agency or university?
          </p>
          <div class=" has-error">
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="true"
                formControlName="isProjectFinancedByFundingGrantsInState"
                name="isProjectFinancedByFundingGrantsInState"
                [readOnly]="readOnly"
                id="inlineCheckbox1"
              />Yes
            </label>
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="false"
                formControlName="isProjectFinancedByFundingGrantsInState"
                name="isProjectFinancedByFundingGrantsInState"
                [readOnly]="readOnly"
                id="inlineCheckbox2"
              />No
            </label>
          </div>
        </div>
        <div
          *ngIf="
            eligibilityQuestionsFormGroup.controls[
              'isProjectFinancedByFundingGrantsInState'
            ].value
          "
        >
          <p class="col-md-12 text-danger bold-font">
            Please contact OEID for eligibility. Projects that are finance
            through funding or grants from a Louisiana state agency or
            university are ineligible.
          </p>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="d-flex justify-content-end">
          <p class="mr-auto">
            4. Does any work on this specific project involve configuring an
            “off the shelf” commercially available product or platform for a
            specific purpose?
          </p>
          <div class=" has-error">
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="true"
                formControlName="isProjectCommericallyAvailable"
                name="isProjectCommericallyAvailable"
                [readOnly]="readOnly"
                id="inlineCheckbox1"
              />Yes
            </label>
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="false"
                formControlName="isProjectCommericallyAvailable"
                name="isProjectCommericallyAvailable"
                [readOnly]="readOnly"
                id="inlineCheckbox2"
              />No
            </label>
          </div>
        </div>
        <div
          *ngIf="
            eligibilityQuestionsFormGroup.controls[
              'isProjectCommericallyAvailable'
            ].value
          "
        >
          <p class="col-md-12 text-danger bold-font">
            Please contact OEID for eligibility.
          </p>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="d-flex justify-content-end">
          <p class="mr-auto">
            5. Is any work on this project sub-contracted to another company?
          </p>
          <div class=" has-error">
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="true"
                formControlName="isProjectSubContracted"
                name="isProjectSubContracted"
                [readOnly]="readOnly"
                id="inlineCheckbox1"
              />Yes
            </label>
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="false"
                formControlName="isProjectSubContracted"
                name="isProjectSubContracted"
                [readOnly]="readOnly"
                id="inlineCheckbox2"
              />No
            </label>
          </div>
        </div>
        <div *ngIf="
            eligibilityQuestionsFormGroup.controls['isProjectSubContracted']
              .value
          ">
          <p class="col-md-12 text-danger bold-font">
            Note that since state law and program rules establish parameters governing sub-contracted work (i.e.
            overhead to the sub-contracted company is not eligible, etc.), you MAY WANT TO CONTACT OEID immediately
            if the percentage of work for the sub-contractors is greater than 50% of the total expenditures.
          </p>
          <div class="col-md-12">
            <label class="form-check-inline" for="isSubContractorPercentage">
              5a. Please enter the percentage of work that will be completed by sub-contractors as a percentage between 1 and 100: &nbsp;
              <input type="number"
                     min="1"
                     max="100"
                     step="1"
                     oninput="(validity.valid) || (value = '');"
                     formControlName="isSubContractorPercentage"
                     id="isSubContractorPercentage"
                     name="isSubContractorPercentage" /> &nbsp; %
            </label>
          </div>

        </div>
      </div>
      <div class="col-xl-12">
        <div class="d-flex justify-content-end">
          <p class="mr-auto">
            6. Will all expenditures occur in Louisiana?
          </p>
          <div class=" has-error">
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="true"
                formControlName="isExpenditureInState"
                name="isExpenditureInState"
                [readOnly]="readOnly"
                id="inlineCheckbox1"
              />Yes
            </label>
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="false"
                formControlName="isExpenditureInState"
                name="isExpenditureInState"
                [readOnly]="readOnly"
                id="inlineCheckbox2"
              />No
            </label>
          </div>
        </div>
        <div
          *ngIf="
            eligibilityQuestionsFormGroup.controls['isExpenditureInState']
              .value === false
          "
        >
          <p class="col-md-12 text-danger bold-font">
            Only eligible expenditures that occur in Louisiana are eligible.
          </p>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="d-flex justify-content-end">
          <p class="mr-auto">
            7. Will all equipment be purchased through businesses physically
            located in Louisiana?
          </p>
          <div class=" has-error">
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="true"
                formControlName="isEquipmentPurchaseInState"
                name="isEquipmentPurchaseInState"
                [readOnly]="readOnly"
                id="inlineCheckbox1"
              />Yes
            </label>
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="false"
                formControlName="isEquipmentPurchaseInState"
                name="isEquipmentPurchaseInState"
                [readOnly]="readOnly"
                id="inlineCheckbox2"
              />No
            </label>
          </div>
        </div>
        <div
          *ngIf="
            eligibilityQuestionsFormGroup.controls['isEquipmentPurchaseInState']
              .value === false
          "
        >
          <p class="col-md-12 text-danger bold-font">
            Only expenditures for equipment purchased at physical Louisiana
            businesses may be considered eligible for the program.
          </p>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="d-flex justify-content-end">
          <p class="mr-auto">
            8. Will all employees performing services, provide this work at a
            physical location in Louisiana?
          </p>
          <div class=" has-error">
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="true"
                formControlName="isEmployeesPerformingServicesInState"
                name="isEmployeesPerformingServicesInState"
                [readOnly]="readOnly"
                id="inlineCheckbox1"
              />Yes
            </label>
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="false"
                formControlName="isEmployeesPerformingServicesInState"
                name="isEmployeesPerformingServicesInState"
                [readOnly]="readOnly"
                id="inlineCheckbox2"
              />No
            </label>
          </div>
        </div>
        <div
          *ngIf="
            eligibilityQuestionsFormGroup.controls[
              'isEmployeesPerformingServicesInState'
            ].value === false
          "
        >
          <p class="col-md-12 text-danger bold-font">
            Only eligible labor that occurs in Louisiana are eligible for the
            program.
          </p>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="d-flex justify-content-end">
          <p class="mr-auto">
            9. Will all of the work being claimed for digital media credits be
            directly related to the development of the project?
          </p>
          <div class=" has-error">
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="true"
                formControlName="isDigitalMediaRelatedToDevelopmentOfProject"
                name="isDigitalMediaRelatedToDevelopmentOfProject"
                [readOnly]="readOnly"
                id="inlineCheckbox1"
              />Yes
            </label>
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="false"
                formControlName="isDigitalMediaRelatedToDevelopmentOfProject"
                name="isDigitalMediaRelatedToDevelopmentOfProject"
                [readOnly]="readOnly"
                id="inlineCheckbox2"
              />No
            </label>
          </div>
        </div>
        <div
          *ngIf="
            eligibilityQuestionsFormGroup.controls[
              'isDigitalMediaRelatedToDevelopmentOfProject'
            ].value === false
          "
        >
          <p class="col-md-12 text-danger bold-font">
            Only work that is directly related to the development of the project
            is eligible for the program.
          </p>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="d-flex justify-content-end">
          <p class="mr-auto w-40">
            10. Does the company or any sub-contractors or investors currently
            participate in any other Louisiana Economic Development programs?
            This includes, but is not limited to, the following:
          </p>
          <div class="has-error">
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="true"
                formControlName="isCompanySubContractorInvestorWithLEDPrograms"
                name="isCompanySubContractorInvestorWithLEDPrograms"
                [readOnly]="readOnly"
                id="inlineCheckbox1"
              />Yes
            </label>
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="false"
                formControlName="isCompanySubContractorInvestorWithLEDPrograms"
                name="isCompanySubContractorInvestorWithLEDPrograms"
                [readOnly]="readOnly"
                id="inlineCheckbox2"
              />No
            </label>
          </div>
        </div>
        <div class="d-flex mr-auto">
          <ul>
            <li>Research and Development tax credit program</li>
            <li>Angel Investor</li>
            <li>Quality Jobs</li>
            <li>Enterprise Zone</li>
          </ul>
        </div>
        <div
          *ngIf="
            eligibilityQuestionsFormGroup.controls[
              'isCompanySubContractorInvestorWithLEDPrograms'
            ].value
          "
        >
          <p class="col-md-12 text-danger bold-font">
            Please list the program and contract number if applicable. Please
            note that employees and expenditures are only eligible on one of the
            Louisiana Economic Development programs. However, a company may have
            some employees enrolled in the Quality Jobs program, while others
            are claimed under the Digital Media incentive.

            <br />
            <textarea
              class="form-control h-100"
              formControlName="companySubContractorInvestorWithLEDProgramsDetails"
              rows="3"
            ></textarea>
          </p>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="d-flex justify-content-end">
          <p class="mr-auto">
            11. Are multiple projects being developed at the same time by your
            company?
          </p>
          <div class=" has-error">
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="true"
                formControlName="isMultipleProjectBeingDeveloped"
                name="isMultipleProjectBeingDeveloped"
                [readOnly]="readOnly"
                id="inlineCheckbox1"
              />Yes
            </label>
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="false"
                formControlName="isMultipleProjectBeingDeveloped"
                name="isMultipleProjectBeingDeveloped"
                [readOnly]="readOnly"
                id="inlineCheckbox2"
              />No
            </label>
          </div>
        </div>
        <div
          *ngIf="
            eligibilityQuestionsFormGroup.controls[
              'isMultipleProjectBeingDeveloped'
            ].value
          "
        >
          <p class="col-md-12 text-danger bold-font">
            Please upload in the attachment section, the adequate documentation
            of time allocation for the projects.
          </p>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="d-flex justify-content-end">
          <p class="mr-auto">
            12. Do expenditures include work that relates to an existing
            product?
          </p>
          <div class=" has-error">
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="true"
                formControlName="isExpenditureRelatesToExistingProduct"
                name="isExpenditureRelatesToExistingProduct"
                [readOnly]="readOnly"
                id="inlineCheckbox1"
              />Yes
            </label>
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="false"
                formControlName="isExpenditureRelatesToExistingProduct"
                name="isExpenditureRelatesToExistingProduct"
                [readOnly]="readOnly"
                id="inlineCheckbox2"
              />No
            </label>
          </div>
        </div>
        <div
          *ngIf="
            eligibilityQuestionsFormGroup.controls[
              'isExpenditureRelatesToExistingProduct'
            ].value
          "
        >
          <p class="col-md-12 text-danger bold-font">
            Please upload in the attachments section, the detailed information
            for the work occurring in Louisiana that adds additional functions
            to the product.
          </p>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="d-flex justify-content-end">
          <p class="mr-auto">
            13. Do you anticipate any related party transactions?
            <span class="fa fa-info-circle ml-1" title="Related-party transaction refers to a deal or arrangement between two parties connected by an earlier business connection or shared interest."
                  data-toggle="tooltip"
                  data-placement="top"></span>
          </p>
          <div class=" has-error">
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="true"
                formControlName="isAnticipateRelatedPartyTransactions"
                name="isAnticipateRelatedPartyTransactions"
                [readOnly]="readOnly"
                id="inlineCheckbox1"
              />Yes
            </label>
            <label class="form-check-inline">
              <input
                type="radio"
                [value]="false"
                formControlName="isAnticipateRelatedPartyTransactions"
                name="isAnticipateRelatedPartyTransactions"
                [readOnly]="readOnly"
                id="inlineCheckbox2"
              />No
            </label>
          </div>
        </div>
        <div
          *ngIf="
            eligibilityQuestionsFormGroup.controls[
              'isAnticipateRelatedPartyTransactions'
            ].value
          "
        >
          <p class="col-md-12 text-danger bold-font">
            Please upload in the attachments section, the disclosure of all RPTs
            and the amounts. Please be aware that CPA firm engaged to perform
            the AUP report will have to validate the business purpose and
            economic substance of the transaction.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
