import { Injectable } from '@angular/core';
declare var $;

@Injectable()
export class DatatablesService {
  constructor() {}

  /**
   * Registers click events for all checkboxes within a datatable.
   * These click events handle selection and deselection of rows. DataTables implementing this should
   * have a header row with a checkbox that has an id.
   *
   * @param {*} dt The DataTable to add event handlers to
   * @param {string} tableId The html element id of the table
   * @param {string} headerCheckboxId The html element id of the checkbox in the header of the table
   * @memberof DatatablesService
   */
  registerCheckboxEvents(dt: any, tableId: string, headerCheckboxId: string) {
    const headerCheckboxSelector = `#${headerCheckboxId}`;
    const tableSelector = `#${tableId}`;

    $(headerCheckboxSelector).click(e => {
      const checked = e.target.checked;
      if (checked) {
        // Select all rows
        dt.rows().select();
      } else {
        // Deselect all rows
        dt.rows().deselect();
      }

      // Checks/Unchecks each row depending on the value of the header row's 'checked' property
      $(`${tableSelector} tr td input[type=checkbox]`).prop('checked', checked);
    });

    const selectOrDeselectHeaderCheckbox = () => {
      const allRowsSelected =
        dt.rows({ selected: true }).count() === dt.rows().count();

      // When all rows are selected, make sure the header's checkbox is checked!
      // Otherwise, uncheck the header checkbox
      $(headerCheckboxSelector).prop('checked', allRowsSelected);
    };

    dt.on('select', selectOrDeselectHeaderCheckbox);
    dt.on('deselect', selectOrDeselectHeaderCheckbox);
  }
}
