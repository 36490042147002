export type ExpenditureStatus =
  | 'Eligible'
  | 'Ineligible'
  | 'Conditionally Eligible';

export const ExpenditureStatuses = {
  eligible: <ExpenditureStatus>'Eligible',
  ineligible: <ExpenditureStatus>'Ineligible',
  conditionallyEligible: <ExpenditureStatus>'Conditionally Eligible'
};

export const FilePath = {
  preliminaryExpenditureTemplate:
    'https://storage.googleapis.com/fastlane-public-files/DM/Expenditure/Preliminary%20Schedule%20of%20Expenditures%2018-7%20%20-%20FLNG%20Import%20Template-%20Locked.xlsx'
};

export const paymentTypes = [
  { value: 'W2', display: 'W2' },
  { value: '1099 (Individual)', display: '1099 (Individual)' },
  {value: 'K1', display: 'K1'}
];

export const oldPaymentTypes = [
  {value: 'Contract Corporation', display: 'Contract Corporation'},
  { value: 'Other', display: 'Other'}
]
