import { FormAction } from '../form.constants';
import { FormActionHandlerAuthorityService } from './action-handler-factory.service';
import { FormShareService } from './share.service';
import { UserContextService } from '../../user/services/user-context.service';

export abstract class FormActionService {
  constructor(
    private actionHandlerAuthority: FormActionHandlerAuthorityService,
    public shareService: FormShareService,
    public userContext: UserContextService
  ) {}

  abstract getActions(): FormAction[];

  exec(action: FormAction): Promise<any> {
    const actionHandler = this.actionHandlerAuthority.getHandler(action);
    return actionHandler.exec();
  }

  updateActions() {
    // When updating actions, we need to take care to preserve the first reference of the actions array

    // Clear the actions array so that we can repopulate it
    if (this.shareService.actions.length > 0) {
      this.shareService.actions.length = 0;
    }

    // Get the actions and populate the initial array with the new values
    const newActions = this.getActions();
    const that = this;
    newActions.forEach(newAction => that.shareService.actions.push(newAction));
  }
}
