<div class="border-bottom w-100">
  <nav class="navbar navbar-static-top white-bg navbar-expand-sm w-100 d-flex justify-content-between" role="navigation" style="margin-bottom: 0; ">
    <div class="p-0">
      <a class="minimalize-styl-2 btn btn-secondary text-white" (click)="toggleNavigation()">
        <i class="fa fa-bars"></i>
      </a>
    </div>

    <div class="topnav p-0">
      <ul *ngIf="userContext.currentUser.userType !== 'nullUser'" class="navbar-nav">
        <li *ngIf="userContext.currentUser.userType !== 'board'" class="nav-item dropdown d-none">
          <a class="fa fa-bell fa-2x topnav dropdown-toggle" id="notifications" data-toggle="dropdown" data-target="#boardUserMenu" aria-haspopup="true"
             aria-expanded="false">
            <div class="superscript">
              <b class="badge-info badge">8</b>
            </div>
          </a>

          <div id="boardUserMenu" class="dropdown-menu px-1 dropdown-menu-right animated flipInY" aria-labelledby="notifications">
            <a class="dropdown-item" href="#">
              <i class="fa fa-envelope fa-fw"></i> You have 16 messages
            </a>
            <a class="dropdown-item" href="#">
              <i class="fa fa-twitter fa-fw"></i> 3 New Followers
            </a>
            <a class="dropdown-item" href="#">
              <i class="fa fa-upload fa-fw"></i> Server Rebooted
            </a>
          </div>
        </li>

        <li class="nav-item dropdown" [title]="userContext.currentUser.emailAddress">
          <a class="dropdown-toggle fa fa-user fa-2x topnav" href="#" role="button" id="profile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>

          <div class="dropdown-menu px-2 dropdown-menu-right animated flipInY" aria-labelledby="profile">
            <div class="dropdown-item text-primary">
              <h5>{{ userContext.currentUser.emailAddress }}</h5>
            </div>

            <div class="dropdown-divider"></div>

            <a class="dropdown-item d-flex align-items-center justify-content-left" routerLink="/{{routingRoot}}/profile">
              <div class="fa fa-cogs fa-2x pr-2" aria-hidden="true"></div>
              <div>Account Settings</div>
            </a>

            <div class="dropdown-divider"></div>

            <a class="dropdown-item d-flex align-items-center justify-content-left" [routerLink]="['/login']">
              <div class="fa fa-sign-out fa-2x pr-2 mr-1" aria-hidden="true"></div>
              <div>Log Out</div>
            </a>
          </div>
        </li>
      </ul>      
    </div>
  </nav>
</div>
