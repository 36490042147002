<div
  class="form-group px-3 mb-0 d-flex flex-wrap justify-content-between"
  [formGroup]="formCriticalDatesFormGroup"
>
  <div class="col-12 col-md-6 py-1 d-flex align-items-center">
    <span class="col-12 col-xl-5 form-control-label"
      >Related Application Received Date:</span
    >
    <div class="col-12 col-xl-6">
      <fl-date-time-picker
        [form]="
          formCriticalDatesFormGroup.get('relatedApplicationReceivedDateGroup')
        "
        [showTime]="false"
        [selectedDate]="initialCertCriticalDate.relatedApplicationReceivedDate"
        [IsRequired]="true"
        (dateTimeChanged)="
          estimatedDateChanged('relatedApplicationReceivedDate', $event)
        "
      ></fl-date-time-picker>
    </div>
  </div>

  <div class="col-12 col-md-6 py-1 d-flex align-items-center">
    <span class="col-12 col-xl-5 form-control-label"
      >Initial Certification Issuance Date:</span
    >
    <div class="col-12 col-xl-6">
      <fl-date-time-picker
        [form]="formCriticalDatesFormGroup.get('initialCertIssuanceDateGroup')"
        [showTime]="false"
        [selectedDate]="initialCertCriticalDate.initialCertIssuanceDate"
        [IsRequired]="false"
        (dateTimeChanged)="
          estimatedDateChanged('initialCertIssuanceDate', $event)
        "
      ></fl-date-time-picker>
    </div>
  </div>
</div>
