import { Router } from '@angular/router';
import { UserContextService } from '../../user/services/user-context.service';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';
import { map } from 'rxjs/operators';
import { SwalService } from '../../fastlane-common/services/swal.service';
import { ProjectDetailService } from 'app/project/services/project-shared.service';
import * as i0 from "@angular/core";
import * as i1 from "../../user/services/user-context.service";
import * as i2 from "@angular/common/http";
import * as i3 from "../services/authentication.service";
import * as i4 from "@angular/router";
import * as i5 from "../../fastlane-common/services/swal.service";
import * as i6 from "../../project/services/project-shared.service";
export class ProjectAccessGuard {
    constructor(userContextService, http, authenticationService, router, swalService, shareService) {
        this.userContextService = userContextService;
        this.http = http;
        this.authenticationService = authenticationService;
        this.router = router;
        this.swalService = swalService;
        this.shareService = shareService;
        this.checkingUrl = `${environment.apiUrl}/project/hasAccess/{projectGuid}`;
    }
    canActivate(next, state) {
        const that = this;
        // Get the project id from the activated route snapshot
        const projectGuid = next.paramMap.get('id');
        // Set the project guid in url
        const auditingCompanyUid = this.userContextService.currentUser
            .auditingCompanyId;
        // Only add auditingCompanyUid when it exists, which is only for auditors
        const url = this.checkingUrl.replace('{projectGuid}', projectGuid) +
            (auditingCompanyUid
                ? `?auditingCompanyUid=${auditingCompanyUid}`
                : '');
        // Ensure that the logged in user can access this project
        return this.http
            .get(url, this.authenticationService.getAuthOptionsForHttpClient())
            .pipe(map(resp => {
            if (!resp) {
                // Show swal to user
                setTimeout(() => {
                    that.swalService.error({
                        title: 'Access Denied',
                        text: 'You attempted to navigate to an area to which you do not have access. \
                As a result, you were redirected to your default page.'
                    });
                }, 0);
                // Redirect to user's default route
                that.router.navigate(['/']);
            }
            return resp;
        }));
    }
}
ProjectAccessGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProjectAccessGuard_Factory() { return new ProjectAccessGuard(i0.ɵɵinject(i1.UserContextService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.AuthenticationService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.SwalService), i0.ɵɵinject(i6.ProjectDetailService)); }, token: ProjectAccessGuard, providedIn: "root" });
