import { ActualJobs } from '../../../shared/models/actual-jobs.model';
import { ActualPayroll } from '../../../shared/models/actual-payroll.model';
import { PropertyUsage } from '../../../project/models/property-usage.model';

export class RestorationDetails {
  propertyUsage: PropertyUsage;
  actualJobs: ActualJobs;
  actualPayroll: ActualPayroll;

  constructor() {
    this.init();
  }

  init() {
    if (!this.propertyUsage) {
      this.propertyUsage = new PropertyUsage();
    }
    if (!this.actualJobs) {
      this.actualJobs = new ActualJobs();
    }
    if (!this.actualPayroll) {
      this.actualPayroll = new ActualPayroll();
    }
  }
}
