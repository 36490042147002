import { BusinessLegalStructure } from './business-legal-structure.model';
import { EstimatedBenefit } from './estimated-benefit.model';
import { PrimaryQualification } from './primary-qualification.model';
export class QualityJobsApplication {

  public estimatedBenefit: EstimatedBenefit[];
  public isHealthCareRequirementVerified?: Boolean;
  public llcMembers: BusinessLegalStructure[];
  public primaryQualification: PrimaryQualification;
  constructor(qj?: any) {
    if (!qj) qj = {};
    this.llcMembers = qj.llcMembers || [];
    this.primaryQualification = qj.primaryQualification || new PrimaryQualification();
    this.isHealthCareRequirementVerified = qj.isHealthCareRequirementVerified ? true : false;

  }
}