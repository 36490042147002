import { Component} from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'shr-loginNotification',
  templateUrl: './loginNotification.component.html',
  styleUrls: ['./loginNotification.component.scss']
})
export class LoginNotificationComponent  {

}
