import { Person } from '../../shared/models/person.model';
import { UserPermissions } from './user-permissions.model';
import { EventNotificationPreferences } from '../../shared/models/event-notification-preference.model';
import { Programs } from '../../project/models/programs.model';
export class User extends Person {
    constructor() {
        super(...arguments);
        this.boards = null;
        this.externalReviewAgency = null;
        this.id = null;
        this.incentivePrograms = [];
        this.isActive = null;
        this.legacyId = null;
        //  This is a property of a user for external reviewer and assessors not a property of address.
        this.governingParish = null;
        this.password = null;
        this.permissions = null;
        this.userType = null;
        this.eventNotificationPreferences = null;
    }
    init() {
        super.init();
        this.boards = this.boards || null;
        this.externalReviewAgency = this.externalReviewAgency || null;
        this.id = this.id || null;
        this.incentivePrograms = this.incentivePrograms || null;
        this.isActive = this.isActive || false;
        this.legacyId = this.legacyId || null;
        this.governingParish = this.governingParish || null;
        this.password = this.password || null;
        this.permissions = new UserPermissions(this.permissions);
        this.userType = this.userType || null;
        this.eventNotificationPreferences = this.eventNotificationPreferences || new EventNotificationPreferences();
        return this;
    }
    getPrograms() {
        return this.incentivePrograms.reduce((seed, next) => {
            seed[next.toLowerCase()] = true;
            return seed;
        }, new Programs());
    }
}
