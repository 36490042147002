import { Address } from "../../shared/models/address.model";

export class BoardMeetingDetails {
    building: string;
    boardType: string;
    date: Date;
    displayDateDate: string;
    displayDateTime: string;
    address: Address;
}
