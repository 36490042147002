import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { UserDataService } from './services/user-data.service';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { UserDetailApplicantComponent } from './components/user-detail/applicant/applicant.component';
import { UserDetailExternalComponent } from './components/user-detail/external/external.component';
import { UserDetailManagerComponent } from './components/user-detail/management/management.component';
import { UserDetailBoardComponent } from './components/user-detail/board/board.component';
import { UserDetailAssessorComponent } from './components/user-detail/assessor/assessor.component';
import { EventNotificationPreferencesComponent } from './event-notification-preferences/event-notification-preferences.component';
// tslint:disable-next-line:max-line-length
import { EventNotificationActionMultiselectComponent } from './event-notification-action-multiselect/event-notification-action-multiselect.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PermissionSettingsComponent } from './permission-settings/permission-settings.component';
import { ProgramSelectionComponent } from './components/user-detail/program-selection/program-selection.component';
import { EntertainmentModule } from '../entertainment/entertainment.module';
import { ModalComponent } from '../shared/layouts/modal/modal.component';

@NgModule({
  declarations: [
    UserDetailComponent,
    UserListComponent,
    UserDetailApplicantComponent,
    UserDetailExternalComponent,
    UserDetailManagerComponent,
    UserDetailBoardComponent,
    UserDetailAssessorComponent,
    EventNotificationPreferencesComponent,
    EventNotificationActionMultiselectComponent,
    PermissionSettingsComponent,
    ProgramSelectionComponent
  ],
  exports: [UserDetailComponent, UserListComponent],
  entryComponents: [UserDetailComponent, ModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    EntertainmentModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [UserDataService]
})
export class UserModule {}
