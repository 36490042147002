import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authService.isUserLoggedIn) {
            // Url where to redirect user upon successfull login
            this.authService.redirectUrl = state.url;

            // Take user back to log in page
            this.router.navigate(['/login']);

            return false;
        }
        return true;
    }

    constructor(
        private authService: AuthenticationService,
        private router: Router
    ) { }
}