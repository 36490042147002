/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./password-feedback.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./password-feedback.component";
var styles_PasswordFeedbackComponent = [i0.styles];
var RenderType_PasswordFeedbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PasswordFeedbackComponent, data: {} });
export { RenderType_PasswordFeedbackComponent as RenderType_PasswordFeedbackComponent };
function View_PasswordFeedbackComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "feedback-suggestion"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
export function View_PasswordFeedbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "small", [["class", "feedback-warning"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ul", [["class", "feedback"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PasswordFeedbackComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.feedback.suggestions; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.feedback.warning; _ck(_v, 2, 0, currVal_0); }); }
export function View_PasswordFeedbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fl-password-feedback", [], null, null, null, View_PasswordFeedbackComponent_0, RenderType_PasswordFeedbackComponent)), i1.ɵdid(1, 114688, null, 0, i3.PasswordFeedbackComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PasswordFeedbackComponentNgFactory = i1.ɵccf("fl-password-feedback", i3.PasswordFeedbackComponent, View_PasswordFeedbackComponent_Host_0, { feedback: "feedback" }, {}, []);
export { PasswordFeedbackComponentNgFactory as PasswordFeedbackComponentNgFactory };
