<div>
  <ng-multiselect-dropdown
    [placeholder]="'Filter by Project Status'"
    [(ngModel)]="selectedProjectStatusObjects"
    name="ProjectStatus"
    [data]="localProjectStatuses"
    [disabled]="disabled"
    [settings]="dropdownSettings"
    (onDeSelectAll)="onSelectAll($event)"
    (onSelectAll)="onSelectAll($event)"
    (onSelect)="onSelectionChange($event, 'select')"
    (onDeSelect)="onSelectionChange($event, 'deselect')"
  >
  </ng-multiselect-dropdown>
</div>
