export const OwnerShipTypes: any[] = [
  { id: 1, name: 'Corporation' },
  { id: 2, name: 'Limited Liability Corporation' },
  { id: 3, name: 'Limited Liability Partnership' },
  { id: 4, name: 'Limited Partnership' },
  { id: 5, name: 'General Partnership' },
  { id: 6, name: 'Sole Proprietorship' },
  { id: 7, name: 'S-Corporation' },
  { id: 8, name: 'State Agency' },
  { id: 9, name: 'Other' },
  { id: 10, name: 'Non-profit Organization' },
  { id: 11, name: 'State/Local Government' },
  { id: 12, name: 'University' },
  { id: 13, name: 'Federal Government/Military' },
  { id: 14, name: 'Foreign Corporation' },
  { id: 15, name: 'Out of State Government' }
];

export const ezAnticipatedBenefits = {
  none: 'None',
  taxCredit: '1.5% Investment Tax Credit',
  taxRebate: 'State sales/Use Tax Rebate'
};

export const allEzAnticipatedBenefits: any[] = [
  { id: 0, name: ezAnticipatedBenefits.none },
  { id: 1, name: ezAnticipatedBenefits.taxRebate },
  { id: 2, name: ezAnticipatedBenefits.taxCredit }
];

export const EZTypes: any[] = [
  { id: 1, name: 'Urban' },
  { id: 2, name: 'Rural' },
  { id: 3, name: 'Act 977' },
  { id: 4, name: 'EDZ' }
];
