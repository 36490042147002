import { userTypeToPermissionsMap } from './security.constants';

/**@summary Use this function to get all available permissions for the provided user type
 * @param userType The user type for which to retrieve available permissions
 * @returns An array of Permissions
 */
export function getPermissionsForUserType(userType: string) {
    try {
        return userTypeToPermissionsMap[userType];
    } catch (error) {
        return [];
    }
}