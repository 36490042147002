export const creditTypes = {
  base: {
    name: 'Louisiana Expenditures (Base Investment)',
    abbrev: 'base'
  },
  outMSA: {
    name: 'Out of New Orleans MSA',
    abbrev: 'outMSA'
  },
  screenplay: {
    name: 'Louisiana Screenplay',
    abbrev: 'screenplay'
  },
  payroll: {
    name: 'Louisiana Resident Payroll',
    abbrev: 'payroll'
  },
  vfx: {
    name: 'Louisiana Visual Effects Expenditures',
    abbrev: 'vfx'
  }
};

export const careerBasedLearning = {
  minimum3PaidPosition:
    // tslint:disable-next-line:max-line-length
    'Minimum of 3 paid internship positions provided to students enrolled in an accredited high school, community college, university or qualified community based program, for a minimum of 75 hours per student and a total of 225 hours',
  minimum8HoursClassroomWorkshop:
    // tslint:disable-next-line:max-line-length
    'Minimum of 8 hours of studio employment and professional skills tour provided to students enrolled in high school, community college, university or qualified community based program',
  minimum8HoursStudioEmployment:
    // tslint:disable-next-line:max-line-length
    'Minimum of 8 hours of classroom workshop provided to students enrolled in an accredited high school, community college, university or qualified community based program',
  minimum8HoursContinuingEducation:
    // tslint:disable-next-line:max-line-length
    'Minimum of 8 hours of continuing education for educators or faculty to observe the set operations, post production and other specialized departments',
  financialContribution:
    // tslint:disable-next-line:max-line-length
    'Financial contribution or donation to a specific local educational agency or higher education institution specializing in arts, media and entertainment career oriented program. Financial contributions calculated at 0.25 percent of the estimated tax credit reservation'
};

export const baseCreditTypes = [
  creditTypes.base.abbrev,
  creditTypes.outMSA.abbrev,
  creditTypes.screenplay.abbrev
];

export const additionalCreditTypes = [
  creditTypes.payroll.abbrev,
  creditTypes.vfx.abbrev
];

export const expenditureTypeFormulaDictionary = {
  expenditures: []
};

export const logoRequirements = [
  'Logo will be included in opening credits',
  'Logo will be included in closing credits',
  'Logo will be placed on production website',
  'Logo will be used in production social media',
  'Other'
];

export const careerLearningTypes = [
  {
    name:
      // tslint:disable-next-line:max-line-length
      'Minimum of 3 paid internship positions provided to students enrolled in an accredited high school, community college, university or qualified community based program, for a minimum of 75 hours per student and a total of 225 hours',
    value: 'Paid Internships'
  },
  {
    name:
      // tslint:disable-next-line:max-line-length
      'Minimum of 8 hours of classroom workshop provided to students enrolled in an accredited high school, community college, university or qualified community based program',
    value: 'Classroom Workshop'
  },
  {
    name:
      // tslint:disable-next-line:max-line-length
      'Minimum of 8 hours of studio employment and professional skills tour provided to students enrolled in high school, community college, university or qualified community based program',
    value: 'Studio Employment'
  },
  {
    name:
      // tslint:disable-next-line:max-line-length
      'Minimum of 8 hours of continuing education for educators or faculty to observe the set operations, post production and other specialized departments',
    value: 'Continued Education'
  },
  {
    name:
      // tslint:disable-next-line:max-line-length
      'Financial contribution or donation to a specific local educational agency or higher education institution specializing in arts, media and entertainment career oriented program. Financial contributions calculated at 0.25 percent of the estimated tax credit reservation',
    value: 'Financial Contribution'
  },
  {
    name: 'Other method of participation approved by the office',
    value: 'Other'
  }
];

export const productionTypes = [
  'Feature Film',
  'TV Movie of the Week',
  'TV Pilot',
  'Scripted Episodic Content',
  'Commercial',
  'Music Video',
  'Web Cast',
  'Short Film',
  'Documentary',
  'Reality TV'
];
