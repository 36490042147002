<div class="card m-t border border-primary" style="overflow:visible;">
  <div class="card-header bg-primary text-white font-weight-bold">
    PERMISSION SETTINGS
  </div>
  <div class="card-body p-4" style="overflow:visible;">
    <div class="d-flex flex-wrap">
      <div *ngFor="let permission of availablePermissions" class="d-flex col-xl-4 col-lg-6 col-12 py-1">
        <label class="col-8 form-control-label px-0">{{permission.display}}</label>
        <div class="col-4">
          <button type="button" [ngClass]="{'active':user.permissions[permission.value]}" (click)="togglePermission(permission.value)"
            class="btn btn-toggle" aria-pressed="user.permissions[permission.value]">
            <div class="handle"></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>