/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./management.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../shared/components/person/person.component.ngfactory";
import * as i4 from "../../../../shared/components/person/person.component";
import * as i5 from "@angular/forms";
import * as i6 from "./management.component";
var styles_UserDetailManagerComponent = [i0.styles];
var RenderType_UserDetailManagerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserDetailManagerComponent, data: {} });
export { RenderType_UserDetailManagerComponent as RenderType_UserDetailManagerComponent };
function View_UserDetailManagerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "d-flex col-xl-4 col-lg-6 col-12 py-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "col-8 form-control-label px-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "col-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "button", [["aria-pressed", "user.permissions[pair[0]]"], ["class", "btn btn-toggle"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changePermission(_v.context.$implicit[0]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { active: 0 }), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "handle"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "btn btn-toggle"; var currVal_2 = _ck(_v, 7, 0, _co.user.permissions[_v.context.$implicit[0]]); _ck(_v, 6, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit[1]; _ck(_v, 2, 0, currVal_0); }); }
export function View_UserDetailManagerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { personComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "shr-person", [], null, null, null, i3.View_PersonComponent_0, i3.RenderType_PersonComponent)), i1.ɵdid(2, 245760, [[1, 4]], 0, i4.PersonComponent, [i5.FormBuilder], { addressRequired: [0, "addressRequired"], form: [1, "form"], person: [2, "person"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "card border-primary m-t"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "card-header bg-primary text-white font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" AVAILABLE MENU OPTIONS "])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "d-flex flex-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserDetailManagerComponent_1)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.userForm; var currVal_2 = _co.user; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.permissionPropertyNameAndLabelPairs; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_UserDetailManagerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fl-user-detail-management", [], null, null, null, View_UserDetailManagerComponent_0, RenderType_UserDetailManagerComponent)), i1.ɵdid(1, 245760, null, 0, i6.UserDetailManagerComponent, [i5.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserDetailManagerComponentNgFactory = i1.ɵccf("fl-user-detail-management", i6.UserDetailManagerComponent, View_UserDetailManagerComponent_Host_0, { form: "form", user: "user" }, {}, []);
export { UserDetailManagerComponentNgFactory as UserDetailManagerComponentNgFactory };
