import {  boardStatus } from '../project/project.constants';
import { FormType, formTypes, oneToOneFormTypes } from './form.constants';
import { chain } from 'underscore';
import { FormIdentifier } from '../project/models/form-identifier.model';

export function getAllFormTypeAndNames() {
  return [
    <FormType>'acr',
    <FormType>'advance',
    <FormType>'afc',
    <FormType>'application',
    <FormType>'cao',
    <FormType>'ccn',
    <FormType>'ccl',
    <FormType>'ccc',
    <FormType>'ecr',
    <FormType>'cft',
    <FormType>'cpt',
    <FormType>'pcr',
    <FormType>'rnw',
    <FormType>'req',
    <FormType>'evr'
  ].map(type => {
    return {
      name: getFormTypeName(type),
      type: type
    };
  });
}

export function getBoardStatuses() {
  return [
    boardStatus.approved,
    boardStatus.deferred,
    boardStatus.denied,
    boardStatus.ready
  ];
}

export function getFormTypeName(formType: string) {
  if (formType === 'app') {
    formType = 'application';
  }

  return chain(formTypes)
    .pairs()
    .filter(pair => pair[1].abbrev === formType)
    .first()
    .value()[1].name;
}

export function getFormsTypesRequiringBusinessSignature() {
  return chain(formTypes)
    .where({ requiresBusinessSignature: true })
    .map(formType => formType.abbrev)
    .value();
}

export function doFormIdsMatch(left: FormIdentifier, right: FormIdentifier) {
  // First Check if form types match, otherwise return false
  if (!left || left.formType !== right.formType) {
    return false;
  }

  // If form type is either adv or app for left, left and right types match, return true to ignore index.
  if (left && oneToOneFormTypes.includes(left.formType)) {
    return true;
  } else {
    // By this point, everything but formIndex is yet to be verified.
    return left.formIndex === right.formIndex;
  }
}
