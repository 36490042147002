export class BenefitParameters {
  jobTaxCredit1: number; // EZ
  jobTaxCredit2: number; // EZ

  benefitRate: number; // ITE, QJ, RTA
  firstPhase: number; // ITE
  secondPhase: number; // ITE
  firstPhaseRate: number; // ITE
  secondPhaseRate: number; // ITE

  millageRate: number; // RTA
  yearsExempt: number; // RTA

  assessmentPercentage: number; // ITE

//#region Digital

  laborOutOfState: number;
  laborInState: number;
  nonLabor: number;

//#endregion

  constructor(params?: any) {
    params = params || {};

    this.jobTaxCredit1 = params.jobTaxCredit1 || 0;
    this.jobTaxCredit2 = params.jobTaxCredit2 || 0;

    this.benefitRate = params.benefitRate || 0;
    this.firstPhaseRate = params.firstPhaseRate || 0;
    this.secondPhaseRate = params.secondPhaseRate || 0;
    this.firstPhase = params.firstPhase || 0;
    this.secondPhase = params.secondPhase || 0;
    this.millageRate = params.millageRate || 0;
    this.yearsExempt = params.yearsExempt || 0;

    this.assessmentPercentage = params.assessmentPercentage || 0;

    // Digital
    this.laborOutOfState = params.laborOutOfState  || 0;
    this.laborInState = params.laborInState || 0;
    this.nonLabor = params.nonLabor || 0;
  }
}
