<div class="d-flex flex-wrap py-1">
  <label class="col-12 col-lg-4 form-control-label" for="activeCompanies"
    >Auditing Company:</label
  >
  <div
    class="col-12 col-lg-8"
    *ngIf="
      activeCompanies && activeCompanies.length > 0;
      then companies;
      else spin
    "
  ></div>
  <ng-template #companies>
    <div class="col-12 col-lg-8">
      <select
        [compareWith]="selectedCompany"
        (change)="selectAuditor($event)"
        id="activeCompanies"
        class="form-control"
        [formControl]="activeCompany"
      >
        <option [disabled]="!company.isActive" [className]="!company.isActive ? 'gray-bg' : ''" *ngFor="let company of activeCompanies" [value]="company.id">{{
          company.name
        }}</option>
      </select>
    </div>
  </ng-template>
  <ng-template #spin>
    <div class="px-3 d-flex align-items-center justify-content-center">
      <i class="fa fa-spinner fa-spin fa-2x"></i>
    </div>
  </ng-template>
</div>
