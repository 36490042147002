import { ProjectInfoBase } from '../../../project/models/project-info-base.model';
import {StepId} from '../../interfaces/step-id.interfaces';

export class ProjectInfo extends ProjectInfoBase implements StepId {
    stepId: string;

    constructor(info?: any) {
        info = info || {};

        super(info);

        this.stepId = info.stepId || null;
      }
}
