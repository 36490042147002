import { Injectable } from '@angular/core';
import { FormIdentifier } from '../../project/models/form-identifier.model';
import { Payment } from '../../fiscal/models/payment.model';
import { Project } from '../../project/models/project.model';
import { ProjectDetailService } from '../../project/services/project-shared.service';
import { ProjectForm } from '../../project/models/form.model';
import { FormPayment } from '../models/form-payment.model';
import { Subject, Observable } from 'rxjs';
import { UserFormPermissions } from '../models/permissions.model';
import { FormAction, oneToOneFormTypes } from '../form.constants';
import { doFormIdsMatch } from '../form.functions';
import { UserDataService } from '../../user/services/user-data.service';
import { User } from '../../user/models/user.model';
import { LookupHttpService } from '../../fastlane-common/services/lookup-http.service';
import { PayPointReturnCode, feeTypes } from '../../fiscal/fiscal.constants';
import { Credit } from '../../entertainment/models/film/credit.model';
import {
  incentiveProgram,
  incentiveCategory
} from '../../project/project.constants';
import { Project as FilmProject } from '../../entertainment/models/film/project.model';
import { Project as DigitalProject } from '../../entertainment/models/digital/project.model';
import { EntertainmentForm } from '../../entertainment/models/entertainment-form.model';
import { OutgoingPayment } from '../../fiscal/models/outgoing-payment.model';
import { ProjectBase } from '../../project/models/project-base.model';
import { AuditInfo } from '../../entertainment/interfaces/audit-info.interface';
import {StepForm} from '../../step/models/step-form.model';
import { Project as StepProject} from '../../step/models/step/project.model';
import {StepApplication} from '../../step/models/step/step-application.model';

@Injectable()
export class FormShareService {
  actions: FormAction[];
  formNewPayment: Payment;
  onFormIdChange: Subject<FormIdentifier>;
  payingContactIndex: number;
  permissions: UserFormPermissions;

  private _formId;

  // get castedForm() {
  //   switch (this.currentIncentiveProgram) {
  //     case incentiveProgram.film.code:
  //       return this.project.getProjectForm(this.formId) as ;
  //   }
  // }

  //#region assingingForm
  // This property is used only for BI forms.
  get form(): ProjectForm {
    return this.project.getProjectForm(this.formId);
  }

  set form(value) {
    if (oneToOneFormTypes.includes(this.formId.formType)) {
      this.project[this.formId.formType] = value;
    } else {
      this.project[this.formId.formType][this.formId.formIndex] = value;
    }
  }

  // This property is used only for step forms.
  get stepForm(): StepApplication {
    return this.stepProject.getProjectForm(this.formId) as StepApplication;
  }

  set stepForm(value) {
    if (oneToOneFormTypes.includes(this.formId.formType)) {
      this.stepProject[this.formId.formType] = value;
    } else {
      this.stepProject[this.formId.formType][this.formId.formIndex] = value;
    }
  }

  // This property is used only for film forms.
  get filmForm(): EntertainmentForm {
    return this.filmProject.getProjectForm(this.formId) as EntertainmentForm;
  }

  set filmForm(value) {
    if (oneToOneFormTypes.includes(this.formId.formType)) {
      this.filmProject[this.formId.formType] = value;
    } else {
      this.filmProject[this.formId.formType][this.formId.formIndex] = value;
    }
  }

  get auditInfo(): AuditInfo {
    return (this.filmForm as any) as AuditInfo;
  }

  // This property is used only for digital forms.
  get digitalForm(): EntertainmentForm {
    return this.digitalProject.getProjectForm(this.formId) as EntertainmentForm;
  }

  set digitalForm(value) {
    if (oneToOneFormTypes.includes(this.formId.formType)) {
      this.digitalProject[this.formId.formType] = value;
    } else {
      this.digitalProject[this.formId.formType][this.formId.formIndex] = value;
    }
  }
  //#endregion
  get formId(): FormIdentifier {
    return this._formId;
  }

  set formId(value) {
    if (JSON.stringify(value) !== JSON.stringify(this._formId)) {
      this._formId = value;
      this.onFormIdChange.next(value);
    }
  }

  get currentIncentiveProgram(): string {
    return this.projectService.currentIncentiveProgram;
  }

  get payments(): FormPayment[] {
    const formId = this.formId;
    const projectId = this.projectBase.projectInfo.projectId;

    const paymentsForThisForm = this.projectService.payments.filter(
      pp =>
        pp.paymentProject.projectId === projectId &&
        pp.paymentProject.feeType !== feeTypes.auditorPayment.upperAbbrev &&
        (pp.returnCode === PayPointReturnCode.Success ||
          pp.paymentMethod.toLowerCase() === 'check') &&
        doFormIdsMatch(pp.paymentProject.formId, formId)
    );

    return paymentsForThisForm.map(payment => {
      return <FormPayment>{
        amountPaid: payment.paymentProject.paidAmount,
        confirmationNumber: payment.confirmationNumber,
        createDate: payment.createDate,
        feeType: payment.paymentProject.feeType,
        paymentMethod: payment.paymentMethod,
        requestUrl: payment.requestUrl,
        returnCode: payment.returnCode
      };
    });
  }

  get formCredits(): Credit[] {
    const formId = this.formId;
    // This filter will create a new array,
    // so the reference to credits in projectDetailsService will be broken.
    // Therefore, when adding a new credit to this array do not forget to adding
    // to the credits array in projectDetails Service.
    return this.projectService.filmCredits.filter(credit =>
      doFormIdsMatch(credit.formIdentifier, formId)
    );
  }

  set formCredits(value: Credit[]) {
    this.projectService.filmCredits.push(...value);
  }

  get project(): Project {
    return this.projectService.project;
  }

  get projectBase(): ProjectBase {
    return this.projectService.projectBase;
  }

  get stepProject(): StepProject {

    return this.projectService.stepProject;
  }

  get filmProject(): FilmProject {
    return this.projectService.filmProject;
  }

  get digitalProject(): DigitalProject {
    return this.projectService.digitalProject;
  }

  constructor(
    private lookupService: LookupHttpService,
    private projectService: ProjectDetailService,
    private userDataService: UserDataService
  ) {
    this.actions = [];
    this.onFormIdChange = new Subject<FormIdentifier>();
    this.permissions = new UserFormPermissions();
  }

  determineFeeEffectiveDate(): Date {
    // If the current form's received date, use it
    if (this.form && this.form.receivedDate) {
      return new Date(this.form.receivedDate);
    }

    // Otherwise use current date time
    return new Date();
  }

  getAdvanceFirstReceivedDateOrDefault(): Date {
    let payments = this.projectService.payments;
    payments = payments.filter(pay => pay.paymentProject.formId != null);
    const advancePayments = payments.filter(
      pay => pay.paymentProject.formId.formType === 'advance'
    );
    let advanceReceivedDate = new Date();
    if (this.projectService.project.advance != null) {
      advanceReceivedDate = this.projectService.project.advance.receivedDate
        ? this.projectService.project.advance.receivedDate
        : new Date();
    }
    const paymentDate: Date =
      advancePayments && advancePayments.length > 0
        ? new Date(advancePayments[0].createDate)
        : advanceReceivedDate;
    return paymentDate;
  }

  getLegislationRule(): string {
    return this.projectService.project.projectInfo.legislation
      ? this.projectService.project.projectInfo.legislation
      : '';
  }

  isUserSignee() {
    return this.projectService.isUserBusinessSignatory();
  }

  getFormAssessors(): Observable<User[]> {
    // Gets assessors based on current project parish.
    return this.userDataService.getAssessors(
      this.project.projectInfo.location.parish
    );
  }

  getProgramManager(): Observable<User> {
    // Gets only one program manager per program.
    return this.lookupService.getProgramManager(this.currentIncentiveProgram);
  }

  refreshPage() {
    this.onFormIdChange.next(this.formId);
  }
}
