import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'shr-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @ViewChild('userDetailRef', { read: ViewContainerRef, static: true })
  userDetailRef: ViewContainerRef;
  constructor() {}

  ngOnInit() {}
}
