import { ProjectBase } from '../../../project/models/project-base.model';
import { AuditDepositCreator } from '../../interfaces/audit-deposit-creator.interface';
import { EntertainmentForm } from '../entertainment-form.model';
import { Application } from './application.model';
import { ProjectInfo } from './project-info.model';
import { InitialCertification } from './initial-certification.model';
import { EntertainmentProject } from '../../interfaces/project.interface';
import { FormIdentifier } from '../../../project/models/form-identifier.model';
import { Audit } from './audit.model';
import { Evr } from './expenditure-verification.model';

export class Project extends ProjectBase {
  application: Application;
  initialCert: EntertainmentForm[];
  audit: EntertainmentForm[];
  evr: EntertainmentForm[];
  projectInfo: ProjectInfo;
  constructor() {
    super();
  }

  getAuditDepositCreatorForm(formId: FormIdentifier): AuditDepositCreator {
    return (this.getProjectForm(
      formId
    ) as EntertainmentForm) as AuditDepositCreator;
  }

  init(): Project {
    // Initialize the base class ProjectBase.
    super.init();

    if (this.application) {
      this.application = Object.assign(new Application(), this.application);
      this.application.init();
    }

    // ProjectInfo in ProjectBase is type ProjectInfoBase.
    // So, Super.init() takes care of initializing ProjectInfoBase in ProjectBase.
    // Here we just use that initialized projectInfoBase and construct FilmProjectInfo.
    this.projectInfo = new ProjectInfo(this.projectInfo);

    //#region onetomanyforms
    if (this.initialCert) {
      this.initialCert = this.initialCert.map(initCert => {
        initCert = Object.assign(new InitialCertification(), initCert);
        initCert.init();
        return initCert;
      });
    }

    if (this.audit) {
      this.audit = this.audit.map(audit => {
        audit = Object.assign(new Audit(), audit);
        audit.init();
        return audit;
      });
    }

    if (this.evr) {
      this.evr = this.evr.map(evr => {
        evr = Object.assign(new Evr(), evr);
        evr.init();
        return evr;
      });
    }
    //#endregion

    return this;
  }
}
