import { NonLaborInfo } from './non-labor-info.model';
import { LaborInfo } from './labor-info.model';
import { Credit } from './credit.model';
import { YearsInSpreadsheet } from './yearsInSpreadsheet.model';

export class Expenditures {
  yearsInSpreadsheet: YearsInSpreadsheet[];
  laborInfo: LaborInfo[];
  laborInStateTotal: number;
  laborOutOfStateTotal: number;
  laborTotal: number;
  nonLaborInfo: NonLaborInfo[];
  nonLaborTotal: number;
  estimatedCredits: Credit[];
  expenditureTotal: number;
  cumulativeCredits: number;
  totalEmployees: number;
  totalEstimatedJobs: number;
  totalEstimatedEligibleJobs: number;
  totalEligibleExpenditure: number;
  totalEligibleLabor: number;
  totalEligibleNonLabor: number;

  constructor(obj?: any) {
    if (!obj) {
      obj = {};
    }
    this.yearsInSpreadsheet = obj.yearsInSpreadsheet || [];
    this.laborInfo = obj.laborInfo || [];
    this.laborTotal = obj.laborTotal >= 0 ? obj.laborTotal : null;
    this.laborInStateTotal = obj.laborInStateTotal || null;
    this.laborOutOfStateTotal =
      obj.laborOutOfStateTotal >= 0 ? obj.laborOutOfStateTotal : null;
    this.nonLaborInfo = obj.nonLaborInfo || [];
    this.estimatedCredits = obj.estimatedCredits || [];
    this.expenditureTotal =
      obj.expenditureTotal >= 0 ? obj.expenditureTotal : null;
    this.laborInStateTotal =
      obj.laborInStateTotal >= 0 ? obj.laborInStateTotal : null;
    this.laborOutOfStateTotal =
      obj.laborOutOfStateTotal >= 0 ? obj.laborOutOfStateTotal : null;
    this.nonLaborTotal = obj.nonLaborTotal >= 0 ? obj.nonLaborTotal : null;
    this.cumulativeCredits = obj.cumulativeCredits || null;
    this.totalEmployees = obj.totalEmployees >= 0 ? obj.totalEmployees : null;
    this.totalEstimatedJobs =
      obj.totalEstimatedJobs >= 0 ? obj.totalEstimatedJobs : null;
    this.totalEstimatedEligibleJobs =
      obj.totalEstimatedEligibleJobs >= 0
        ? obj.totalEstimatedEligibleJobs
        : null;
    this.totalEligibleExpenditure =
      obj.totalEligibleExpenditure >= 0 ? obj.totalEligibleExpenditure : null;
    this.totalEligibleLabor =
      obj.totalEligibleLabor >= 0 ? obj.totalEligibleLabor : null;
    this.totalEligibleNonLabor =
      obj.totalEligibleNonLabor >= 0 ? obj.totalEligibleNonLabor : null;
  }
}
