import { Contact } from './project-contact.model';
import { Location } from './location.model';

export class ProjectInfoBase {
    companyName: string;
    contractSignatory: Contact;
    isConfidential: Boolean;
    counter?: number;
    fein: string;
    incentiveProgram: string;
    legacyApplicationUid?: number;
    legacyUid?: number;
    location: Location;
    projectId: string;
    projectName: string;
    projectPhase: string;
    legislation: string;
    year?: number;

    constructor(info?: any) {
        info = info || {};

        this.companyName = info.companyName || null;
        this.contractSignatory = info.contractSignatory || new Contact().init();
        this.counter = info.counter || null;
        this.incentiveProgram = info.incentiveProgram || null;
        this.fein = info.fein || null;
        this.isConfidential = info.isConfidential || false;

        this.legacyApplicationUid = info.legacyApplicationUid || null;
        this.legacyUid = info.legacyUid || null;
        this.legislation = info.legislation || null;
        this.location = info.location
          ? new Location(info.location)
          : new Location();

        this.projectId = info.projectId || null;
        this.projectName = info.projectName || null;
        this.projectPhase = info.projectPhase || null;
        this.year = info.year || null;
    }
}