import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { ProjectDetail } from '../../../../entertainment/models/digital/project-detail.model';
import { Subscription } from 'rxjs';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidatorFn
} from '@angular/forms';
import { DateTimePickerComponent } from '../../../../shared/components/date-time-picker/date-time-picker.component';
import { EventEmitter } from 'protractor';
import {
  projectTypes,
  oldProjectTypes
} from '../project-details/project-details.constants';
import { FormValidationService } from '../../../../form/services/validation.service';
import { FormShareService } from '../../../../form/services/share.service';
import { formTypes } from '../../../../form/form.constants';
import * as moment from 'moment';
import { formStatus } from '../../../../form/form.constants';
import { ProjectDetailService } from '../../../../project/services/project-shared.service';
import { deepCopy } from '../../../../shared/shared.functions';
@Component({
  selector: 'fl-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit, OnDestroy {
  @Input() projectDetails: ProjectDetail;
  @ViewChild('anticipatedProjectStartDate', { static: true })
  anticipatedProjectStartDateComp: DateTimePickerComponent;
  @ViewChild('anticipdatedProjectEndDate', { static: true })
  anticipatedProjectEndDateComp: DateTimePickerComponent;
  private subscriptions: Subscription[] = [];
  projectDetailsFormGroup: FormGroup;
  projectTypes = deepCopy(projectTypes);
  isStartDateValid: boolean;
  isEndDateValid: boolean;
  projectStartDateGroup: FormGroup;
  projectEndDateGroup: FormGroup;
  projectStartDateValidator: ValidatorFn | ValidatorFn[];
  projectEndDateValidator: ValidatorFn | ValidatorFn[];

  constructor(
    private fb: FormBuilder,
    private validationService: FormValidationService,
    private formShareService: FormShareService,
    private projectDetailService: ProjectDetailService
  ) {}
  // #region "Custom Methods"
  /**
   * @summary Destroy the subscription after rendering the page to prevent leaks.
   * @param sub Subription that was register.
   */
  addSubscriberToBeDestroy(sub: Subscription) {
    // Add subscriberts to the Subscription array to be destroy later.
    this.subscriptions.push(sub);
  }
  /**
   * @summary Initialize the form controls
   */
  initializeForm() {
    const that = this;
    // Update projectTypes array
    if (that.projectDetailService.digitalProject.projectInfo.legacyUid) {
      this.projectTypes.push(...oldProjectTypes);
    }
    // Assign values to the form control
    this.projectEndDateGroup = this.fb.group({});
    this.projectStartDateGroup = this.fb.group({});
    this.projectDetailsFormGroup = this.fb.group({
      projectType: [this.projectDetails.projectType, [Validators.required]],
      descriptionProject: [
        this.projectDetails.projectDescription,
        [Validators.required]
      ],
      descriptionUserInterface: [
        this.projectDetails.userInterfaceDescription,
        [Validators.required]
      ],
      distributionPlan: [
        this.projectDetails.distributionPlan,
        [Validators.required]
      ],
      productWebsite: [
        this.projectDetails.productWebsite,
        [Validators.required]
      ],
      projectStartDateGroup: this.projectStartDateGroup,
      projectEndDateGroup: this.projectEndDateGroup
    });

    // Add event handlers to form to update data model
    const subprojectDetailsFormGroup = this.projectDetailsFormGroup.valueChanges.subscribe(
      value => {
        that.projectDetails.projectType = value.projectType;
        that.projectDetails.projectDescription = value.descriptionProject;
        that.projectDetails.userInterfaceDescription =
          value.descriptionUserInterface;
        that.projectDetails.distributionPlan = value.distributionPlan;
        that.projectDetails.productWebsite = value.productWebsite;
      }
    );
    // Set AnticipatedProjectStartDate
    this.anticipatedProjectStartDateComp.IsRequired = true;
    // Set AnticipatedProjectEndDate
    this.anticipatedProjectEndDateComp.IsRequired = true;
    this.disableValidation();
    // Add the current form group to validation service
    this.validationService.form.setControl(
      'projectDetailsFormGroup',
      this.projectDetailsFormGroup
    );
    // Add subscribers that needs to be destroy
    this.addSubscriberToBeDestroy(subprojectDetailsFormGroup);
    this.validateAnticipatedDate(
      this.projectDetails.anticipatedProjectStartDate,
      this.projectDetails.anticipatedProjectEndDate
    );
  }
  /**
   * @summary Clear validation depending on formTypes
   */
  disableValidation() {
    if (
      this.formShareService.form.type === formTypes.initialCert.abbrev ||
      this.formShareService.form.type === formTypes.audit.abbrev
    ) {
      // Set AnticipatedProjectStartDate
      this.anticipatedProjectStartDateComp.IsRequired = false;
      // Set AnticipatedProjectEndDate
      this.anticipatedProjectEndDateComp.IsRequired = false;
      for (const i in this.projectDetailsFormGroup.controls) {
        if (<Object>this.projectDetailsFormGroup.controls.hasOwnProperty(i)) {
          this.projectDetailsFormGroup.controls[i].clearValidators();
          this.projectDetailsFormGroup.controls[i].updateValueAndValidity();
        }
      }
    }
  }
  /**
   * @summary Update the model when the date has change.
   */
  DateChanged(enteredDate: Date, controlName: String) {
    if (
      enteredDate &&
      enteredDate instanceof Date &&
      !isNaN(enteredDate.getTime())
    ) {
      if (
        controlName.toUpperCase().toString() ===
        'anticipatedProjectStartDate'.toUpperCase().toString()
      ) {
        // Send value to property
        this.projectDetails.anticipatedProjectStartDate = enteredDate;
      } else if (
        controlName.toUpperCase().toString() ===
        'anticipatedProjectEndDate'.toUpperCase().toString()
      ) {
        // Send value to property
        this.projectDetails.anticipatedProjectEndDate = enteredDate;
      }
    } else if (enteredDate === null) {
      if (
        controlName.toUpperCase().toString() ===
        'anticipatedProjectStartDate'.toUpperCase().toString()
      ) {
        this.projectDetails.anticipatedProjectStartDate = null;
      } else if (
        controlName.toUpperCase().toString() ===
        'anticipatedProjectEndDate'.toUpperCase().toString()
      ) {
        this.projectDetails.anticipatedProjectEndDate = null;
      }
    }

    this.validateAnticipatedDate(
      this.projectDetails.anticipatedProjectStartDate,
      this.projectDetails.anticipatedProjectEndDate
    );
  }
  validateAnticipatedDate(startDate: Date, endDate: Date) {
    if (
      this.projectDetailService.digitalProject.application === undefined ||
      (this.projectDetailService.digitalProject.application &&
        this.projectDetailService.digitalProject.application.statuses[0]
          .status === formStatus.pending)
    ) {
      if (this.formShareService.form.type === formTypes.application.abbrev) {
        let diffInDays: number;
        let numberOfDays: number;
        let calDate: any;
        // Anticipated Start Date
        if (startDate && !isNaN(Date.parse(startDate.toString()))) {
          numberOfDays = 182; /** Six months in days */
          // Current date
          calDate = moment(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
          );
          // Retrieve number of days between the dates.
          diffInDays = Math.abs(moment(startDate).diff(calDate, 'days'));
          // Set flag
          diffInDays > numberOfDays && calDate > moment(startDate)
            ? (this.isStartDateValid = false)
            : (this.isStartDateValid = true);
          this.projectStartDateValidator = [
            (control: AbstractControl) => {
              const isValid =
                this.isStartDateValid === true
                  ? null
                  : { error: 'Invalid Start Date' };
              return isValid;
            }
          ];
          this.anticipatedProjectStartDateComp.dateValidator = this.projectStartDateValidator;
        }
        // Anticipated End Date
        if (endDate && !isNaN(Date.parse(endDate.toString()))) {
          let startDateIn3Years: any;
          // The end date can not be greater than 3 years from the start date.
          numberOfDays = 1095; /** 365 days times 3 */
          calDate = moment(this.projectDetails.anticipatedProjectStartDate);

          startDateIn3Years = moment(
            this.projectDetails.anticipatedProjectStartDate
          ).add(3, 'year');

          // Set flag
          startDateIn3Years < endDate
            ? (this.isEndDateValid = false)
            : (this.isEndDateValid = true);
          this.projectEndDateValidator = [
            (control: AbstractControl) => {
              const isValid =
                this.isEndDateValid === true
                  ? null
                  : {
                      error: 'Does not matter what is return when it is false'
                    };
              return isValid;
            }
          ];
          this.anticipatedProjectEndDateComp.dateValidator = this.projectEndDateValidator;
        }
      }
    }
  }
  // #endregion

  // #region "System Methods"
  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
  ngOnInit() {
    this.initializeForm();
  }

  // #endregion
}
