import { Component, OnInit } from '@angular/core';
import { EmailService } from '../../../email/services/email.service';
import { User } from '../../../user/models/user.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalService } from '../../../fastlane-common/services/swal.service';
import { ActivatedRoute } from '@angular/router';
import { UserDataService } from '../../../user/services/user-data.service';

@Component({
  selector: 'fl-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
  emailed: boolean;
  emailing: boolean;
  verificationForm: FormGroup;
  verificationToken: string;
  verified: boolean;

  constructor(
    private emailService: EmailService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private swal: SwalService,
    private userService: UserDataService
  ) {
    this.emailed = false;
    this.emailing = false;
    this.verified = null;
  }

  initializeVerificationForm() {
    this.verificationForm = this.fb.group({
      email: ['', Validators.required]
    });
  }

  ngOnInit() {
    const that = this;
    this.route.params.subscribe(params => {
      that.verificationToken = params['tok'];
      that.userService.verifyUser(that.verificationToken).subscribe(
        res => {
          if (res.isVerified) {
            that.verified = true;
          } else {
            that.verified = false;
            that.swal.error({
              title: 'Unable to verify your account',
              text: res.reason
            });
          }
        },
        error => {
          that.verified = false;
          that.swal.error({
            title: 'Oops!',
            text:
              'An error occurred while verifying your user account. Please try again later.'
          });
        }
      );
    });
  }

  sendAnotherVerificationLink() {
    const that = this;

    this.emailing = true;

    this.emailService
      .resendUserVerificationEmail(this.verificationToken)
      .subscribe(
        success => {
          that.emailing = false;
          that.emailed = true;

          that.swal.success({
            title: 'Email Sent',
            text:
              'Please check your inbox and follow the instructions provided in the email.'
          });
        },
        error => {
          that.emailing = false;
          that.emailed = false;

          that.swal.error({
            title: 'Unable to send email,',
            text: 'Please check that the email address entered is correct and try again.'
          });
        }
      );
  }
}
