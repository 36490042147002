import { BusinessLegalStructure } from '../../../../project/models/business-legal-structure.model';
import { ContractChangeOwnership } from '../../models/contract-change-ownership.model';

export class EnterpriseZoneCao {
  ownersSchedule1: BusinessLegalStructure[];
  affiliatesSchedule2: BusinessLegalStructure[];
  affiliatesSchedule3: BusinessLegalStructure[];
  oldOwnersSchedule1: BusinessLegalStructure[];
  oldAffiliatesSchedule2: BusinessLegalStructure[];
  oldAffiliatesSchedule3: BusinessLegalStructure[];

  constructor(ez?: any) {
    if (!ez) ez = {};
    if (!this.ownersSchedule1) {
      this.ownersSchedule1 = [];
    }
    if (!this.affiliatesSchedule2) {
      this.affiliatesSchedule2 = [];
    }
    if (!this.affiliatesSchedule3) {
      this.affiliatesSchedule3 = [];
    }
    if (!this.oldOwnersSchedule1) {
      this.oldOwnersSchedule1 = [];
    }
    if (!this.oldAffiliatesSchedule2) {
      this.oldAffiliatesSchedule2 = [];
    }
    if (!this.oldAffiliatesSchedule3) {
      this.oldAffiliatesSchedule3 = [];
    }
  }
}
