export class InitialCertCriticalDates {
  relatedApplicationReceivedDate: Date;
  initialCertIssuanceDate: Date;
  eligibleExpenditureStartDate: Date;
  eligibleExpenditureEndDate: Date;
  costReportFinalEligibleDate: Date;
  postProductionCostReportDate: Date;
  principalPhotographyStartDueDate: Date;
  proofofPrincipalPhotographyReceivedDate: Date;
  constructor(obj?: any) {
    obj = obj ? obj : {};
    this.relatedApplicationReceivedDate = obj.relatedApplicationReceivedDate
      ? new Date(obj.relatedApplicationReceivedDate)
      : null;
    this.initialCertIssuanceDate = obj.initialCertIssuanceDate
      ? new Date(obj.initialCertIssuanceDate)
      : null;
    this.eligibleExpenditureStartDate = obj.eligibleExpenditureStartDate
      ? new Date(obj.eligibleExpenditureStartDate)
      : null;
    this.eligibleExpenditureEndDate = obj.eligibleExpenditureEndDate
      ? new Date(obj.eligibleExpenditureEndDate)
      : null;
    this.costReportFinalEligibleDate = obj.costReportFinalEligibleDate
      ? new Date(obj.costReportFinalEligibleDate)
      : null;
    this.postProductionCostReportDate = obj.postProductionCostReportDate
      ? new Date(obj.postProductionCostReportDate)
      : null;
    this.principalPhotographyStartDueDate = obj.principalPhotographyStartDueDate
      ? new Date(obj.principalPhotographyStartDueDate)
      : null;
    this.proofofPrincipalPhotographyReceivedDate = obj.proofofPrincipalPhotographyReceivedDate
      ? new Date(obj.proofofPrincipalPhotographyReceivedDate)
      : null;
  }
}
