import {userType} from './user.constants';
import {chain} from 'underscore';
import {User} from './models/user.model';
import {externalReviewAgencies} from '../project/project.constants';

export function getUserModule(currentUserType: string): string {
  const userTypeToModulePair = chain(userType)
    .pairs()
    .find(pair => pair[1].code.toLowerCase() === currentUserType.toLowerCase())
    .value();

  const module = userTypeToModulePair ? userTypeToModulePair[0] : null

  return module || '';
}

export function getSpecificUserTypeOrAgency(user: User) {
  // If external review agency is set, return it
  if (user.externalReviewAgency) {
    switch (user.externalReviewAgency) {
      case externalReviewAgencies.lga.code:
        return externalReviewAgencies.lga.abbrev.toLowerCase();
      case externalReviewAgencies.ldr.code:
        return externalReviewAgencies.ldr.abbrev.toLowerCase();
      case externalReviewAgencies.lwc.code:
        return externalReviewAgencies.lwc.abbrev.toLowerCase();
      case externalReviewAgencies.led.code:
        // TODO: fix abbrevations to be consistent with constants - Ivan
        return 'led';
    }
  } else {
    return user.userType;
  }
}
