import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LoginComponent } from './core/components/login/login.component';
import { LoggedInComponent } from './core/layouts/logged-in/logged-in.component';
import { RegisterComponent } from './core/components/register/register.component';
import { VerifyComponent } from './core/components/verify/verify.component';
import { PasswordResetComponent } from './security/password/reset/reset.component';
import { PublicReportsComponent } from './core/public-reports/public-reports.component';
import { DocsComponent } from './core/docs/docs.component';
import { HelpComponent } from './core/help/help.component';
import { PublicSearchComponent } from './core/public-search/public-search.component';
import { LandingComponent } from './core/landing/landing.component';
import { DefaultRouteGuard } from './security/guards/default-route.guard';
import { RoleGuard } from './security/guards/role.guard';
import { userType } from './user/user.constants';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FastlaneCommonModule } from './fastlane-common/fastlane-common.module';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2Module } from 'angulartics2';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { PublicEntSearchComponent } from './core/public-ent-search/public-ent-search.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AuthenticationComponent } from './core/components/authentication/authentication.component';
import {AuthenticationDocsComponent} from './core/components/authentication/two-factor-auth-docs/authentication-docs.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FastlaneCommonModule,
    CoreModule,
    RouterModule.forRoot(
      [
        {
          path: '',
          component: LoggedInComponent,
          children: [
            {
              path: '',
              component: LandingComponent,
              canActivate: [DefaultRouteGuard]
            },
            { path: 'login', component: LoginComponent },
            { path: 'authenticate', component: AuthenticationComponent },
            { path: 'auth-documentation', component: AuthenticationDocsComponent},
            { path: 'register', component: RegisterComponent },
            { path: 'verify/:tok', component: VerifyComponent },
            { path: 'password-reset/:tok', component: PasswordResetComponent },
            { path: 'public/reports', component: PublicReportsComponent },
            { path: 'public/search/bi', component: PublicSearchComponent },
            { path: 'public/search/ent', component: PublicEntSearchComponent },
            { path: 'document-checklist', component: DocsComponent },
            { path: 'help', component: HelpComponent }
          ]
        },
        // Each user group has their own wildcard redirect
        {
          path: userType.applicant.route,
          data: {
            rolesAllowed: [userType.applicant.code]
          },
          canActivate: [RoleGuard],
          loadChildren: () => import('./applicant/applicant.module').then(m => m.ApplicantModule)
        },
        {
          path: userType.assessor.route,
          data: {
            rolesAllowed: [userType.assessor.code]
          },
          canActivate: [RoleGuard],
          loadChildren: () => import('./assessor/assessor.module').then(m => m.AssessorModule)
        },
        {
          path: userType.auditor.route,
          data: {
            rolesAllowed: [userType.auditor.code]
          },
          canActivate: [RoleGuard],
          loadChildren: () => import('./auditor/auditor.module').then(m => m.AuditorModule)
        },
        {
          path: userType.board.route,
          data: {
            rolesAllowed: [userType.board.code]
          },
          canActivate: [RoleGuard],
          loadChildren: () => import('./board/board.module').then(m => m.BoardModule)
        },
        {
          path: userType.external.route,
          data: {
            rolesAllowed: [userType.external.code]
          },
          canActivate: [RoleGuard],
          loadChildren: () => import('./external/external.module').then(m => m.ExternalModule)
        },
        {
          path: userType.guest.route,
          loadChildren: () => import('./guest/guest.module').then(m => m.GuestModule)
        },
        {
          path: userType.management.route,
          data: {
            rolesAllowed: [userType.management.code]
          },
          canActivate: [RoleGuard],
          loadChildren: () => import('./management/management.module').then(m => m.ManagementModule)
        },
        // Catch-all wildcard redirect -- all unmatched paths will go to the login component
        { path: '**', redirectTo: '/login', pathMatch: 'full' }
      ],
      { enableTracing: false, scrollPositionRestoration: 'disabled' }
    ),
    Angulartics2Module.forRoot([Angulartics2GoogleAnalytics], {
      pageTracking: {
        clearIds: true,
        idsRegExp: new RegExp(
          '^d+$|^[a-z0-9]{24}$|^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
        ),
        clearQueryParams: true
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.enableServiceWorker,
      // registrationStrategy: 'registerImmediately'

    }),
  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule {}
