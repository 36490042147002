/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./header.component";
import * as i5 from "../../../user/services/user-context.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "li", [["class", "nav-item dropdown d-none"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["aria-expanded", "false"], ["aria-haspopup", "true"], ["class", "fa fa-bell fa-2x topnav dropdown-toggle"], ["data-target", "#boardUserMenu"], ["data-toggle", "dropdown"], ["id", "notifications"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "superscript"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "b", [["class", "badge-info badge"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["8"])), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["aria-labelledby", "notifications"], ["class", "dropdown-menu px-1 dropdown-menu-right animated flipInY"], ["id", "boardUserMenu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [["class", "dropdown-item"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fa fa-envelope fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You have 16 messages "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "a", [["class", "dropdown-item"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "fa fa-twitter fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 3 New Followers "])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "a", [["class", "dropdown-item"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["class", "fa fa-upload fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Server Rebooted "]))], null, null); }
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "ul", [["class", "navbar-nav"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 18, "li", [["class", "nav-item dropdown"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "a", [["aria-expanded", "false"], ["aria-haspopup", "true"], ["class", "dropdown-toggle fa fa-user fa-2x topnav"], ["data-toggle", "dropdown"], ["href", "#"], ["id", "profile"], ["role", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 16, "div", [["aria-labelledby", "profile"], ["class", "dropdown-menu px-2 dropdown-menu-right animated flipInY"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "dropdown-item text-primary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "dropdown-divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "a", [["class", "dropdown-item d-flex align-items-center justify-content-left"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["aria-hidden", "true"], ["class", "fa fa-cogs fa-2x pr-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Account Settings"])), (_l()(), i1.ɵeld(15, 0, null, null, 0, "div", [["class", "dropdown-divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 5, "a", [["class", "dropdown-item d-flex align-items-center justify-content-left"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(18, 1), (_l()(), i1.ɵeld(19, 0, null, null, 0, "div", [["aria-hidden", "true"], ["class", "fa fa-sign-out fa-2x pr-2 mr-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Log Out"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.userContext.currentUser.userType !== "board"); _ck(_v, 2, 0, currVal_0); var currVal_5 = i1.ɵinlineInterpolate(1, "/", _co.routingRoot, "/profile"); _ck(_v, 11, 0, currVal_5); var currVal_8 = _ck(_v, 18, 0, "/login"); _ck(_v, 17, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.userContext.currentUser.emailAddress; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.userContext.currentUser.emailAddress; _ck(_v, 8, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 11).target; var currVal_4 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 17).target; var currVal_7 = i1.ɵnov(_v, 17).href; _ck(_v, 16, 0, currVal_6, currVal_7); }); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "border-bottom w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "nav", [["class", "navbar navbar-static-top white-bg navbar-expand-sm w-100 d-flex justify-content-between"], ["role", "navigation"], ["style", "margin-bottom: 0; "]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", "minimalize-styl-2 btn btn-secondary text-white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleNavigation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-bars"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "topnav p-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.userContext.currentUser.userType !== "nullUser"); _ck(_v, 7, 0, currVal_0); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fl-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 49152, null, 0, i4.HeaderComponent, [i5.UserContextService], null, null)], null, null); }
var HeaderComponentNgFactory = i1.ɵccf("fl-header", i4.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
