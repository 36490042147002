<div class="no-borders d-flex justify-content-center align-items-center animated fadeInUp">
  <div class="d-flex flex-column col-sm-12 col-md-10 col-lg-9 col-xl-6">
    <div class="text-center">
      <h2 class="text-primary">Verify User Account</h2>
    </div>

    <div class="dropdown-divider my-2"></div>

    <p *ngIf="verified == null">Verifying account
      <i class="ml-2 fa fa-spinner fa-spin"></i>
    </p>

    <div *ngIf="verified != null && !verified" id="verificationForm" class="animated fadeInUp" [formGroup]="verificationForm">
      <p>We were unable to verify your account. Please enter your email address and try again. An email will be sent to you with a new verification link.</p>

      <div class='d-flex flex-wrap py-1'>
        <label class="col-12 col-lg-4 form-control-label">Email Address:</label>
        <div class="col-12 col-lg-8">
          <input type="email" class="form-control no-borders" formControlName="email">
        </div>
      </div>

      <div class="col-md-12 col-lg-6 my-2">
        <button type="submit" form="verificationForm" class="btn w-100 font-weight-bold" [ngClass]="{'btn-secondary': !emailing && !emailed, 'btn-warning': emailing, 'btn-success animated pulse': emailed}"
          [disabled]="verificationForm.invalid || emailing || emailed" (click)="register()">
          <span *ngIf="!emailing && !emailed">Send Me Another Link</span>
          <span *ngIf="emailing">Sending
            <i class="ml-2 fa fa-spinner fa-spin"></i>
          </span>
          <span *ngIf="emailed">Sent
            <i class="ml-2 fa fa-check"></i>
          </span>
        </button>
      </div>
    </div>

    <div *ngIf="verified" class="animated fadeInUp text-center">
      <p>Your account has been verified! Click the button below to continue to the login page.</p>
      
      <div class="col-md-12 col-lg-6 my-2 mx-auto">
        <a class="btn btn-primary w-100 font-weight-bold animated pulse" [routerLink]="['/login']">Continue<i class="ml-2 fa fa-arrow-right"></i></a>
      </div>
    </div>
  </div>
</div>