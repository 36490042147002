/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./footer.component";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "footer p-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "d-flex justify-content-between align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "footer-logo p-0 h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", "d-inline-block h-100"], ["href", "https://www.opportunitylouisiana.com/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "Louisiana Economic Development"], ["src", "assets/images/LEDLogo.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "p-0"], ["style", "text-align:right;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["FASTLANE NEXT GENERATION"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "small", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["href", "https://www.opportunitylouisiana.com/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Louisiana Economic Development"]))], null, null); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fl-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i2.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("fl-footer", i2.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
