import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  AbstractControl,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { User } from '../../../user/models/user.model';
import { Subscription } from 'rxjs';
import { extend } from 'underscore';
import { UserDataService } from '../../../user/services/user-data.service';
import { userType } from '../../../user/user.constants';
import { SwalService } from '../../../fastlane-common/services/swal.service';
import { SweetAlertOptions } from 'sweetalert2';
import { PasswordInputComponent } from '../../../security/password/input/new/new.component';
import * as zxcvbn from 'zxcvbn';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'fl-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnDestroy, OnInit {
  @ViewChild(PasswordInputComponent, { static: true })
  passwordInputComponent: PasswordInputComponent;
  registering: boolean;
  registered: boolean;
  registerForm: FormGroup;
  user: User;

  private subs: Subscription[];

  constructor(
    private fb: FormBuilder,
    private userDataService: UserDataService,
    private swalService: SwalService
  ) {
    // This property is set to true when register is clicked and request is made to api -- false when api returns
    this.registering = false;

    // This is set to true when user registers successfully
    this.registered = false;

    // Instantiate form group
    this.registerForm = this.fb.group({});

    // Initialize subcriptions array
    this.subs = [];

    // Initialize user to pass to profile component
    this.user = new User();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  ngOnInit() {}

  register() {
    const that = this;

    // Register process started -- reflect it in the property
    this.registering = true;

    // Before registering set user type as applicant and inactive
    extend(this.user, {
      isActive: false,
      userType: userType.applicant.code,
      password: this.passwordInputComponent.value
    });

    // Send to database for creation
    this.subs.push(
      this.userDataService.registerUser(this.user).subscribe(
        user => {
          this.user.id = user.id;
          that.registering = false;
          that.registered = true;
          that.registerForm.disable();
          that.swalService.success(<SweetAlertOptions>{
            title: 'Account Created!',
            text:
              'Your user account has been created successfully.' +
              'You will receive an email to validate the account before being able to log in.'
          });
        },
        error => {
          let errMsg: string;
          if (error instanceof HttpErrorResponse) {
            errMsg = `${error.status} - ${error.statusText || ''} ${
              error.message
            }`;

            // Let user know if there is a duplicate error.
            if (error.status === 409) {
              that.swalService.error(<SweetAlertOptions>{
                title: 'Duplicate Account',
                text:
                  "An account already exists with the email provided, try the 'Forgot Password' feature."
              });
            }
          } else {
            // Tell the user that an error occurred.
            that.swalService.error(<SweetAlertOptions>{
              title: 'Oops!',
              text:
                'An error occurred while creating your user account. Please try again later.'
            });
          }

          // API request is finished, so we're no longer registering.
          that.registering = false;
        }
      )
    );
  }
}
