/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ite-public-search.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./ite-public-search.component";
import * as i3 from "../../../project/services/project-data.service";
var styles_ItePublicSearchComponent = [i0.styles];
var RenderType_ItePublicSearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ItePublicSearchComponent, data: {} });
export { RenderType_ItePublicSearchComponent as RenderType_ItePublicSearchComponent };
export function View_ItePublicSearchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "pb-2 font-weight-bold text-blue bg-white px-4 pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Board Approved Industrial Tax Exemption Program Projects Results"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "white-bg pt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "table", [["cellspacing", "0"], ["class", "table table-striped table-bordered table-hover"], ["id", "ite-table"], ["width", "100%"]], null, null, null, null, null))], null, null); }
export function View_ItePublicSearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fl-ite-public-search", [], null, null, null, View_ItePublicSearchComponent_0, RenderType_ItePublicSearchComponent)), i1.ɵdid(1, 245760, null, 0, i2.ItePublicSearchComponent, [i3.ProjectDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ItePublicSearchComponentNgFactory = i1.ɵccf("fl-ite-public-search", i2.ItePublicSearchComponent, View_ItePublicSearchComponent_Host_0, { parentSubject: "parentSubject", parentSearchFilters: "parentSearchFilters" }, {}, []);
export { ItePublicSearchComponentNgFactory as ItePublicSearchComponentNgFactory };
