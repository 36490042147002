/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loginNotification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./loginNotification.component";
var styles_LoginNotificationComponent = [i0.styles];
var RenderType_LoginNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginNotificationComponent, data: {} });
export { RenderType_LoginNotificationComponent as RenderType_LoginNotificationComponent };
export function View_LoginNotificationComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_LoginNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shr-loginNotification", [], null, null, null, View_LoginNotificationComponent_0, RenderType_LoginNotificationComponent)), i1.ɵdid(1, 49152, null, 0, i2.LoginNotificationComponent, [], null, null)], null, null); }
var LoginNotificationComponentNgFactory = i1.ɵccf("shr-loginNotification", i2.LoginNotificationComponent, View_LoginNotificationComponent_Host_0, {}, {}, []);
export { LoginNotificationComponentNgFactory as LoginNotificationComponentNgFactory };
