<div class="loginColumns animated fadeInDown">
  <div class="d-flex flex-row flex-wrap justify-content-center">
    
    <div class="col-8 position-relative">
        <img class="" alt="image" src="/assets/images/fng_app.svg" class="m-b">
      <div id="login" class="animated">
        <form class="bg-white p-4 no-validation-border" [formGroup]='loginForm' novalidate>
          <div class="form-group">
            <div class="mb-3">
              <input type="email" class="form-control" id="username" formControlName='username' placeholder="Email" required>
            </div>
            <div class="mb-3">
              <input type="password" class="form-control" id="password" formControlName='password' placeholder="Password" required>
            </div>
            <button type="submit" (click)="login()" class="btn btn-secondary block w-100 m-b" [disabled]='loginForm.invalid'>Log In</button>
          </div>
          <div>
            <a class="btn btn-link text-primary pl-0" (click)="showPasswordResetForm()">
              <small>Forgot password?</small>
            </a>
            <p class="text-muted text-center">
              <small>Do not have an account?</small>
            </p>
            <a class="btn btn-light border border-primary text-center text-primary w-100" [routerLink]="['../register']">Create an account</a>
          </div>
          <div>
            <br>
            <p class="text-muted">
              <small>For assistance contact Louisiana Economic Development at (225)342-3000.</small>
            </p>
            <p class="text-muted">
              <small>If you need help using the Fastlane system, download the
                <a href="assets/images/help/FASTLANE NEXT GENERATION - User Guide.pdf" target="_blank">User Guide.</a>
              </small>
            </p>
          </div>
        </form>
      </div>

      <div id="passwordReset" class="position-absolute h-100 w-100 bg-white d-block animated flipInY" *ngIf="doShowResetPasswordForm">
        <div class="card ">
          <div class="card-body">
            <div class="card-title d-flex justify-content-between align-items-center">
              <div>
                <h5>Password Reset</h5>
              </div>
              <div>
                <button title="Return to login screen" (click)="hidePasswordResetForm()" class="btn btn-link">
                  <i class="fa fa-times fa-2x" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <form class="no-validation-border" novalidate [formGroup]="forgotPasswordFormGroup">

                <div class="row">
                  <div class="col-12">
                    <div class="m-b">
                      <input #resetEmail type="email" class="form-control" placeholder="Email address" formControlName="emailAddress" required
                        email>
                    </div>
                    <button type="submit" (click)="requestPasswordReset(resetEmail.value)" class="btn btn-primary block full-width m-b forgotpassword"
                      [disabled]="forgotPasswordFormGroup.invalid">Send Reset Link</button>
                  </div>
                </div>
                <div>
                  <small>
                    A temporary link will be sent to your inbox. It is important that you use the link to reset the password as it has an expiration
                    date after which you will have to request another password reset liink. After three attempts, the account
                    will be locked for twenty-four hours.
                  </small>
                  <br/>
                  <br/>
                  <p class="text-muted">
                    <small>For assistance, please contact the FastLane Helpdesk or call (225) 342-3000.</small>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
