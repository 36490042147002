export class BusinessIncentiveProjectInfo {
  deptOfLaborInsuranceId: string;
  deptOfRevenueId: string;
  fiscalYear: string;
  naicsCode: string;
  projectDescription: string;
  projectEndDate: Date;
  projectStartDate: Date;
  projectType: string;
  commencementDate: Date;
  isOperationalBeforeCompletion: boolean;
  calendarYears: string[];
  isMultiAddressDescription: string;
  isMultiStructure: boolean;
  isMultiAddress: boolean;

  constructor(info?: any) {
    info = info || {};
    this.deptOfLaborInsuranceId = info.deptOfLaborInsuranceId || null;
    this.deptOfRevenueId = info.deptOfRevenueId || null;
    this.fiscalYear = info.fiscalYear || null;
    this.naicsCode = info.naicsCode || null;
    this.projectDescription = info.projectDescription || null;
    this.projectEndDate = info.projectEndDate
      ? new Date(info.projectEndDate)
      : null;
    this.projectStartDate = info.projectStartDate
      ? new Date(info.projectStartDate)
      : null;
    this.projectType = info.projectType || null;
    this.calendarYears = info.calendarYears || [];
    this.isOperationalBeforeCompletion = info.isOperationalBeforeCompletion ;
    this.commencementDate = info.commencementDate
      ? new Date(info.commencementDate)
      : null;

    this.isMultiAddressDescription = info.isMultiAddressDescription;
    this.isMultiStructure = info.isMultiStructure;
    this.isMultiAddress = info.isMultiAddress;
  }
  }

