import { AuthenticationService } from '../../../security/services/authentication.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UserDataService } from '../../../user/services/user-data.service';
import { SwalService } from '../../../fastlane-common/services/swal.service';
import { SweetAlertOptions } from 'sweetalert2';
import { ToastService } from '../../../fastlane-common/services/toast.service';

declare var $: any;

@Component({
  selector: 'fl-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements AfterViewInit, OnInit {
  public loginForm: FormGroup;
  doShowResetPasswordForm = false;
  forgotPasswordFormGroup: FormGroup;

  constructor(
    private authService: AuthenticationService,
    private dataService: UserDataService,
    private formBuilder: FormBuilder,
    private router: Router,
    private swalService: SwalService,
    private toastr: ToastService
  ) {
    this.forgotPasswordFormGroup = formBuilder.group({
      emailAddress: ''
    });
  }

  login() {
    const that = this;

    // get credential values from login form
    const username = this.loginForm.controls['username'].value;
    const password = this.loginForm.controls['password'].value;

    // use credentials to log in
    this.authService.login(username, password).subscribe(
      isLoggedIn => {
        if (isLoggedIn) {
          if (that.authService.tempSession) {
            that.router.navigate(['authenticate']);
          } else {
            //Redirect if required
            if (that.authService.redirectUrl) {
              that.router.navigate([that.authService.redirectUrl]);
              //this.toastr.sticky({
              //  title: 'REMINDER: ALL COVID-19 RELATED FILING DEADLINE EXTENSIONS WILL EXPIRE ON AUGUST 23, 2020.',
              //  position: 'bottom-left',
              //  timer: 0,
              //});
              // Clear redirect url after redirection
              that.authService.redirectUrl = null;
            } else {
              // TODO: Review this implementation
              that.router.navigate(['/applicant']);
            }
          }

        } else {
          console.log('invalid user data!');
        }
      },
      error => {
        that.swalService
          .error({
            title: 'Unable to authenticate',
            text: 'Please verify your credentials and try again!'
          })
          .catch();
      }
    );
  }

  hidePasswordResetForm() {
    this.doShowResetPasswordForm = false;
    $('#login').addClass('flipInY');
    $('#login').removeClass('d-none');
  }

  ngAfterViewInit() {
    setTimeout(() => this.loginForm.updateValueAndValidity(), 0);
    // automatically focus in on the email input
    $('#username').focus();

    // Clear the token
    this.authService.logout();
    console.log('after view init');
  }

  ngOnInit() {
    // Show First Timer Passwd Reset Msg
    this.showFirstTimePasswordResetMsg();

    // initialize the form for validations
    this.loginForm = this.formBuilder.group({
      username: '',
      password: ''
    });
  }

  requestPasswordReset(emailAddress: string) {
    const that = this;
    this.dataService.resetPassword(emailAddress).subscribe(
      verificationResp => {
        if (verificationResp.isVerified) {
          that.swalService
            .success(<SweetAlertOptions>{
              title: 'Password Request',
              text:
                'The request has been submitted. Expect to receive an email shortly with further instructions to reset your password',
              onClose: element => {
                that.hidePasswordResetForm();
              }
            })
            .catch();
        } else {
          that.swalService
            .success(<SweetAlertOptions>{
              title: 'Password Request Error',
              text: 'Unable to complete the request as specified'
            })
            .catch();
        }
      },
      error => {
        // Tell the user that an error occurred.
        that.swalService
          .error(<SweetAlertOptions>{
            title: 'Oops!',
            text:
              'An error occurred while creating your user account. Please try again later.'
          })
          .catch();
      }
    );
  }

  showPasswordResetForm() {
    this.doShowResetPasswordForm = true;
    $('#login').removeClass('flipInY');
    $('#login').addClass('d-none');
  }

  showFirstTimePasswordResetMsg() {
    const that = this;

    // Has user already seen the message?
    const seen = localStorage.getItem('firstTimePasswdReset');

    if (!seen) {
      this.swalService.warn({
        title: 'Password Reset Required',
        confirmButtonText: 'RESET PASSWORD',
        html:
          '<p class="text-left">If you created a user account in the old version of FastLane, ' +
          'the email address associated with that account and any projects within that account have ' +
          'been migrated to this new system.</p> <p class="text-left">There are no usernames in the new system. Instead, ' +
          'the email address associated with the account will be used to log in. Reset your password by clicking the ' +
          '“forgot password” feature. If you do not receive the “forgot password” email or the email address used' +
          ' on the account is no longer valid, please call the LED support line for assistance.</p>',
        preConfirm: () => {
          that.showPasswordResetForm();
        },
        showCloseButton: true
      }).catch(() => { });
      localStorage.setItem('firstTimePasswdReset', 'true');
      return true;
    }

    return false;
  }
}
