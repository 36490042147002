import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';

import { Project } from '../models/project.model';
import { Payment } from '../../fiscal/models/payment.model';
import {
  ProjectDataService,
  ResolvedProject
} from '../services/project-data.service';
import { ProjectDetailService } from '../services/project-shared.service';

import { Observable, Scheduler, Subscriber } from 'rxjs';
import { AuthenticationService } from '../../security/services/authentication.service';
import { subscribeOn } from 'rxjs/operators';
import { AsyncScheduler } from 'rxjs/internal/scheduler/AsyncScheduler';
import { AsyncAction } from 'rxjs/internal/scheduler/AsyncAction';
import { incentiveCategory, incentiveProgram } from '../project.constants';
import { ProjectBase } from '../models/project-base.model';

@Injectable()
export class ProjectResolver implements Resolve<ResolvedProject> {
  constructor(
    private dataService: ProjectDataService,
    private shareService: ProjectDetailService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ResolvedProject> {
    const id = route.paramMap.get('id');

    // based on what may already be loaded, we need a conditional to see if we need to
    // make a trip to the database or not

    this.shareService.relatedProjectsIds = [];
    return refreshResolvedProject(id, this.dataService, this.shareService);
  }
}

export function getResolvedProject(
  shareService: ProjectDetailService
): ResolvedProject {
  const project = incentiveCategory.business.incentivePrograms.includes(
    shareService.currentIncentiveProgram
  )
    ? shareService.project
    : shareService.currentIncentiveProgram === incentiveProgram.film.code
    ? shareService.filmProject
    : shareService.digitalProject;
  return <ResolvedProject>{
    payments: shareService.payments,
    project: project,
    projectDates: shareService.projectDates
  };
}

export function refreshResolvedProject(
  id: string,
  dataService: ProjectDataService,
  shareService: ProjectDetailService
) {
  return new Observable<ResolvedProject>(subscriber => {
    dataService.resolveProject(id).subscribe(resolvedProject => {
      shareService.setResolvedProject(resolvedProject);
      if (!resolvedProject.project || !resolvedProject.project['projectInfo']) {
        subscriber.complete();
      } else {
        dataService
          .getRelatedProjects(
            resolvedProject.project.projectInfo.year,
            resolvedProject.project.projectInfo.counter,
            resolvedProject.project.projectInfo.incentiveProgram,
            resolvedProject.project.id
          )
          .subscribe(relatedProjects => {
            shareService.relatedProjects = relatedProjects;
            shareService.relatedProjectsIds = relatedProjects.map(
              proj => proj.projectInfo.projectId
            );
            subscriber.next(getResolvedProject(shareService));
            subscriber.complete();
          });
      }
    }, subscriber.complete);
  });
}
