export class EstimatedJobs {
  construction: number;

  /**
   * When in the context of QJ, this field is also referred to as the "baseline"
   *
   * @type {number}
   * @memberof EstimatedJobs
   */
  existing: number;

  new: number;
  numberofEmployeesTransferredFromInState: number;
  numberofEmployeesTransferredFromOutState: number;
  newJobsCurrentPhase: number;
  jobDescription: string;
  selectedPhase: string;
  willCreateNewJobs?: boolean;
  contract: number;
  areExistingJobsRetained?: boolean;
  statewide: number;
  jobsCreatedInPhaseDesc: string;


  constructor(calculations?: any) {
    calculations = calculations || {};

    this.construction = calculations.construction === 0 ? calculations.construction :
      calculations.construction ? calculations.construction : null;

    this.existing = calculations.existing === 0 ? calculations.existing :
      calculations.existing ? calculations.existing : null;

    this.new = calculations.new === 0 ? calculations.new :
      calculations.new ? calculations.new : null;

      this.statewide = calculations.statewide === 0 ? calculations.statewide :
      calculations.statewide ? calculations.statewide : null;

    this.numberofEmployeesTransferredFromInState = calculations.numberofEmployeesTransferredFromInState === 0 ?
      calculations.numberofEmployeesTransferredFromInState :
      calculations.numberofEmployeesTransferredFromInState ? calculations.numberofEmployeesTransferredFromInState : null;

    this.numberofEmployeesTransferredFromOutState = calculations.numberofEmployeesTransferredFromOutState === 0 ?
      calculations.numberofEmployeesTransferredFromOutState :
      calculations.numberofEmployeesTransferredFromOutState ? calculations.numberofEmployeesTransferredFromOutState : null;

    this.newJobsCurrentPhase = calculations.newJobsCurrentPhase === 0 ? calculations.newJobsCurrentPhase :
      calculations.newJobsCurrentPhase ? calculations.newJobsCurrentPhase : null;

      this.contract = calculations.contract === 0 ? calculations.contract :
      calculations.contract ? calculations.contract : null;

      this.jobsCreatedInPhaseDesc = calculations.jobsCreatedInPhaseDesc;
      this.areExistingJobsRetained = calculations.areExistingJobsRetained;
      this.willCreateNewJobs = calculations.willCreateNewJobs;


  }
}
