import { ComplianceForm } from './compliance-form.model';
import { CostBreakDown } from './cost-break-down.model';
import { RestorationDetails } from './restoration-details.model';
import { formTypes } from 'app/form/form.constants';

export class AffidavitFinalCost extends ComplianceForm {
  costBreakdowns: CostBreakDown[];
  restrictedAmount: number;
  restorationDetails: RestorationDetails;
  buildingTotal: number;
  equipmentTotal: number;
  investmentTotal: number;
  constructor(formIndex: number = null) {
    super(formIndex);
    this.init();
  }

  init() {
    super.init();
    if (!this.costBreakdowns) {
      this.costBreakdowns = [];
    }
    if (!this.restorationDetails) {
      this.restorationDetails = new RestorationDetails();
    }
    if (!this.restrictedAmount) {
      this.restrictedAmount = 0;
    }
    this.type = formTypes.afc.abbrev;
  }

  get qualifier() {
    switch (this.formIndex) {
      case 0:
        return '';
      default:
        return 'Amendment';
    }
  }
}
