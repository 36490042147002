import { AuditDepositCreator } from '../../interfaces/audit-deposit-creator.interface';
import { FormIdentifier } from '../../../project/models/form-identifier.model';
import { formTypes } from '../../../form/form.constants';
import { InitialCertification } from './initial-certification.model';
import { AuditInfo } from '../../../entertainment/interfaces/audit-info.interface';
import { Expenditures } from './expenditures.model';
import { ExpenditureNotes } from './expenditure-notes.model';
import { Jobs } from './jobs.model';
import { Days } from './days.model';
import { FinalCertificationRecipient } from './final-certification-recipient.model';
import { OutgoingPayment } from '../../../fiscal/models/outgoing-payment.model';
import { AuditForm } from '../../interfaces/audit-form.interface';
import { ActualExpenditureYearBreakdown } from './actual-expenditure-year-breakdown.model';
import { AuditCriticalDates } from './audit-critical-dates.model';

/**
 * @summary This Form contains audit information with regards to the current project
 */
export class Audit extends InitialCertification
  implements AuditDepositCreator, AuditInfo, AuditForm {
  auditingCompanyId: string;
  actualExpenditureYearBreakdown: ActualExpenditureYearBreakdown[];
  auditedExpenditures: Expenditures;
  qualifiedExpenditures: Expenditures;
  notQualifiedExpenditures: Expenditures;
  auditorPayments: OutgoingPayment[];
  notes: ExpenditureNotes;
  actualDays: Days;
  actualJobs: Jobs;
  finalCertificationRecipient: FinalCertificationRecipient;
  totalCreditsIssued?: number;
  totalCreditsDeferred?: number;
  totalDeferredCreditsGranted?: number;
  isAttestationSent?: boolean;
  isFinalCertSent?: boolean;
  isAttestationExecuted?: boolean;
  auditCriticalDates: AuditCriticalDates;
  careerLearningNotes: string;
  /**
   * @summary Returns the form identifier of this Audit. Unlike the application which returns the formId of the
   * matching audit.
   */
  get auditFormId(): FormIdentifier {
    return <FormIdentifier>{
      formIndex: this.formIndex,
      formType: this.type
    };
  }

  constructor(formIndex: number = null) {
    super();
    this.formIndex = formIndex;
  }

  /**
   * @summary Ensure all properties are set for rendering purposes
   * @param breakdown
   */
  addMissingBreakdownDetails(breakdown: ActualExpenditureYearBreakdown[]) {
    breakdown.forEach(b => {
      // Does it have auditedExpenditures?
      if (!b.auditedExpenditures) {
        b.auditedExpenditures = new Expenditures();
      }

      // Does it have notQualifiedExpenditures?
      if (!b.notQualifiedExpenditures) {
        b.notQualifiedExpenditures = new Expenditures();
      }

      // Does it have qualifiedExpenditures?
      if (!b.qualifiedExpenditures) {
        b.qualifiedExpenditures = new Expenditures();
      }

      // Does it have notes?
      if (!b.notes) {
        b.notes = new ExpenditureNotes();
      }
    });
  }

  init(): Audit {
    super.init();
    this.type = formTypes.audit.abbrev;
    this.auditedExpenditures = new Expenditures(this.auditedExpenditures);
    this.qualifiedExpenditures = new Expenditures(this.qualifiedExpenditures);
    this.notQualifiedExpenditures = new Expenditures(
      this.notQualifiedExpenditures
    );
    this.notes = new ExpenditureNotes(this.notes);
    this.actualDays = this.actualDays || new Days();
    this.actualJobs = this.actualJobs || new Jobs();
    this.finalCertificationRecipient =
      this.finalCertificationRecipient || new FinalCertificationRecipient();
    this.totalCreditsIssued =
      this.totalCreditsIssued >= 0 ? this.totalCreditsIssued : null;
    this.totalCreditsDeferred =
      this.totalCreditsDeferred >= 0 ? this.totalCreditsDeferred : null;
    this.totalDeferredCreditsGranted =
      this.totalDeferredCreditsGranted >= 0
        ? this.totalDeferredCreditsGranted
        : null;

    this.auditorPayments = this.auditorPayments || [];
    this.actualExpenditureYearBreakdown =
      this.actualExpenditureYearBreakdown || [];

    // Ensure breakdown has complete properties for migrated data and rendering
    this.addMissingBreakdownDetails(this.actualExpenditureYearBreakdown);
    this.auditCriticalDates = this.auditCriticalDates
      ? this.auditCriticalDates
      : new AuditCriticalDates(this.auditCriticalDates);

    return this;
  }

  get laExpenditure(): number {
    return this.expenditures.laExpenditure;
  }

  set laExpenditure(value: number) {
    this.expenditures.laExpenditure = value;
  }

  get qualifier() {
    if (this.productionDetails.season) {
      return `Season : ${this.productionDetails.season}`;
    }
    return '';
  }

  get season() {
    return this.productionDetails.season;
  }
}
