import { throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../security/services/authentication.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { httpResponseCodes } from '../../shared/shared.constants';
import * as i0 from "@angular/core";
import * as i1 from "../../security/services/authentication.service";
import * as i2 from "@angular/common/http";
export class ApplicationService {
    constructor(authService, httpClient) {
        this.authService = authService;
        this.httpClient = httpClient;
        this.convertDigitalSpreadsheetToJsonUrl = 
        // environment.apiUrl +
        // '/file/process/expenditure/file?file=:file&legislation=:legislation';
        environment.apiUrl + '/file/process/expenditure/file';
    }
    // #region "Custom Methods"
    /**
     * @summary Call the lookup service to retrieve the audit company name
     * @param spreadsheetFile Expenditure spreadsheet template.
     * @param legislation ProjectInfo
     */
    convertDigitalSpreadsheetToJson(spreadsheetFile, legislation) {
        const _formData = new FormData();
        _formData.append('file', spreadsheetFile, spreadsheetFile.name);
        _formData.append('legislation', legislation);
        const data = this.httpClient
            .post(`${this.convertDigitalSpreadsheetToJsonUrl}`, _formData, this.authService.getAuthOptionsForHttpClient())
            .pipe(map(this.extractData))
            .pipe(catchError(this.handleError));
        return data;
    }
    extractData(res) {
        return res;
    }
    handleError(error) {
        // In a real world app, you might use a remote logging infrastructure
        let errMsg;
        if (error instanceof HttpErrorResponse) {
            errMsg = `${error.status} - ${error.statusText || ''} ${error.message}`;
        }
        else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.log('Fantastic, it does not work!!');
        console.error(errMsg);
        if (error.status === httpResponseCodes.dataConflict) {
            return throwError(error.status);
        }
        else {
            return throwError(errMsg);
        }
    }
}
ApplicationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApplicationService_Factory() { return new ApplicationService(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.HttpClient)); }, token: ApplicationService, providedIn: "root" });
