<div class="mb-4" [ngClass]="{ 'ng-invalid': invalidObservable | async }">
  <div class="card">
    <div class="card-header"><b>PROGRAMMING LANGUAGES</b></div>
    <div class="card-body px-5 mb-0">
      <div class="form-group form-row py-2 px-1">
        <label class="form-control-label col-12 col-md-2"
          >Programming Language:</label
        >
        <input
          type="text"
          class="form-control col-12 col-md-9"
          data-toggle="tooltip"
          data-placement="top"
          [readOnly]="readOnly"
          #programmingLanguage
        />&nbsp;&nbsp;
        <button
          type="button"
          class="btn btn-primary block"
          [disabled]="readOnly"
          (click)="addProgrammingLanguage(programmingLanguage)"
        >
          Add
        </button>
      </div>
      <div class="form-group form-row col-12 py-2 px-1" >
        <table
          class="table table-striped table-bordered table-hover"
          cellspacing="0"
          width="100%"

        >
          <thead>
            <tr>
              <td>Programming Language</td>
              <td *ngIf="!readOnly">Actions</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of programmingLanguages; let i = index">
              <td>{{ row }} </td>
              <td *ngIf="!readOnly">
                <button class="btn btn-link">
                  <i
                    class="fas fa-trash-alt"
                    (click)="deleteProgrammingLanguage(i)"
                  >
                  </i>
                </button>
              </td>
            </tr>
            <tr *ngIf="programmingLanguages.length === 0">
              <td class="text-center text-warning" colspan="7">
                <i class="fal fa-exclamation-triangle mr-1"></i>No Data Found
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
