import { FormIdentifier } from './form-identifier.model';

export class ProjectDate {
  id: string;
  type: string;
  formId: FormIdentifier;
  category: string;
  date: Date;
  fulfilled: Date;
  fulfiller: string;
  received: Date;
  projectId: string;
  created: Date;
  creator: string;
  updated: Date;
  updater: string;
  constructor(date?: any) {
    if (!date) {
      date = {};
    }
    this.type = date.type ? date.type : '';
    this.date = date.date ? new Date(date.date) : null;
    this.formId = date.formId || null;
    this.category = date.category ? date.category : '';
    this.fulfilled = date.dateFulfilled ? date.dateFulfilled : null;
    this.received = date.received ? date.received : null;
    this.projectId = date.projectId ? date.projectId : '';
  }
}
