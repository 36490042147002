import { ComplianceForm } from './compliance-form.model';
import { formTypes } from 'app/form/form.constants';
export class EmployeeCertificationReport extends ComplianceForm {
  baseline?: number;
  certifiedDate?: Date;
  taxCreditsEarned?: number;
  year?: number;

  // Average Calculations
  netFullTime?: number;
  reportingMonthsCount?: number;
  netFullTimeAverage?: number;
  previousAnnualAverage?: number;
  netNew?: number; // calculated field
  applicablePercentage?: number; // calculated field
  netNewCertifiedMinimum?: number; // calculated field

  // New Employees Hired
  newHired?: number;
  newHiredWithLaLicense?: number;

  // New Employees Certification Requirements
  qualifiedPublicAssistance?: number;
  otherPublicAssistance?: number;
  lacksSkill?: number;
  unemployable?: number;
  resident?: number;
  certified?: number; // calculated field
  uncertified?: number;

  constructor(formIndex: number = null) {
    super(formIndex);
    this.init();
  }

  init() {
    super.init();

    this.type = formTypes.ecr.abbrev;
    this.certifiedDate = this.certifiedDate
      ? new Date(this.certifiedDate)
      : null;
  }

  get qualifier() {
    return `Year: ${this.year}`;
  }
}
