import {ProjectForm} from './form.model';
import { formTypes } from 'app/form/form.constants';

export type BalanceAdjustmentRequestType = 'payment' | 'refund';

export class BalanceAdjustmentRequest extends ProjectForm {
  adjustmentAmount: number = null;
  adjustmentSubtype: string = null;
  adjustmentType: BalanceAdjustmentRequestType = null;
  comments: string = null;

  constructor(formIndex: number = null) {
    super(formIndex);
    this.init();
  }

  init(): BalanceAdjustmentRequest {
    super.init();

    this.type = formTypes.balanceAdjustmentRequest.abbrev;

    return this;
  }

  get qualifier() {
    return '';
  }
}
