export class FeeRange {
  max: number;
  min: number;
  constructor(params?: any) {
    if (!params) {
      params = {};
    }
    this.max = params.max || 0;
    this.min = params.min || 0;
  }
}
