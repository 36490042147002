export class EstimatedDates {
  costReportSubmissionDate: Date;
  photographyStartDate: Date;
  photographyEndDate: Date;
  isPostProductionInLA: boolean;
  postProductionStartDate: Date;
  postProductionEndDate: Date;

  constructor(obj?: any) {
    if (!obj) {
      obj = {};
    }
    this.costReportSubmissionDate = obj.costReportSubmissionDate
      ? new Date(obj.costReportSubmissionDate)
      : null;
    this.photographyStartDate = obj.photographyStartDate
      ? new Date(obj.photographyStartDate)
      : null;
    this.photographyEndDate = obj.photographyEndDate
      ? new Date(obj.photographyEndDate)
      : null;
    this.postProductionStartDate = obj.postProductionStartDate
      ? new Date(obj.postProductionStartDate)
      : null;
    this.postProductionEndDate = obj.postProductionEndDate
      ? new Date(obj.postProductionEndDate)
      : null;
  }
}
