import { Person } from '../../shared/models/person.model';

export class Contact extends Person {
  public companyName: string = null;
  public contactType: string = null;
  public role: string = null;
  public title: string = null;

  constructor() {
    super();
  }

  init() {
    super.init();
    this.role = this.role || null;
    this.title = this.title || null;
    this.companyName = this.companyName || null;
    this.contactType = this.contactType || null;
    return this;
  }
}
