import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { parishes } from '../../shared.constants';
import { NgMultiSelectOption } from '../../interfaces/ng-multi-select-option.interface';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'shr-parish-multiselect',
  templateUrl: './parish-multiselect.component.html',
  styleUrls: ['./parish-multiselect.component.scss']
})
export class ParishMultiselectComponent implements OnInit {
  //#region Settings
  dropdownSettings = {
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  //#endregion

  @Input()
  disabled = false;
  @Output()
  change: EventEmitter<NgMultiSelectOption[]> = new EventEmitter<NgMultiSelectOption[]>();

  // Get list of parishes from constants
  parishes: NgMultiSelectOption[] = parishes.map(p => {
    return {
      id: p.name,
      text: p.name
    };
  });

  selectedParishes: string[] = [];
  selectedParishObjects: NgMultiSelectOption[] = [];

  constructor() {}

  ngOnInit() {}

  onSelectionChange(parish: NgMultiSelectOption, event: 'select' | 'deselect') {
    // Handle appropiate selection event to modify the selectedParishes array.
    if (event === 'select') {
      this.selectedParishes.push(parish.text);
    } else {
      this.selectedParishes.splice(
        this.selectedParishes.indexOf(parish.text),
        1
      );
    }

    // Emit new parishes
    this.change.emit(this.selectedParishObjects);
  }

  onSelectAll(selectedParishes: NgMultiSelectOption[]) {
    this.selectedParishes = selectedParishes.map(sp => sp.text);

    this.selectedParishObjects.splice(0);
    this.selectedParishObjects.push(...selectedParishes);
    // Emit new parishes
    this.change.emit(this.selectedParishObjects);
  }

  parseSelectedParishes(selectedParishes: string[]) {
    // Parse Parishes to comply with ng-multiselect ngModel
    if (selectedParishes && selectedParishes.length > 0) {
      this.selectedParishObjects = selectedParishes.map(p => {
        return {
          id: p,
          text: p
        };
      });
    } else {
      this.selectedParishObjects = [];
    }

    // Set selected parishes property
    if (selectedParishes) {
      this.selectedParishes = selectedParishes;
    } else {
      this.selectedParishes = [];
    }
  }
}
