<div class="card h-100 border">
  <div *ngIf="!isAudit" class="card-header"><b>ESTIMATED PRINCIPAL PHOTOGRAPHY DAYS</b></div>
  <div *ngIf="isAudit" class="card-header"><b>ACTUAL PRINCIPAL PHOTOGRAPHY DAYS</b></div>

  <div
    class="card-body form-group px-3 mb-0 d-flex flex-wrap flex-column justify-content-between no-borders"
    [formGroup]="daysForm"
  >
    <div class="row py-1 w-100 mx-0">
      <label class="col-12 col-xl-6 form-control-label pr-0"
        >Total Estimated Days in Louisiana:</label
      >
      <div class="col-12 col-xl-6 float-right">
        <input
          id="totalDaysInLA"
          formControlName="totalDaysInLA"
          type="number"
          class="form-control"
          pattern="[0-9]+"
          placeholder="0"
        />
      </div>
    </div>

    <div class="row py-1 w-100 mx-0">
      <label class="col-12 col-xl-6 form-control-label pr-0"
        >Total Days of Principal Photography in Louisiana:</label
      >
      <div class="col-12 col-xl-6 float-right">
        <input
          id="totalPrincipalPhotographyDaysInLA"
          formControlName="totalPrincipalPhotographyDaysInLA"
          type="number"
          class="form-control"
          pattern="[0-9]+"
          placeholder="0"
        />
      </div>
    </div>

    <div class="row py-1 w-100 mx-0">
      <label class="col-12 col-xl-6 form-control-label pr-0"
        >Total Days of Principal Photography in Louisiana outside of the New
        Orleans MSA:</label
      >
      <div class="col-12 col-xl-6 float-right">
        <input
          id="totalPhotographyDaysOutside"
          formControlName="totalPhotographyDaysOutside"
          type="number"
          class="form-control"
          pattern="[0-9]+"
          placeholder="0"
        />
      </div>
    </div>

    <div class="row py-1 w-100 mx-0">
      <label class="col-12 col-xl-6 form-control-label pr-0"
        >Percentage of Principal Photography in Louisiana outside of the New
        Orleans MSA:</label
      >
      <div class="col-12 col-xl-6 float-right">
        {{
          days.totalPrincipalPhotographyDaysInLA === 0 ||
          !days.totalPrincipalPhotographyDaysInLA
            ? (0 | percent: '1.0-2')
            : (days.percentPhotographyDaysOutside | percent: '1.0-2')
        }}
      </div>
    </div>
  </div>
</div>
