import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './guards/auth.guard';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { PasswordResetComponent } from './password/reset/reset.component';
import { PasswordInputComponent } from './password/input/new/new.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StrengthMeterComponent } from './password/strength-meter/strength-meter.component';
import { PasswordFeedbackComponent } from './password/password-feedback/password-feedback.component';
import { StrongPasswordValidatorDirective } from './password/strong-password.directive';
import { RoleGuard } from './guards/role.guard';
import { DefaultRouteGuard } from './guards/default-route.guard';
import { PermissionsGuard } from './guards/permissions.guard';
import { ProjectAccessGuard } from './guards/project-access.guard';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [
    PasswordResetComponent,
    PasswordInputComponent,
    StrengthMeterComponent,
    PasswordFeedbackComponent,
    StrongPasswordValidatorDirective
  ],
  exports: [PasswordResetComponent, PasswordInputComponent],
  providers: [AuthGuard, CanDeactivateGuard, RoleGuard, DefaultRouteGuard, PermissionsGuard, ProjectAccessGuard]
})
export class SecurityModule {}
