<div id="filter" class="d-flex flex-column">
  <div class="bg-white px-4">
    <div class="pb-2 font-weight-bold text-blue bg-white">
      <h1 class="">Public Search</h1>
    </div>

    <!-- Report Type Row -->
    <div class="row align-items-center pr-3 pb-3">
      <div class="my-1 col-12 col-md-3 d-flex align-items-center">
        <label class="form-control-label">Criteria:</label>
        <i class="fa fa-info-circle fa-2x pl-2" aria-hidden="true" data-toggle="tooltip" data-placement="right"
           title="Search FastLane for Advance Notifications or Board Approved Projects received by LED. By default, the search will display all advances received within last 90 days for all available incentive programs. Searches can be refined by filtering on criteria such as parish, region, and date ranges. Additionally, keyword searching on data elements within the results is available. If you want to find a specific company, address, city, or NAICS the keyword search is ideal.   Additional details are available to view on each project when clicked. Results are exportable to csv, excel, or pdf including the additional data not shown in search result columns."></i>
      </div>

      <div class="w-100"></div>

      <div class="col-12 col-md-5 align-items-center">
        <select class="form-control" [(ngModel)]="searchFilters.reportType">
          <option *ngFor="let report of reportTypes" [ngValue]="report.abbrev">{{ report.name }}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="mt-0 bg-white">
    <div class="bg-white px-4">
      <!-- Incentive Program For Board Approved Applications -->
      <div *ngIf="searchFilters.reportType !== publicReportTypesLocal.advance.abbrev"
           class="row align-items-center m-t pr-3">
        <div class="col-12 col-md-3">
          <label class="form-control-label">Incentive Program:</label>
        </div>

        <div class="w-100"></div>

        <div class="col-12 col-md-5 align-items-center">
          <select class="form-control" [(ngModel)]="selectedBoardReportType">
            <option *ngFor="let report of publicBoardApprovedReports" [ngValue]="report.abbrev">{{ report.name }}</option>
          </select>
        </div>
      </div>

      <!-- Incentive Program Row -->
      <div *ngIf="searchFilters.reportType === publicReportTypesLocal.advance.abbrev"
           class="row align-items-center m-t pr-3">
        <div class="col-12 col-md-3 ">
          <label class="form-control-label">Incentive Program:</label>
        </div>

        <div class="w-100"></div>

        <div class='col-9 align-items-center'>
          <shr-checkbox [controlId]="'ez'" [label]="'EZ'" [(checked)]="programs.ez"></shr-checkbox>
          <shr-checkbox [controlId]="'qj'" [label]="'QJ'" [(checked)]="programs.qj"></shr-checkbox>
          <shr-checkbox [controlId]="'rta'" [label]="'RTA'" [(checked)]="programs.rta"></shr-checkbox>
          <shr-checkbox [controlId]="'ite'" [label]="'ITE'" [(checked)]="programs.ite"></shr-checkbox>
        </div>
      </div>

      <!-- Program Status Row -->
      <div class="row align-items-center m-t pr-3">
        <div class="col-12 col-md-3">
          <label class="form-control-label">Program Status:</label>
        </div>

        <div class="w-100"></div>

        <div class='col-12 col-md-5'>
          <fl-shr-project-status-multiselect
            (change)="filterDataTableByProjectStatus($event)"></fl-shr-project-status-multiselect>
        </div>
      </div>

      <!-- Parish Selection Row -->
      <div class="row align-items-center m-t pr-3">
        <div class="col-12 col-md-3">
          <label class="form-control-label">Parish:</label>
        </div>

        <div class="w-100"></div>

        <div class='col-12 col-md-5'>
          <shr-parish-multiselect (change)="filterDataTableByParish($event)"></shr-parish-multiselect>
        </div>
      </div>

      <!-- Region Selection Row -->
      <div class="row align-items-center m-t pr-3">
        <div class="col-12 col-md-3">
          <label class="form-control-label">Region:</label>
        </div>

        <div class="w-100"></div>

        <div class='col-12 col-md-5'>
          <shr-region-multiselect (change)="filterDataTableByRegion($event)"></shr-region-multiselect>
        </div>
      </div>

      <!-- Advance Received Filter -->
      <div *ngIf="searchFilters.reportType === publicReportTypesLocal.advance.abbrev"
           class='row align-items-center m-t pr-3'>
        <div class="col-12 col-md-3">
          <label class="form-control-label">Advance Received Date:</label>
        </div>

        <div class="w-100"></div>

        <div class='col-12 col-md-5'>
          <fl-date-range-picker [elementId]="'advance-received-range'"
                                [(selectedDateRange)]="searchFilters.advanceReceivedDateRange"
                                [show90Days]="true"></fl-date-range-picker>
        </div>
      </div>

      <div *ngIf="searchFilters.reportType !== publicReportTypesLocal.advance.abbrev" class="d-flex flex-wrap">

        <!-- Application Receive Range Group -->
        <div class="col-12 col-md-6 col-xl-3 m-t pl-0 flex-row">
          <div class="pl-0">
            <label class="form-control-label">Application Received Date:</label>
          </div>

          <div class='px-0 col-12'>
            <fl-date-range-picker [elementId]="'application-received-range'"
                                  [(selectedDateRange)]="searchFilters.applicationReceivedDateRange"></fl-date-range-picker>

          </div>
        </div>

        <!-- Board Approval Date -->
        <div class="col-12 col-md-6 col-xl-3 m-t pl-0 flex-row">
          <div class="pl-0">
            <label class="form-control-label">Board Approval Date:</label>
          </div>

          <div class='px-0 col-12'>
            <fl-date-range-picker [elementId]="'boardApprovalDateRange'"
                                  [(selectedDateRange)]="searchFilters.boardApprovalDateRange"></fl-date-range-picker>

          </div>
        </div>

        <div class="w-100"></div>

        <!-- Contract Effective Date -->
        <div class="col-12 col-md-6 col-xl-3 m-t pl-0 flex-row mr-1">
          <div class="pl-0">
            <label class="form-control-label">Contract Effective Date:</label>
          </div>

          <div class='px-0 col-12'>
            <fl-date-range-picker [elementId]="'contractEffectiveDateRange'"
                                  [(selectedDateRange)]="searchFilters.contractEffectiveDateRange"></fl-date-range-picker>

          </div>
        </div>

        <!-- Contract Expiration Filter -->
        <div class="col-12 col-md-6 col-xl-3 m-t pl-0 flex-row mr-1">
          <div class="pl-0">
            <label class="form-control-label">Contract Expiration Date:</label>
          </div>

          <div class='px-0 col-12'>
            <fl-date-range-picker [elementId]="'contractExpirationDateRange'"
                                  [(selectedDateRange)]="searchFilters.contractExpirationDateRange"></fl-date-range-picker>

          </div>
        </div>

        <div class="w-100"></div>

        <!-- Renewal Contract Effective Filter -->
        <div class="col-12 col-md-6 col-xl-3 m-t pl-0 flex-row mr-1">
          <div class="pl-0">
            <label class="form-control-label">Renewal Contract Effective Date:</label>
          </div>

          <div class='px-0 col-12'>
            <fl-date-range-picker [elementId]="'renewalEffectiveDateRange'"
                                  [(selectedDateRange)]="searchFilters.renewalEffectiveDateRange"></fl-date-range-picker>

          </div>
        </div>

        <!-- Renewal Contract Expiration Filter -->
        <div class="col-12 col-md-6 col-xl-3 m-t pl-0 flex-row mr-1">
          <div class="pl-0">
            <label class="form-control-label">Renewal Contract Expiration Date:</label>
          </div>

          <div class='px-0 col-12'>
            <fl-date-range-picker [elementId]="'renewalExpirationDateRange'"
                                  [(selectedDateRange)]="searchFilters.renewalExpirationDateRange"></fl-date-range-picker>

          </div>
        </div>

        <div class="w-100"></div>

        <!-- Renewal Application Received Date Filter -->
        <div class="col-12 col-md-6 col-xl-3 m-t pl-0 flex-row mr-1">
          <div class="pl-0">
            <label class="form-control-label">Renewal Application Received Date:</label>
          </div>

          <div class='px-0 col-12'>
            <fl-date-range-picker [elementId]="'renewalReceivedDateRange'"
                                  [(selectedDateRange)]="searchFilters.renewalReceivedDateRange"></fl-date-range-picker>
          </div>
        </div>

      </div>

      <div class="row pl-3 pb-4">
        <!-- Search Button -->
        <div id="search-button" class="m-t mr-2">
          <button (click)='sendFilters()' class="btn btn-primary d-block">
            <span class="ml-1">Search</span>
          </button>
        </div>

        <!-- Clear Button -->
        <div id="clear-button" class="m-t">
          <button (click)='clearFilters()' class="btn btn-primary d-block">
            <span class="ml-1">Clear</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="searchFilters.reportType === publicReportTypesLocal.advance.abbrev">
  <fl-advance-public-search [parentSubject]="filtersSubject"
                            [parentSearchFilters]="searchFilters"></fl-advance-public-search>
</div>

<div
  *ngIf="selectedBoardReportType === publicBoardApprovedReportsLocal.ez.abbrev && searchFilters.reportType !== publicReportTypesLocal.advance.abbrev">
  <fl-ez-public-search [parentSubject]="filtersSubject" [parentSearchFilters]="searchFilters"></fl-ez-public-search>
</div>

<div
  *ngIf="selectedBoardReportType === publicBoardApprovedReportsLocal.ite.abbrev && searchFilters.reportType !== publicReportTypesLocal.advance.abbrev">
  <fl-ite-public-search [parentSubject]="filtersSubject" [parentSearchFilters]="searchFilters"></fl-ite-public-search>
</div>

<div
  *ngIf="selectedBoardReportType === publicBoardApprovedReportsLocal.qj.abbrev && searchFilters.reportType !== publicReportTypesLocal.advance.abbrev">
  <fl-qj-public-search [parentSubject]="filtersSubject" [parentSearchFilters]="searchFilters"></fl-qj-public-search>
</div>

<div
  *ngIf="selectedBoardReportType === publicBoardApprovedReportsLocal.rta.abbrev && searchFilters.reportType !== publicReportTypesLocal.advance.abbrev">
  <fl-rta-public-search [parentSubject]="filtersSubject" [parentSearchFilters]="searchFilters"></fl-rta-public-search>
</div>