<div class="footer p-3">

  <div class="d-flex justify-content-between align-items-center">

    <div class="footer-logo p-0 h-100">
      <a href="https://www.opportunitylouisiana.com/" target="_blank" class='d-inline-block h-100'>
        <img src="assets/images/LEDLogo.svg" alt='Louisiana Economic Development' />
      </a>
    </div>

    <div class="p-0" style="text-align:right;">
    
      
        <b>FASTLANE NEXT GENERATION</b><br />
            <small>
        <a href="https://www.opportunitylouisiana.com/" target="_blank">Louisiana Economic Development</a>
      </small>
   
    </div>

  </div>

</div>
