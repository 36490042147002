<div class="mb-4" [ngClass]="{ 'ng-invalid': invalidObservable | async }">
  <div class="card">
    <div class="card-header"><b>PROJECT PHASES/STAGES</b></div>
    <div class="card-body px-5">
      <div class="form-row">
        <div class="form-group col-md-6 py-2 px-1">
          <label class="form-control-label">Stage/Phase:</label>
          <input
            type="text"
            class="form-control"
            data-toggle="tooltip"
            data-placement="top"
            [readOnly]="readOnly"
            #stagePhase
          />
        </div>
        <div class="form-group col-md-6 py-2 px-1">
          <label class="form-control-label">Length (in weeks):</label>
          <input
            type="number"
            max="52"
            min="0"
            class="form-control"
            data-toggle="tooltip"
            data-placement="top"
            [readOnly]="readOnly"
            #duration
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6 py-2 px-1">
          <label class="form-control-label">Activities to be completed:</label>
          <input
            type="text"
            class="form-control"
            data-toggle="tooltip"
            data-placement="top"
            [readOnly]="readOnly"
            #info
          />
        </div>
        <div class="form-group col-md-6 py-2 px-1">
          <label class="form-control-label"
            >If concurrent with another phase, specify which phase:</label
          >
          <input
            type="text"
            class="form-control"
            data-toggle="tooltip"
            data-placement="top"
            [readOnly]="readOnly"
            #concurrentPhase
          />
        </div>
      </div>
      <div class="form-group form-row col-md-6 py-2 px-1">
        <button
          type="button"
          class="btn btn-primary block"
          [disabled]="readOnly"
          (click)="addStage(stagePhase, duration, info, concurrentPhase)"
        >
          Add
        </button>
      </div>
      <div class="form-group form-row col-12 col-md-12 py-2 px-1">
        <table
          class="table table-striped table-bordered table-hover"
          cellspacing="0"
          width="100%"
        >
          <thead>
            <tr>
              <td>Stage/Phase</td>
              <td>Activities to be completed</td>
              <td>Length</td>
              <td>Concurrent Phase</td>
              <td *ngIf="!readOnly">Actions</td>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let row of stages; let i = index; trackBy: trackElement"
            >
              <td>{{ row.name }}</td>
              <td>{{ row.info }}</td>
              <td>{{ row.duration }}</td>
              <td>{{ row.concurrentPhase }}</td>
              <td *ngIf="!readOnly">
                <button class="btn btn-link">
                  <i class="fas fa-trash-alt" (click)="deleteStage(i)"> </i>
                </button>
              </td>
            </tr>
            <tr *ngIf="stages.length === 0">
              <td class="text-center text-warning" colspan="7">
                <i class="fal fa-exclamation-triangle mr-1"></i>No Data Found
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
