import { BoardAgendaFormSummary } from './board-agenda-form-summary.model';

export class BoardAgendaProgramSummary {
  incentiveProgram: string;
  formSummaries: BoardAgendaFormSummary[];

  constructor(incentiveProgram: any, formGroups: any[]) {
    this.incentiveProgram = incentiveProgram;
    this.formSummaries = formGroups;
  }
}
