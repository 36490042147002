import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { InitialCertificationComponent } from './initial-certification/initial-certification.component';
import { FilmInitialCertificationComponent } from './initial-certification/film/film.component';
import { FilmApplicationComponent } from './application/film/film.component';
import { FilmCreditManagementComponent } from './credit-management/film/credit-management.component';
import { ExpendituresComponent as FilmExpendituresComponent } from './application/film/expenditures/expenditures.component';
import { JobsComponent } from './application/film/jobs/jobs.component';
import { PrincipalPhotographyDaysComponent } from './application/film/principal-photography-days/principal-photography-days.component';
import { DatesComponent } from './application/film/dates/dates.component';
import { PrincipalsComponent } from './application/film/principals/principals.component';
import { RelatedTransactionsComponent } from './application/film/related-transactions/related-transactions.component';
import { ProjectModule } from '../project/project.module';
import { EntertainmentApplicationComponent } from './application/application.component';
import { ProductionDetailsComponent } from './application/film/production-details/production-details.component';
import { CertificationComponent } from './application/film/certification/certification.component';
import { AuditDepositComponent } from './audit-deposit/audit-deposit.component';
import { AuditComponent } from './audit/audit.component';
import { InitialCertManageComponent } from './initial-cert-manage/initial-cert-manage.component';
import { AuditorAssignmentComponent } from './auditor-assignment/auditor-assignment.component';
import { ActualExpenditureComponent } from './actual-expenditure/actual-expenditure.component';
import { FilmAuditComponent } from './audit/film/film.component';
import { FinalCertificationRecipientComponent } from './final-certification-recipient/final-certification-recipient.component';
import { AuditorPaymentComponent } from './auditor-payment/auditor-payment.component';
import { FiscalModule } from '../fiscal/fiscal.module';
import { FilmSummaryComponent } from './summary/film/summary/summary.component';
import { DigitalApplicationComponent } from './application/digital/digital.component';
import { ProjectDetailsComponent } from './application/digital/project-details/project-details.component';
import { ProgrammingLanguagesComponent } from './application/digital/programming-languages/programming-languages.component';
import { ExpendituresComponent as DmExpendituresComponent } from './application/digital/expenditures/expenditures.component';
import { StagesComponent } from './application/digital/stages/stages.component';
import { DigitalInitialCertificationComponent } from './initial-certification/digital/digital.component';
import { DigitalAuditComponent } from './audit/digital/digital.component';
import { DigitalCreditManagementComponent } from './credit-management/digital/credit-management.component';
import { DigitalSummaryComponent } from './summary/digital/summary/summary.component';
import { EligibilityQuestionsComponent } from './application/digital/eligibility-questions/eligibility-questions.component';
import { SearchComponent } from './initial-certification/search/search.component';
import { FilmInitialCertFormCriticalDatesComponent } from './initial-certification/film/form-critical-dates/form-critical-dates.component';
import { DigitalInitialCertFormCriticalDatesComponent } from './initial-certification/digital/form-critical-dates/form-critical-dates.component';
import { FilmAuditFormCriticalDatesComponent } from './audit/film/form-critical-dates/form-critical-dates.component';
import { DigitalAuditFormCriticalDatesComponent } from './audit/digital/form-critical-dates/form-critical-dates.component';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ProjectModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyMaskModule,
    FiscalModule
  ],
  declarations: [
    InitialCertificationComponent,
    EntertainmentApplicationComponent,
    FilmInitialCertificationComponent,
    FilmApplicationComponent,
    FilmCreditManagementComponent,
    FilmExpendituresComponent,
    JobsComponent,
    PrincipalPhotographyDaysComponent,
    DatesComponent,
    PrincipalsComponent,
    RelatedTransactionsComponent,
    ProductionDetailsComponent,
    CertificationComponent,
    AuditDepositComponent,
    AuditComponent,
    InitialCertManageComponent,
    AuditorAssignmentComponent,
    ActualExpenditureComponent,
    FilmAuditComponent,
    FinalCertificationRecipientComponent,
    AuditorPaymentComponent,
    FilmSummaryComponent,
    DigitalApplicationComponent,
    DigitalInitialCertificationComponent,
    ProjectDetailsComponent,
    ProgrammingLanguagesComponent,
    DmExpendituresComponent,
    StagesComponent,
    DigitalAuditComponent,
    DigitalCreditManagementComponent,
    DigitalSummaryComponent,
    EligibilityQuestionsComponent,
    SearchComponent,
    FilmInitialCertFormCriticalDatesComponent,
    DigitalInitialCertFormCriticalDatesComponent,
    FilmAuditFormCriticalDatesComponent,
    DigitalAuditFormCriticalDatesComponent
  ],
  entryComponents: [
    FilmApplicationComponent,
    FilmCreditManagementComponent,
    DigitalApplicationComponent,
    FilmInitialCertFormCriticalDatesComponent,
    DigitalInitialCertFormCriticalDatesComponent
  ],
  exports: [
    EntertainmentApplicationComponent,
    InitialCertificationComponent,
    AuditorAssignmentComponent,
    FilmSummaryComponent,
    DigitalApplicationComponent,
    DigitalSummaryComponent
  ],
  providers: []
})
export class EntertainmentModule {}
