export const projectTypes = [
  { name: 'Interactive Training Platform' },
  { name: 'Mobile Software' },
  { name: 'Simulation Software' },
  { name: 'Video Game' },
  { name: 'Medical Software' },
  { name: 'Government Software' },
  { name: 'Interactive Application/Platform' },
  { name: 'Enterprise Software' }
];

export const oldProjectTypes = [
  { name: 'Social Media Website' },
  { name: 'Video Content' }
];
