
<div id='wrapper' class="d-flex flex-column">
  <div class='side-nav-wrapper'>
    <fl-navigation></fl-navigation>
  </div>

  <div id="page-wrapper" class="gray-bg">
    <shr-loginNotification></shr-loginNotification>
    <fl-header></fl-header>

    <!-- Main view/routes wrapper-->
    <div class="wrapper mt-3" style="padding-bottom: 100px;">
      <router-outlet></router-outlet>
    </div>

    <fl-footer></fl-footer>
  </div>
</div>
