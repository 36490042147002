import { Payment } from './payment.model';
import { StatusLog } from '../../project/models/status-log.model';
import { Attachment } from '../../project/models/attachment.model';

export class OutgoingPayment extends Payment {
  auditingCompanyId: string;
  datePaid: Date;
  outgoingPaymentStatuses: StatusLog[];
  fiscalNotificationDate: Date;
  invoiceAttachment: Attachment;
  invoiceId: string;

  constructor() {
    super();
  }
}
