//#region UserConstants

// Navigation menu permission labels
export const managementPermissionLabels = {
  // biDashBoard: 'BI Dashboard',
  // board: 'Board',
  // codeTables: 'Code Tables',
  // createNewUser: 'Create New User',
  // emDashBoard: 'EM Dashboard',
  // externalReview: 'External Review',
  // formReview: 'Form Review',
  // iteTaxRates: 'ITE Tax Rates',
  // payments: 'Payments',
  // projectAccess: 'Project Access',
  // reports: 'Reports',
  // search: 'Search',
  // signatures: 'Signatures',
  // systemSettings: 'System Settings',
  // userAccounts: 'User Accounts',
  // verificationReports: 'Verification Reports'

  biDashBoard: 'BI Dashboard',
  entDashBoard: 'Entertainment Dashboard',
  stepDashBoard: 'STEP Dashboard',
  search: 'Search',
  projectSummaryReadWrite: 'Project Summary View',
  formReview: 'Form Review',
  externalReview: 'External Review',
  projectSummaryActions: 'Project Summary Actions',
  signatures: 'Signatures',
  payments: 'Payments',
  board: 'Board',
  userAccounts: 'User Accounts',
  createNewUser: 'Create New User',
  projectAccess: 'Project Access',
  filmCreditTracking: 'Film Credit Tracking',
  lookupTables: 'Lookup Tables',
  viewCharts: 'View Charts'
};

// Defines the existing userTypes in the system.
export const userType: { [key: string]: UserType } = {
  applicant: {
    code: 'applicant',
    name: 'Applicant',
    route: 'applicant'
  },
  assessor: {
    code: 'assessor',
    name: 'Assessor',
    route: 'assessor'
  },
  auditor: {
    code: 'auditor',
    name: 'Auditor',
    route: 'auditor'
  },
  board: {
    code: 'board',
    name: 'Board Member',
    route: 'board'
  },
  external: {
    code: 'externalReviewer',
    name: 'External Reviewer',
    route: 'external'
  },
  governor: {
    code: 'governor',
    name: 'Governor',
    route: ''
  },
  guest: {
    code: 'guest',
    name: 'Guest User',
    route: 'guest'
  },
  management: {
    code: 'manager',
    name: 'LED Manager',
    route: 'management'
  }
};

export interface UserType {
  code: string;
  name: string;
  route: string;
}

//#endregion
