import {ExternalReviewStatus} from './external-review-status.model';
import {externalReviewStatuses} from '../project.constants';

export class ExternalReview {
  get hasObjections() {
    return this.hasAnyStatus(externalReviewStatuses.objection.name);
  }

  get hasAnyPending() {
    return this.hasAnyStatus(externalReviewStatuses.pendingReview.name);
  }

  economicDevelopmentSecretary: ExternalReviewStatus[];
  economicDevelopmentSecretaryDueDate?: Date;

  localGoverningAuthorities: ExternalReviewStatus[];
  localGoverningAuthoritiesDueDate?: Date;

  revenueDepartment: ExternalReviewStatus[];
  revenueDepartmentDueDate?: Date;

  workforceComission: ExternalReviewStatus[];
  workforceComissionDueDate?: Date;

  private hasAnyStatus(erStatus: string) {
    return [this.economicDevelopmentSecretary, this.localGoverningAuthorities, this.revenueDepartment, this.workforceComission]
      .filter(statuses =>
        statuses !== undefined
        && statuses != null
        && statuses.length > 0
        && statuses[0] !== undefined
        && statuses[0] != null)
      .map(statuses => statuses[0].status)
      .filter(status => status === erStatus)
      .length > 0;
  }
}
