import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'fl-authentication-docs',
  templateUrl: './authentication-docs.component.html',
  styleUrls: ['./authentication-docs.component.scss']
})
export class AuthenticationDocsComponent {
  constructor() {
  }
}
