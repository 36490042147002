import { Address } from './address.model';
import { UserPermissions } from '../../user/models/user-permissions.model';

export class Person {
  public address: Address = new Address();
  public emailAddress: string = null;
  public faxNumber: string = null;
  public firstName: string = null;
  public middleInitial: string = null;
  public lastName: string = null;
  public phoneNumber: string = null;
  public phoneNumberExtension: number = null;
  public prefix: string = null;
  public suffix: string = null;
  public permissions: UserPermissions = new UserPermissions();

  static constructFullName(
    firstName: string,
    middleInitial: string,
    lastName: string
  ) {
    // Get this person's names into an array and remove any undefined or null names
    const names = [firstName, middleInitial, lastName].filter(
      n => n !== undefined && n != null && n.trim() !== ''
    );

    // Join all names together with a single space
    return names.join(' ');
  }

  public fullName(): string {
    return Person.constructFullName(
      this.firstName,
      this.middleInitial,
      this.lastName
    );
  }

  init(): Person {
    this.address = this.address ? new Address(this.address) : new Address();
    this.permissions = new UserPermissions(this.permissions);
    return this;
  }
}
