import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { getUserModule } from '../../user/user.functions';

@Injectable()
export class DefaultRouteGuard implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.isUserLoggedIn) {
      return true;
    } else {
      // Url where to redirect user upon successfull login
      this.authService.redirectUrl = state.url;

      // User Route
      const defaultRoute = getUserModule(this.authService.getUserRole());

      // Take user back to log in page
      this.router.navigate([`/${defaultRoute}`]);
      return false;
    }
  }

  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}
}
