import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectorRef
} from '@angular/core';
import { ProductionDetails } from '../../../models/film/production-details.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { productionTypes } from '../../../../entertainment/entertainment.constants';
import { FormValidationService } from '../../../../form/services/validation.service';
import {
  events,
  publishSubscribeEventStrings
} from '../../../../fastlane-common/event/event.constants';
import { PublishSubscribeService } from '../../../../fastlane-common/services/publish-subscribe.service';

@Component({
  selector: 'fl-production-details',
  templateUrl: './production-details.component.html',
  styleUrls: ['./production-details.component.css']
})
export class ProductionDetailsComponent implements OnInit, OnDestroy {
  @Input() productionDetails: ProductionDetails;
  productionDetailsForm: FormGroup;
  productionTypes = productionTypes;
  private subs: Subscription[] = [];

  changeProductionType(type: any) {
    this.productionDetails.productionType = type;
  }

  constructor(
    private formBuilder: FormBuilder,
    private validationService: FormValidationService,
    private pubSubService: PublishSubscribeService
  ) {}

  ngOnDestroy(): void {
    // Ensure no subscription remains active
    this.subs.forEach(sub => sub.unsubscribe());
  }

  ngOnInit() {
    this.productionDetailsForm = this.formBuilder.group({
      detailedSynopsis: [
        this.productionDetails.detailedSynopsis,
        Validators.required
      ],
      episodesCount: [this.productionDetails.episodesCount],
      season: [this.productionDetails.season],
      isScreenPlayCreatedByResident: [
        this.productionDetails.isScreenPlayCreatedByResident,
        Validators.required
      ],
      productionType: [
        this.productionDetails.productionType,
        Validators.required
      ],
      wasTaxReturnFiledPreviousYear: [
        this.productionDetails.wasTaxReturnFiledPreviousYear,
        Validators.required
      ]
    });
    const that = this;
    let sub = this.productionDetailsForm.valueChanges.subscribe(value => {
      Object.assign(that.productionDetails, value);
      // Raise creditEligibility changed event so that all the subscribers can listen to the changes.
      that.pubSubService.raise<any>(
        events.creditEligibilityChanged.code,
        'isScreenPlayCreatedByResident',
        value.isScreenPlayCreatedByResident
      );
    });
    this.subs.push(sub);

    that.validationService.form.setControl(
      'productionDetailsForm',
      that.productionDetailsForm
    );

    // Subscribe for changes just for the production type.
    sub = this.productionDetailsForm
      .get('productionType')
      .valueChanges.subscribe(value => {
        if (value === 'Scripted Episodic Content') {
          this.toggleEpisodicContentRequiredFields(true);
        } else {
          this.toggleEpisodicContentRequiredFields(false);
        }
      });

    const sub1 = this.productionDetailsForm
      .get('isScreenPlayCreatedByResident')
      .valueChanges.subscribe(() => {
        that.pubSubService.publish(
          publishSubscribeEventStrings.refreshAttachmentValidity
        );
      });
    const sub2 = this.productionDetailsForm
      .get('wasTaxReturnFiledPreviousYear')
      .valueChanges.subscribe(() => {
        that.pubSubService.publish(
          publishSubscribeEventStrings.refreshAttachmentValidity
        );
      });
    this.subs.push(...[sub, sub1, sub2]);
  }

  /**
   * @summary Used for updating requirement of episodic seasonal content
   * exclusive fields
   * @param mode Whether validation should be required or not for those fields
   */
  toggleEpisodicContentRequiredFields(mode: boolean) {
    const that = this;
    const affectedControls = ['episodesCount', 'season'];

    affectedControls.forEach(control => {
      this.productionDetailsForm
        .get(control)
        .setValidators(mode ? Validators.required : Validators.nullValidator);
      if (!mode) {
        that.productionDetails[control] = null;
        that.productionDetailsForm.get(control).setValue(null);
      }
      this.productionDetailsForm.get(control).updateValueAndValidity();
    });
  }
}
