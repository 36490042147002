<form class="no-borders" novalidate>
  <!--Eligibility Questions-->
  <fl-eligibility-questions
    [eligibilityQuestions]="digitalApplication.eligibilityQuestions"
    [readOnly]="!permissions.canUserEdit"
  ></fl-eligibility-questions>

  <div class="mb-4" [formGroup]="initialCertCustomDisplayTextFormGroup">
    <div class="card">
      <div class="card-header"><b>Initial Certification Custom Template Content</b></div>
      <div class="card-body px-5">
        <div class="form-group py-2 px-1">
          <label class="form-control-label">Initial certification custom template content text:</label>
          <textarea
            class="form-control h-100"
            formControlName="initialCertCustomDisplayText"
            id="initialCertCustomDisplayText"
            rows="3"
            placeholder="Add any specific additional items and/or content that needs to be noted on the ICL."
          ></textarea>
        </div>
      </div>
    </div>
  </div>

  <!--Company History-->
  <div class="mb-4" [formGroup]="companyHistoryFormGroup">
    <div class="card">
      <div class="card-header"><b>COMPANY HISTORY</b></div>
      <div class="card-body px-5">
        <div class="form-group py-2 px-1">
          <label class="form-control-label">Company History:</label>
          <textarea
            class="form-control h-100"
            formControlName="companyHistory"
            id="companyHistoryTextarea"
            rows="3"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
  <!--Project Details-->
  <fl-project-details #projectDetails></fl-project-details>
  <!--Stages-->
  <fl-stages [readOnly]="!permissions.canUserEdit" #stages></fl-stages>
  <!--Programming Languages-->
  <fl-programming-languages
    [readOnly]="!permissions.canUserEdit"
    #programmingLanguages
  ></fl-programming-languages>
  <!--Expenditures-->
  <ng-container *ngIf="!hideExpenditures">
    <fl-dm-expenditures
      [expenditures]="digitalApplication.estimatedExpenditures"
    ></fl-dm-expenditures>
  </ng-container>
  <!--Audit-->
  <ng-container *ngIf="!hideDeposit">
    <fl-audit-deposit
      [form]="form"
      class="w-100"
      [attr.data-valid]=""
    ></fl-audit-deposit>
  </ng-container>
</form>
