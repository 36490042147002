export class Stages {
  name: string;
  duration: number;
  info: string;
  concurrentPhase: string;

  constructor(obj?: any) {
    if (!obj) {
      obj = {};
    }
    this.name = obj.name || null;
    this.duration = obj.duration >= 0 ? obj.duration : null;
    this.info = obj.info || null;
    this.concurrentPhase = obj.concurrentPhase || null;
  }
}
