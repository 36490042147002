<div [formGroup]="projectDetailsFormGroup">
  <div class="mb-4">
    <div class="card">
      <div class="card-header"><b>PROJECT DETAILS</b></div>
      <div class="card-body px-5">
        <div class="form-group form-row py-2 px-1">
          <label class="form-control-label col-12 col-md-2"
            >Project Type:</label
          >
          <select class="form-control-sm" formControlName="projectType">
            <option></option>
            <option *ngFor="let pt of projectTypes" [ngValue]="pt.name">{{
              pt.name
            }}</option>
          </select>
        </div>

        <div class="form-group form-row py-2 px-1">
          <label class="form-control-label col-12 col-md-2"
            >Anticipated Start Date:</label
          >
          <fl-date-time-picker
            [form]="projectStartDateGroup"
            [showTime]="false"
            [selectedDate]="projectDetails.anticipatedProjectStartDate"
            (dateTimeChanged)="
              DateChanged($event, 'anticipatedProjectStartDate')
            "

            #anticipatedProjectStartDate
          ></fl-date-time-picker>
          <div
            class="form-group form-row py-2 px-1"
            *ngIf="isStartDateValid === false"
          >
            <p class="col-md-12 text-danger bold-font">
              The start date can not be prior to 6 months before today's date.
            </p>
          </div>
        </div>

        <div class="form-group form-row py-2 px-1">
          <label class="form-control-label col-12 col-md-2"
            >Anticipated End Date:</label
          >
          <fl-date-time-picker
            [form]="projectEndDateGroup"
            [showTime]="false"
            [selectedDate]="projectDetails.anticipatedProjectEndDate"
            (dateTimeChanged)="DateChanged($event, 'anticipatedProjectEndDate')"
            #anticipdatedProjectEndDate
          ></fl-date-time-picker>
          <div
            class="form-group form-row py-2 px-1"
            *ngIf="isEndDateValid === false"
          >
            <p class="col-md-12 text-danger bold-font">
              The end date can not be greater than 3 years from the anticipated
              start date.
            </p>
          </div>
        </div>

        <div class="form-group py-2 px-1">
          <label class="form-control-label">Description of Project:</label>
          <textarea
            class="form-control h-100"
            formControlName="descriptionProject"
            id="descriptionProjectTextarea"
            rows="3"
          ></textarea>
        </div>
        <div class="form-group py-2 px-1">
          <label class="form-control-label"
            >Description of User Interface:</label
          >
          <textarea
            class="form-control h-100"
            formControlName="descriptionUserInterface"
            id="descriptionUserInterfaceTextarea"
            rows="3"
          ></textarea>
        </div>
        <div class="form-group col-12 py-2 px-1">
          <label class="form-control-label"
            >Commercial Release Plan:<!--Distribution Plan--></label
          >
          <textarea
            class="form-control h-100"
            formControlName="distributionPlan"
            id="distributionPlanTextarea"
            rows="3"
          ></textarea>
        </div>
        <div class="form-group form-row py-2 px-1">
          <label class="form-control-label col-12 col-md-2"
            >Product Website:</label
          >
          <input
            type="text"
            class="form-control col-12 col-md-10"
            data-toggle="tooltip"
            data-placement="top"
            formControlName="productWebsite"
          />
        </div>
      </div>
    </div>
  </div>
</div>
