import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { getSpecificUserTypeOrAgency } from '../user.functions';
import { User } from '../models/user.model';
import { externalReviewAgencies } from 'app/project/project.constants';
import { chain } from 'underscore';
import {
  EventType,
  events,
  userTypeToNotificationsMap
} from '../../fastlane-common/event/event.constants';

@Component({
  selector: 'fl-event-notification-preferences',
  templateUrl: './event-notification-preferences.component.html',
  styleUrls: ['./event-notification-preferences.component.scss']
})
export class EventNotificationPreferencesComponent
  implements OnInit, OnChanges {
  applicableEvents: EventType[];
  @Input() agency: string;
  @Input()
  user: User;
  specificUserType: string;

  constructor() {}

  ngOnInit() {
    this.updateApplicableEvents();
  }

  ngOnChanges() {
    this.updateApplicableEvents();
  }

  getEventDisplayName(eventName: string) {
    return events[eventName].display;
  }

  getEventActions(eventName: string) {
    return events[eventName].actions;
  }

  getSelectedActions(eventName: string) {
    // Get array for given event from user object
    return this.user.eventNotificationPreferences[eventName];
  }

  updateEventActionPreferences(eventName: string, values: {id: string, text: string}[]) {
     // Updating reference with a new aggregated one of all values
    this.user.eventNotificationPreferences[eventName] = values.map(value => value.text);
  }

  private updateApplicableEvents() {
    let agency: string;

    const agencyPair = chain(externalReviewAgencies)
      .pairs()
      .find(pair => pair[1].code === this.agency)
      .value();

    if (agencyPair) {
      agency = agencyPair[0];
    }

    this.specificUserType = agency || getSpecificUserTypeOrAgency(this.user);
    this.applicableEvents =
      userTypeToNotificationsMap[this.specificUserType] || [];
  }
}
