export class ContractInformation {
  contractEffectiveDate: Date;

  // just for data model ( calculations )
  totalCumulativeGrossPayroll: number;
  totalNewDirectJobsCreated: number;

  constructor(contractInfo?: any) {
    if (!contractInfo) { contractInfo = {}; }
    this.contractEffectiveDate = contractInfo.contractEffectiveDate ? new Date(contractInfo.contractEffectiveDate) : null;
    this.totalCumulativeGrossPayroll = contractInfo.totalCumulativeGrossPayroll || 0;
    this.totalNewDirectJobsCreated = contractInfo.totalNewDirectJobsCreated || 0;
  }
}
