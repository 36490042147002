import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { User } from '../models/user.model';
import { Permission } from '../../security/security.constants';
import { getPermissionsForUserType } from '../../security/security.functions';
import { UserPermissions } from '../models/user-permissions.model';

@Component({
  selector: 'fl-permission-settings',
  templateUrl: './permission-settings.component.html',
  styleUrls: ['./permission-settings.component.scss']
})
export class PermissionSettingsComponent implements OnInit, OnChanges {
  @Input()
  user: User;

  availablePermissions: Permission[];

  /**@summary This function toggles the selected or clicked permission
   * @param This is the value property of the permission that was selected. It will be used to search the permissions object via subscript
   */
  togglePermission(permissionName: string) {
    this.user.permissions[permissionName] = !this.user.permissions[
      permissionName
    ];
  }

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {}

  ngOnInit() {
    // The only change is user so whenever there is a change, refresh permissions
    this.availablePermissions = getPermissionsForUserType(this.user.userType);
  }
}
