<nav class="navbar-light navbar-static-side overflow-visible" role="navigation">
  <div class="sidebar-collapse default-bg">

    <div class="nav-header">
      <div class="dropdown profile-element">
        <a [routerLink]="navigationData.headerRouterLink">
          <img class="img-fluid animated bounceInLeft" [alt]="navigationData.headerImgAlt" [src]="navigationData.headerImgSrc">
        </a>
      </div>
    </div>

    <ul class="nav metismenu" id="side-menu">
      <li *ngFor="let navItem of navigationData.items" [ngClass]="{'nav-item': true, selected: navItem.submenu ? activeRoutePrefix(navItem.submenu.highlightRoute) : activeRoute(navItem.routerLink), active: !navItem.submenu && activeRoute(navItem.routerLink)}">

        <ng-template *ngIf="navItem.submenu; then thenBlock else elseBlock"></ng-template>

        <ng-template #thenBlock>
          <!-- submenu block -->
          <a href="" class="nav-link has-arrow" [attr.data-toggle]="'collapse'" [attr.data-target]="'#' + navItem.submenu.id" [attr.aria-controls]="navItem.submenu.id"
            [attr.aria-expanded]="activeRoutePrefix(navItem.submenu.highlightRoute)" [attr.aria-label]="'Toggle navigation'">
            <i [class]="'p-0 ' + navItem.faIcon"></i>
            <span class='nav-label'>{{navItem.label}}</span>
          </a>

          <div class="collapsemenu nav-item collapse" [id]="navItem.submenu.id" [ngClass]="{'show': activeRoutePrefix(navItem.submenu.highlightRoute)}">
            <div *ngFor="let navSubitem of navItem.submenu.items" [ngClass]="{nav: true, active: activeRoute(navSubitem.routerLink), selected: activeRoute(navSubitem.routerLink)}">
              <a [routerLink]="[navSubitem.routerLink]" class='nav-link nav-linklink'>{{navSubitem.label}}</a>
            </div>
          </div>
        </ng-template>

        <!-- regular link block -->
        <ng-template #elseBlock>
          <a class="nav-link" [routerLink]="[navItem.routerLink]">
            <i [class]="navItem.faIcon"></i>
            <span class="nav-label">{{navItem.label}}</span>
          </a>
        </ng-template>

      </li>
    </ul>

  </div>
</nav>