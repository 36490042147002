export class InitialCertCriticalDates {
  relatedApplicationReceivedDate: Date;
  initialCertIssuanceDate: Date;
  constructor(obj?: any) {
    obj = obj ? obj : {};
    this.relatedApplicationReceivedDate = obj.relatedApplicationReceivedDate
      ? new Date(obj.relatedApplicationReceivedDate)
      : null;
    this.initialCertIssuanceDate = obj.initialCertIssuanceDate
      ? new Date(obj.initialCertIssuanceDate)
      : null;
  }
}
