import { CreditType } from '../creditType.model';
import { FormIdentifier } from '../../../project/models/form-identifier.model';
import { StatusLog } from '../../../project/models/status-log.model';
import { CreditStatuses } from '../../../entertainment/credit.constants';
import { formTypes } from '../../../form/form.constants';

export class Credit extends CreditType {
  id: string;
  // This is the amount which is reserved or issued.
  fundingAmount?: number;
  // Maintains the current status of the credit
  // Credit Statuses(Pending-Reservation,Reserved,Pending-Issuance,Issued)
  creditStatuses: StatusLog[];
  // This is used to indicate if the current credit is deferred.
  isDeferred?: boolean;
  // This is used to indicate if the current credit is expired.
  isExpired?: boolean;
  // Fiscal year to which credits belongs to.
  fiscalYear: number;
  // Relates the credits to a form.
  formIdentifier: FormIdentifier;
  note: string;
  season: number;
  // Sub Category which is used to reserve or issue credits.
  subCategory: string;

  constructor(obj?: any) {
    super(obj);
    if (!obj) {
      obj = {};
    }
    this.fundingAmount = obj.amount >= 0 ? obj.amount : null;
    this.formIdentifier = obj.formIdentifier || null;
    this.creditStatuses = obj.creditStatuses || [
      <StatusLog>{
        statusDate: new Date(),
        status:
          this.formIdentifier &&
          this.formIdentifier.formType === formTypes.audit.abbrev
            ? CreditStatuses.pendingIssuance
            : CreditStatuses.pendingReservation
      }
    ];
    this.isDeferred =
      obj.isDeferred === null || obj.isDeferred === undefined
        ? null
        : obj.isDeferred;
    this.isExpired =
      obj.isExpired === null || obj.isExpired === undefined
        ? null
        : obj.isExpired;
    this.fiscalYear = obj.fiscalYear || null;
    this.note = obj.note || null;
    this.season = obj.season || null;
    this.subCategory = obj.subCategory || null;
  }
}
