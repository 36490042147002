<form class="no-borders" [formGroup]="productionDetailsForm">
  <div class="row d-flex align-items-center py-2 px-5">
    <label class="form-control-label col-12 col-md-2"> Production Type: </label>
    <select
      class="form-control col-12 col-md-4"
      formControlName="productionType"
      (ngModelChange)="changeProductionType($event)"
      required
    >
      <option *ngFor="let req of productionTypes" [value]="req">{{
        req
      }}</option>
    </select>
    <ng-container
      *ngIf="productionDetails.productionType === 'Scripted Episodic Content'"
    >
      <label class="form-control-label col-12 col-md-2"> # of Episodes: </label>
      <input
        type="number"
        class="form-control col-12 col-md-1 "
        formControlName="episodesCount"
      />
    </ng-container>
  </div>
  <div
    class="row d-flex align-items-center py-2 px-5"
    *ngIf="productionDetails.productionType === 'Scripted Episodic Content'"
  >
    <label class="form-control-label col-12 col-md-2 "> Season: </label>
    <input
      type="number"
      class="form-control col-12 col-md-1 "
      formControlName="season"
    />
  </div>
  <div class="row d-flex align-items-center py-2 px-5">
    <label class="form-control-label col-12 col-md-2 ">
      Detailed Synopsis:
    </label>

    <textarea
      class="form-control col-12 col-md-6"
      id="exampleTextarea1"
      rows="5"
      formControlName="detailedSynopsis"
    ></textarea>
  </div>
  <div class="row d-flex align-items-center py-2 px-5">
    <label class="form-control-label col-12 col-md-6"
      >Is your production based on a screenplay created by an LA resident? If
      yes, you must attach Proof of Authorship.</label
    >
    <div class="col-12 col-md-3">
      <label class="form-check-inline">
        <input
          type="radio"
          [value]="true"
          id="inlineCheckbox1"
          formControlName="isScreenPlayCreatedByResident"
        />Yes</label
      >
      <label class="form-check-inline">
        <input
          type="radio"
          [value]="false"
          id="inlineCheckbox2"
          formControlName="isScreenPlayCreatedByResident"
        />No</label
      >
    </div>
  </div>

  <div class="row d-flex align-items-center py-2 px-5">
    <label class="form-control-label col-12 col-md-6"
      >Did the screenwriter file a LA Resident tax return for the previous year?
      If yes, you must attach a Declaration of Residency.</label
    >
    <div class="col-12 col-md-3">
      <label class="form-check-inline">
        <input
          type="radio"
          [value]="true"
          id="inlineCheckbox1"
          formControlName="wasTaxReturnFiledPreviousYear"
        />Yes</label
      >
      <label class="form-check-inline">
        <input
          type="radio"
          [value]="false"
          id="inlineCheckbox2"
          formControlName="wasTaxReturnFiledPreviousYear"
        />No</label
      >
    </div>
  </div>
</form>
