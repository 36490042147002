
export class IndustrialTaxExemptionRenewal {

  priorJobs: number;
  currentJobs: number;
  newJobs: number;
  projectDescription: string;
  jobsReductionReason: string;
  manufacturingChanges: string;

  affidavitInvestmentTotal: number;
  applicationInvestmentTotal: number;
  millageRate: number;
  naicsCode: string;
  projectType: string;

  annualAdValorem: number;
  annualAdValoremDepreciation: number;

  constructor() {
  }

}