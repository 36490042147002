<!-- FILTERS -->
<section id="filters" class="mt-tenth">
  <div id="criteria" class="collapse">
    <div class="p-3 bg-white mt-0">
      <form [formGroup]="filters">
        <!-- PROGRAM TYPE -->
        <div class="form-group col-12 col-md-8 col-xl-4">
          <label for="statusFilter">Incentive Program</label>
          <select
            class="form-control"
            id="incentiveProgramFilter"
            formControlName="incentiveProgram"
          >
            <option value="FILM">FILM</option>
            <option value="DM">DM</option>
          </select>
        </div>

        <!-- APPLICATION RECEIVED DATE -->
        <div class="form-group mt-2 col-12 col-md-8 col-xl-4">
          <label for="appReceivedDate">Application Received Date</label>
          <fl-date-range-picker
            [selectedDateRange]="appReceivedDateRange"
            (selectedDateRangeChange)="appReceivedDateSelected($event)"
            [elementId]="'appReceivedDate'"
          ></fl-date-range-picker>
        </div>

        <!-- INITIAL CERT ISSUANCE DATE -->
        <div class="form-group mt-2 col-12 col-md-8 col-xl-4">
          <label for="initCertIssuanceDate">Initial Cert Issuance Date</label>
          <fl-date-range-picker
            [selectedDateRange]="initialCertIssDateRange"
            (selectedDateRangeChange)="initialCertIssDateSelected($event)"
            [elementId]="'initCertIssuanceDate'"
          ></fl-date-range-picker>
        </div>
        <button
          type="submit"
          class="btn btn-primary mt-3 ml-3"
          (click)="search()"
        >
          SEARCH
        </button>
      </form>
    </div>
  </div>
  <div id="handle" class="d-flex justify-content-center">
    <button
      class="btn btn-primary d-block"
      data-toggle="collapse"
      data-target="#criteria"
    >
      <i class="fas fa-filter text-white"></i>
      <span class="d-md-inline d-none pl-2">Filter</span>
    </button>
  </div>
</section>

<!-- HEADER -->
<section>
  <h2>Initial Certification Search</h2>
</section>

<!-- TABLE -->
<section class="white-bg pt-3">
  <table
    id="initial-cert-search-table"
    class="table table-striped table-bordered table-hover"
    cellspacing="0"
    width="100%"
  >
    <tfoot></tfoot>
  </table>
</section>
