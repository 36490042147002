import { Injectable } from '@angular/core';
import { Expenditures } from '../../entertainment/models/digital/expenditures.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../security/services/authentication.service';
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators';
import {
  httpResponseCodes,
  httpStatusCodeToErrorMap
} from '../../shared/shared.constants';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  private readonly convertDigitalSpreadsheetToJsonUrl =
    // environment.apiUrl +
    // '/file/process/expenditure/file?file=:file&legislation=:legislation';
    environment.apiUrl + '/file/process/expenditure/file';
  constructor(
    private authService: AuthenticationService,
    private httpClient: HttpClient
  ) {}

  // #region "Custom Methods"
  /**
   * @summary Call the lookup service to retrieve the audit company name
   * @param spreadsheetFile Expenditure spreadsheet template.
   * @param legislation ProjectInfo
   */
  convertDigitalSpreadsheetToJson(
    spreadsheetFile: File,
    legislation: string
  ): Observable<Expenditures> {
    const _formData = new FormData();
    _formData.append('file', spreadsheetFile, spreadsheetFile.name);
    _formData.append('legislation', legislation);
    const data = this.httpClient
      .post<Expenditures>(
        `${this.convertDigitalSpreadsheetToJsonUrl}`,
        _formData,
        this.authService.getAuthOptionsForHttpClient()
      )
      .pipe(map(this.extractData))
      .pipe(catchError(this.handleError));
    return data;
  }

  private extractData<T>(res: T) {
    return res;
  }

  private handleError(error: HttpErrorResponse | any) {
    // In a real world app, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      errMsg = `${error.status} - ${error.statusText || ''} ${error.message}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.log('Fantastic, it does not work!!');
    console.error(errMsg);
    if (error.status === httpResponseCodes.dataConflict) {
      return throwError(error.status);
    } else {
      return throwError(errMsg);
    }
  }
  // #endregion
}
