import { Component, OnInit, ViewContainerRef } from '@angular/core';

import { detectBody } from '../../../shared/shared.functions';
import { SwalService } from '../../../fastlane-common/services/swal.service';

@Component({
  selector: 'fl-logged-in',
  templateUrl: './logged-in.component.html',
  styleUrls: ['./logged-in.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    '(window:resize)': 'onResize()'
  }
})
export class LoggedInComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    detectBody();
  }

  onResize(): void {
    detectBody();
  }
}
