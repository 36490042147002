import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserContextService } from '../../user/services/user-context.service';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';
import { map } from 'rxjs/operators';
import { SwalService } from '../../fastlane-common/services/swal.service';
import { ProjectDetailService } from 'app/project/services/project-shared.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectAccessGuard implements CanActivate {
  private checkingUrl = `${environment.apiUrl}/project/hasAccess/{projectGuid}`;

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const that = this;

    // Get the project id from the activated route snapshot
    const projectGuid = next.paramMap.get('id');

    // Set the project guid in url
    const auditingCompanyUid = this.userContextService.currentUser
      .auditingCompanyId;

    // Only add auditingCompanyUid when it exists, which is only for auditors
    const url =
      this.checkingUrl.replace('{projectGuid}', projectGuid) +
      (auditingCompanyUid
        ? `?auditingCompanyUid=${auditingCompanyUid}`
        : '');

    // Ensure that the logged in user can access this project
    return this.http
      .get(url, this.authenticationService.getAuthOptionsForHttpClient())
      .pipe(
        map(resp => {
          if (!resp) {
            // Show swal to user
            setTimeout(() => {
              that.swalService.error({
                title: 'Access Denied',
                text:
                  'You attempted to navigate to an area to which you do not have access. \
                As a result, you were redirected to your default page.'
              });
            }, 0);

            // Redirect to user's default route
            that.router.navigate(['/']);
          }
          return resp as boolean;
        })
      );
  }

  constructor(
    private userContextService: UserContextService,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private router: Router,
    private swalService: SwalService,
    private shareService: ProjectDetailService
  ) {}
}
