/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./checkbox.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./checkbox.component";
var styles_CheckboxComponent = [i0.styles];
var RenderType_CheckboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckboxComponent, data: {} });
export { RenderType_CheckboxComponent as RenderType_CheckboxComponent };
function View_CheckboxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-check line-height-5-quarters"]], null, null, null, null, null))], null, null); }
export function View_CheckboxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "label", [["id", "checkbox-label"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "d-flex justify-content-center"], ["id", "check-icons"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckboxComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["id", "check-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["\u00A0", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "input", [["autocomplete", "off"], ["type", "checkbox"]], [[8, "id", 0], [8, "readOnly", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.checked = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i3.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.CheckboxControlValueAccessor]), i1.ɵdid(9, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(11, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.checked; _ck(_v, 3, 0, currVal_1); var currVal_12 = _co.checked; _ck(_v, 9, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getCssClass(); _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.label; _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "checkbox-", _co.controlId, ""); var currVal_4 = _co.readonly; var currVal_5 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_6 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_7 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_8 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_9 = i1.ɵnov(_v, 11).ngClassValid; var currVal_10 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_11 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 6, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); }); }
export function View_CheckboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shr-checkbox", [], null, null, null, View_CheckboxComponent_0, RenderType_CheckboxComponent)), i1.ɵdid(1, 49152, null, 0, i4.CheckboxComponent, [], null, null)], null, null); }
var CheckboxComponentNgFactory = i1.ɵccf("shr-checkbox", i4.CheckboxComponent, View_CheckboxComponent_Host_0, { controlId: "controlId", readonly: "readonly", label: "label", form: "form", checked: "checked" }, { checkedChange: "checkedChange" }, []);
export { CheckboxComponentNgFactory as CheckboxComponentNgFactory };
