<div class='d-flex py-1'>
  <label class="col-4 form-control-label">External Review Agency:</label>
  <div class="col-8 no-borders" [formGroup]="userForm">
    <select class="form-control" name="userType" id="userType" (change)="selectedAgecyChange($event)" formControlName="agency">
      <option value="null" disabled>Select an agency</option>
      <option [value]="led.code">{{led.name}}</option>
      <option [value]="ldr.code">{{ldr.name}}</option>
      <option [value]="lga.code">{{lga.name}}</option>
      <option [value]="lwc.code">{{lwc.name}}</option>
    </select>
  </div>
</div>

<shr-person [form]="userForm" [person]="user" [addressRequired]="false"></shr-person>

<div *ngIf="selectedAgency === lga.code" class="py-1">
  <div class='d-flex'>
    <label class="col-4 form-control-label">Governing Parish :</label>
    <div class="col-8">
      <shr-parish [(parish)]="user.governingParish" [readonly]="false" [form]="form"></shr-parish>
    </div>
  </div>
</div>