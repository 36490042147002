import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { User } from 'app/user/models/user.model';
import { Programs } from 'app/project/models/programs.model';
import {
  FormGroup,
  FormBuilder,
  AbstractControl,
  Validators
} from '@angular/forms';
import { keys, values } from 'underscore';
import { userType } from 'app/user/user.constants';
import { externalReviewAgencies } from 'app/project/project.constants';

@Component({
  selector: 'fl-user-detail-program-selection',
  templateUrl: './program-selection.component.html',
  styleUrls: ['./program-selection.component.css']
})
export class ProgramSelectionComponent implements OnDestroy, OnInit {
  @Input() form: FormGroup;
  @Input() user: User;

  restrictedPrograms: Programs = new Programs();
  selectedPrograms: Programs = new Programs();
  selectedProgramsForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnDestroy() {
    // Remove the form from the parent form
    if (this.form) {
      this.form.removeControl('selectedPrograms');
    }

  }

  ngOnInit() {
    const that = this;
    that.initProgramSelectionControl();
    that.initForm();
  }

  update() {
    // Update both form and data models
    this.selectedProgramsForm.patchValue(Object.assign(this.selectedPrograms));
    this.updateDataModel();
  }

  private initForm() {
    const validator = this.form
      ? (control: AbstractControl) => {
          if (!values(control.value).includes(true)) {
            return {
              noSelectionError: 'At least one program must be selected'
            };
          }

          return null;
        }
      : Validators.nullValidator;

    this.selectedProgramsForm = this.fb.group(
      Object.assign({}, this.selectedPrograms),
      { validator: validator }
    );

    if (this.form) {
      // Add it in the next macro task to ensure it complies with content change detection
      const that = this;
      that.form.addControl('selectedPrograms', this.selectedProgramsForm);
    }
  }

  private initProgramSelectionControl() {
    if (!this.user) {
      return;
    }

    // Ensure user has the programs array initialized
    if (!this.user.incentivePrograms) {
      this.user.incentivePrograms = [];
    }

    // From user's selected incentive programs array, populate the Programs object for selectedPrograms
    this.selectedPrograms = this.user.getPrograms();

    // Use the user's type and external review agency to determine what programs to display/hide
    const availablePrograms =
      this.user.userType === userType.assessor.code
        ? new Programs(false, false, true, true)
        : this.user.userType === userType.external.code &&
          this.user.externalReviewAgency === externalReviewAgencies.lga.code
        ? new Programs(true, true, true, true)
        : new Programs(true, true, true, true, true, true, true);

    this.restrictedPrograms = availablePrograms.getNegation();
  }

  private updateDataModel() {
    // Get new values from Programs object
    const that = this;
    const newValues = keys(this.selectedPrograms)
      .filter(key => that.selectedPrograms[key])
      .map(key => key.toUpperCase());

    // Remove all old values from user's program array, then re-add all new values
    this.user.incentivePrograms.splice(0);
    this.user.incentivePrograms.push(...newValues);
  }
}
