/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./program-type-checkbox.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../checkbox/checkbox.component.ngfactory";
import * as i3 from "../checkbox/checkbox.component";
import * as i4 from "@angular/common";
import * as i5 from "./program-type-checkbox.component";
var styles_ProgramTypeCheckboxComponent = [i0.styles];
var RenderType_ProgramTypeCheckboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProgramTypeCheckboxComponent, data: {} });
export { RenderType_ProgramTypeCheckboxComponent as RenderType_ProgramTypeCheckboxComponent };
function View_ProgramTypeCheckboxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shr-checkbox", [], null, [[null, "checkedChange"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkedChange" === en)) {
        var pd_0 = ((_co.selectedPrograms[_v.context.$implicit] = $event) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_CheckboxComponent_0, i2.RenderType_CheckboxComponent)), i1.ɵdid(1, 49152, null, 0, i3.CheckboxComponent, [], { controlId: [0, "controlId"], label: [1, "label"], checked: [2, "checked"] }, { checkedChange: "checkedChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _v.context.$implicit.toUpperCase(); var currVal_2 = _co.selectedPrograms[_v.context.$implicit]; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ProgramTypeCheckboxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgramTypeCheckboxComponent_1)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.programsToDisplay; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ProgramTypeCheckboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shr-program-type-checkbox", [], null, null, null, View_ProgramTypeCheckboxComponent_0, RenderType_ProgramTypeCheckboxComponent)), i1.ɵdid(1, 114688, null, 0, i5.ProgramTypeCheckboxComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProgramTypeCheckboxComponentNgFactory = i1.ɵccf("shr-program-type-checkbox", i5.ProgramTypeCheckboxComponent, View_ProgramTypeCheckboxComponent_Host_0, { selectedPrograms: "selectedPrograms", programRestrictions: "programRestrictions" }, { selectionChanged: "selectionChanged" }, []);
export { ProgramTypeCheckboxComponentNgFactory as ProgramTypeCheckboxComponentNgFactory };
