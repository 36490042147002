import { Person } from '../../../shared/models/person.model';

export class Principal extends Person {
    role: string;

constructor() {
    super();
  }
  init() {
    super.init();
    this.fullName = this.fullName || null;
    this.role = this.role || null;
    return this;
  }
}