<div class="wrapper" style="padding-bottom: 100px;">

  <div class="text-blue m-b">
    <h2>
      <b>Document Checklists</b>
    </h2>

  </div>
  <div class="row white-bg p-3">

  <div class="col-6">
  <div class=" m-b">
    <h4 class="text-blue">
      <b><i class="fa fa-user-tie pr-2"></i>Business Incentives</b>
    </h4>
   <p> <b>Click a checklist to view the forms needed when filing for each Business Incentive program.</b></p>
   <img class="center w-100" alt="image" src="assets/images/doc1.jpg">
  </div>



  <div id="accordion">
    <div class="card">
      <div class="card-header" id="EZ">
        <h5 class="mb-0">
          <button class="btn btn-link text-teal pl-0 collapsed" data-toggle="collapse" title="Click to Expand/Collapse" data-target="#collapseEZ" aria-expanded="false" aria-controls="EZ">
            <h5><i class="fa fa-arrow-alt-circle-right pr-2"></i>Enterprise Zone (EZ) Program </h5>
          </button>
        </h5>
      </div>

      <div id="collapseEZ" class="collapse" aria-labelledby="EZ" data-parent="#accordion">
        <div class="card-body">
          <p>The following may be needed when filing forms for the EZ Program.</p>
          <p>
            <b>Applications</b>: </p>
          <ul>
            <li>
              <a href="https://storage.googleapis.com/fastlane-public-files/EZ/EZ-BaselineCalculationWorksheet.docx" target="_blank">Baseline Calculation Worksheet</a>
            </li>
            <li>
              <a href="https://storage.googleapis.com/fastlane-public-files/EZ/ECR_Worksheets_PostAct18_Protected_REVISED_6.30.2020.xlsx" target="_blank">Post Act 18 Baseline Calculation Worksheet</a>
            </li>
            <li>
              <a href="https://storage.googleapis.com/fastlane-public-files/EZ/EZ-QualificationCertification.pdf" target="_blank">Qualification Certification</a>
            </li>
            <li>Census tract block group supporting documentation along with corresponding page(s) of the EZ Listing</li>
            <li>Louisiana Workforce Commission assigned NAICS code supporting documentation</li>
            <li>Local Endorsement Resolution</li>
            <li>Sales Tax Registration Certificate</li>
            <li>If the business is not a ‘C’ Corporation, provide a copy of the business structure</li>
          </ul>
          <p>
            <b>Employee Certification Reports</b>:</p>
          <ul>
            <li>
              <a href="https://storage.googleapis.com/fastlane-public-files/EZ/ECR_Worksheets_PreAct423_Protected.xlsx" target="_blank">ECR Worksheet (Pre Act 423)</a>
            </li>
            <li>
              <a href="https://storage.googleapis.com/fastlane-public-files/EZ/ECR_Worksheets_PostAct423_Protected.xlsx" target="_blank">ECR Worksheet (Post Act 423)</a>
            </li>
            <li>
              <a href="https://storage.googleapis.com/fastlane-public-files/EZ/ECR_Worksheets_PostAct18_Protected.xlsx" target="_blank">ECR Worksheet (Post Act 18)</a>
            </li>
            <li>
              <a href="https://storage.googleapis.com/fastlane-public-files/EZ/ECR_Worksheets_July2021_Protected_REVISED_3.4.2024.xlsx" target="_blank">ECR Worksheet (July 2021)</a>
            </li>
            <li>Copies of all quarterly ES-4s/SUTA reports for the four months prior to your contract start date.</li>
            <li>Supporting documentation for Number of Nationwide Employees Including Affiliates.</li>
          </ul>
          <p>
            <b>Change of Company Name</b>:</p>
          <ul>
            <li>Secretary of State Verification</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header"  id="ITE">
        <h5 class="mb-0">
          <button class="btn btn-link text-teal pl-0 collapsed" data-toggle="collapse" title="Click to Expand/Collapse" data-target="#collapseITE" aria-expanded="false" aria-controls="collapseITE">
            <h5><i class="fa fa-arrow-alt-circle-right pr-2"></i>Industrial Tax Exemption (ITE) Program</h5>
          </button>
        </h5>
      </div>
      <div id="collapseITE" class="collapse" aria-labelledby="ITE" data-parent="#accordion">
        <div class="card-body">
          <p>The following may be needed when filing forms for the ITE Program.</p>
    <p>
      <b>Applications</b>: </p>
    <ul>
      <li>
        <a href="https://storage.googleapis.com/fastlane-public-files/ITE/ITEBreakdownofPurchases.xlsx" target="_blank">Breakdown of Purchases</a>
      </li>
      <li> <a href="https://storage.googleapis.com/fastlane-public-files/ITE/ProofofMillageLocationVerificationForm.pdf" target="_blank">Proof of millage rate from Parish Assessor</a></li>
      <li>Description of New Jobs</li>
      <li>ES4s</li>
      <li><a href="https://storage.googleapis.com/fastlane-public-files/ITE/ITEPContractAffidavit-Revised-08-15-2022.pdf" target="_blank">Notarized Affidavit</a></li>
      <li><a href="https://storage.googleapis.com/fastlane-public-files/ITE/ITEEmploymentBaselineCalculationWorksheet(Fillable).pdf" target="_blank">Industrial Tax Exemption Program Baseline Employment Worksheet</a></li>
      <li>Exhibit A</li>
      <li>LDR No Objection Letter</li>
      <li>Exhibit B - Sheriff</li>
      <li>Exhibit B - School Board</li>
      <li>Exhibit B - Parish/Police Jury</li>
      <li>Exhibit B - Municipality</li>

    </ul>
    <p>
      <b>Renewal Applications</b>: </p>

    <ul>
      <li><a href="https://storage.googleapis.com/fastlane-public-files/ITE/ITEPContractAffidavit-Revised-08-15-2022.pdf" target="_blank">Notarized Affidavit</a></li>
      <li>Proof of millage rate from Parish Assessor</li>

    </ul>
    <p>
      <b>Annual Compliance</b>: </p>

    <ul>
      <li>Pre-Executive Order
        <ul>
            <li><a href="https://storage.googleapis.com/fastlane-public-files/ITE/Annual%20Project%20Status%20Report%20(Pre%20%26%20Post-EO).doc" target="_blank">Annual Project Status Report (Front-End Apps/Contracts Only)</a></li>
            <li><a href="https://storage.googleapis.com/fastlane-public-files/ITE/Annual%20Report.pdf" target="_blank">Annual Report</a></li>

        </ul>
      </li>
      <li>Post-Executive Order
          <ul>
              <li><a href="https://storage.googleapis.com/fastlane-public-files/ITE/Annual%20Project%20Status%20Report%20(Pre%20%26%20Post-EO).doc" target="_blank">Annual Project Status Report (Front-End Apps/Contracts Only)</a></li>
              <li><a href="https://storage.googleapis.com/fastlane-public-files/ITE/ITEAnnualComplianceReport(ITE%20ACR)%20Rev%204%2022.xlsx" target="_blank">ITE Annual Compliance Report (ITE ACR)</a></li>
              <li><a href="https://storage.googleapis.com/fastlane-public-files/ITE/ITEEmploymentBaselineCalculationWorksheet(Fillable).pdf" target="_blank">Industrial Tax Exemption Program Baseline Employment Worksheet</a></li>

          </ul>
        </li>



    </ul>
    <p>
      <b>Change of Company Name</b>:</p>
    <ul>
      <li>Secretary of State Verification</li>
    </ul>
    <p>
      <b>Full Transfer of Ownership</b>:</p>
    <ul>
      <li>Proof that ownership has been changed with the Louisiana Secretary of State</li>
      <li>Proof that ownership has been changed with the Louisiana Department of Revenue</li>
    </ul>
    <p>
      <b>Partial Transfer of Ownership</b>:</p>
    <ul>
      <li>Proof that ownership has been changed with the Louisiana Secretary of State</li>
      <li>Proof that ownership has been changed with the Louisiana Department of Revenue</li>
    </ul>

        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="QJ">
        <h5 class="mb-0">
          <button class="btn btn-link text-teal pl-0 collapsed" data-toggle="collapse" title="Click to Expand/Collapse" data-target="#collapseQJ" aria-expanded="false" aria-controls="collapseQJ">
            <h5><i class="fa fa-arrow-alt-circle-right pr-2"></i>Quality Jobs (QJ) Program </h5>
          </button>
        </h5>
      </div>
      <div id="collapseQJ" class="collapse" aria-labelledby="QJ" data-parent="#accordion">
        <div class="card-body">
          <p>The following may be needed when filing forms for the QJ Program.</p>
    <p>
      <b>Applications</b>: </p>
    <ul>
      <li>
        <a href="https://storage.googleapis.com/fastlane-public-files/QJ/QJ_InitialBaselineWorksheet_Application.xlsx" target="_blank">Initial Baseline Calculation Worksheet</a> - for Advances filed PRIOR to January 20, 2023
      </li>
      <ul><li>All ES4/SUTA reports filed with the LWC (state-wide, including all affiliates in Louisiana)</li></ul>
      <li>
        <a href="https://storage.googleapis.com/fastlane-public-files/QJ/QJ_InitialBaselineWorksheet_Application_AFTER_JAN_20_23.xlsx" target="_blank">Initial Baseline Calculation Worksheet</a> - for Advances filed ON or AFTER to January 20, 2023
      </li>
      <ul><li>All ES4/SUTA reports filed with the LWC (state-wide, including all affiliates in Louisiana)</li></ul>

      <li>Local Endorsement Resolution - If you are seeking local sales tax rebates, Business Incentive Services must receive the endorsement resolution from the local governing authority prior to the application receiving approval from the Board of Commerce and Industry</li>
    <li>If qualifying as an employer in an oil and gas field service business defined in North American Industry Classification System (NAICS) code 213112 which has Louisiana as the national or regional headquarters of a multi-state business whose service territory includes at least Louisiana and the Gulf of Mexico </li>
  <ul>
    <li>A corporate organizational chart showing the locations of all offices throughout the region and the United States</li>
      <li>Information describing the nature of the services provided by this Louisiana headquarters to other business locations</li>
      <li>The locations and dates the Louisiana headquarters began providing these services to the other locations</li>
      <li>Indicate the territory this location actually services at the time of the advance notification</li>
  </ul>
  </ul>
    <p>
      <b>Employee Certification Reports</b>:</p>
    <ul>
      <li>
        <a href="https://storage.googleapis.com/fastlane-public-files/EZ/ECR_Worksheets_PostAct18_Protected.xlsx" target="_blank">ECR Worksheet (Post Act 18)</a>
      </li>

    </ul>
    <p>
      <b>Annual Certification Report</b>:</p>
    <ul>
        <li>
            <a href="https://storage.googleapis.com/fastlane-public-files/QJ/ACR_Doc_Rev_01_22.pdf" target="_blank">Annual Certification (ACR)</a>
          </li>
      <li>
        <a href="https://storage.googleapis.com/fastlane-public-files/QJ/QJ_ACR_Pre-Act_387_Rev_12_21.xlsx" target="_blank">Baseline & Annual Certification Spreadsheet (Pre-Act 387)</a>
      </li>
      <li>
        <a href="https://storage.googleapis.com/fastlane-public-files/QJ/QJ_ACR_Act_387_Rev_12_21.xlsx" target="_blank">Baseline & Annual Certification Spreadsheet (Act 387)</a>
      </li>
      <li>
        <a href="https://storage.googleapis.com/fastlane-public-files/QJ/QJ_ACR_Act_386_Rev_12_21.xlsx" target="_blank">Baseline & Annual Certification Spreadsheet (Act 386)</a>
      </li>
      <li>
        <a href="https://storage.googleapis.com/fastlane-public-files/QJ/cert_primary_qualification_pre-act_387.pdf" target="_blank">Certification of Primary Qualification (Pre-Act 387)</a>
      </li>
      <li>
        <a href="https://storage.googleapis.com/fastlane-public-files/QJ/cert_primary_qualification_act_387.pdf" target="_blank">Certification of Primary Qualification (Act 387)</a>
      </li>
      <li>
        <a href="https://storage.googleapis.com/fastlane-public-files/QJ/CertificationofPrimaryQualificationAct386.pdf" target="_blank">Certification of Primary Qualification (Act 386)</a>
      </li>
      <li>
        <a href="https://storage.googleapis.com/fastlane-public-files/QJ/ACR-HealthCare-Act387.pdf" target="_blank">ACR-Health Care Certification Form (Act 387)</a>
      </li>
      <li>
        <a href="https://storage.googleapis.com/fastlane-public-files/QJ/QJ_Initial_Baseline_Calculation_Worksheet.pdf" target="_blank">Initial Baseline Calculation Worksheet</a>
      </li>
    </ul>


    <p>
      <b>Change of Company Name</b>:</p>
    <ul>
      <li>Secretary of State Verification</li>
    </ul>
    <p>
      <b>Full Transfer of Ownership</b>:</p>
    <ul>
      <li>Proof that ownership has been changed with the Louisiana Secretary of State</li>
      <li>Proof that ownership has been changed with the Louisiana Department of Revenue</li>
    </ul>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="RTA">
        <h5 class="mb-0">
          <button class="btn btn-link text-teal pl-0 collapsed" data-toggle="collapse" title="Click to Expand/Collapse" data-target="#collapseRTA" aria-expanded="false" aria-controls="collapseRTA">
            <h5><i class="fa fa-arrow-alt-circle-right pr-2"></i>Restoration Tax Abatement (RTA) Program </h5>
          </button>
        </h5>
      </div>
      <div id="collapseRTA" class="collapse" aria-labelledby="RTA" data-parent="#accordion">
        <div class="card-body">
          <p>The following may be needed when filing forms for the RTA Program.</p>
          <p>
            <b>Applications</b>: </p>
          <ul>
            <li>Resolution of the Property Owners / Resolution of Board of Directors with original signatures stating that whomever
              files this documentation has the authority to do</li>
            <li>Proof of Ownership: Act of sale or option to acquire the property.</li>
            <li>Legal property description (suitable for insertion into the exemption contract)</li>
            <li>Plot Map</li>
            <li>A copy of the building permit issued for the project</li>
            <li>Picture of the structure before beginning the project</li>
            <li>A rendering of the structure as it will appear after completion of the project</li>
            <li>Names and addresses of all owners (if a corporation, the principal stockholders of the corporation)</li>
            <li>Current assessed value of the structure only (improvements) and the taxes paid on the structure only</li>
            <li>A copy of the tax invoice for the year prior to beginning of the project from the parish assessor</li>
            <li>Certification from the local governing authority that the structure is in a Downtown Development District, a Historic
              District, or an Economic Development District specifically designated as such for this program.</li>
            <li>Proof of Millage Rate from parish assessor</li>
            <li>
              <a href="https://storage.googleapis.com/fastlane-public-files/RTA/RTA_MinimumRehabCertStatement.pdf" target="_blank">Minimum Rehabilitation Certification Statement</a>
            </li>
            <li>
              <a href="https://storage.googleapis.com/fastlane-public-files/RTA/RTA_24MonthCompletionCertStatement.pdf" target="_blank">24-Month Completion Period Certification</a>
            </li>
            <li>A written certification on the applicants company letterhead or personal letterhead, referencing the RTA application
              number and stating that: "...taxes have not been paid on improvements for which an exemption is being applied for
              under the Restoration Tax Abatement Program pursuant to R.S. 47:4315, paragraph (A)(4)."R.S. 47:4315, paragraph (A)
              (4) of the programs enabling statutes states in part: "... The Board shall not consider an application for exemption
              on any project if ad Valorem taxes have been paid on the basis of an assessed valuation which reflects the improvements
              made by the project.</li>
          </ul>

          <p>
            <b>Change of Company Name</b>:</p>
          <ul>
            <li>Secretary of State Verification</li>
          </ul>
          <p>
            <b>Full Transfer of Ownership</b>:</p>
          <ul>
            <li>Proof that ownership has been changed with the Louisiana Secretary of State</li>
            <li>Proof that ownership has been changed with the Louisiana Department of Revenue</li>
          </ul>
          <p>
            <b>Partial Transfer of Ownership</b>:</p>
          <ul>
            <li>Proof that ownership has been changed with the Louisiana Secretary of State</li>
            <li>Proof that ownership has been changed with the Louisiana Department of Revenue</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

  <div class="col-6">
    <div class=" m-b">
      <h4 class="text-blue">
        <b><i class="fa fa-video pr-2"></i>Entertainment Incentives</b>
      </h4>
     <p> <b>Click a checklist to view the forms needed when filing for each Entertainment Incentive program.</b></p>
     <img class="center w-100" alt="image" src="assets/images/doc2.jpg">
    </div>

    <div id="accordion">
        <div class="card">
          <div class="card-header" id="DM">
            <h5 class="mb-0">
              <button class="btn btn-link text-teal pl-0 collapsed" data-toggle="collapse" title="Click to Expand/Collapse" data-target="#collapseDM" aria-expanded="true" aria-controls="collapseDM">
                  <h5><i class="fa fa-arrow-alt-circle-right pr-2"></i> Digital Interactive Media and Software Tax Credit (DM) Program</h5>
              </button>
            </h5>
          </div>

          <div id="collapseDM" class="collapse" aria-labelledby="DM" data-parent="#accordion">
            <div class="card-body">
                <p>The following may be needed when filing forms for the Digital Interactive Media and Software Tax Credit (DM) Program.</p>

                <p>
                  <b>Application</b>: </p>
                <ul>
                  <li><a href="https://storage.googleapis.com/fastlane-public-files/DM/Expenditure/Preliminary%20Schedule%20of%20Expenditures%2018-7%20%20-%20FLNG%20Import%20Template-%20Locked.xlsx" target="_blank">Preliminary Schedule of Expenditures Template</a></li>

                    </ul>
                <p>
                    <b>Expenditure Verification Report</b>: </p>
                  <ul>
                    <li>Cost Report
                      </li>
                      </ul>
                      <p>
                        <b>Initial Certification Documents</b>: </p>
                      <ul>
                        <li><a href="https://storage.googleapis.com/fastlane-public-files/DM/2017DMStatute.pdf" target="_blank">DM Statute</a></li>
                        <li><a href="https://storage.googleapis.com/fastlane-public-files/DM/2017DMRules.pdf" target="_blank">DM Rules</a></li>
                        <li><a href="https://storage.googleapis.com/fastlane-public-files/DM/DMAUP-2017.pdf" target="_blank">Agreed Upon Procedures (AUP)</a></li>
                        <li><a href="https://storage.googleapis.com/fastlane-public-files/DM/DM-CostReportTemplate.pdf" target="_blank">Cost Report Format</a></li>
                        <li><a href="https://storage.googleapis.com/fastlane-public-files/DM/LouisianaEntertainment-LogoGuide.pdf" target="_blank">Logo Guidelines</a></li>
                        <li><a href="https://storage.googleapis.com/fastlane-public-files/DM/2018EntLogo.zip" target="_blank">Logo Files</a></li>
                          </ul>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="Film">
            <h5 class="mb-0">
              <button class="btn btn-link text-teal pl-0 collapsed" data-toggle="collapse" title="Click to Expand/Collapse" data-target="#collapseFilm" aria-expanded="false" aria-controls="collapseFilm">
                  <h5><i class="fa fa-arrow-alt-circle-right pr-2"></i> Motion Picture Production Tax Credit (FILM) Program </h5>
              </button>
            </h5>
          </div>
          <div id="collapseFilm" class="collapse" aria-labelledby="Film" data-parent="#accordion">
            <div class="card-body">
                <p>The following may be needed when filing forms for the Motion Picture Production Tax Credit (FILM) Program.</p>
                <p>
                  <b>Applications</b>: </p>
                <ul>
                  <li>
                      Detailed Preliminary Budget (including above & below the line hires)
                  </li>
                  <li>Detailed Distribution Plan</li>
                  <li>Script (3-5 pages)</li>
                  <li><a href="https://storage.googleapis.com/fastlane-public-files/FILM/Notarized-Statement-creditor-claims.docx" target="_blank">Scanned Notarized statement in accordance with the provisions of LA RS 47:6007 (D)(2)(e)</a></li>
                  <li>Louisiana Spend Budget</li>
                  <li>Declaration of Louisiana residency for LA screenwriter</li>
                  <li>Proof of authorship for LA screenwriter</li>
                  <li>If LA spend includes Finance fees, insurance fees, or goods from a procurement company, documentation describing these expenditures may be required.</li>
                  </ul>
                  <p>
                      <b>Additional Season Applications</b>: </p>
                    <ul>
                      <li>
                          Detailed Preliminary Budget (including above & below the line hires)
                      </li>
                      <li>Detailed Distribution Plan</li>
                      <li>Script (3-5 pages)</li>
                      <li><a href="https://storage.googleapis.com/fastlane-public-files/FILM/Notarized-Statement-creditor-claims.docx" target="_blank">Scanned Notarized statement in accordance with the provisions of LA RS 47:6007 (D)(2)(e)</a></li>
                      <li>Louisiana Spend Budget</li>
                      <li>Declaration of Louisiana residency for LA screenwriter</li>
                      <li>Proof of authorship for LA screenwriter</li>
                      <li>If LA spend includes Finance fees, insurance fees, or goods from a procurement company, documentation describing these expenditures may be required.</li>
                      </ul>
                      <p>
                          <b>Audit</b>: </p>
                        <ul>
                          <li>Cost Report
                            </li>
                            </ul>

            </div>
          </div>
        </div>

      </div>







    </div>

</div>



</div>
