import { Injectable } from '@angular/core';
import { Payment } from '../../fiscal/models/payment.model';
import { FormShareService } from './share.service';
import { ProjectDataService } from '../../project/services/project-data.service';
import { ProjectDetailService } from '../../project/services/project-shared.service';
import { PayPointReturnCode } from '../../fiscal/fiscal.constants';
import { FormBuilder } from '@angular/forms';
import { FormValidationService } from './validation.service';
import { Observable, throwError, Subject, of, interval } from 'rxjs';
import { ProjectForm } from '../../project/models/form.model';
import { FormIdentifier } from '../../project/models/form-identifier.model';
import { Credit as FilmCredit } from '../../entertainment/models/film/credit.model';
import { PublishSubscribeService } from '../../fastlane-common/services/publish-subscribe.service';
import {
  events,
  publishSubscribeEventStrings
} from '../../fastlane-common/event/event.constants';
import { EventContext } from '../../fastlane-common/event/interfaces/event-context.interface';
import {
  formDateActionMap,
  incentiveProgram,
  updateFormDateActions,
  dateTypes,
  legislationRuleNames
} from '../../project/project.constants';
import * as _ from 'underscore';
import { OutgoingPayment } from '../../fiscal/models/outgoing-payment.model';
import {
  CastProject,
  setStartoftheDayTime
} from '../../project/project.functions';
import { Audit } from '../../entertainment/models/film/audit.model';
import { ProjectDate } from '../../project/models/project-date.model';
import { FormType, formStatuses, formTypes } from '../form.constants';
import { Credit as DigitalCredit } from '../../entertainment/models/digital/credit.model';
import { CreditType } from '../../entertainment/models/creditType.model';
import { first, switchMap } from 'rxjs/operators';
import swal from 'sweetalert2';
import { OrbiPayPaymentRequest } from '../models/orbipay-payment-request.model';
import { AttachmentsService } from '../../project/services/attachments.service';
import { UserContextService } from '../../user/services/user-context.service';
import { SwalService } from '../../fastlane-common/services/swal.service';
import { incentiveCategory } from '../../project/project.constants';

@Injectable()
export class FormDataService {
  formSavedSubject: Subject<ProjectForm>;

  constructor(
    private fb: FormBuilder,
    private shareService: FormShareService,
    private projectDataService: ProjectDataService,
    private validationService: FormValidationService,
    private projectShareService: ProjectDetailService,
    private pubSubService: PublishSubscribeService,
    private attachmentService: AttachmentsService,
    private userContext: UserContextService,
    private swal: SwalService
  ) {
    this.formSavedSubject = new Subject<ProjectForm>();

    //#region FormDatesEventHandlers

    pubSubService.handleAllActionsForEvents<EventContext<ProjectForm>>(
      [events.updateFormDate.code],
      context => {
        const dateUpater = _.findWhere(formDateActionMap, {
          formDateAction: context.eventAction,
          incentiveProgram: projectShareService.currentIncentiveProgram,
          formType: context.data.type
        });
        if (dateUpater) {
          dateUpater.handler(
            projectShareService.projectDates,
            context.data,
            projectShareService.project.id,
            context.eventAction
          );
          if (
            ![
              updateFormDateActions.relatedApplicationReceivedDateChanged
            ].includes(context.eventAction)
          ) {
            pubSubService.publish(publishSubscribeEventStrings.formDateChanged);
          }
          pubSubService.publish(publishSubscribeEventStrings.update_formDate);
        }
      }
    );
    //#endregion
  }

  createPayment(immaturePayment: Payment) {
    return this.projectDataService.createPayment(immaturePayment);
  }

  fakePayment(immaturePayment: Payment) {
    return this.projectDataService.simulateSuccessfulPayment(immaturePayment);
  }

  registerConfirmation(payment: Payment): Promise<any> {
    const that = this;
    return new Promise((resolve, reject) => {
      that.projectDataService.registerConfirmation(payment).subscribe(
        resolvedProject => {
          // Replace old shared values with new ones that reflect changes after payment
          that.projectShareService.setResolvedProject(resolvedProject);

          if (payment.returnCode !== PayPointReturnCode.Success) {
            let returnMessage: string;
            switch (payment.returnCode) {
              case PayPointReturnCode.CommunicationError:
                returnMessage =
                  'Payment transaction failed due to a communication error.';
                break;
              case PayPointReturnCode.Declined:
                returnMessage = 'Your payment method was declined.';
                break;
              case PayPointReturnCode.TechnicalDifficultyError:
                returnMessage =
                  'Payment transaction failed due to a technical difficulty error.';
                break;
              case PayPointReturnCode.UnacceptedCardType:
                returnMessage = 'We do not accept the card type submitted.';
                break;
              case PayPointReturnCode.VerificationFailed:
                returnMessage = 'Failed to verify your payment method.';
                break;
            }
            reject(returnMessage);
          }

          resolve();
        },
        error => {
          reject(
            'An error occurred while registering your payment confirmation.'
          );
        }
      );
    });
  }

  saveCredits() {
    const that = this;
    let credits: CreditType[];
    if (
      that.projectShareService.currentIncentiveProgram ===
      incentiveProgram.film.code
    ) {
      credits = that.projectShareService.filmCredits;
    }
    if (
      that.projectShareService.currentIncentiveProgram ===
      incentiveProgram.dm.code
    ) {
      credits = that.projectShareService.digitalCredits;
    }
    return new Observable<CreditType[]>(subscriber => {
      that.projectDataService
        .updateCredits(
          credits,
          that.projectShareService.project.id,
          that.projectShareService.currentIncentiveProgram
        )
        .subscribe(
          success => {
            if (
              that.projectShareService.currentIncentiveProgram ===
              incentiveProgram.film.code
            ) {
              subscriber.next(that.projectShareService.filmCredits);
            }
            if (
              that.projectShareService.currentIncentiveProgram ===
              incentiveProgram.dm.code
            ) {
              subscriber.next(that.projectShareService.digitalCredits);
            }
            subscriber.complete();
          },
          error => {
            throwError('An error occurred while saving your form.');
            subscriber.complete();
          }
        );
    });
  }

  saveForm() {
    // This instantiation of the formbuilder to make the form readonly after signing.
    this.validationService.form = this.fb.group({});

    // Saving a form actually saves the underlying project
    const that = this;
    return new Observable<ProjectForm>(subscriber => {
      that.projectDataService
        .updateProject(that.shareService.project)
        .subscribe(
          success => {
            that.projectDataService
              .updateProjectDates(
                that.projectShareService.projectDates,
                that.shareService.project.id
              )
              .subscribe(
                saved => {
                  if (this.attachmentService.newAttachments.length > 0)
                  {
                    if (
                      incentiveCategory.entertainment.incentivePrograms.includes(this.shareService.project.projectInfo.incentiveProgram)
                      && (this.userContext.currentUser.userType == "applicant" || this.userContext.currentUser.userType == "auditor")
                      && !(
                        (
                          this.shareService.form.currentFormStatus == "Pending" ||
                          this.shareService.form.currentFormStatus == "Pending Payment" ||
                          this.shareService.form.currentFormStatus == "Pending Signature"
                        ) &&
                        this.shareService.form.type == formTypes.application.abbrev
                      )
                    ) {
                      that.swal.load('Emailing Managers...');
                      this.attachmentService.emailNewAttachments(this.shareService.project).subscribe(
                        successfulEmail => { }
                      );
                    }
                    else {
                      this.attachmentService.removeFromNewAttachments();
                    }
                  }
                  // We next the formSavedSubject every time the form is saved to notify any listeners that a form was saved successfully
                  that.formSavedSubject.next(that.shareService.form);

                  // For the currect observable, emit a value and close the observable
                  subscriber.next(that.shareService.form);
                  subscriber.complete();
                },
                failed => {
                  subscriber.error(failed);
                }
              );
          },
          error => {
            subscriber.error(error);
          }
        );
    });
  }

  takeFormSnapShot() {
    const form = this.shareService.form;
    const formId = new FormIdentifier({
      formType: form.type,
      formIndex: form.formIndex,
      projectGuid: this.shareService.project.id
    });
    return this.projectDataService.createFormSnapshot(formId);
  }

  saveOutgoingPayment(outgoingPayment: OutgoingPayment) {
    const that = this;
    return new Observable<OutgoingPayment>(subscriber => {
      that.projectDataService.addOutgoingPayment(outgoingPayment).subscribe(
        success => {
          subscriber.next(success);
          subscriber.complete();
        },
        error => {
          throwError('An error occurred while adding your payment.');
          subscriber.complete();
        }
      );
    });
  }

  updateOutgoingPayment(auditorPayment: OutgoingPayment) {
    const that = this;
    return new Observable<OutgoingPayment>(subscriber => {
      that.projectDataService.updateOutgoingPayment(auditorPayment).subscribe(
        success => {
          subscriber.next(success);
          subscriber.complete();
        },
        error => {
          throwError('An error occurred while updating your payment.');
          subscriber.complete();
        }
      );
    });
  }
  checkPendingOutgoingPaymentsForForm(formId: FormIdentifier) {
    const that = this;
    return new Observable<OutgoingPayment[]>(subscriber => {
      that.projectDataService.getUnpaidOutgoingPayments(formId).subscribe(
        success => {
          subscriber.next(success);
          subscriber.complete();
        },
        error => {
          throwError('An error occurred while getting your payments.');
          subscriber.complete();
        }
      );
    });
  }
  updateAuditorPaymentForForm(auditorPayment: OutgoingPayment) {
    const that = this;
    return new Promise((resolve, reject) => {
      that.projectDataService
        .getProject(auditorPayment.paymentProject.formId.projectGuid)
        .subscribe(project => {
          const prototypeProject = CastProject(
            project,
            project.projectInfo.incentiveProgram
          );
          Object.assign(prototypeProject, project);

          prototypeProject.init();

          const audit = <Audit>(
            prototypeProject.getProjectForm(
              auditorPayment.paymentProject.formId
            )
          );
          const index = audit.auditorPayments.findIndex(
            payment => payment.id === auditorPayment.id
          );
          if (index >= 0) {
            audit.auditorPayments[index] = auditorPayment;
          }
          that.projectDataService.updateProject(prototypeProject).subscribe(
            () => {
              resolve();
            },
            error =>
              reject(
                'An error occurred while changing the status of a payment.'
              )
          );
        });
    });
  }

  addAuditFormDate(
    formDate: {
      name: string;
      form: FormType;
      isDueDate: boolean;
      isFormDate: boolean;
      category: string;
    },
    formId: FormIdentifier,
    currentUserId: string
  ) {
    const newDate = new ProjectDate();
    newDate.type = formDate.name;
    newDate.category = formDate.category;
    newDate.formId = formId;
    newDate.projectId = this.shareService.project.projectInfo.projectId;
    newDate.created = new Date();
    newDate.creator = currentUserId;
    newDate.date = setStartoftheDayTime(new Date());
    this.projectShareService.projectDates.push(newDate);
  }

  // confirmToken(token: any): Observable<any> {
  //   return interval(5000).pipe(first(),switchMap(() => of({getOut: 'now!!'})));
  // }

  confirmToken(paymentRequest: OrbiPayPaymentRequest): Observable<Payment> {
     return this.projectDataService.getOrbiPayConfirmation(paymentRequest);
  }


}
