import {Address} from '../../shared/models/address.model';

export class Location {
  address: Address;
  coordinates: number[];
  formattedAddress: string;
  googlePlaceId: string;
  inCityLimits?: boolean;
  parish: string;
  region: string;
  type: string;
  viewport: number[][];

  constructor(location?: any) {
    location = location || {};

    this.address = location.address
      ? new Address(location.address)
      : new Address();
    this.coordinates = location.coordinates || null;
    this.formattedAddress = location.formattedAddress || null;
    this.googlePlaceId = location.googlePlaceId || null;
    this.inCityLimits = location.inCityLimits || null;
    this.parish = location.parish || null;
    this.region = location.region || null;
    this.viewport = location.viewport || null;
  }
}
