<div class="card h-100 border">
  <div *ngIf="!isAudit" class="card-header">
    <b>ESTIMATED JOBS</b>
  </div>
  <div *ngIf="isAudit" class="card-header">
    <b>ACTUAL JOBS</b>
  </div>

  <div class="card-body form-group px-3 mb-0 d-flex flex-wrap flex-column justify-content-between no-borders" [formGroup]="jobsForm">


    <div class="row py-1 w-100 mx-0">
      <label *ngIf="!isAudit" class="col-12 col-xl-6 form-control-label pr-0">Estimated Above the Line Jobs:</label>
      <label *ngIf="isAudit" class="col-12 col-xl-6 form-control-label pr-0">Actual Above the Line Jobs:</label>
      <div class="col-12 col-xl-6 float-right">
        <input id="aboveTheLineJobs" formControlName="aboveTheLineJobs" type="number" class="form-control" pattern="[0-9]+" placeholder="0">
      </div>
    </div>

    <div class="row py-1 w-100 mx-0">
      <label *ngIf="!isAudit" class="col-12 col-xl-6 form-control-label pr-0">Estimated Below the Line Jobs:</label>
      <label *ngIf="isAudit" class="col-12 col-xl-6 form-control-label pr-0">Actual Below the Line Jobs:</label>
      <div class="col-12 col-xl-6 float-right">
        <input id="belowTheLineJobs" formControlName="belowTheLineJobs" type="number"
          class="form-control" pattern="[0-9]+" placeholder="0">
                
      </div>
    </div>

    <div class="row py-1 w-100 mx-0">
      <label *ngIf="!isAudit" class="col-12 col-xl-6 form-control-label pr-0">Estimated Extras:</label>
      <label *ngIf="isAudit" class="col-12 col-xl-6 form-control-label pr-0">Actual Extras:</label>
      <div class="col-12 col-xl-6 float-right">
        <input id="extras" formControlName="extras" type="number"
          class="form-control" pattern="[0-9]+" placeholder="0">
      </div>
    </div>

    <div class="row py-1 w-100 mx-0">
      <label *ngIf="!isAudit" class="col-12 col-xl-6 form-control-label pr-0">
        <b>Estimated Total Jobs:</b>
      </label>
      <label *ngIf="isAudit" class="col-12 col-xl-6 form-control-label pr-0">
        <b>Actual Total Jobs:</b>
      </label>
      <div class="col-12 col-xl-6">
        <input readonly tabindex="-1" type="text" class="form-control bold-font" value="{{(jobs.aboveTheLineJobs || 0) + (jobs.belowTheLineJobs || 0) + (jobs.extras || 0)}}">
      </div>
    </div>
  </div>
</div>