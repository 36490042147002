import { Component, OnInit, Input } from '@angular/core';
import { InitialCertCriticalDates } from '../../../models/film/initialCert-critical-dates.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EventContext } from '../../../../fastlane-common/event/interfaces/event-context.interface';
import { ProjectForm } from '../../../../project/models/form.model';
import { events } from '../../../../fastlane-common/event/event.constants';
import { updateFormDateActions } from '../../../../project/project.constants';
import { FormShareService } from '../../../../form/services/share.service';
import { PublishSubscribeService } from '../../../../fastlane-common/services/publish-subscribe.service';
import * as _ from 'underscore';
import { Application } from '../../../models/film/film-application.model';
import { InitialCertification } from '../../../models/film/initial-certification.model';
import { deepCopy } from '../../../../shared/shared.functions';
import { ProjectDetailService } from '../../../../project/services/project-shared.service';
import { FormValidationService } from '../../../../form/services/validation.service';

@Component({
  selector: 'fl-film-initialcert-form-critical-dates',
  templateUrl: './form-critical-dates.component.html',
  styleUrls: ['./form-critical-dates.component.scss']
})
export class FilmInitialCertFormCriticalDatesComponent implements OnInit {
  @Input() initialCertCriticalDate: InitialCertCriticalDates;
  formCriticalDatesFormGroup: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private formShareService: FormShareService,
    private projectShareService: ProjectDetailService,
    private pubSubService: PublishSubscribeService,
    private validationService: FormValidationService
  ) {}

  ngOnInit() {
    const that = this;
    const initialCert = <InitialCertification>this.formShareService.form;
    const sourceFormId = initialCert.sourceFormId;
    if (sourceFormId) {
      const applicationSnapshot = _.extend(
        new Application(),
        sourceFormId.formIndex >= 0
          ? deepCopy(
              this.projectShareService.filmProject[sourceFormId.formType][
                sourceFormId.formIndex
              ]
            )
          : deepCopy(
              this.projectShareService.filmProject[sourceFormId.formType]
            )
      );
      this.initialCertCriticalDate.relatedApplicationReceivedDate = new Date(
        applicationSnapshot.receivedDate
      );
      this.raiseRelatedApplicationReceivedDateChanged();
    }
    this.formCriticalDatesFormGroup = that.formBuilder.group({
      relatedApplicationReceivedDateGroup: that.formBuilder.group({}),
      initialCertIssuanceDateGroup: that.formBuilder.group({}),
      eligibleExpenditureStartDateGroup: that.formBuilder.group({}),
      eligibleExpenditureEndDateGroup: that.formBuilder.group({}),
      costReportFinalEligibleDateGroup: that.formBuilder.group({}),
      postProductionCostReportDateGroup: that.formBuilder.group({}),
      principalPhotographyStartDueDateGroup: that.formBuilder.group({}),
      proofofPrincipalPhotographyReceivedDateGroup: that.formBuilder.group({})
    });
    this.validationService.form.setControl(
      'initialCertCriticalDates',
      this.formCriticalDatesFormGroup
    );
  }
  estimatedDateChanged(property: string, date: Date) {
    this.initialCertCriticalDate[property] = date;
    if (property === 'relatedApplicationReceivedDate') {
      this.raiseRelatedApplicationReceivedDateChanged();
    }
    if (property === 'initialCertIssuanceDate') {
      this.raiseInitialCertIssuanceDateChanged();
    }
    if (property === 'eligibleExpenditureStartDate') {
      this.raiseEligibleExpenditureStartDateChanged();
    }
    if (property === 'eligibleExpenditureEndDate') {
      this.raiseEligibleExpenditureEndDateChanged();
    }
    if (property === 'costReportFinalEligibleDate') {
      this.raiseCostReportFinalEligibleDateChanged();
    }
    if (property === 'postProductionCostReportDate') {
      this.raisePostProductionCostReportDateChanged();
    }
    if (property === 'principalPhotographyStartDueDate') {
      this.raisePrincipalPhotographyStartDueDateChanged();
    }
    if (property === 'proofofPrincipalPhotographyReceivedDate') {
      this.raiseProofofPrincipalPhotographyReceivedDateChanged();
    }
  }

  //#region Raise Event Handlers
  raiseRelatedApplicationReceivedDateChanged() {
    const that = this;
    const eventContext = <EventContext<ProjectForm>>{
      data: that.formShareService.form,
      eventType: events.updateFormDate.code,
      eventAction: updateFormDateActions.relatedApplicationReceivedDateChanged
    };
    this.pubSubService.raise<EventContext<ProjectForm>>(
      eventContext.eventType,
      eventContext.eventAction,
      eventContext
    );
  }
  raiseInitialCertIssuanceDateChanged() {
    const that = this;
    const eventContext = <EventContext<ProjectForm>>{
      data: that.formShareService.form,
      eventType: events.updateFormDate.code,
      eventAction: updateFormDateActions.initialCertIssuanceDateChanged
    };
    this.pubSubService.raise<EventContext<ProjectForm>>(
      eventContext.eventType,
      eventContext.eventAction,
      eventContext
    );
  }
  raiseEligibleExpenditureStartDateChanged() {
    const that = this;
    const eventContext = <EventContext<ProjectForm>>{
      data: that.formShareService.form,
      eventType: events.updateFormDate.code,
      eventAction: updateFormDateActions.eligibleExpenditureStartDateChanged
    };
    this.pubSubService.raise<EventContext<ProjectForm>>(
      eventContext.eventType,
      eventContext.eventAction,
      eventContext
    );
  }
  raiseEligibleExpenditureEndDateChanged() {
    const that = this;
    const eventContext = <EventContext<ProjectForm>>{
      data: that.formShareService.form,
      eventType: events.updateFormDate.code,
      eventAction: updateFormDateActions.eligibleExpenditureEndDateChanged
    };
    this.pubSubService.raise<EventContext<ProjectForm>>(
      eventContext.eventType,
      eventContext.eventAction,
      eventContext
    );
  }
  raiseCostReportFinalEligibleDateChanged() {
    const that = this;
    const eventContext = <EventContext<ProjectForm>>{
      data: that.formShareService.form,
      eventType: events.updateFormDate.code,
      eventAction: updateFormDateActions.costReportFinalEligibleDateChanged
    };
    this.pubSubService.raise<EventContext<ProjectForm>>(
      eventContext.eventType,
      eventContext.eventAction,
      eventContext
    );
  }
  raisePostProductionCostReportDateChanged() {
    const that = this;
    const eventContext = <EventContext<ProjectForm>>{
      data: that.formShareService.form,
      eventType: events.updateFormDate.code,
      eventAction: updateFormDateActions.postProductionCostReportDateChanged
    };
    this.pubSubService.raise<EventContext<ProjectForm>>(
      eventContext.eventType,
      eventContext.eventAction,
      eventContext
    );
  }
  raisePrincipalPhotographyStartDueDateChanged() {
    const that = this;
    const eventContext = <EventContext<ProjectForm>>{
      data: that.formShareService.form,
      eventType: events.updateFormDate.code,
      eventAction: updateFormDateActions.principalPhotographyStartDueDateChanged
    };
    this.pubSubService.raise<EventContext<ProjectForm>>(
      eventContext.eventType,
      eventContext.eventAction,
      eventContext
    );
  }
  raiseProofofPrincipalPhotographyReceivedDateChanged() {
    const that = this;
    const eventContext = <EventContext<ProjectForm>>{
      data: that.formShareService.form,
      eventType: events.updateFormDate.code,
      eventAction:
        updateFormDateActions.proofofPrincipalPhotographyReceivedDateChanged
    };
    this.pubSubService.raise<EventContext<ProjectForm>>(
      eventContext.eventType,
      eventContext.eventAction,
      eventContext
    );
  }
  //#endregion
}
