import { ProjectForm } from './form.model';
import { formTypes } from 'app/form/form.constants';

export class SpecialRequest extends ProjectForm {
  comments: string;

  constructor(formIndex: number = null) {
    super(formIndex);
    this.init();
  }

  init(): SpecialRequest {
    super.init();

    this.type = formTypes.specialRequest.abbrev;

    return this;
  }

  get qualifier() {
    return '';
  }
}
