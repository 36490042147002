import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { formTypes } from '../../../../form/form.constants';
import { FormShareService } from '../../../../form/services/share.service';

@Component({
  selector: 'fl-programming-languages',
  templateUrl: './programming-languages.component.html',
  styleUrls: ['./programming-languages.component.scss']
})
export class ProgrammingLanguagesComponent implements OnInit {
  programmingLanguages: String[];
  invalidObservable: Observable<boolean>;
  @Input() readOnly = false;
  constructor(private formShareService: FormShareService) {}
  // #region "Custom Methods"
  /**
   * @summary Add programming language
   */
  addProgrammingLanguage(programmingLanguageControl: HTMLInputElement) {
    if (programmingLanguageControl.value.length > 0) {
      if (
        this.programmingLanguages.filter(
          x => x === programmingLanguageControl.value.trim()
        ).length === 0
      ) {
        this.programmingLanguages.push(programmingLanguageControl.value.trim());
        programmingLanguageControl.value = null;
        this.emitValidityObservable();
      }
    }
  }
  deleteProgrammingLanguage(i: number) {
    this.programmingLanguages.splice(i, 1);
    this.emitValidityObservable();
  }
  trackElement(index: number, element: any) {
    return element ? element.programmingLanguage : null;
  }
  /**
   * @summary Validate if there is at least one array then it is valid.
   */
  emitValidityObservable() {
    const that = this;
    this.invalidObservable = new Observable<boolean>(subscriber => {
      setTimeout(() => {
        subscriber.next(
          (that.programmingLanguages && that.programmingLanguages.length > 0) ||
            (this.formShareService.form.type === formTypes.initialCert.abbrev ||
              this.formShareService.form.type === formTypes.audit.abbrev)
            ? false
            : true
        );
        subscriber.complete();
        subscriber.unsubscribe();
      });
    });
  }
  // #endregion
  // #region "Custom Methods"
  ngOnInit() {
    this.emitValidityObservable();
  }
  // #endregion
}
