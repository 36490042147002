import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ProjectDataService } from '../../../project/services/project-data.service';
import { Subscription ,  Subject } from 'rxjs';
import { PublicAdvance } from '../../models/public-advance.model';
import { formatDateForDisplay } from '../../../shared/shared.functions';
import { SwalService } from '../../../fastlane-common/services/swal.service';
import { formatCurrency } from '../../../project/project.functions';
import { Address } from '../../../shared/models/address.model';

declare var $;

@Component({
  selector: 'fl-advance-public-search',
  templateUrl: './advance-public-search.component.html',
  styleUrls: ['./advance-public-search.component.scss']
})
export class AdvancePublicSearchComponent implements OnInit, OnDestroy {
  @Input() parentSubject: Subject<any>;
  @Input() parentSearchFilters: any;
  searchFilters = {};
  table;

  private dataSubscription: Subscription;
  private subjectSubscription: Subscription;

  constructor(
    private projectDataService: ProjectDataService,
    private swalService: SwalService
  ) {}

  dataIsNull(value) {
    if (value === '' || value == null) {
      return '';
    }

    return ', ' + value;
  }

  drawTable() {
    if (this.table) {
      this.table.draw();
    } else {
      this.initializeDataTable();
    }
  }

  getDate(value: any) {
    return formatDateForDisplay(new Date(value));
  }

  getAddress(value: PublicAdvance) {
    return new Address(value.projectInfo.location.address).toStringInline();
  }

  initializeDataTable() {
    const that = this;

    this.table = $('#advance-table').DataTable({
      serverSide: true,
      initComplete: function(settings, json) {},
      ajax: function(data, callback, settings) {
        Object.assign(data, that.searchFilters);
        if (that.dataSubscription && !that.dataSubscription.closed) {
          console.log('unsubscribed from data subs');
          that.dataSubscription.unsubscribe();
          that.dataSubscription = null;
        }

        that.dataSubscription = that.projectDataService
          .getPublicReportsForDataTablesCancellable(data, callback)
          .subscribe(() => setTimeout(() => that.table.columns.adjust(), 1000));
      },
      columns: [
        {
          title: 'Project ID',
          name: 'projectInfo.projectId',
          className: 'text-nowrap',
          data: 'projectInfo.projectId'
        },
        {
          title: 'Company Name',
          name: 'projectInfo.companyName',
          data: 'projectInfo.companyName'
        },
        {
          title: 'Project Physical Location',
          name: 'projectInfo.location.address.addressLine1',
          render: function(data, type, row) {
            return that.getAddress(row);
          }
        },
        {
          title: 'Project Parish',
          name: 'projectInfo.location.parish',
          data: 'projectInfo.location.parish',
          defaultContent: ''
        },
        {
          title: 'Project Current Status',
          name: 'projectStatusLog.status',
          data: 'projectStatusLog.status',
          defaultContent: ''
        },
        {
          title: 'Region',
          name: 'projectInfo.location.region',
          data: 'projectInfo.location.region',
          defaultContent: ''
        },
        {
          title: 'Advance Received Date',
          name: 'advance.receivedDate',
          render: function(data, type, row: PublicAdvance) {
            return that.getDate(row.advance.receivedDate);
          }
        },
        {
          title: 'NAICS',
          name: 'advance.projectDetails.naicsCode',
          data: 'advance.projectDetails.naicsCode',
          defaultContent: ''
        },
        {
          title: 'Estimated Investment Total',
          name: 'advance.estimatedInvestments.totalInvestments',
          render: function(data, type, row: PublicAdvance) {
            return formatCurrency(
              row.advance.estimatedInvestments.totalInvestments
            );
          },
          defaultContent: '0'
        },
        {
          title: 'Estimated Payroll Total',
          name: 'advance.estimatedPayroll.totalPayroll',
          render: function(data, type, row: PublicAdvance) {
            return formatCurrency(row.advance.estimatedPayroll.totalPayroll);
          },
          defaultContent: '0'
        },
        {
          title: 'Estimated Jobs Total',
          name: 'advance.estimatedJobs.totalJobs',
          data: 'advance.estimatedJobs.totalJobs',
          defaultContent: '0'
        },
        {
          title: 'Estimated New Payroll',
          name: 'advance.estimatedPayroll.new',
          render: function(data, type, row: PublicAdvance) {
            return formatCurrency(row.advance.estimatedPayroll.new);
          },
          defaultContent: '0'
        },
        {
          title: 'Estimated New Jobs',
          name: 'advance.estimatedJobs.new',
          data: 'advance.estimatedJobs.new',
          defaultContent: '0'
        },
        {
          title: 'Existing Jobs',
          name: 'advance.estimatedJobs.existing',
          data: 'advance.estimatedJobs.existing',
          defaultContent: '0'
        },
        {
          title: 'Existing Payroll',
          name: 'advance.estimatedPayroll.existing',
          render: function(data, type, row: PublicAdvance) {
            return formatCurrency(row.advance.estimatedPayroll.existing);
          },
          defaultContent: '0'
        },
        {
          title: 'Estimated Construction Jobs',
          data: 'advance.estimatedJobs.construction',
          defaultContent: '0'
        },
        {
          title: 'Estimated Construction Payroll',
          name: 'advance.estimatedPayroll.construction',
          render: function(data, type, row: PublicAdvance) {
            return formatCurrency(row.advance.estimatedPayroll.construction);
          },
          defaultContent: '0'
        },
        {
          title: 'Project Description',
          name: 'advance.projectDetails.projectDescription',
          data: 'advance.projectDetails.projectDescription',
          defaultContent: ''
        },
        {
          title: 'Project Type',
          name: 'advance.projectDetails.projectType',
          data: 'advance.projectDetails.projectType',
          defaultContent: ''
        },
        {
          title: 'Estimated Project Start Date',
          name: 'advance.projectDetails.projectStartDate',
          render: function(data, type, row: PublicAdvance) {
            if (row.advance.projectDetails.projectStartDate) {
              return formatDateForDisplay(
                new Date(row.advance.projectDetails.projectStartDate)
              );
            } else {
              return 'N/A';
            }
          },
          defaultContent: ''
        },
        {
          title: 'Estimated Project End Date',
          name: 'advance.projectDetails.projectEndDate',
          render: function(data, type, row: PublicAdvance) {
            if (row.advance.projectDetails.projectEndDate) {
              return formatDateForDisplay(
                new Date(row.advance.projectDetails.projectEndDate)
              );
            } else {
              return 'N/A';
            }
          },

          defaultContent: ''
        },
        {
          title: 'Program',
          name: 'projectInfo.incentiveProgram',
          data: 'projectInfo.incentiveProgram'
        },
        {
          title: 'Legislation Rule',
          name: 'projectInfo.legislation',
          data: 'projectInfo.legislation'
        },
        {
          title: 'Estimated Building & Material',
          name: 'advance.estimatedInvestments.buildingAndMaterials',
          render: function(data, type, row: PublicAdvance) {
            return formatCurrency(
              row.advance.estimatedInvestments.buildingAndMaterials
            );
          },
          defaultContent: '0'
        },
        {
          title: 'Estimated Machinery & Equipment',
          name: 'advance.estimatedInvestments.machineryAndEquipment',
          render: function(data, type, row: PublicAdvance) {
            return formatCurrency(
              row.advance.estimatedInvestments.machineryAndEquipment
            );
          },
          defaultContent: '0'
        },
        {
          title: 'Estimated Labor & Engineering',
          name: 'advance.estimatedInvestments.laborAndEngineering',
          render: function(data, type, row: PublicAdvance) {
            return formatCurrency(
              row.advance.estimatedInvestments.laborAndEngineering
            );
          },
          defaultContent: '0'
        }
      ],
      pageLength: 1000,
      lengthMenu: [50, 75, 100, 1000],
      dom:
        `<'col-xl-12'<'row d-flex justify-content-between'<'p-0'f><'ml-2 mr-2 p-0'l><'html5buttons'B>>` +
        `<'row'<'table-only-wrapper position-relative col-xl-12 p-0'tr>>` +
        `<'row d-flex justify-content-end'<'mr-auto p-0'i><'p-0'p>>>`,
      buttons: [
        { extend: 'copy' },
        { extend: 'csv' },
        {
          extend: 'excelHtml5',
          title: 'Projects',
          customize: xlsx => {
            $(xlsx.xl['styles.xml'])
              .find('numFmt[numFmtId="164"]')
              .attr('formatCode', '[$$-45C] #,##0.00_-');
          }
        },
        { extend: 'pdf', title: 'Projects', orientation: 'landscape', pageSize : 'LEGAL',
          customize: function(doc) {
            doc.styles.tableHeader.fontSize = 9;
            doc.defaultStyle.fontSize = 8;
          } },
        {
          extend: 'print',
          title: 'Projects',
          customize: function(win) {
            $(win.document.body).addClass('white-bg');
            $(win.document.body).css('font-size', '10px');

            $(win.document.body)
              .find('table')
              .addClass('compact')
              .css('font-size', 'inherit');
          }
        }
      ],
      select: {
        style: 'single',
        items: 'row'
      },
      responsive: true,
      stateSave: true,
      processing: true,
      deferRender: true,
      language: {
        processing: 'Loading, please wait....'
      },
      order: [[6, 'desc']]
    });
  }

  ngOnDestroy() {
    // needed if child gets re-created (eg on some model changes)
    // note that subsequent subscriptions on the same subject will fail
    // so the parent has to re-create parentSubject on changes
    if (this.subjectSubscription) {
      this.subjectSubscription.unsubscribe();
    }

    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    const that = this;
    this.subjectSubscription = this.parentSubject.subscribe(filters => {
      Object.assign(that.searchFilters, filters);
      that.drawTable();
    });
    this.searchFilters = this.parentSearchFilters;
  }
}
