export class Legislator {
  stateRepName: string;
  stateRepDistrict: string;
  stateSenatorName: string;
  stateSenatorDistrict: string;
  usSenator1Name: string;
  usSenator2Name: string;

  constructor(legislator?: any) {
    legislator = legislator || {};

    this.stateRepName = legislator.stateRepName || null;
    this.stateRepDistrict = legislator.stateRepDistrict || null;
    this.stateSenatorName = legislator.stateSenatorName || null;
    this.stateSenatorDistrict = legislator.stateSenatorDistrict || null;
    this.usSenator1Name = legislator.usSenator1Name || null;
    this.usSenator2Name = legislator.usSenator2Name || null;
  }
}
