import { ContractChange } from './contract-change.model';
import { Location } from '../../../project/models/location.model';
import { formTypes } from 'app/form/form.constants';
export class ContractChangeLocation extends ContractChange {

    location: Location;
    currentLocation: Location;

    constructor(formIndex: number = null, form?: any) {
        super(formIndex, form);
        this.init();
    }

    init() {
        super.init();
        if (!this.location) {
            this.location = new Location();
        }
        if (!this.currentLocation) {
            this.currentLocation = new Location();
        }
        this.type = formTypes.changeLocation.abbrev;
    }
}