import { PaymentProject } from './payment.project.model';
import { PaymentMethod, PayPointReturnCode } from '../fiscal.constants';
import { ReturnQueryParms } from '../fiscal.functions';
import { ClassificationAuditLog } from './classification-audit-log.model';
export class Payment {
  classificationNumber: string;
  confirmationNumber: string;
  createDate: Date;
  datePaid: Date;
  hash: string;
  id: string;
  paymentMethod: string;
  paymentProject: PaymentProject;
  requestUrl: string;
  responseUrl: string;
  returnCode: PayPointReturnCode;
  returnMessage: string;
  settlementSubmissionDate: Date;
  totalPaidAmount: number;
  classificationAuditLog: ClassificationAuditLog[];
  status: String;
  orbiPayId: String;
  currencyCode: String;
  returnDate: Date;


  constructor() {}

}
