/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./board.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/checkbox/checkbox.component.ngfactory";
import * as i3 from "../../../../shared/components/checkbox/checkbox.component";
import * as i4 from "../../../../shared/components/person/person.component.ngfactory";
import * as i5 from "../../../../shared/components/person/person.component";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/common";
import * as i8 from "./board.component";
var styles_UserDetailBoardComponent = [i0.styles];
var RenderType_UserDetailBoardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserDetailBoardComponent, data: {} });
export { RenderType_UserDetailBoardComponent as RenderType_UserDetailBoardComponent };
function View_UserDetailBoardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shr-checkbox", [], null, [[null, "checkedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkedChange" === en)) {
        var pd_0 = (_co.checkedBoardType(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CheckboxComponent_0, i2.RenderType_CheckboxComponent)), i1.ɵdid(1, 49152, null, 0, i3.CheckboxComponent, [], { controlId: [0, "controlId"], label: [1, "label"], checked: [2, "checked"] }, { checkedChange: "checkedChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "board-type-checkbox"; var currVal_1 = ("Board of " + _v.context.$implicit); var currVal_2 = (_co.user.boards ? _co.user.boards.includes(_v.context.$implicit) : false); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_UserDetailBoardComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { personComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "shr-person", [], null, null, null, i4.View_PersonComponent_0, i4.RenderType_PersonComponent)), i1.ɵdid(2, 245760, [[1, 4]], 0, i5.PersonComponent, [i6.FormBuilder], { addressRequired: [0, "addressRequired"], form: [1, "form"], person: [2, "person"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "d-flex align-items-center py-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h6", [["class", "form-control-label mb-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Board Committees:"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-info-circle fa-2x ml-2"], ["data-placement", "top"], ["data-toggle", "tooltip"], ["title", "At least one board committee must be selected."]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "col-12 d-flex flex-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserDetailBoardComponent_1)), i1.ɵdid(9, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.userForm; var currVal_2 = _co.user; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.boardTypesAvailable; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_UserDetailBoardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fl-user-detail-board", [], null, null, null, View_UserDetailBoardComponent_0, RenderType_UserDetailBoardComponent)), i1.ɵdid(1, 4440064, null, 0, i8.UserDetailBoardComponent, [i6.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserDetailBoardComponentNgFactory = i1.ɵccf("fl-user-detail-board", i8.UserDetailBoardComponent, View_UserDetailBoardComponent_Host_0, { form: "form", user: "user" }, {}, []);
export { UserDetailBoardComponentNgFactory as UserDetailBoardComponentNgFactory };
