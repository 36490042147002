export class EligibilityQuestions {
  public isProjectForInstitutionalPrivateInternal?: boolean;
  public isProjectForStateAgency?: boolean;
  public isProjectFinancedByFundingGrantsInState?: boolean;
  public isProjectCommericallyAvailable?: boolean;
  public isProjectSubContracted?: boolean;
  public isExpenditureInState?: boolean;
  public isEquipmentPurchaseInState?: boolean;
  public isEmployeesPerformingServicesInState?: boolean;
  public isDigitalMediaRelatedToDevelopmentOfProject?: boolean;
  public isCompanySubContractorInvestorWithLEDPrograms?: boolean;
  public companySubContractorInvestorWithLEDProgramsDetails: string;
  public isMultipleProjectBeingDeveloped?: boolean;
  public isExpenditureRelatesToExistingProduct?: boolean;
  public isAnticipateRelatedPartyTransactions?: boolean;
  public isSubContractorPercentage?: number;
  constructor(obj?: any) {
    if (!obj) {
      obj = {};
    }
    this.isProjectForInstitutionalPrivateInternal =
      obj.isProjectForInstitutionalPrivateInternal === undefined ||
      obj.isProjectForInstitutionalPrivateInternal === null
        ? null
        : obj.isProjectForInstitutionalPrivateInternal;
    this.isProjectForStateAgency =
      obj.isProjectForStateAgency === undefined ||
      obj.isProjectForStateAgency === null
        ? null
        : obj.isProjectForStateAgency;
    this.isProjectFinancedByFundingGrantsInState =
      obj.isProjectFinancedByFundingGrantsInState === undefined ||
      obj.isProjectFinancedByFundingGrantsInState === null
        ? null
        : obj.isProjectFinancedByFundingGrantsInState;
    this.isProjectCommericallyAvailable =
      obj.isProjectCommericallyAvailable === undefined ||
      obj.isProjectCommericallyAvailable === null
        ? null
        : obj.isProjectCommericallyAvailable;
    this.isProjectSubContracted =
      obj.isProjectSubContracted === undefined ||
      obj.isProjectSubContracted === null
        ? null
        : obj.isProjectSubContracted;
    this.isExpenditureInState =
      obj.isExpenditureInState === undefined ||
      obj.isExpenditureInState === null
        ? null
        : obj.isExpenditureInState;
    this.isEquipmentPurchaseInState =
      obj.isEquipmentPurchaseInState === undefined ||
      obj.isEquipmentPurchaseInState === null
        ? null
        : obj.isEquipmentPurchaseInState;
    this.isEmployeesPerformingServicesInState =
      obj.isEmployeesPerformingServicesInState === undefined ||
      obj.isEmployeesPerformingServicesInState === null
        ? null
        : obj.isEmployeesPerformingServicesInState;
    this.isDigitalMediaRelatedToDevelopmentOfProject =
      obj.isDigitalMediaRelatedToDevelopmentOfProject === undefined ||
      obj.isDigitalMediaRelatedToDevelopmentOfProject === null
        ? null
        : obj.isDigitalMediaRelatedToDevelopmentOfProject;
    this.isCompanySubContractorInvestorWithLEDPrograms =
      obj.isCompanySubContractorInvestorWithLEDPrograms === undefined ||
      obj.isCompanySubContractorInvestorWithLEDPrograms === null
        ? null
        : obj.isCompanySubContractorInvestorWithLEDPrograms;
    this.companySubContractorInvestorWithLEDProgramsDetails =
      obj.companySubContractorInvestorWithLEDProgramsDetails || null;
    this.isSubContractorPercentage =
      obj.isSubContractorPercentage || null;
    this.isMultipleProjectBeingDeveloped =
      obj.isMultipleProjectBeingDeveloped === undefined ||
      obj.isMultipleProjectBeingDeveloped === null
        ? null
        : obj.isMultipleProjectBeingDeveloped;
    this.isExpenditureRelatesToExistingProduct =
      obj.isExpenditureRelatesToExistingProduct === undefined ||
      obj.isExpenditureRelatesToExistingProduct === null
        ? null
        : obj.isExpenditureRelatesToExistingProduct;
    this.isAnticipateRelatedPartyTransactions =
      obj.isAnticipateRelatedPartyTransactions === undefined ||
      obj.isAnticipateRelatedPartyTransactions === null
        ? null
        : obj.isAnticipateRelatedPartyTransactions;
  }
}
