export const parishes = [
  { name: 'Acadia', abbrev: 'ACAD' },
  { name: 'Allen', abbrev: 'ALLE' },
  { name: 'Ascension', abbrev: 'ASCE' },
  { name: 'Assumption', abbrev: 'ASSU' },
  { name: 'Avoyelles', abbrev: 'AVOY' },
  { name: 'Beauregard', abbrev: 'BEAU' },
  { name: 'Bienville', abbrev: 'BIEN' },
  { name: 'Bossier', abbrev: 'BOSS' },
  { name: 'Caddo', abbrev: 'CADD' },
  { name: 'Calcasieu', abbrev: 'CALC' },
  { name: 'Caldwell', abbrev: 'CALD' },
  { name: 'Cameron', abbrev: 'CAME' },
  { name: 'Catahoula', abbrev: 'CATA' },
  { name: 'Claiborne', abbrev: 'CLAI' },
  { name: 'Concordia', abbrev: 'CONC' },
  { name: 'De Soto', abbrev: 'DESO' },
  { name: 'East Baton Rouge', abbrev: 'EBR' },
  { name: 'East Carroll', abbrev: 'ECAR' },
  { name: 'East Feliciana', abbrev: 'EFEL' },
  { name: 'Evangeline', abbrev: 'EVAN' },
  { name: 'Franklin', abbrev: 'FRAN' },
  { name: 'Grant', abbrev: 'GRAN' },
  { name: 'Iberia', abbrev: 'IBER' },
  { name: 'Iberville', abbrev: 'IBVL' },
  { name: 'Jackson', abbrev: 'JACK' },
  { name: 'Jefferson Davis', abbrev: 'JFDV' },
  { name: 'Jefferson', abbrev: 'JEFF' },
  { name: 'La Salle', abbrev: 'LASA' },
  { name: 'Lafayette', abbrev: 'LAFA' },
  { name: 'Lafourche', abbrev: 'LAFO' },
  { name: 'Lincoln', abbrev: 'LINC' },
  { name: 'Livingston', abbrev: 'LIVI' },
  { name: 'Madison', abbrev: 'MADI' },
  { name: 'Morehouse', abbrev: 'MORE' },
  { name: 'Natchitoches', abbrev: 'NATC' },
  { name: 'Orleans', abbrev: 'ORLE' },
  { name: 'Ouachita', abbrev: 'OUAC' },
  { name: 'Plaquemines', abbrev: 'PLAQ' },
  { name: 'Pointe Coupee', abbrev: 'PCP' },
  { name: 'Rapides', abbrev: 'RAPI' },
  { name: 'Red River', abbrev: 'REDR' },
  { name: 'Richland', abbrev: 'RICH' },
  { name: 'Sabine', abbrev: 'SABI' },
  { name: 'St. Bernard', abbrev: 'SBND' },
  { name: 'St. Charles', abbrev: 'SCHL' },
  { name: 'St. Helena', abbrev: 'SHEL' },
  { name: 'St. James', abbrev: 'SJAM' },
  { name: 'St. John the Baptist', abbrev: 'SJB' },
  { name: 'St. Landry', abbrev: 'SLAN' },
  { name: 'St. Martin', abbrev: 'SMT' },
  { name: 'St. Mary', abbrev: 'SMAR' },
  { name: 'St. Tammany', abbrev: 'STAM' },
  { name: 'Tangipahoa', abbrev: 'TANG' },
  { name: 'Tensas', abbrev: 'TENS' },
  { name: 'Terrebonne', abbrev: 'TERR' },
  { name: 'Union', abbrev: 'UNIO' },
  { name: 'Vermilion', abbrev: 'VERM' },
  { name: 'Vernon', abbrev: 'VERN' },
  { name: 'Washington', abbrev: 'WASH' },
  { name: 'Webster', abbrev: 'WEBS' },
  { name: 'West Baton Rouge', abbrev: 'WBR' },
  { name: 'West Carroll', abbrev: 'WCAR' },
  { name: 'West Feliciana', abbrev: 'WFEL' },
  { name: 'Winn', abbrev: 'WINN' }
];

export const fiscalYears = [
  { name: '1996' , value: '1996' },
  { name: '1997' , value: '1997' },
  { name: '1998' , value: '1998' },
  { name: '1999' , value: '1999' },
  { name: '2000' , value: '2000' },
  { name: '2001' , value: '2001' },
  { name: '2002' , value: '2002' },
  { name: '2003' , value: '2003' },
  { name: '2004' , value: '2004' },
  { name: '2005' , value: '2005' },
  { name: '2006' , value: '2006' },
  { name: '2007' , value: '2007' },
  { name: '2008' , value: '2008' },
  { name: '2009' , value: '2009' },
  { name: '2010' , value: '2010' },
  { name: '2011' , value: '2011' },
  { name: '2012' , value: '2012' },
  { name: '2013' , value: '2013' },
  { name: '2014' , value: '2014' },
  { name: '2015' , value: '2015' },
  { name: '2016' , value: '2016' },
  { name: '2017' , value: '2017' },
  { name: '2018' , value: '2018' },
  { name: '2019' , value: '2019' },
  { name: '2020' , value: '2020' },
  { name: '2021' , value: '2021' },
  { name: '2022' , value: '2022' },
  { name: '2023' , value: '2023' },
  { name: '2024' , value: '2024' },
  { name: '2025' , value: '2025' },
  { name: '2026' , value: '2026' },
  { name: '2027' , value: '2027' },
  { name: '2028' , value: '2028' },
  { name: '2029' , value: '2029' },
  { name: '2030' , value: '2030' },
  { name: '2031' , value: '2031' },
  { name: '2032' , value: '2032' },
  { name: '2033' , value: '2033' },
  { name: '2034' , value: '2034' },
  { name: '2035' , value: '2035' },
  { name: '2036' , value: '2036' },
  { name: '2037' , value: '2037' },
  { name: '2038' , value: '2038' },
  { name: '2039' , value: '2039' },
  { name: '2040' , value: '2040' }

];

export const regions = [
  { name: 'Acadiana' },
  { name: 'Bayou' },
  { name: 'Capital' },
  { name: 'Central' },
  { name: 'Northeast' },
  { name: 'Northwest' },
  { name: 'Southeast' },
  { name: 'Southwest' }
];

export const states = [
  { name: 'Alabama', abbrev: 'AL' },
  { name: 'Alaska', abbrev: 'AK' },
  { name: 'Arizona', abbrev: 'AZ' },
  { name: 'Arkansas', abbrev: 'AR' },
  { name: 'California', abbrev: 'CA' },
  { name: 'Colorado', abbrev: 'CO' },
  { name: 'Connecticut', abbrev: 'CT' },
  { name: 'Delaware', abbrev: 'DE' },
  { name: 'District of Columbia', abbrev: 'DC' },
  { name: 'Florida', abbrev: 'FL' },
  { name: 'Georgia', abbrev: 'GA' },
  { name: 'Hawaii', abbrev: 'HI' },
  { name: 'Idaho', abbrev: 'ID' },
  { name: 'Illinois', abbrev: 'IL' },
  { name: 'Indiana', abbrev: 'IN' },
  { name: 'Iowa', abbrev: 'IA' },
  { name: 'Kansas', abbrev: 'KS' },
  { name: 'Kentucky', abbrev: 'KY' },
  { name: 'Louisiana', abbrev: 'LA' },
  { name: 'Maine', abbrev: 'ME' },
  { name: 'Maryland', abbrev: 'MD' },
  { name: 'Massachusetts', abbrev: 'MA' },
  { name: 'Michigan', abbrev: 'MI' },
  { name: 'Minnesota', abbrev: 'MN' },
  { name: 'Mississippi', abbrev: 'MS' },
  { name: 'Missouri', abbrev: 'MO' },
  { name: 'Montana', abbrev: 'MT' },
  { name: 'Nebraska', abbrev: 'NE' },
  { name: 'Nevada', abbrev: 'NV' },
  { name: 'New Hampshire', abbrev: 'NH' },
  { name: 'New Jersey', abbrev: 'NJ' },
  { name: 'New Mexico', abbrev: 'NM' },
  { name: 'New York', abbrev: 'NY' },
  { name: 'North Carolina', abbrev: 'NC' },
  { name: 'North Dakota', abbrev: 'ND' },
  { name: 'Ohio', abbrev: 'OH' },
  { name: 'Oklahoma', abbrev: 'OK' },
  { name: 'Oregon', abbrev: 'OR' },
  { name: 'Pennsylvania', abbrev: 'PA' },
  { name: 'Rhode Island', abbrev: 'RI' },
  { name: 'South Carolina', abbrev: 'SC' },
  { name: 'South Dakota', abbrev: 'SD' },
  { name: 'Tennessee', abbrev: 'TN' },
  { name: 'Texas', abbrev: 'TX' },
  { name: 'Utah', abbrev: 'UT' },
  { name: 'Vermont', abbrev: 'VT' },
  { name: 'Virginia', abbrev: 'VA' },
  { name: 'Washington', abbrev: 'WA' },
  { name: 'West Virginia', abbrev: 'WV' },
  { name: 'Wisconsin', abbrev: 'WI' },
  { name: 'Wyoming', abbrev: 'WY' }
];

export const dataMask = {
  phoneNumber: {
    name: 'Phone',
    mask: [
      '(',
      /[1-9]/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ]
  },
  zipCode: {
    name: 'Zip',
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/]
  },
  ssn: {
    name: 'Ssn',
    mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  ldr: {
    name: 'Ldr',
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
  },
  fein: {
    name: 'Fein',
    mask: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  },
  naics: {
    name: 'Naics',
    mask: [/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/]
  },
  lwc: {
    name: 'lwc',
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/]
  }
};

export const programTypes = {
  qj: {
    name: 'qj',
    value: false
  },
  ez: {
    name: 'ez',
    value: false
  },
  rta: {
    name: 'rta',
    value: false
  },
  ite: {
    name: 'ite',
    value: false
  },
  dm: {
    name: 'dm',
    value: false
  },
  film: {
    name: 'film',
    value: false
  },
  step: {
    name: 'step',
    value: false
  }
};

export const regionParishMappings = {
  Acadia: 'Acadiana',
  Allen: 'Southwest',
  Ascension: 'Capital',
  Assumption: 'Bayou',
  Avoyelles: 'Central',
  Beauregard: 'Southwest',
  Bienville: 'Northwest',
  Bossier: 'Northwest',
  Caddo: 'Northwest',
  Calcasieu: 'Southwest',
  Caldwell: 'Northeast',
  Cameron: 'Southwest',
  Catahoula: 'Central',
  Claiborne: 'Northwest',
  Concordia: 'Central',
  'De Soto': 'Northwest',
  'East Baton Rouge': 'Capital',
  'East Carroll': 'Northeast',
  'East Feliciana': 'Capital',
  Evangeline: 'Acadiana',
  Franklin: 'Northeast',
  Grant: 'Central',
  Iberia: 'Acadiana',
  Iberville: 'Capital',
  Jackson: 'Northwest',
  Jefferson: 'Southeast',
  'Jefferson Davis': 'Southwest',
  Lafayette: 'Acadiana',
  Lafourche: 'Bayou',
  'La Salle': 'Central',
  Lincoln: 'Northwest',
  Livingston: 'Capital',
  Madison: 'Northeast',
  Morehouse: 'Northeast',
  Natchitoches: 'Northwest',
  Ouachita: 'Northeast',
  Orleans: 'Southeast',
  Plaquemines: 'Southeast',
  'Pointe Coupee': 'Capital',
  Rapides: 'Central',
  'Red River': 'Northwest',
  Richland: 'Northeast',
  Sabine: 'Northwest',
  'St. Bernard': 'Southeast',
  'St. Charles': 'Southeast',
  'St. Helena': 'Capital',
  'St. James': 'Southeast',
  'St. John the Baptist': 'Southeast',
  'St. Landry': 'Acadiana',
  'St. Martin': 'Acadiana',
  'St. Mary': 'Bayou',
  'St. Tammany': 'Southeast',
  Tangipahoa: 'Southeast',
  Tensas: 'Northeast',
  Terrebonne: 'Bayou',
  Union: 'Northeast',
  Vermilion: 'Acadiana',
  Vernon: 'Central',
  Washington: 'Southeast',
  Webster: 'Northwest',
  'West Baton Rouge': 'Capital',
  'West Carroll': 'Northeast',
  'West Feliciana': 'Capital',
  Winn: 'Central'
};

export const colors = [
  '#236467',
  '#67989A',
  '#417D80',
  '#0D4A4D',
  '#003133',
  '#550200',
  '#801815',
  '#D46D6A',
  '#FFACAA',
  '#AA3C39',
  '#116416',
  '#54A759',
  '#236467',
  '#67989A',
  '#417D80',
  '#0D4A4D',
  '#003133',
  '#550200',
  '#801815',
  '#D46D6A',
  '#FFACAA',
  '#AA3C39',
  '#116416',
  '#54A759',
  '#236467',
  '#67989A',
  '#417D80',
  '#0D4A4D',
  '#003133',
  '#550200',
  '#801815',
  '#D46D6A',
  '#FFACAA',
  '#AA3C39',
  '#116416',
  '#54A759'
];

export const httpStatusCodeToErrorMap = {
  409: 'Another user has modified the data since you last loaded which caused your data \
  to be out of sync. Your data will be refreshed to ensure you are editing the \
  latest information.'
};

export const httpResponseCodes = {
  dataConflict: 409
}

export const outofMSAParishes = [
  'Orleans',
  'Jefferson',
  'Plaquemines',
  'St. Bernard',
  'St. Charles',
  'St. James',
  'St. Tammany'
];
