import { InitialCertification } from './initial-certification.model';
import { AuditDepositCreator } from '../../interfaces/audit-deposit-creator.interface';
import { AuditInfo } from '../../interfaces/audit-info.interface';
import { OutgoingPayment } from '../../../fiscal/models/outgoing-payment.model';
import { FormIdentifier } from '../../../project/models/form-identifier.model';
import { formTypes } from '../../../form/form.constants';
import { FinalCertificationRecipient } from '../film/final-certification-recipient.model';
import { AuditForm } from '../../interfaces/audit-form.interface';
import { incentiveProgram } from '../../../project/project.constants';
import { Expenditures } from './expenditures.model';
import { AuditCriticalDates } from './audit-critical-dates.model';

export class Audit extends InitialCertification
  implements AuditDepositCreator, AuditInfo, AuditForm {
  auditingCompanyId: string;

  actualJobs: number;
  auditPeriod: Date[];
  auditorPayments: OutgoingPayment[];
  finalCertificationRecipient: FinalCertificationRecipient;
  totalCreditsIssued?: number;
  totalLaborCreditsIssued?: number;
  totalNonLaborCreditsIssued?: number;
  qualifiedExpenditures: Expenditures;
  auditCriticalDates: AuditCriticalDates;
  isAttestationSent?: boolean;
  isFinalCertSent?: boolean;
  isAttestationExecuted?: boolean;
  /**
   * @summary Returns the form identifier of this Audit. Unlike the application which returns the formId of the
   * matching audit.
   */
  get auditFormId(): FormIdentifier {
    return <FormIdentifier>{
      formIndex: this.formIndex,
      formType: this.type
    };
  }

  constructor(formIndex: number = null) {
    super();
    this.formIndex = formIndex;
  }

  init(): Audit {
    super.init();
    this.type = formTypes.evr.abbrev;
    this.auditorPayments = this.auditorPayments || [];
    this.finalCertificationRecipient =
      this.finalCertificationRecipient || new FinalCertificationRecipient();
    this.totalCreditsIssued =
      this.totalCreditsIssued >= 0 ? this.totalCreditsIssued : null;
    this.totalLaborCreditsIssued =
      this.totalLaborCreditsIssued >= 0 ? this.totalLaborCreditsIssued : null;
    this.totalNonLaborCreditsIssued =
      this.totalNonLaborCreditsIssued >= 0 ? this.totalNonLaborCreditsIssued : null;
    this.actualJobs = this.actualJobs >= 0 ? this.actualJobs : null;
    this.auditPeriod = this.auditPeriod || [];
    this.qualifiedExpenditures = new Expenditures(this.qualifiedExpenditures);
    this.auditCriticalDates = this.auditCriticalDates
      ? this.auditCriticalDates
      : new AuditCriticalDates(this.auditCriticalDates);
    return this;
  }

  /**
   * @summary Get the applicable LA Expenditure total for calculating the deposit.
   * This is not related to laResident in the spreadsheet.
   */
  get laExpenditure(): number {
    return this.estimatedExpenditures.expenditureTotal;
  }

  set laExpenditure(value: number) {
    this.estimatedExpenditures.expenditureTotal = value;
  }

  get qualifier() {
    try {
      if (this.auditPeriod && this.auditPeriod.length === 2) {
        const start = new Date(this.auditPeriod[0]).toLocaleDateString();
        const end = new Date(this.auditPeriod[1]).toLocaleDateString();
        return `Audit Period : ${start} - ${end}`;
      }
      return '';
    } catch (e) {
      console.error(e);
      return '';
    }
  }
}
