/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./authentication.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "../../../shared/components/checkbox/checkbox.component.ngfactory";
import * as i5 from "../../../shared/components/checkbox/checkbox.component";
import * as i6 from "./authentication.component";
import * as i7 from "../../../security/services/authentication.service";
import * as i8 from "@angular/router";
import * as i9 from "../../../user/services/user-context.service";
var styles_AuthenticationComponent = [i0.styles];
var RenderType_AuthenticationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthenticationComponent, data: {} });
export { RenderType_AuthenticationComponent as RenderType_AuthenticationComponent };
function View_AuthenticationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "col-8 position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["href", "/auth-documentation"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Two-Factor Authentication Instructions"]))], null, null); }
function View_AuthenticationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "d-flex flex-row flex-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Set Up Two-Factor Authentication"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Two-Factor Authentication Powered By Google Authenticator."]))], null, null); }
function View_AuthenticationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "d-flex flex-row flex-wrap justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "image"], ["class", "m-b"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["Manual Entry Code: ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.setUpQRUrl; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.manualEntryKey; _ck(_v, 3, 0, currVal_1); }); }
function View_AuthenticationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "col-8 position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthenticationComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["href", "/auth-documentation"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Two-Factor Authentication Instructions"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthenticationComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showSetupQR; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.showSetupQR; _ck(_v, 8, 0, currVal_1); }, null); }
function View_AuthenticationComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["class", "text-danger"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Invalid Authenticator Code"]))], null, null); }
function View_AuthenticationComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "col-8 position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "animated"], ["id", "login"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 21, "form", [["class", "bg-white p-4 no-validation-border"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i3.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(4, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i1.ɵdid(6, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 11, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 7, "input", [["class", "form-control"], ["formControlName", "passcode"], ["id", "passcode"], ["placeholder", "Validation Code"], ["required", ""], ["type", "text"]], [[1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 10)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 10)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(11, 16384, null, 0, i3.RequiredValidator, [], { required: [0, "required"] }, null), i1.ɵprd(1024, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i3.RequiredValidator]), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(14, 671744, null, 0, i3.FormControlName, [[3, i3.ControlContainer], [6, i3.NG_VALIDATORS], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlName]), i1.ɵdid(16, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "btn btn-secondary block w-100 m-b"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.validate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Validate"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthenticationComponent_6)), i1.ɵdid(20, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "shr-checkbox", [], null, [[null, "checkedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkedChange" === en)) {
        var pd_0 = ((_co.twoFactorRememberMe = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CheckboxComponent_0, i4.RenderType_CheckboxComponent)), i1.ɵdid(23, 49152, null, 0, i5.CheckboxComponent, [], { controlId: [0, "controlId"], label: [1, "label"], checked: [2, "checked"] }, { checkedChange: "checkedChange" })], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.validateForm; _ck(_v, 4, 0, currVal_7); var currVal_16 = ""; _ck(_v, 11, 0, currVal_16); var currVal_17 = "passcode"; _ck(_v, 14, 0, currVal_17); var currVal_18 = _co.invalidAuthCode; _ck(_v, 20, 0, currVal_18); var currVal_19 = "remember-device-checkbox"; var currVal_20 = "Remember This Device"; var currVal_21 = _co.twoFactorRememberMe; _ck(_v, 23, 0, currVal_19, currVal_20, currVal_21); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = (i1.ɵnov(_v, 11).required ? "" : null); var currVal_9 = i1.ɵnov(_v, 16).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 16).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 16).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 16).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 16).ngClassValid; var currVal_14 = i1.ɵnov(_v, 16).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 16).ngClassPending; _ck(_v, 9, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); }); }
export function View_AuthenticationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "loginColumns animated fadeInDown"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "d-flex flex-row flex-wrap justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "image"], ["class", "m-b"], ["src", "/assets/images/fng_app.svg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthenticationComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthenticationComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthenticationComponent_5)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeAuthentication; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.activeAuthentication; _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.activeAuthentication || (!_co.activeAuthentication && _co.showSetupQR)); _ck(_v, 8, 0, currVal_2); }, null); }
export function View_AuthenticationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fl-authentication", [], null, null, null, View_AuthenticationComponent_0, RenderType_AuthenticationComponent)), i1.ɵdid(1, 4308992, null, 0, i6.AuthenticationComponent, [i7.AuthenticationService, i3.FormBuilder, i8.Router, i9.UserContextService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthenticationComponentNgFactory = i1.ɵccf("fl-authentication", i6.AuthenticationComponent, View_AuthenticationComponent_Host_0, {}, {}, []);
export { AuthenticationComponentNgFactory as AuthenticationComponentNgFactory };
