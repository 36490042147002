import { Injectable } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder } from '@angular/forms';

@Injectable()
export class FormValidationService {
  form: FormGroup;

  constructor(fb: FormBuilder) {
    this.form = fb.group({});
  }
}
