import { Component, OnInit, Input } from '@angular/core';
import { Stages } from '../../../../entertainment/models/digital/stages.model';
import { Observable } from 'rxjs';
import { formTypes } from '../../../../form/form.constants';
import { FormShareService } from '../../../../form/services/share.service';

@Component({
  selector: 'fl-stages',
  templateUrl: './stages.component.html',
  styleUrls: ['./stages.component.scss']
})
export class StagesComponent implements OnInit {
  stages: Stages[];
  invalidObservable: Observable<boolean>;
  @Input() readOnly = false;
  constructor(private formShareService: FormShareService
  ) {}
  // #region "Custom Methods"
  /**
   * @summary Add programming language
   */
  addStage(
    stageControl: HTMLInputElement,
    durationControl: HTMLInputElement,
    infoControl: HTMLInputElement,
    concurrentPhaseControl: HTMLInputElement
  ) {
    if (
      stageControl.value.length > 0 ||
      durationControl.value.length > 0 ||
      infoControl.value.length > 0 ||
      concurrentPhaseControl.value.length > 0
    ) {
      if (
        this.stages.filter(x => x.name === stageControl.value.trim()).length ===
        0
      ) {
        this.stages.push(
          new Stages({
            name: stageControl.value,
            duration: durationControl.value,
            info: infoControl.value,
            concurrentPhase: concurrentPhaseControl.value
          })
        );
        // Clear fields
        stageControl.value = null;
        durationControl.value = null;
        infoControl.value = null;
        concurrentPhaseControl.value = null;
        this.emitValidityObservable();
      }
    }
  }
  deleteStage(i: number) {
    // const testing = name;
    this.stages.splice(i, 1);
    this.emitValidityObservable();
  }
  trackElement(index: number, element: any) {
    return element ? element.name : null;
  }
   /**
   * @summary Create a form group for validation. If there is at least one array then it is valid.
   */
  // #endregion
  // #region "Custom Methods"
  /**
   * @summary Validate if there is at least one array then it is valid.
   */
  emitValidityObservable() {
    const that = this;
     this.invalidObservable = new Observable<boolean>(subscriber => {
      setTimeout(() => {
        subscriber.next(
          (that.stages && that.stages.length > 0) ||
            (this.formShareService.form.type === formTypes.initialCert.abbrev ||
              this.formShareService.form.type === formTypes.audit.abbrev)
            ? false
            : true
        );
        subscriber.complete();
        subscriber.unsubscribe();
      });
    });
  }
  ngOnInit() {
    this.emitValidityObservable();
  }
  // #endregion
}
