import { BusinessIncentiveProjectInfo } from './business-incentive-project-info.model';
import { ConsultantConfirmation } from './consultant-confirmation.model';
import { EstimatedInvestments } from './estimated-investments.model';
import { EstimatedJobs } from './estimated-jobs.model';
import { EstimatedPayroll } from './estimated-payroll.model';
import { ProjectForm } from './form.model';
import { Eligibility } from './project-eligibility.model';
import { formTypes } from 'app/form/form.constants';

export class Advance extends ProjectForm {
  consultantConfirmation: ConsultantConfirmation;
  estimatedInvestments: EstimatedInvestments;
  estimatedJobs: EstimatedJobs;
  estimatedPayroll: EstimatedPayroll;
  projectDetails: BusinessIncentiveProjectInfo;
  projectEligibility: Eligibility;

  constructor(advance?: any) {
    super(null, advance);
    advance = advance || {};
    this.consultantConfirmation =
      advance.consultantConfirmation || new ConsultantConfirmation();
    this.estimatedInvestments = new EstimatedInvestments(
      advance.estimatedInvestments
    );
    this.estimatedJobs = new EstimatedJobs(advance.estimatedJobs);
    this.estimatedPayroll = new EstimatedPayroll(advance.estimatedPayroll);

    this.projectDetails = new BusinessIncentiveProjectInfo(
      advance.projectDetails
    );
    this.projectEligibility = new Eligibility(advance.projectEligibility);
    this.type = formTypes.advance.abbrev;
  }

  get qualifier() {
    return '';
  }
}
