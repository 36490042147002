export class StatusLog {

    public status: any;
    public statusDate: Date;
    public creator?: string;
    public comment?: string;

    constructor(statusLog: StatusLog) {
        this.status = statusLog.status ? statusLog.status : '';
        this.statusDate = statusLog.statusDate ? statusLog.statusDate : new Date();
        this.comment = statusLog.comment || null;
        this.creator = statusLog.creator || null;
    }
}