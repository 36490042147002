<div class="col-12 white-bg p-4">
   <h1 class="text-blue">Public Reports</h1><br>
   
   <div class="dropdown-divider py-2"></div>

   <p>The file format for all public reports on this page is comma separated values (CSV). It is recommended to use Microsoft Excel to view these reports.</p>

   <table cellpadding="10">
       <th>REPORT</th>
       <th>REPORT DESCRIPTION</th>
       <tr>
           <td>
            <a (click)="retrievePublicReportFromApi('publicReportAdvance')"><i class="fa fa-download pr-2"></i>Advance Report</a>
           </td>
           <td>
                All advance notifications submitted to LED for Industrial Tax Exemption, Restoration Tax Abatement, Quality Jobs and Enterprise Zone incentive programs. 
           </td>
       </tr>
       <tr>
           <td>
            <a (click)="retrievePublicReportFromApi('publicReportEz')"><i class="fa  fa-download pr-2"></i>Enterprise Zone Projects Report </a>
           </td>
           <td>
            All projects submitted to LED for Enterprise Zone incentive program. This report includes columns for Board Approvals and Contract Dates available for each project. The project status can be used to filter projects.
           </td>
       </tr>
       <tr>
           <td><a (click)="retrievePublicReportFromApi('publicReportIte')"><i class="fa fa-download pr-2"></i>Industrial Tax Exemption Projects Report </a></td>
           <td>All projects submitted to LED for Industrial Tax Exemption incentive program. This report includes columns for Board Approvals, Contract Dates, and Renewal Requests if available for each project. The project status can be used to filter projects.  </td>
           
       </tr>
       <tr>
            <td> <a (click)="retrievePublicReportFromApi('publicReportQj')"><i class="fa  fa-download pr-2"></i>Quality Jobs Projects Report </a> </td>
            <td>All projects submitted to LED for Quality Jobs incentive program. This report includes columns for Board Approvals, Contract Dates, and Renewal Requests if available for each project. The project status can be used to filter projects.</td>
        </tr>
        <tr>
            <td> <a (click)="retrievePublicReportFromApi('publicReportRta')"><i class="fa  fa-download pr-2"></i>Restoration Tax Abatement Projects Report </a> </td>
            <td>All projects submitted to LED for Restoration Tax Abatement incentive program. This report includes columns for Board Approvals, Contract Dates, and Renewal Requests if available for each project. The project status can be used to filter projects.</td>
        </tr>
   </table>

 


</div>

