/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./public-reports.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./public-reports.component";
import * as i3 from "../../fastlane-common/services/download.service";
var styles_PublicReportsComponent = [i0.styles];
var RenderType_PublicReportsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PublicReportsComponent, data: {} });
export { RenderType_PublicReportsComponent as RenderType_PublicReportsComponent };
export function View_PublicReportsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 46, "div", [["class", "col-12 white-bg p-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "text-blue"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Public Reports"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "dropdown-divider py-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The file format for all public reports on this page is comma separated values (CSV). It is recommended to use Microsoft Excel to view these reports."])), (_l()(), i1.ɵeld(7, 0, null, null, 39, "table", [["cellpadding", "10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["REPORT"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["REPORT DESCRIPTION"])), (_l()(), i1.ɵeld(12, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.retrievePublicReportFromApi("publicReportAdvance") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "fa fa-download pr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Advance Report"])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" All advance notifications submitted to LED for Industrial Tax Exemption, Restoration Tax Abatement, Quality Jobs and Enterprise Zone incentive programs. "])), (_l()(), i1.ɵeld(19, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.retrievePublicReportFromApi("publicReportEz") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "i", [["class", "fa  fa-download pr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Enterprise Zone Projects Report "])), (_l()(), i1.ɵeld(24, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" All projects submitted to LED for Enterprise Zone incentive program. This report includes columns for Board Approvals and Contract Dates available for each project. The project status can be used to filter projects. "])), (_l()(), i1.ɵeld(26, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.retrievePublicReportFromApi("publicReportIte") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 0, "i", [["class", "fa fa-download pr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Industrial Tax Exemption Projects Report "])), (_l()(), i1.ɵeld(31, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["All projects submitted to LED for Industrial Tax Exemption incentive program. This report includes columns for Board Approvals, Contract Dates, and Renewal Requests if available for each project. The project status can be used to filter projects. "])), (_l()(), i1.ɵeld(33, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(35, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.retrievePublicReportFromApi("publicReportQj") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(36, 0, null, null, 0, "i", [["class", "fa  fa-download pr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Quality Jobs Projects Report "])), (_l()(), i1.ɵeld(38, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["All projects submitted to LED for Quality Jobs incentive program. This report includes columns for Board Approvals, Contract Dates, and Renewal Requests if available for each project. The project status can be used to filter projects."])), (_l()(), i1.ɵeld(40, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(41, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(42, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.retrievePublicReportFromApi("publicReportRta") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(43, 0, null, null, 0, "i", [["class", "fa  fa-download pr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Restoration Tax Abatement Projects Report "])), (_l()(), i1.ɵeld(45, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["All projects submitted to LED for Restoration Tax Abatement incentive program. This report includes columns for Board Approvals, Contract Dates, and Renewal Requests if available for each project. The project status can be used to filter projects."]))], null, null); }
export function View_PublicReportsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fl-public-reports", [], null, null, null, View_PublicReportsComponent_0, RenderType_PublicReportsComponent)), i1.ɵdid(1, 114688, null, 0, i2.PublicReportsComponent, [i3.DownloadService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PublicReportsComponentNgFactory = i1.ɵccf("fl-public-reports", i2.PublicReportsComponent, View_PublicReportsComponent_Host_0, {}, {}, []);
export { PublicReportsComponentNgFactory as PublicReportsComponentNgFactory };
