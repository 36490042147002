import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';

@Component({
  selector: 'fl-event-notification-action-multiselect',
  templateUrl: './event-notification-action-multiselect.component.html',
  styleUrls: ['./event-notification-action-multiselect.component.scss']
})
export class EventNotificationActionMultiselectComponent
  implements OnInit, OnChanges {
  @Output()
  change: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Input()
  eventActions: string[] = [];

  @Input()
  selectedEventActions: string[] = [];

  //#region Settings
  dropdownSettings = <IDropdownSettings>{
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: true
  };
  //#endregion

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    // Ensure user is defined
    // if (changes['user'].currentValue['userType']) {
    //   this.specificUserType = getSpecificUserTypeOrAgency(this.user);
    //   this.applicableEvents = userTypeToNotificationsMap[this.specificUserType];
    // }
  }

  ngOnInit() {}

  onSelectAll(values: string[]) {
    this.selectedEventActions = values;

    // Emit new values
    this.change.emit(this.selectedEventActions);
  }

  onSelectionChange(value: string, event: 'select' | 'deselect') {
    // Handle appropiate selection event to modify the selectedParishes array.
    // if (event === 'select') {
    //   this.selectedEventActions.push(value);
    // } else {
    //   this.selectedEventActions.splice(this.selectedEventActions.indexOf(value), 1);
    // }

    // Emit new values
    this.change.emit(this.selectedEventActions);
  }
}
