import { Injectable, OnDestroy, ApplicationRef, NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastService } from './toast.service';
import { first, tap, timeout, takeUntil, mapTo } from 'rxjs/operators';
import { Subscription, interval, concat, race } from 'rxjs';

@Injectable()
export class VersionService implements OnDestroy {
  private subs: Subscription[] = [];
  private updateCheckFrequency = 10000;
  private applicableStableTimeout = 10000;

  constructor(
    updates: SwUpdate,
    toasterService: ToastService,
    appRef: ApplicationRef
  ) {
    console.log('Are updates enabled?', updates.isEnabled);
    // Ensure Service Worker is enabled
    if (updates.isEnabled) {
      //#region Update Check

      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      // TODO: The stable check below is broken due to other subscriptions started before app is actually stable
      const appIsStable$ = appRef.isStable.pipe(
        first(stable => stable),
        tap(stable => {
          console.log(`App is stable now ${stable}`);
        })
      );
      // const appStabilityWaitingThreshold$ = race(appIsStable$, interval(this.applicableStableTimeout).pipe(first()).pipe(mapTo('App not Stable but threshold reached.')));

      const versionCheckInterval$ = interval(this.updateCheckFrequency).pipe(
        tap(() => {
          console.log('Version checked');
        })
      );
      const checkVersionOnceAppIsStable$ = concat(
        appIsStable$,
        versionCheckInterval$
      );

      let sub: Subscription;

      try {
        sub = checkVersionOnceAppIsStable$.subscribe(
          value => {
            console.log('Checking for updates ' + value);
            updates.checkForUpdate();
          },
          error => {
            console.trace(error);
          }
        );
        this.subs.push(sub);
      } catch (error) {
        console.trace(error);
      }

      //#endregion

      //#region Update Notifications
      //Create observable to work only after app is stable
      // const updatesAvailable$ = appIsStable$.pipe(concatMap((isStable => updates.available)));
      sub = updates.available.subscribe(event => {
        console.log('current version is: ', event.current);
        console.log('available version is: ', event.available);

        toasterService.sticky({
          title: 'New Fastlane Version available',
          position: 'bottom-left',
          timer: 0,
          confirmButtonText: 'RELOAD',
          preConfirm: () => {
            updates.activateUpdate().then(() => {
              console.log(
                'Update is now activated:',
                navigator.serviceWorker.controller
              );

              // Get service worker registration for this URL
              const registration = navigator.serviceWorker.getRegistration();

              // Force update of service worker
              registration.then(r => {
                console.trace('Registration was obtained, yay!!!');
                r.update().then(
                  _ => {
                    console.trace('The update happened, yes!!!!');
                    window.location.reload();
                  },
                  _ => {
                    console.trace('Error updating service worker');
                  }
                );
              });
            });
          }
        });
      });
      this.subs.push(sub);

      sub = updates.activated.subscribe(event => {
        console.log('old version was', event.previous);
        console.log('new version is', event.current);
      });
      this.subs.push(sub);

      //#endregion
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
