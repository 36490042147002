import { Environment } from './environment-model';
import { MailAddress } from '../app/email/models/mail-address.model';
import { versionHash } from '../runtime';

const testersGroupAddress: MailAddress = {
  emailAddress: 'ldavis@tri-core.net',
  displayName: 'Tri-Core Dev'
};

export const environment: Environment = {
  apiUrl: 'https://api.fastlaneng-alpha.tri-core.net/api',
  enableServiceWorker: true,
  isProduction: false,
  emailFromAddress: {
    emailAddress: 'TriCoreDevTeam@sandbox280fe36815cf427d828c8bcaa28286d4.mailgun.org',
    displayName: 'Alpha Tester'
  },
  testEmailRecipientAddressess: [testersGroupAddress],
  paypointUrl: 'https://uat.thepayplace.com/la/economic/fastlane/default.aspx?',
  cloudPublicFilesPrefix:
    'https://storage.googleapis.com/fastlane-public-files/',
  versionHash: versionHash,
  shouldBypassPaypoint: true,
  orbiPayClientKey: ''
};
