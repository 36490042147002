export class Eligibility {
  hasGamingActivityAtProjectSite: boolean;
  hasGamingActivityElsewhereByOwner: boolean;

  constructor(eligibility?: any) {
    eligibility = eligibility || {};
    this.hasGamingActivityAtProjectSite =
      eligibility.hasGamingActivityAtProjectSite;
    this.hasGamingActivityElsewhereByOwner =
      eligibility.hasGamingActivityElsewhereByOwner;
  }
}
