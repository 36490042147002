var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { incentiveProgram, actNumber, sessionNumber, projectType, projectStatus, externalReviewAgencies, externalReviewStatuses, legislationRuleNames, defaultBiProjectDatesArray, firstProgramForm, dateTypeMap, boardStatus, defaultProjectDatesArrayMap, ProgramstoFormTypesMap, programsToFormStatusesMap, programsToProjectStatusesMap, formStatusCategory, entProjectStatus, incentiveCategory } from './project.constants';
import { formTypes, formStatus, formStatuses } from '../form/form.constants';
import { chain, extend } from 'underscore';
import { Project } from './models/project.model';
import { userType } from '../user/user.constants';
import { assessorAttachmentTypes } from '../core/core.constants';
import { deepCopy } from '../shared/shared.functions';
import { map } from 'rxjs/operators';
import * as _ from 'underscore';
import { BoardAgendaProgramSummary } from '../board/models/board-agenda-summary.model';
import { BoardAgendaFormSummary } from '../board/models/board-agenda-form-summary.model';
import { isHttpStatusCode } from '../shared/shared.functions';
import { publishSubscribeEvents } from '../event/event.constants';
import { Project as FilmProject } from '../entertainment/models/film/project.model';
import { Project as DigitalProject } from '../entertainment/models/digital/project.model';
import { Project as StepProject } from '../step/models/step/project.model';
import { agreementStatus } from '../management/signatures/signatures.constants';
import { doFormIdsMatch } from '../form/form.functions';
export function addProjectStatusTooltips() {
    const statusToTooltipTextMap = getProjectCurrentStatusesTooltips();
    chain(statusToTooltipTextMap)
        .pairs()
        .forEach(pair => {
        // Set tooltip text
        $(`[data-projectstatus="${pair[0]}"]`).prop('title', pair[1]);
        // Set tooltip
        $(`[data-projectstatus="${pair[0]}"]`).attr('data-toggle', 'tooltip');
        $(`[data-projectstatus="${pair[0]}"]`).attr('data-position', 'top');
    });
    // Enable tooltips
    $('[data-projectstatus]').tooltip();
    $('[data-projectstatus]').prop('title', '');
}
export function getAllIncentivePrograms() {
    return [
        Object.assign({}, incentiveProgram.dm),
        Object.assign({}, incentiveProgram.ez),
        Object.assign({}, incentiveProgram.film),
        Object.assign({}, incentiveProgram.ite),
        Object.assign({}, incentiveProgram.qj),
        Object.assign({}, incentiveProgram.rta),
        Object.assign({}, incentiveProgram.step)
    ];
}
export function getApplicableProjectTypes(program, formType) {
    if (formType === formTypes.advance.abbrev && program !== incentiveProgram.ite.code) {
        return [
            projectType.addition,
            projectType.expansion,
            projectType.misc,
            projectType.renovation,
            projectType.startup
        ];
    }
    switch (program) {
        case incentiveProgram.ez.code:
            return [projectType.addition, projectType.expansion, projectType.startup];
        case incentiveProgram.ite.code:
            return [projectType.addition, projectType.misc, projectType.startup];
        case incentiveProgram.qj.code:
            return [projectType.addition, projectType.expansion, projectType.startup];
        case incentiveProgram.rta.code:
            return [
                projectType.addition,
                projectType.expansion,
                projectType.development,
                projectType.improvement,
                projectType.renovation,
                projectType.restoration
            ];
    }
}
/**
 * @summary Provides applicable form statuses given the incentive category
 * @param incentiveCategory Either *business* or *entertainment*
 */
export function getApplicableFormStatuses(incentiveCategory) {
    // Switch context depending of whether bi or ent dashboard
    return Object.keys(formStatusCategory).reduce((prev, next) => {
        if (formStatusCategory[next].incentiveCategory.includes(incentiveCategory)) {
            prev[next] = formStatusCategory[next];
            return prev;
        }
        return prev;
    }, {});
}
export function getFormTypeBoardOrder(type) {
    try {
        return chain(formTypes)
            .pairs()
            .find(pair => pair[1].abbrev === type)
            .value()[1].boardOrder;
    }
    catch (_a) {
        if (type === 'appeal') {
            return 11;
        }
        else if (type === 'lateApplication') {
            return 1;
        }
        else if (type === 'lateRenewal') {
            return 3;
        }
        // Default to 1000 for unmatched form types (ordered to the end, essentially)
        // This is the case for appeals that are sent before the board
        return 1000;
    }
}
export function getIncentiveCategory(program) {
    const incentiveProgramConst = chain(incentiveProgram)
        .pairs()
        .find(pair => pair[1].code === program)
        .value()[1];
    if (incentiveProgramConst) {
        return incentiveProgramConst.category;
    }
    return null;
}
export function getIncentiveProgramBoardOrder(program) {
    return chain(incentiveProgram)
        .pairs()
        .find(pair => pair[1].code === program)
        .value()[1].boardOrder;
}
export function getIncentiveProgramName(program) {
    return chain(incentiveProgram)
        .pairs()
        .find(pair => pair[1].code === program)
        .value()[1].name;
}
export function getContractChangeForms(program) {
    if (program.toUpperCase() === 'QJ') {
        return [
            formTypes.changeCompanyName,
            formTypes.contractClosure,
            formTypes.changeAffiliatesOwners,
            formTypes.changeLocation
        ];
    }
    if (program.toUpperCase() === 'ITE') {
        return [
            formTypes.changeCompanyName,
            formTypes.contractClosure,
            formTypes.partialTransferOwnership,
            formTypes.fullTransferOwnership,
            formTypes.changeLocation
        ];
    }
    if (program.toUpperCase() === 'EZ') {
        return [
            formTypes.changeCompanyName,
            formTypes.contractClosure,
            formTypes.changeAffiliatesOwners
        ];
    }
    if (program.toUpperCase() === 'RTA') {
        return [
            formTypes.changeCompanyName,
            formTypes.contractClosure,
            formTypes.fullTransferOwnership,
            formTypes.partialTransferOwnership
        ];
    }
}
export function getApplicableProjectForms(currentIncentiveProgram, projectBase, currentUserType, governorSignatures, projectDataService) {
    return __awaiter(this, void 0, void 0, function* () {
        //#region managerForms
        // Return an array formTypes applicable to current context for a manager.
        // Allow specific usertype to create a specific form type.
        if (currentUserType.toLowerCase() === userType.management.code.toLowerCase()) {
            // This is for film projects.
            if (currentIncentiveProgram.toUpperCase() === incentiveProgram.film.code) {
                return [formTypes.initialCert, formTypes.audit, formTypes.balanceAdjustmentRequest];
                // This is for digital projects.
            }
            else if (currentIncentiveProgram.toUpperCase() === incentiveProgram.dm.code) {
                return [formTypes.initialCert, formTypes.evr];
            }
            else if (currentIncentiveProgram.toUpperCase() === incentiveProgram.step.code) {
                return [];
            }
            // This is for all Bi Projects.
            return [
                formTypes.balanceAdjustmentRequest,
                formTypes.contractClosure,
                formTypes.specialRequest
            ];
        }
        //#endregion
        //#region applicantForms
        // Return an array formTypes applicable to current context for an applicant.
        if (currentIncentiveProgram.toUpperCase() === incentiveProgram.qj.code) {
            const project = projectBase;
            // condition for renewals (there should be afc)
            if (project.application != null) {
                return [
                    formTypes.afc,
                    formTypes.pcr,
                    formTypes.ecr,
                    formTypes.acr,
                    formTypes.renewalApplication,
                    formTypes.changeCompanyName,
                    formTypes.changeLocation,
                    formTypes.fullTransferOwnership,
                    formTypes.changeAffiliatesOwners,
                    formTypes.contractClosure
                ];
            }
            const additionalFormsArray = [
                formTypes.afc,
                formTypes.pcr,
                formTypes.ecr,
                formTypes.acr,
                formTypes.changeCompanyName,
                formTypes.changeLocation,
                formTypes.fullTransferOwnership,
                formTypes.changeAffiliatesOwners,
                formTypes.contractClosure
            ];
            // allows to add forms only when application is present.
            if (project.application != null) {
                return additionalFormsArray;
            }
            else if (project.projectInfo.partialTransfer > 0) {
                // exception case for partial transfers.
                return additionalFormsArray;
            }
            return getArrayofFormTypes(project);
        }
        if (currentIncentiveProgram.toUpperCase() === incentiveProgram.ite.code) {
            const project = projectBase;
            let advanceHasBeenRecPostExecOrder = false;
            let applicationBoardApproved = false;
            // We are checking original project's advance if this is a phased project
            if (!!project.projectInfo.projectPhase) {
                const originalProjectId = project.projectInfo.projectId.replace(/\D+/g, '') + '-ITE';
                const resolvedProjectId = yield new Promise((resolve, reject) => {
                    projectDataService.getProjectGuidAndInfo(originalProjectId).subscribe(requestedProjectId => resolve(requestedProjectId));
                });
                if (resolvedProjectId && resolvedProjectId.id) {
                    const cocReqAdvanceResult = yield new Promise((resolve, reject) => {
                        projectDataService.cocReqAdvance(project.id, resolvedProjectId.id)
                            .subscribe(requestedProject => resolve(requestedProject));
                    });
                    advanceHasBeenRecPostExecOrder = cocReqAdvanceResult;
                }
            }
            else {
                advanceHasBeenRecPostExecOrder = project.advance &&
                    project.advance.statuses && project.advance.statuses.length > 0 &&
                    project.advance.statuses.filter(x => {
                        if (!x.statusDate) {
                            return false;
                        }
                        if (x.status !== 'Received') {
                            return false;
                        }
                        const statusDate = new moment(`${x.statusDate.getMonth() + 1}/${x.statusDate.getDate()}/${x.statusDate.getFullYear()}`);
                        const execOrderDate = new moment(`06/24/2016`);
                        return statusDate >= execOrderDate;
                    }).length > 0;
            }
            applicationBoardApproved = project.application &&
                project.application.boardStatuses &&
                project.application.boardStatuses.length > 0 &&
                project.application.boardStatuses.filter(x => x.status === 'Approved').length > 0;
            if (project.application != null && project.afc != null) {
                let types = [
                    formTypes.afc,
                    formTypes.pcr,
                    formTypes.partialTransferOwnership,
                    formTypes.fullTransferOwnership,
                    formTypes.changeCompanyName,
                    formTypes.changeLocation,
                    formTypes.contractClosure
                ];
                if (project.afc && project.afc.length > 0) {
                    const approvedAfcs = project.afc.filter(x => x.statuses[0].status === formStatuses.reviewComplete.name);
                    if (approvedAfcs.length > 0) {
                        types.push(formTypes.renewalApplication);
                    }
                }
                if (advanceHasBeenRecPostExecOrder && applicationBoardApproved) {
                    types.push(formTypes.coc);
                }
                return types;
            }
            const additionalFormsArray = [
                formTypes.afc,
                formTypes.pcr,
                formTypes.partialTransferOwnership,
                formTypes.fullTransferOwnership,
                formTypes.changeCompanyName,
                formTypes.changeLocation,
                formTypes.contractClosure
            ];
            // allows to add forms only when application is present.
            if (project.application != null) {
                if (project.advance &&
                    project.advance.receivedDate) {
                    additionalFormsArray.push(formTypes.extensionRequest);
                }
                if (advanceHasBeenRecPostExecOrder && applicationBoardApproved) {
                    additionalFormsArray.push(formTypes.coc);
                }
                return additionalFormsArray;
            }
            else if (project.projectInfo.partialTransfer > 0) {
                // exeption case for partial transfers.
                return additionalFormsArray;
            }
            return getArrayofFormTypes(project);
        }
        if (currentIncentiveProgram.toUpperCase() === incentiveProgram.ez.code) {
            const project = projectBase;
            const additionalFormsArray = [
                formTypes.afc,
                formTypes.ecr,
                formTypes.pcr,
                formTypes.changeCompanyName,
                formTypes.changeLocation,
                formTypes.changeAffiliatesOwners,
                formTypes.contractClosure
            ];
            // allows to add forms only when application is present.
            if (project.application != null) {
                if (!project.application.receivedDate) {
                    let post2021LegislationChange = true;
                    if (project.advance.receivedDate) {
                        post2021LegislationChange = project.advance.receivedDate > new Date(2021, 6, 20);
                    }
                    if (!post2021LegislationChange) {
                        additionalFormsArray.push(formTypes.extensionRequest);
                    }
                    else {
                        if (project.advance.projectDetails && project.advance.projectDetails.projectEndDate) {
                            const endDateMoment = moment(project.advance.projectDetails.projectEndDate);
                            const now = new moment();
                            if (!moment(now).isAfter(endDateMoment, 'day')) {
                                additionalFormsArray.push(formTypes.extensionRequest);
                            }
                        }
                    }
                }
                return additionalFormsArray;
            }
            else if (project.projectInfo.partialTransfer > 0) {
                // exeption case for partial transfers.
                return additionalFormsArray;
            }
            return getArrayofFormTypes(project);
        }
        if (currentIncentiveProgram.toUpperCase() === incentiveProgram.rta.code) {
            const project = projectBase;
            // condition for renewals
            if (project.application != null && project.afc != null) {
                return [
                    formTypes.afc,
                    formTypes.pcr,
                    formTypes.renewalApplication,
                    formTypes.fullTransferOwnership,
                    formTypes.partialTransferOwnership,
                    formTypes.changeCompanyName,
                    formTypes.contractClosure
                ];
            }
            const additionalFormsArray = [
                formTypes.afc,
                formTypes.pcr,
                formTypes.fullTransferOwnership,
                formTypes.partialTransferOwnership,
                formTypes.changeCompanyName,
                formTypes.contractClosure
            ];
            // allows to add forms only when application is present.
            if (project.application != null) {
                if (project.advance &&
                    project.advance.receivedDate &&
                    !project.application.receivedDate) {
                    additionalFormsArray.push(formTypes.extensionRequest);
                }
                return additionalFormsArray;
            }
            else if (project.projectInfo.partialTransfer > 0) {
                // exeption case for partial transfers.
                return additionalFormsArray;
            }
            return getArrayofFormTypes(project);
        }
        if (currentIncentiveProgram.toUpperCase() === incentiveProgram.film.code) {
            return [formTypes.application, formTypes.asa];
        }
        if (currentIncentiveProgram.toUpperCase() === incentiveProgram.dm.code) {
            return [formTypes.application];
        }
        if (currentIncentiveProgram.toUpperCase() === incentiveProgram.step.code) {
            let availableForms = [];
            availableForms.push(formTypes.stepApplication);
            return availableForms;
        }
        //#endregion
    });
}
export function setEndoftheDayTime(date) {
    return new Date(date.setHours(23, 59, 59, 999));
}
export function setStartoftheDayTime(date) {
    return new Date(date.setHours(0, 0, 0));
}
export function setStartoftheCSTDayTime(date) {
    if (!date) {
        return date;
    }
    date = new Date(deepCopy(date));
    let returnDate;
    const offset = date.getTimezoneOffset() / 60;
    const minutesOffset = (offset % 1) * 60;
    if (date.getUTCMonth() > 10 || date.getUTCMonth() < 4) {
        returnDate = new Date(date.setHours(6 - offset, 0 - minutesOffset, 0));
        return returnDate;
    }
    else {
        returnDate = new Date(date.setHours(5 - offset, 0 - minutesOffset, 0));
        return returnDate;
    }
}
export function setCSTDayTime(date) {
    if (!date) {
        return date;
    }
    date = new Date(deepCopy(date));
    let returnDate;
    const offset = date.getTimezoneOffset() / 60;
    const minutesOffset = (offset % 1) * 60;
    if (date.getUTCMonth() > 10 || date.getUTCMonth() < 4) {
        returnDate = new Date(date.setHours(6 - offset + date.getHours(), 0 - minutesOffset + date.getMinutes(), 0));
        return returnDate;
    }
    else {
        returnDate = new Date(date.setHours(5 - offset + date.getHours(), 0 - minutesOffset + date.getMinutes(), 0));
        return returnDate;
    }
}
// summary//
// This function mimics the provided timezone's time in the current timezone//
// of the client.//
export function setTimezone(timezone, date) {
    if (!date) {
        return date;
    }
    const parsedDate = new Date(deepCopy(date));
    const momentInTime = window.moment(parsedDate).tz(timezone);
    return momentInTime.toDate();
}
export function getArrayofFormTypes(project) {
    // allows to add application when advance is present.
    if (project.advance != null && project.advance.receivedDate != null) {
        const availableFormTypes = [formTypes.application];
        // RTA, EZ, and ITE are allowed to have project extension request forms
        if ([
            incentiveProgram.ez.code,
            incentiveProgram.ite.code,
            incentiveProgram.rta.code
        ].includes(project.projectInfo.incentiveProgram)) {
            if (project.projectInfo.incentiveProgram === incentiveProgram.ez.code) {
                if (project.advance.projectDetails && project.advance.projectDetails.projectEndDate) {
                    const endDateMoment = moment(project.advance.projectDetails.projectEndDate);
                    const now = new moment();
                    if (!moment(now).isAfter(endDateMoment, 'day')) {
                        availableFormTypes.push(formTypes.extensionRequest);
                    }
                }
            }
            else {
                availableFormTypes.push(formTypes.extensionRequest);
            }
        }
        return availableFormTypes;
    }
    else {
        const array = [];
        if (project.advance && !project.advance.receivedDate) {
            return array;
        }
        else if (!project.advance && project.projectInfo.projectPhase) {
            // allows to add application even when advance is not present
            // this is special case for phases.
            array.push(formTypes.application);
            return array;
        }
        else {
            array.push(formTypes.advance);
            return array;
        }
    }
}
export function getApplicableDateTypes(program, project, currentFormId) {
    if (currentFormId && program) {
        const applicableDateTypeMap = _.findWhere(dateTypeMap, {
            incentiveProgram: program,
            formType: currentFormId.formType
        });
        if (applicableDateTypeMap && applicableDateTypeMap.dateTypes) {
            return applicableDateTypeMap.dateTypes;
        }
        else {
            return defaultBiProjectDatesArray;
        }
    }
    else if (program) {
        return defaultProjectDatesArrayMap[program];
    }
    return defaultBiProjectDatesArray;
}
export function getInitialCertForms(project, program) {
    if (program === incentiveProgram.film.code) {
        const filmProject = project;
        let forms = filmProject.initialCert || [];
        forms = forms.filter(f => f !== undefined && f != null);
        return forms;
    }
}
export function getProjectCurrentStatusesTooltips() {
    const statusToTooltipMap = {};
    statusToTooltipMap[projectStatus.contractActive] =
        'All Projects with Active Executed Contracts';
    statusToTooltipMap[projectStatus.contractCancelled] =
        'All Projects with Cancelled Contracts';
    statusToTooltipMap[projectStatus.contractComplete] =
        'All Projects with Completed Contracts';
    statusToTooltipMap[projectStatus.contractPending] =
        'All Board Approved Projects waiting on an Executed Contract';
    statusToTooltipMap[projectStatus.projectInReview] =
        'All Projects Not Board Approved';
    statusToTooltipMap[projectStatus.projectPending] =
        'Projects with no form submitted';
    statusToTooltipMap[projectStatus.projectWithdrawn] =
        'All Projects that have had an Advance or Application Cancelled';
    // The following statuses don't have any tooltips
    // statusToTooltipMap[projectStatus.projectCertified] = '';
    // statusToTooltipMap[projectStatus.projectInitiallyCertified] = '';
    statusToTooltipMap[projectStatus.renewalContractActive] =
        'All Projects with Active Executed Renewal Contracts';
    statusToTooltipMap[projectStatus.renewalContractCancelled] =
        'All Projects with Cancelled Renewal Contracts';
    statusToTooltipMap[projectStatus.renewalContractComplete] =
        'All Projects with Completed Renewal Contracts';
    statusToTooltipMap[projectStatus.renewalContractPending] =
        'All Renewal Board Approved Projects waiting on Executed Renewal Contracts';
    return statusToTooltipMap;
}
export function getProjectFormIdFromWrapper(projectFormWrapper) {
    return {
        formIndex: projectFormWrapper.form.formIndex,
        formType: projectFormWrapper.form.type,
        projectGuid: projectFormWrapper.projectGuid
    };
}
export function getProjectForms(project, program) {
    // Film
    if (program === incentiveProgram.film.code) {
        const filmProject = project;
        let forms = [
            filmProject.application,
            ...(filmProject.asa || []),
            ...(filmProject.initialCert || []),
            ...(filmProject.audit || []),
            ...(filmProject.bar || [])
        ];
        // Remove forms that are undefined
        forms = forms.filter(f => f !== undefined && f != null);
        return forms;
        // Digital
    }
    else if (program === incentiveProgram.dm.code) {
        const digitalProject = project;
        let forms = [
            digitalProject.application,
            ...(digitalProject.initialCert || []),
            ...(digitalProject.evr || [])
        ];
        // Remove forms that are undefined
        forms = forms.filter(f => f !== undefined && f != null);
        return forms;
        // BI
    }
    else if (program === incentiveProgram.step.code) {
        const stepProject = project;
        let forms = [
            ...(stepProject.stepApplication || [])
        ];
        forms = forms.filter(f => f !== undefined && f != null);
        return forms;
    }
    else {
        const biProject = project;
        // Compile list of forms
        let forms = [
            biProject.advance,
            biProject.application,
            ...(biProject.acr || []),
            ...(biProject.afc || []),
            ...(biProject.bar || []),
            ...(biProject.cao || []),
            ...(biProject.ccc || []),
            ...(biProject.ccl || []),
            ...(biProject.ccn || []),
            ...(biProject.cft || []),
            ...(biProject.cpt || []),
            ...(biProject.ecr || []),
            ...(biProject.ext || []),
            ...(biProject.pcr || []),
            ...(biProject.req || []),
            ...(biProject.coc || []),
            biProject.rnw
        ];
        // Remove forms that are undefined
        forms = forms.filter(f => f !== undefined && f != null);
        return forms;
    }
}
/**
 * Gets bootstrap badge pill CSS classes for project statuses
 * Returns no CSS classes for statuses not recognized by the program
 *
 * @export
 * @param {string} status The status of the project
 * @returns A string representing the CSS classes for a certain project status' badge-pill
 */
export function getProjectStatusPillCssClasses(status) {
    // Based on the status, a set of css classes are chosen
    // Some statuses share css classes, but some don't
    if (status === projectStatus.projectInReview) {
        return 'badgefont badge-primary';
    }
    else if (status === projectStatus.projectWithdrawn) {
        return 'badgefont badge-warning';
    }
    else if ([
        projectStatus.contractComplete,
        projectStatus.renewalContractComplete,
        entProjectStatus.projectComplete
    ].includes(status)) {
        return 'badgefont badge-purple';
    }
    else if ([
        projectStatus.contractPending,
        projectStatus.renewalContractPending
    ].includes(status)) {
        return 'badgefont badge-info';
    }
    else if ([
        projectStatus.contractActive,
        projectStatus.renewalContractActive,
        entProjectStatus.projectActive
    ].includes(status)) {
        return 'badgefont badge-navy';
    }
    else if ([
        projectStatus.contractCancelled,
        projectStatus.renewalContractCancelled,
        entProjectStatus.projectClosed,
        projectStatus.projectDenied
    ].includes(status)) {
        return 'badgefont badge-danger';
    }
    else if ([projectStatus.projectPending].includes(status)) {
        return 'badgefont badge-gray';
    }
    else if ([entProjectStatus.historical].includes(status)) {
        return 'badgefont badge-danger';
    }
    // By default, return the empty string to represent no css classes
    return '';
}
export function getExternalReviewStatusPillCssClasses(status) {
    return status === externalReviewStatuses.noObjection.name
        ? 'badgefont badge-pill-green'
        : status === externalReviewStatuses.objection.name
            ? 'badgefont badge-pill-danger'
            : status === externalReviewStatuses.objectionLifted.name
                ? 'badgefont badge-pill-blue'
                : 'badgefont badge-pill-cyan';
}
export function getFormStatusPillCssClasses(status) {
    // Based on the status, a set of css classes are chosen
    // Some statuses share css classes, but some don't
    if (status === formStatus.pending ||
        status === formStatus.pendingSignature ||
        status === formStatus.pendingPayment ||
        status === formStatus.pendingSubmit) {
        return 'badgefont badge-pill-gray';
    }
    else if (status === formStatus.onHold) {
        return 'badgefont badge-pill-cyan';
    }
    else if (status === formStatus.infoRequired ||
        status === formStatus.auditorAssigned ||
        status === formStatus.awaitingAudit ||
        status === formStatus.awaitingExpenditureVerification) {
        return 'badgefont badge-warning';
    }
    else if (status === formStatus.received ||
        status === 'Received - Under Review') {
        return 'badgefont badge-pill-blue';
    }
    else if (status === formStatus.reviewComplete) {
        return 'badgefont badge-success';
    }
    else if (status === formStatus.cancelled) {
        return 'badgefont badge-danger';
    }
    else if (status === formStatus.depositReceived) {
        return 'badgefont badge-pill-blue';
    }
    // By default, return the empty string to represent no css classes
    return '';
}
export function getBoardStatusSpanElementForDatatable(status) {
    const color = status === boardStatus.approved
        ? 'green'
        : status === boardStatus.deferred
            ? 'warning'
            : status === boardStatus.denied
                ? 'danger'
                : status === boardStatus.ready
                    ? 'info'
                    : 'gray';
    return `<span class="badge badgefont badge-${color}">${status ||
        'No Status'}</span>`;
}
export function getBoardStatusClasses(status) {
    const color = status === boardStatus.approved
        ? 'green'
        : status === boardStatus.deferred
            ? 'warning'
            : status === boardStatus.denied
                ? 'danger'
                : status === boardStatus.ready
                    ? 'info'
                    : 'gray';
    return `badge badgefont badge-${color}`;
}
export function getProjectStatusSpanElementForDatatable(status) {
    if (status === projectStatus.projectInReview) {
        return `<span class="badgefont badge-primary">${status}</span>`;
    }
    if (status === projectStatus.projectPending) {
        return `<span class="badgefont badge-pill-gray">${status}</span>`;
    }
    if (status === projectStatus.projectWithdrawn) {
        return `<span class="badgefont badge-pill-warning">${status}</span>`;
    }
    if (status === projectStatus.contractPending ||
        status === projectStatus.renewalContractPending) {
        return `<span class="badgefont badge-pill-cyan">${status}</span>`;
    }
    if (status === projectStatus.contractActive ||
        status === projectStatus.renewalContractActive ||
        status === projectStatus.projectActive) {
        return `<span class="badgefont badge-navy">${status}</span>`;
    }
    if (status === projectStatus.contractComplete ||
        status === projectStatus.renewalContractComplete) {
        return `<span class="badgefont badge-pill-purple">${status}</span>`;
    }
    if (status === projectStatus.contractCancelled ||
        status === projectStatus.renewalContractCancelled) {
        return `<span class="badgefont badge-pill-danger">${status}</span>`;
    }
    return `<span class="badgefont badge-pill-danger">${status}</span>`;
}
export function getSignatureStatusSpanElementForDatatable(status, forCsv = false) {
    const stringEqualsIgnoreCase = (str1, str2) => str1.toUpperCase() === str2.toUpperCase();
    const color = stringEqualsIgnoreCase(status, agreementStatus.executed)
        ? 'green'
        : stringEqualsIgnoreCase(status, agreementStatus.sentToManager)
            ? 'lazur'
            : stringEqualsIgnoreCase(status, agreementStatus.sentToCompany)
                ? 'warning'
                : stringEqualsIgnoreCase(status, agreementStatus.sentToDeputySecretary)
                    ? 'purple'
                    : stringEqualsIgnoreCase(status, agreementStatus.sentToGovernor)
                        ? 'primary'
                        : stringEqualsIgnoreCase(status, agreementStatus.cancelled) ||
                            stringEqualsIgnoreCase(status, agreementStatus.expired) ||
                            stringEqualsIgnoreCase(status, agreementStatus.voided)
                            ? 'danger'
                            : 'gray';
    if (forCsv) {
        return status || 'No Status';
    }
    return `<span class="badge badgefont badge-${color}">${status ||
        'No Status'}</span>`;
}
export function getSignatureStatusClass(status) {
    const stringEqualsIgnoreCase = (str1, str2) => str1.toUpperCase() === str2.toUpperCase();
    const color = stringEqualsIgnoreCase(status, agreementStatus.executed)
        ? 'green'
        : stringEqualsIgnoreCase(status, agreementStatus.sentToManager)
            ? 'lazur'
            : stringEqualsIgnoreCase(status, agreementStatus.sentToCompany)
                ? 'warning'
                : stringEqualsIgnoreCase(status, agreementStatus.sentToDeputySecretary)
                    ? 'purple'
                    : stringEqualsIgnoreCase(status, agreementStatus.sentToGovernor)
                        ? 'primary'
                        : stringEqualsIgnoreCase(status, agreementStatus.cancelled) ||
                            stringEqualsIgnoreCase(status, agreementStatus.expired) ||
                            stringEqualsIgnoreCase(status, agreementStatus.voided)
                            ? 'danger'
                            : 'gray';
    return `badge badgefont badge-${color}`;
}
export function getComplianceForms(program) {
    if (program.toUpperCase() === 'QJ') {
        return [formTypes.acr, formTypes.afc, formTypes.ecr, formTypes.pcr];
    }
    if (program.toUpperCase() === 'ITE') {
        return [formTypes.acr, formTypes.afc, formTypes.pcr];
    }
    if (program.toUpperCase() === 'EZ') {
        return [formTypes.afc, formTypes.ecr, formTypes.pcr];
    }
    if (program.toUpperCase() === 'RTA') {
        return [formTypes.afc, formTypes.ecr];
    }
}
export function getActNumber(program, receivedDate) {
    const act = chain(actNumber)
        .pairs()
        .find(pair => pair[1].abbrev === incentiveProgram)
        .value()[1];
    if (receivedDate >= act.threshold) {
        return act.new;
    }
    else {
        return act.old;
    }
}
export function getSession(program, receivedDate) {
    const session = chain(sessionNumber)
        .pairs()
        .find(pair => pair[1].abbrev === program)
        .value()[1];
    if (receivedDate >= session.threshold) {
        return session.new;
    }
    else {
        return session.old;
    }
}
export function getApplicationInvestmentAmount(project) {
    let applicationInvestmentAmount = 0;
    if (project.application) {
        if (project.application.ite) {
            applicationInvestmentAmount = project.application.estimatedInvestments
                .totalInvestmentsIte
                ? project.application.estimatedInvestments.totalInvestmentsIte
                : 0;
        }
        else {
            applicationInvestmentAmount = project.application.estimatedInvestments
                .totalInvestments
                ? project.application.estimatedInvestments.totalInvestments
                : 0;
        }
        return ('$' +
            applicationInvestmentAmount
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                .toString());
    }
    else {
        return '';
    }
}
export function boardAgendaObservableTransformer(inputObservable) {
    return inputObservable.pipe(map((res) => {
        if (res) {
            return _.chain(res)
                .groupBy(summary => summary.incentiveProgram)
                .pairs()
                .sortBy(summaryGroup => summaryGroup[0])
                .map(summaryGroup => new BoardAgendaProgramSummary(summaryGroup[0], _.chain(summaryGroup[1])
                .sortBy(formGroup => getFormTypeBoardOrder(formGroup.formType))
                .map(formGroup => Object.assign(new BoardAgendaFormSummary(), formGroup))
                .value()))
                .value()
                .sort((a, b) => getIncentiveProgramBoardOrder(a.incentiveProgram) -
                getIncentiveProgramBoardOrder(b.incentiveProgram))
                .map(ps => {
                ps.formSummaries.forEach(fs => fs.forms.sort((a, b) => {
                    if (a.projectInfo.incentiveProgram === incentiveProgram.ite.code) {
                        const orderA = _.chain(legislationRuleNames.ite)
                            .pairs()
                            .find(pair => pair[1].name === a.projectInfo.legislation)
                            .value()[1].boardOrder;
                        const orderB = _.chain(legislationRuleNames.ite)
                            .pairs()
                            .find(pair => pair[1].name === b.projectInfo.legislation)
                            .value()[1].boardOrder;
                        if (orderA > orderB) {
                            return 1;
                        }
                        else if (orderA < orderB) {
                            return -1;
                        }
                    }
                    if (a.projectInfo.companyName > b.projectInfo.companyName) {
                        return 1;
                    }
                    else if (a.projectInfo.companyName < b.projectInfo.companyName) {
                        return -1;
                    }
                    else if (a.projectInfo.projectId > b.projectInfo.projectId) {
                        return 1;
                    }
                    else if (a.projectInfo.projectId < b.projectInfo.projectId) {
                        return -1;
                    }
                    // else if (a.projectInfo.year > b.projectInfo.year) {
                    //   return true;
                    // } else if (a.projectInfo.year < b.projectInfo.year) {
                    //   return false;
                    // } else if (a.projectInfo.counter > b.projectInfo.counter) {
                    //   return true;
                    // } else if (a.projectInfo.counter < b.projectInfo.counter) {
                    //   return false;
                    // }
                    return 1;
                }));
                return ps;
            });
        }
        return [];
    }));
}
export function formatCurrency(value) {
    let currency = '$0.00';
    if (value) {
        currency =
            '$' +
                parseFloat(value)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    .toString();
    }
    return currency;
}
export function getAFCInvestmentAmount(project) {
    let AFCInvestmentAmount = 0;
    if (project.afc) {
        AFCInvestmentAmount = project.afc.investmentTotal
            ? project.afc.investmentTotal
            : 0;
        return ('$' +
            AFCInvestmentAmount.toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                .toString());
    }
    else {
        return '';
    }
}
export function getCompanyAndProjectNameText(companyName, projectName, filmId, digitalId) {
    const companyElement = companyName && companyName.length > 40
        ? `${companyName.substring(0, 40)}`
        : `${companyName}`;
    const projectNameElement = projectName;
    if (filmId && filmId !== undefined) {
        const filmIdElement = filmId;
        return `${companyElement} ${projectNameElement} ${filmIdElement}`;
    }
    if (digitalId && digitalId !== undefined) {
        const digitalIdElement = digitalId;
        return `${companyElement} ${projectNameElement} ${digitalIdElement}`;
    }
    return `${companyElement} ${projectNameElement}`;
}
export function getCompanyAndProjectName(companyName, projectName, filmId, digitalId, forCsv = false) {
    if (forCsv) {
        const companyElementCsv = companyName && companyName.length > 40
            ? `${companyName.substring(0, 40)}...`
            : companyName;
        const projectNameElementCsv = projectName;
        if (filmId && filmId !== undefined) {
            const filmIdElementCsv = filmId;
            return `${companyElementCsv}${projectNameElementCsv}${filmIdElementCsv}`;
        }
        if (digitalId && digitalId !== undefined) {
            const digitalIdElementCsv = digitalId;
            return `${companyElementCsv}${projectNameElementCsv}${digitalIdElementCsv}`;
        }
        return `${companyElementCsv}${projectNameElementCsv}`;
    }
    const companyElement = companyName && companyName.length > 40
        ? `<b title="${companyName}">${companyName.substring(0, 40)}...(</b> || [])`
        : `<b>${companyName}</b>`;
    const projectNameElement = '</br> <small>' + projectName + '</small>';
    if (filmId && filmId !== undefined) {
        const filmIdElement = '</br> <small>' + filmId + '</small>';
        return `${companyElement}${projectNameElement}${filmIdElement}`;
    }
    if (digitalId && digitalId !== undefined) {
        const digitalIdElement = '</br> <small>' + digitalId + '</small>';
        return `${companyElement}${projectNameElement}${digitalIdElement}`;
    }
    return `${companyElement}${projectNameElement}`;
}
export function getExternalAgencyName(agencyCode) {
    try {
        return chain(externalReviewAgencies)
            .pairs()
            .find(pair => pair[1].code === agencyCode)
            .value()[1].name;
    }
    catch (error) {
        console.error(`External review agency code "${agencyCode}" does not exist.`);
        return null;
    }
}
export function getExternalAgencyAbbrev(agencyCode) {
    try {
        return chain(externalReviewAgencies)
            .pairs()
            .find(pair => pair[1].code === agencyCode)
            .value()[1].abbrev;
    }
    catch (error) {
        console.error(`External review agency code "${agencyCode}" does not exist.`);
        return 'agencyAbbrev';
    }
}
export function filterAttachmentsForAssessors(attachments) {
    const attachmentsVisibleToAssessor = assessorAttachmentTypes;
    const filtered = attachments.filter(a => attachmentsVisibleToAssessor.includes(a.documentType));
    return filtered;
}
export function ProjectOutdatedSwal(component, title = 'Project Data Out of Sync') {
    component.swal
        .error({
        title: title,
        text: 'Another user has modified the data since you last loaded which caused your data \
      to be out of sync. Your data will be refreshed to ensure you are editing the \
      latest information.'
    })
        .then(response => {
        // Request components to ask api for a new resolved project
        component.projectDataService
            .resolveProject(component.project.id)
            .subscribe(resolvedProject => {
            // Once we get the latest ResolvedProject, replace the old one in detail service
            component.projectDetailService.setResolvedProject(resolvedProject);
            // Ask components wrappers (layout) components to refresh their project data
            // This impacts the this.project instances locally to each component
            component.refreshData();
            // Ask the navigation service to reload the current route to force
            // the complete lifecycle of each layout component
            component.navService.refresh();
        });
    })
        .catch(() => { });
}
export function UpdateProjectErrorHandler(errorMsg, swalService, pubSubService, sweetAlertOptions) {
    const defaultSweetAlertOptions = {
        title: 'Error Updating Project',
        text: 'An error ocurred while updating the project!'
    };
    // Merge default with user provided options
    const combinedOptions = Object.assign({}, (defaultSweetAlertOptions || []), (sweetAlertOptions || []));
    // Check whether or not is a error code that is custom by api
    if (isHttpStatusCode(errorMsg)) {
        pubSubService.publish(publishSubscribeEvents.outdatedData);
    }
    else {
        swalService
            .error(combinedOptions)
            .then(() => { })
            .catch(() => { });
    }
}
//#region Entertainment
/**
 * @param  {ProjectBase} project
 * @returns T
 */
export function CastProject(project, programType) {
    let castedProject = project;
    // Remove the init function to replace it further down with a strongly type one
    castedProject = _.omit(castedProject, 'init');
    if (programType === incentiveProgram.film.code) {
        castedProject = extend(new FilmProject(), castedProject);
        return castedProject.init();
    }
    else if (programType === incentiveProgram.dm.code) {
        return extend(new DigitalProject(), castedProject).init();
    }
    else if (programType === incentiveProgram.step.code) {
        return extend(new StepProject(), castedProject).init();
    }
    else {
        return extend(new Project(), castedProject).init();
    }
}
/**
 * @summary Use this to update the project status based on program and formtype. Only one status is set and that
 * is when the first form per program is received.
 * @param statuses The projectStatusLog for the current project from the project share service
 * @param program The incentive program to use
 * @param formType The form that just became Received
 */
export function setProjectStatus(statuses, program, formType, id) {
    if (firstProgramForm[program].includes(formType)) {
        const projectStatusLog = {
            creator: id,
            status: projectStatus.projectInReview,
            statusDate: statuses[0].statusDate
        };
        statuses.unshift(projectStatusLog);
    }
}
export function setProjectLegislationRule(getLegislationRule, projectInfo, form, program) {
    // if its an advance and has a recieved date - set the legislation rule corresponding to the incentive program
    if (!!form.receivedDate) {
        if (incentiveCategory.entertainment.incentivePrograms.includes(program)) {
            // call the getLegislationRule method in projectShareService and set the Rule
            // Since there is no received Date use today's date.
            projectInfo.legislation = getLegislationRule(projectInfo.incentiveProgram.toLowerCase(), new Date());
        }
        else {
            if (!projectInfo.legislation) {
                // call the getLegislationRule method in projectShareService and set the Rule
                // Since there is no received Date use today's date.
                projectInfo.legislation = getLegislationRule(projectInfo.incentiveProgram.toLowerCase(), new Date());
            }
        }
    }
}
export function getTypesArrayForPrograms(programs, type) {
    const consolidatedMap = {
        formType: ProgramstoFormTypesMap,
        formStatus: programsToFormStatusesMap,
        projectStatus: programsToProjectStatusesMap
    };
    const programsToTypeMap = consolidatedMap[type];
    const mulitpleProgramsTypeArray = [];
    programs.forEach(program => {
        mulitpleProgramsTypeArray.push(programsToTypeMap[program]);
    });
    return _.union(...(mulitpleProgramsTypeArray || []));
}
export function getProgramsArrayFromProgramsObject(programs) {
    // create an array of programs which were selected.
    return chain(programs)
        .pairs()
        .value()
        .filter(pg => pg[1])
        .map(pg => pg[0].toString().toUpperCase());
}
export function redirectToDMApplication(status, sourceFormId, swalServ, navService) {
    if (formTypes.evr.abbrev) {
        // User is not manager and sourceForm is application
        const urlTree = navService.getFormUrlTreeForCurrentUserContext(sourceFormId);
        return swalServ
            .yesOrNo({
            title: 'Deposit Payment Successful',
            // tslint:disable-next-line:max-line-length
            text: 'The application must be completed and application fee must be paid for the application and the audit deposit to be processed by LED. Would you like to go back to application?'
        })
            .then(result => {
            // If user clicked yes
            if (!result.dismiss) {
                swalServ
                    .selfDestructIn({ title: 'Redirecting in...' }, () => (window.location.href = navService.convertUrlTreeToString(urlTree)) // Redirect user to paypoint
                )
                    .then(() => { })
                    .catch(() => { });
            }
        }, rejected => { })
            .catch(() => { });
    }
}
export function showApplicationRedirectIfApplicable(form, sourceFormId, swalServ, navService) {
    // User is not manager and sourceForm is application
    if (
    //status === formStatus.depositReceived &&
    [formTypes.audit.abbrev, formTypes.evr.abbrev].includes(form),
        sourceFormId &&
            [formTypes.application.abbrev, formTypes.asa.abbrev].includes(sourceFormId.formType)) {
        const urlTree = navService.getFormUrlTreeForCurrentUserContext(sourceFormId);
        return swalServ
            .yesOrNo({
            title: 'Deposit Payment Successful',
            // tslint:disable-next-line:max-line-length
            text: 'The application must be completed and application fee must be paid for the application and the audit deposit to be processed by LED. Would you like to go back to application?'
        })
            .then(result => {
            // If user clicked yes
            if (!result.dismiss) {
                swalServ
                    .selfDestructIn({ title: 'Redirecting in...' }, () => (window.location.href = navService.convertUrlTreeToString(urlTree)) // Redirect user to paypoint
                )
                    .then(() => { })
                    .catch(() => { });
            }
        }, rejected => {
            if (rejected === 'cancel') {
                if ([formTypes.audit.abbrev, formTypes.evr.abbrev].includes(form)) {
                    window.location.reload();
                }
            }
        })
            .catch(() => { });
    }
}
export function replaceFilmCredits(sourceArray, replaceArray, criteria) {
    let index = sourceArray.findIndex(cd => doFormIdsMatch(cd.formIdentifier, criteria));
    while (index >= 0) {
        sourceArray.splice(index, 1);
        index = sourceArray.findIndex(cd => doFormIdsMatch(cd.formIdentifier, criteria));
    }
    sourceArray.push(...(replaceArray || []));
}
export function replaceDigitalCredits(sourceArray, replaceArray, criteria) {
    let index = sourceArray.findIndex(cd => doFormIdsMatch(cd.formIdentifier, criteria));
    while (index >= 0) {
        sourceArray.splice(index, 1);
        index = sourceArray.findIndex(cd => doFormIdsMatch(cd.formIdentifier, criteria));
    }
    sourceArray.push(...(replaceArray || []));
}
//#endregion
