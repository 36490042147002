<shr-person [form]="userForm" [person]="user" [addressRequired]="false"></shr-person>

<div class='d-flex align-items-center py-2'>
  <h6 class="form-control-label mb-0">Board Committees:</h6>
  <i class="fa fa-info-circle fa-2x ml-2" data-toggle="tooltip" title="At least one board committee must be selected."
     data-placement="top"></i>
</div>

<div class="col-12 d-flex flex-wrap">
  <shr-checkbox *ngFor="let bt of boardTypesAvailable" [controlId]="'board-type-checkbox'" [label]="'Board of ' + bt" [checked]="user.boards ? user.boards.includes(bt) : false"
    (checkedChange)="checkedBoardType(bt, $event)">
  </shr-checkbox>
</div>
