export class RelatedTransaction {
  public name: string;
  public relationship: string;
  public transactionAmount: number;
  constructor() {
  }
  init() {
    this.name = this.name || '';
    this.relationship = this.relationship || '';
    this.transactionAmount = this.transactionAmount || 0;
    return this;
  }
}
