import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LookupHttpService } from '../../fastlane-common/services/lookup-http.service';
import { AuditingCompany } from '../../fastlane-common/models/lookup/auditing-company.model';
import { FormControl } from '@angular/forms';
import { AuditInfo } from '../interfaces/audit-info.interface';
import { Subscription } from 'rxjs';
import { FormStatusService } from '../../form/services/status.service';
import { formTypes, formStatus } from '../../form/form.constants';
import { FormValidationService } from '../../form/services/validation.service';

@Component({
  selector: 'fl-auditor-assignment',
  templateUrl: './auditor-assignment.component.html',
  styleUrls: ['./auditor-assignment.component.scss']
})
export class AuditorAssignmentComponent implements OnInit, OnDestroy {
  @Input() form: AuditInfo;
  @Input() readOnly = false;
  activeCompanies: AuditingCompany[];
  activeCompany: FormControl = new FormControl();
  activeCompanySub: Subscription;
  constructor(
    private lookupService: LookupHttpService,
    private statusService: FormStatusService,
    private validationService: FormValidationService
  ) {
    const that = this;
    // Go get active companies
    lookupService.getActiveAuditingCompanies().subscribe(auditingCompanies => {
      that.activeCompanies = auditingCompanies;
    });
  }

  selectedCompany(c1: string, c2: string) {
    return c1 && c2 && c1 === c2;
  }

  selectAuditor($event) {}

  ngOnDestroy() {
    // Ensure no active subscription
    if (this.activeCompanySub && this.activeCompanySub.unsubscribe) {
      this.activeCompanySub.unsubscribe();
    }
  }

  ngOnInit() {
    const that = this;

    // Patch value of the selected Audit Company if applicable
    if (this.form && this.form.auditingCompanyId) {
      this.activeCompany.patchValue(this.form.auditingCompanyId);
    }

    // Subscribe to changes made by user
    this.activeCompanySub = this.activeCompany.valueChanges.subscribe(value => {
      that.form.auditingCompanyId = value;
    });
  }
}
