<div id="app_modal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="p-3">
        <button type="button" class="close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span
          ><span class="sr-only">Close</span>
        </button>
        <ng-container #userDetailRef></ng-container>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
