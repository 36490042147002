import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { ProjectDataService } from '../../../project/services/project-data.service';
import { dateTypes } from '../../../project/project.constants';
import { PublicApplication } from '../../models/public-application';
import { formatDateForDisplay } from '../../../shared/shared.functions';
import {formatCurrency} from '../../../project/project.functions';
import {Address} from '../../../shared/models/address.model';
declare var $;

@Component({
  selector: 'fl-ez-public-search',
  templateUrl: './ez-public-search.component.html',
  styleUrls: ['./ez-public-search.component.scss']
})
export class EzPublicSearchComponent implements OnInit, OnDestroy {
  @Input()
  parentSubject: Subject<any>;
  @Input()
  parentSearchFilters: any;
  searchFilters = {};
  table;
  private dataSubscription: Subscription;
  private subjectSubscription: Subscription;

  constructor(
    private projectDataService: ProjectDataService
  ) {}

  dataIsNull(value) {
    if (value === '' || value == null) {
      return '';
    }
    return ', ' + value;
  }

  drawTable() {
    if (this.table) {
      this.table.draw();
    } else {
      this.initializeDataTable();
    }
  }

  getAddress(value: PublicApplication) {
    return new Address(value.projectInfo.location.address).toStringInline();
  }

  getEstimatedTotalJobs(value: PublicApplication) {
    let total = 0;
    if (value.application.estimatedJobs) {
      total =
        (value.application.estimatedJobs.construction
          ? value.application.estimatedJobs.construction
          : 0) +
        (value.application.estimatedJobs.existing
          ? value.application.estimatedJobs.existing
          : 0) +
        (value.application.estimatedJobs.new
          ? value.application.estimatedJobs.new
          : 0);
    }
    return total;
  }

  getDate(value: PublicApplication) {
    return formatDateForDisplay(new Date(value.application.receivedDate));
  }

  getEstimatedTotalInvestment(value: PublicApplication) {
    return (
      '$' +
      value.application.estimatedInvestments.totalInvestments
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        .toString()
    );
  }

  getEstimatedTotalPayroll(value: PublicApplication) {
    let total = 0;
    if (value.application.estimatedPayroll) {
      total =
        (value.application.estimatedPayroll.construction
          ? value.application.estimatedPayroll.construction
          : 0) +
        (value.application.estimatedPayroll.existing
          ? value.application.estimatedPayroll.existing
          : 0) +
        (value.application.estimatedPayroll.new
          ? value.application.estimatedPayroll.new
          : 0);
    }
    return (
      '$' +
      total
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        .toString()
    );
  }

  getPayrollRebate(value: PublicApplication) {
    let total = 0;
    if (value.application.fees.length > 0) {
      total = value.application.fees[0].estimatedBenefits;
    }
    return (
      '$' +
      total
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        .toString()
    );
  }

  getProjectFacilityExpenseRebate(value: PublicApplication) {
    let total = 0;
    if (value.application.fees.length > 0) {
      total = value.application.fees[0].estimatedRebates;
    }
    return (
      '$' +
      total
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        .toString()
    );
  }

  initializeDataTable() {
    const that = this;

    this.table = $('#ez-table').DataTable({
      serverSide: true,
      initComplete: function(settings, json) {},
      ajax: function(data, callback, settings) {
        Object.assign(data, that.searchFilters);
        if (that.dataSubscription && !that.dataSubscription.closed) {
          console.log('unsubscribed from data subs');
          that.dataSubscription.unsubscribe();
          that.dataSubscription = null;
        }

        that.dataSubscription = that.projectDataService
          .getPublicReportsForDataTablesCancellable(data, callback)
          .subscribe(() => setTimeout(() => that.table.columns.adjust(), 1000));
      },
      columns: [
        {
          title: 'Project ID',
          name: 'projectInfo.projectId',
          className: 'text-nowrap',
          bSortable: true,
          data: 'projectInfo.projectId'
        },
        {
          title: 'Company Name',
          name: 'projectInfo.companyName',
          bSortable: true,
          data: 'projectInfo.companyName'
        },
        {
          title: 'Project Physical Location',
          name: 'projectInfo.location.address.addressLine1',
          render: function(data, type, row: PublicApplication) {
            return that.getAddress(row);
          }
        },
        {
          title: 'Project Parish',
          name: 'projectInfo.location.parish',
          bSortable: true,
          data: 'projectInfo.location.parish',
          defaultContent: ''
        },
        {
          title: 'NAICS',
          name: 'application.projectDetails.naicsCode',
          bSortable: true,
          data: 'application.projectDetails.naicsCode',
          defaultContent: ''
        },
        {
          title: 'Application Received Date',
          name: 'application.receivedDate',
          render: function(data, type, row: PublicApplication) {
            return that.getDate(row);
          }
        },
        {
          title: 'Estimated Total Investment',
          name: 'application.estimatedInvestments.totalInvestments',
          render: function(data, type, row: PublicApplication) {
            return that.getEstimatedTotalInvestment(row);
          }
        },
        {
          title: 'Estimated Total Jobs',
          name: 'application.estimatedJobs.totalJobs',
          render: function(data, type, row: PublicApplication) {
            return that.getEstimatedTotalJobs(row);
          },
          defaultContent: ''
        },
        {
          title: 'Estimated Total Payroll',
          name: 'application.estimatedPayroll.totalPayroll',
          render: function(data, type, row: PublicApplication) {
            return that.getEstimatedTotalPayroll(row);
          }
        },
        {
          title: 'Estimated 5 Year Payroll Credit',
          name: 'application.fees.0.estimatedBenefits',
          render: function(data, type, row: PublicApplication) {
            return that.getPayrollRebate(row);
          }
        },
        {
          title: 'Estimated 5 Year S&U',
          name: 'application.fees.0.estimatedRebates',
          render: function(data, type, row: PublicApplication) {
            const sAndU = row.application.salesTaxInvestmentTax.estimatedStateRebateOrCredit || 0
              + row.application.salesTaxInvestmentTax.estimatedLocalRebate || 0;
            return formatCurrency(sAndU.toFixed(2));
          }
        },
        {
          title: 'Investment Tax Credit (ITC)',
          name: 'application.salesTaxInvestmentTax.projectFacilityExpenseRebate',
          render: function(data, type, row: PublicApplication) {
            const itc = row.application.salesTaxInvestmentTax.projectFacilityExpenseRebate || 0;
            return formatCurrency(itc.toFixed(2));
          }
        },
        {
          title: 'Legislation Rule',
          name: 'projectInfo.legislation',
          render: function(data, type, row: PublicApplication) {
            return row.projectInfo.legislation
              ? row.projectInfo.legislation
              : '';
          }
        },
        {
          title: 'Project Status',
          name: 'projectStatusLog.status',
          data: 'projectStatusLog.status'
        },
        {
          title: 'Project Region',
          name: 'projectInfo.location.region',
          data: 'projectInfo.location.region'
        },
        {
          title: 'Estimated Building & Material',
          name: 'application.estimatedInvestments.buildingAndMaterials',
          render: function(data, type, row: PublicApplication) {
            return formatCurrency(
              row.application.estimatedInvestments.buildingAndMaterials
            );
          },
          defaultContent: '0'
        },
        {
          title: 'Estimated Machinery & Equipment',
          name: 'application.estimatedInvestments.machineryAndEquipment',
          render: function(data, type, row: PublicApplication) {
            return formatCurrency(
              row.application.estimatedInvestments.machineryAndEquipment
            );
          },
          defaultContent: '0'
        },
        {
          title: 'Estimated Labor & Engineering',
          name: 'application.estimatedInvestments.laborAndEngineering',
          render: function(data, type, row: PublicApplication) {
            return formatCurrency(
              row.application.estimatedInvestments.laborAndEngineering
            );
          },
          defaultContent: '0'
        },
        {
          title: 'Estimated 5 Year New Payroll',
          name: 'application.estimatedPayroll.new',
          render: function(data, type, row: PublicApplication) {
            return formatCurrency(row.application.estimatedPayroll.new);
          },
          defaultContent: '0'
        },
        {
          title: 'Estimated 5 Year New Jobs',
          name: 'application.estimatedJobs.new',
          data: 'application.estimatedJobs.new',
          defaultContent: '0'
        },
        {
          title: 'Existing Jobs',
          name: 'application.estimatedJobs.existing',
          data: 'application.estimatedJobs.existing',
          defaultContent: '0'
        },
        {
          title: 'Estimated Construction Jobs',
          name: 'application.estimatedJobs.construction',
          data: 'application.estimatedJobs.construction',
          defaultContent: '0'
        },
        {
          title: 'Existing Payroll',
          name: 'application.estimatedPayroll.existing',
          render: function(data, type, row: PublicApplication) {
            return formatCurrency(
              row.application.estimatedPayroll.existing
            );
          },
          defaultContent: '0'
        },
        {
          title: 'Estimated Construction Payroll',
          name: 'application.estimatedPayroll.construction',
          render: function(data, type, row: PublicApplication) {
            return formatCurrency(
              row.application.estimatedPayroll.construction
            );
          },
          defaultContent: '0'
        },
        {
          title: 'Project Description',
          name: 'application.projectDetails.projectDescription',
          render: function(data, type, row: PublicApplication) {
            return row.application.projectDetails.projectDescription
              ? row.application.projectDetails.projectDescription
              : '';
          }
        },
        {
          title: 'Project Type',
          name: 'application.projectDetails.projectType',
          render: function(data, type, row: PublicApplication) {
            return row.application.projectDetails.projectType
              ? row.application.projectDetails.projectType
              : '';
          }
        },
        {
          title: 'Project Start Date',
          name: 'application.projectDetails.projectStartDate',
          render: function(data, type, row: PublicApplication) {
            if (row.application.projectDetails.projectStartDate) {
              return formatDateForDisplay(
                new Date(row.application.projectDetails.projectStartDate)
              );
            } else {
              return 'N/A';
            }
          }
        },
        {
          title: 'Project End Date',
          name: 'application.projectDetails.projectEndDate',
          render: function(data, type, row: PublicApplication) {
            if (row.application.projectDetails.projectEndDate) {
              return formatDateForDisplay(
                new Date(row.application.projectDetails.projectEndDate)
              );
            } else {
              return 'N/A';
            }
          }
        },
        {
          title: 'Application Board Approval Date',
          name: 'applicationBoardMeetings.0.date',
          render: function(data, type, row: PublicApplication) {
            return row.applicationBoardMeetings.length > 0
              ? formatDateForDisplay(
                  new Date(row.applicationBoardMeetings[0].date)
                )
              : null;
          }
        },
        {
          title: 'Contract Effective Date',
          name: 'contractEffectiveDate',
          render: function(data, type, row: PublicApplication) {
            return row.projectDates.filter(
              pd => pd.type === dateTypes.contractEffectiveDate.name
            ).length > 0
              ? formatDateForDisplay(
                  new Date(
                    row.projectDates.filter(
                      pd => pd.type === dateTypes.contractEffectiveDate.name
                    )[0].date
                  )
                )
              : null;
          }
        },
        {
          title: 'Contract Expiration Date',
          name: 'contractExpirationDate',
          render: function(data, type, row: PublicApplication) {
            return row.projectDates.filter(
              pd => pd.type === dateTypes.contractExpirationDate.name
            ).length > 0
              ? formatDateForDisplay(
                  new Date(
                    row.projectDates.filter(
                      pd => pd.type === dateTypes.contractExpirationDate.name
                    )[0].date
                  )
                )
              : null;
          }
        }
      ],
      pageLength: 1000,
      lengthMenu: [50, 75, 100, 1000],
      dom:
        `<'col-xl-12'<'row d-flex justify-content-between'<'p-0'f><'ml-2 mr-2 p-0'l><'html5buttons'B>>` +
        `<'row'<'table-only-wrapper position-relative col-xl-12 p-0'tr>>` +
        `<'row d-flex justify-content-end'<'mr-auto p-0'i><'p-0'p>>>`,
      buttons: [
        { extend: 'copy' },
        { extend: 'csv' },
        {
          extend: 'excelHtml5',
          title: 'Projects',
          customize: (xlsx) => {
            $(xlsx.xl['styles.xml']).find('numFmt[numFmtId="164"]').attr('formatCode', '[$$-45C] #,##0.00_-');
          }
        },
        { extend: 'pdf', title: 'Projects', orientation: 'landscape', pageSize : 'LEGAL',
          customize: function(doc) {
            doc.styles.tableHeader.fontSize = 9;
            doc.defaultStyle.fontSize = 8;
          } },
        {
          extend: 'print',
          title: 'Projects',
          customize: function(win) {
            $(win.document.body).addClass('white-bg');
            $(win.document.body).css('font-size', '10px');

            $(win.document.body)
              .find('table')
              .addClass('compact')
              .css('font-size', 'inherit');
          }
        }
      ],
      select: {
        style: 'single',
        items: 'row'
      },
      responsive: true,
      stateSave: false,
      processing: true,
      deferRender: true,
      language: {
        processing: 'Loading, please wait....'
      },
      order: [[5, 'desc']]
    });

    this.table.on('draw', () => {
      $('[data-toggle="tooltip"]').tooltip({
        trigger: 'hover'
      });
      $('a[data-toggle="tooltip"]').click(e => {
        $('a[data-toggle="tooltip"]').tooltip('hide');
      });
    });
  }

  ngOnDestroy() {
    // needed if child gets re-created (eg on some model changes)
    // note that subsequent subscriptions on the same subject will fail
    // so the parent has to re-create parentSubject on changes
    if (this.subjectSubscription) {
      this.subjectSubscription.unsubscribe();
    }

    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    const that = this;
    this.subjectSubscription = this.parentSubject.subscribe(filters => {
      Object.assign(that.searchFilters, filters);

      that.drawTable();
    });
    this.searchFilters = this.parentSearchFilters;
  }
}
