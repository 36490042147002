<div class="card border m-t m-b">
  <div class="card-header d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <b>PRINCIPALS IN THE PRODUCTION</b>
    </div>

    <span
      data-toggle="tooltip"
      title="Add Principal for Application"
      data-placement="left"
    >
      <button
        *ngIf="!readonly"
        type="button"
        (click)="select('Add')"
        class="btn text-primary bg-transparent pt-0 pb-0"
        data-toggle="modal"
        data-target="#principalModal"
      >
        <i class="fa fa-user-plus fa-2x" aria-hidden="true"></i>
      </button>
    </span>
  </div>
  <div class="card-body px-5">
    <table class="w-100 table table-striped table-bordered table-hover">
      <thead>
        <tr>
          <th>Principal Name</th>
          <th>Principal Role</th>
          <th *ngIf="!readonly"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let principal of principals; index as i">
          <td>{{ principal.firstName + ' ' + principal.lastName }}</td>
          <td>{{ principal.role }}</td>
          <td *ngIf="!readonly">
            <div class="d-flex align-items-center mx-1">
              <button
                type="button"
                class="btn px-1 py-1 bg-transparent"
                (click)="select('Save', i)"
                data-toggle="modal"
                data-target="#principalModal"
              >
                <i class="fa fa-pencil fa-2x text-gray"></i>
              </button>
              <button
                *ngIf="i !== 0"
                type="button"
                class="btn px-1 py-1 bg-transparent"
                (click)="delete(i)"
              >
                <i class="fa fa-trash fa-2x text-gray"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div id="principalModal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content p-3">
      <button type="button" class="close" data-dismiss="modal">
        <span aria-hidden="true">&times;</span>
        <span class="sr-only">Close</span>
      </button>

      <div class="modal-header p-xs">
        <h2 class="modal-title">{{ mode }} Principal</h2>
      </div>

      <div class="modal-body py-2 px-4">
        <form
          id="principalForm"
          [formGroup]="principalForm"
          class="form-group row no-borders"
          novalidate
        >
          <div class="row">
            <div class="my-1 col-12 d-flex">
              <label class="col-3 form-control-label">Principal Name:</label>
              <div class="col-4 px-0">
                <input
                  type="text"
                  class="form-control"
                  formControlName="firstName"
                  required
                  placeholder="First"
                />
              </div>

              <div class="col-2">
                <input
                  type="text"
                  class="form-control"
                  formControlName="middleInitial"
                  placeholder="MI"
                />
              </div>

              <div class="col-3 px-0">
                <input
                  type="text"
                  class="form-control"
                  formControlName="lastName"
                  required
                  placeholder="Last"
                />
              </div>
            </div>

            <div class="my-1 col-12 d-flex">
              <label class="col-3 form-control-label">Role:</label>
              <div class="col-4 px-0">
                <select
                  class="form-control"
                  id="role"
                  formControlName="role"
                  required
                >
                  <option value="" disabled selected>Choose a role</option>
                  <option value="Actor">Actor</option>
                  <option value="Director">Director</option>
                  <option value="Producer">Producer</option>
                  <option value="Writer">Writer</option>
                </select>
              </div>
              <div class="d-md-block d-none col-4"></div>
            </div>
          </div>
        </form>

        <div class="modal-footer p-xs button-group">
          <button type="button" class="btn btn-white" data-dismiss="modal">
            CLOSE
          </button>
          <button
            type="submit"
            form="principalForm"
            class="btn btn-primary"
            (click)="save()"
            [disabled]="principalForm && principalForm.invalid"
            data-dismiss="modal"
          >
            {{ mode | uppercase }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
