import { Location } from './location.model';
import { Contact } from './project-contact.model';
import { Address } from 'app/shared/models/address.model';
import { ProjectInfoBase } from './project-info-base.model';

export class ProjectInfo extends ProjectInfoBase {
  legacyAdvanceUid: number;
  partialTransfer?: number;
  originalLegislation: string;

  constructor(info?: any) {
    info = info || {};

    super(info);

    this.legacyAdvanceUid = info.legacyAdvanceUid || null;
    this.originalLegislation = info.originalLegislation || null;
    this.partialTransfer = info.partialTransfer || null;

  }
}
