import { Application } from './application.model';
import { formTypes } from '../../../form/form.constants';
import { InitialCertCriticalDates } from './initialCert-critical-dates.model';

export class InitialCertification extends Application {
  template: string;
  totalCreditsReserved?: number;
  isInitialCertSent?: boolean;
  isInitialCertExecuted?: boolean;
  initialCertCriticalDates: InitialCertCriticalDates;
  constructor(formIndex: number = null) {
    super();
    this.formIndex = formIndex;
  }
  init(): InitialCertification {
    super.init();
    this.type = formTypes.initialCert.abbrev;
    this.totalCreditsReserved =
      this.totalCreditsReserved >= 0 ? this.totalCreditsReserved : null;
    this.initialCertCriticalDates = this.initialCertCriticalDates
      ? this.initialCertCriticalDates
      : new InitialCertCriticalDates(this.initialCertCriticalDates);
    return this;
  }

  // get qualifier() {
  //   if (this.productionDetails.season) {
  //     return `Season : ${this.productionDetails.season}`;
  //   }
  //   return null;
  // }
}
