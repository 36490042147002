import { ProjectForm } from '../../project/models/form.model';
import { FormIdentifier } from '../../project/models/form-identifier.model';
export abstract class StepForm extends ProjectForm {
  checklistStartDate?: Date;
  checklistEndDate?: Date;
  checklistAwardAmount?: number;
  checklistAwardYear?: number;
  checklistApproved?: boolean;

  constructor(formIndex: number = null) {
    super(formIndex);
  }
}
