<div class="mb-4" [ngClass]="{ 'ng-invalid': invalidObservable | async }">
  <div class="card">
    <div class="card-header"><b>EXPENDITURES</b></div>
    <div class="card-body px-5">

      <!--#region Import Area  -->
      <ng-container *ngIf="!editMode">
        <div [formGroup] ="expendituresUploadFormGroup" class="expenditures-upload">

          <p>
            Download the <a [href]="FilePathConst.preliminaryExpenditureTemplate" target="_blank">
              Template for
              Preliminary Schedule of Expenditures.
            </a> Read the instructions in this excel template and enter the project
            expenditures. Click the Import Preliminary Schedule of Expenditures icon to import the data from within the
            spreadsheet into this application. If needed, changes can be made to the excel spreadsheet and the file can be
            imported again. This will clear any data previously imported.
          </p>
          <br />

          <label for="file-upload" class="btn">
            <i class="fa fa-2x text-primary fa-cloud-upload mr-2"></i>Import
            Preliminary Schedule of Expenditures
          </label>
          <input id="file-upload" name="upload_cont_img" type="file" style="display:none;"
                 (change)="uploadExpenditureFile($event)" formControlName="expendituresUpload"/>
          <br />
        </div>
      </ng-container>
      <!--#endregion-->

      <!--#region Status Buttons-->
      <ng-container *ngIf="editMode">
        <div class="d-flex justify-content-start align-items-center">
          <label class="m-1 font-weight-bold">Change Expenditures Status :</label>
          <button class="btn bg-transparent m-2" *ngFor="let expenditureStatus of allExpenditureStatuses"
            (click)="changeExpenditureStatus(expenditureStatus)">
            <i class="fas fa-2x" [ngClass]="{
                'fa-check text-success': expenditureStatus === 'Eligible',
                'fa-ban text-danger': expenditureStatus === 'Ineligible',
                'fa-pennant text-warning':
                  expenditureStatus === 'Conditionally Eligible'
              }" title="Mark {{ expenditureStatus }}" data-toggle="tooltip" data-placement="top"></i>
          </button>
        </div>
        <div class="d-flex justify-content-start align-items-center">
          <label class="m-1 font-weight-bold">Check All / Uncheck All :</label>
          <input type="checkbox" class="uncheckAllCheckbox m-2" (change)="uncheckCheckAll($event.target.checked)" />
        </div>
      </ng-container>
      <!--#endregion-->

      <!--#region Expenditures -->
      <div class="card laborExpenditures" *ngIf="expenditures.laborInfo.length > 0">
        <div class="card-header"><b>LABOR EXPENDITURES</b></div>
        <div class="card-body px-2">
          <!-- PRINT PERSON DETAIL HEADERS WITH THEIR DATA -->
          <!-- PRINT THIS ROW ONLY ONCE, AT IT IS ONLY FOR WIDE SCREENS AND SHOWN AS HEADERS -->
          <!-- LABOR INFO TABLE -->
          <div class="labor-info-table mb-2">
            <!-- LABOR INFO HEADER - ONLY PRINTED ONCE -->
            <div class="labor-info-row header">
              <!-- DATA, WHICH IS ACTUALLY HEADERS ONLY -->
              <div class="summary" [ngClass]="{
                  editMode: editMode
                }">
                <div class="cell" *ngFor="let header of personDetailHeaders; let h = index">
                  {{ header.display }}
                </div>
              </div>

              <!-- NO DETAILS FOR THIS ROW AS IN HEADER ONLY -->
            </div>

            <ng-container *ngFor="let info of expenditures?.laborInfo; let i = index">
              <!-- LABOR INFO ROWS LOOP - AS MANY AS THERE ARE RECORDS -->
              <div class="labor-info-row">
                <div class="summary" [ngClass]="{
                    editMode: editMode
                  }">
                  <div class="expenditure-btn-grp">
                  <button data-toggle="collapse" attr.data-target="[data-row='{{i}}']"
                      class="btn btn-link fas fa-2x" aria-expanded="false"></button>
                    <ng-container *ngIf="editMode">
                      <input type="checkbox" class="expenditureRowCheckbox"
                        (change)="laborInfoChecked(info, $event.target.checked)" />
                      <button type="button" class="btn btn-link bg-transparent" (click)="editLaborExpenditure(info, i)"
                        data-toggle="modal" data-target="#laborModal">
                        <i class="fas fa-pencil" title="Edit Labor Expenditure" data-toggle="tooltip"
                          data-placement="top"></i>
                      </button>
                    </ng-container>
                  </div>
                  <div class="cell" [attr.data-name]="header.display" *ngFor="let header of personDetailHeaders">
                    {{ renderValue(header.code, info[header.code]) }}
                  </div>
                </div>
                <div class="collapse" [attr.data-row]="i">
                  <br />
                  <div class="container">
                    <div class="row ml-2" *ngFor="let header of personDetailAdditionalHeaders">
                      <div class="header col-12 col-lg-2">
                        {{ header.display }}
                      </div>
                      <div class="col-12 col-lg-10" [attr.data-name]="header.display">
                        {{ renderValue(header.code, info[header.code]) }}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="details">
                    <div class="cell header" [attr.data-name]="header.display" *ngFor="
                        let header of yearDetailHeaders;
                        let yindex = index
                      ">
                      {{ header.display }}
                    </div>
                    <ng-container *ngFor="
                        let yinfo of info.yearDetails;
                        let yearIndex = index
                      ">
                      <div class="cell" [attr.data-name]="yearDetailHeaders[yindex].display"
                        *ngFor="let key of getKeys(yinfo); let yindex = index">
                        {{ renderValue(key, yinfo[key]) }}
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <br />
      <div class="card" *ngIf="expenditures.nonLaborInfo.length > 0">
        <div class="card-header"><b>NON LABOR EXPENDITURES</b></div>
        <div class="card-body px-2">
          <div class="non-labor-info-table mb-2">
            <div class="non-labor-info-row header">
              <!-- DATA, WHICH IS ACTUALLY HEADERS ONLY -->
              <div class="summary" [ngClass]="{
                  editMode: editMode
                }">
                <div class="cell" *ngFor="let header of itemDetailsHeaders">
                  {{ header.display }}
                </div>
              </div>

              <!-- NO DETAILS FOR THIS ROW AS IN HEADER ONLY -->
            </div>

            <ng-container *ngFor="
                let nonLaborinfo of expenditures?.nonLaborInfo;
                let i = index
              ">
              <!-- NON LABOR INFO ROWS LOOP - AS MANY AS THERE ARE RECORDS -->
              <div class="non-labor-info-row">
                <div class="summary" [ngClass]="{
                    editMode: editMode
                  }">
                  <div class="expenditure-btn-grp">
                  <button data-toggle="collapse" attr.data-target="[data-item-row='{{i}}']"
                      class="btn btn-link fas fa-2x" aria-expanded="false"></button>
                    <ng-container *ngIf="editMode">
                      <input type="checkbox" class="expenditureRowCheckbox" (change)="
                          nonLaborInfoChecked(
                            nonLaborinfo,
                            $event.target.checked
                          )
                        " />
                      <button type="button" class="btn btn-link bg-transparent"
                        (click)="editNonLaborExpenditure(nonLaborinfo, i)" data-toggle="modal"
                        data-target="#nonLaborModal">
                        <i class="fas fa-pencil" title="Edit NonLabor Expenditure" data-toggle="tooltip"
                          data-placement="top"></i>
                      </button>
                    </ng-container>
                  </div>
                  <div class="cell" [attr.data-name]="header.display" *ngFor="let header of itemDetailsHeaders">
                    {{ renderValue(header.code, nonLaborinfo[header.code]) }}
                  </div>
                </div>
                <div class="collapse" [attr.data-item-row]="i">
                  <div class="details">
                    <div class="cell header" [attr.data-name]="header.display" *ngFor="
                        let header of yearItemDetailHeaders;
                        let yindex = index
                      ">
                      {{ header.display }}
                    </div>
                    <ng-container *ngFor="
                        let yinfo of nonLaborinfo.yearDetails;
                        let yearIndex = index
                      ">
                      <div class="cell" [attr.data-name]="yearItemDetailHeaders[yindex].display"
                        *ngFor="let key of getKeys(yinfo); let yindex = index">
                        {{ renderValue(key, yinfo[key]) }}
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <!--#endregion-->

      <!--#region Totals  -->
      <br />
      <div class="card" *ngIf="expenditures.estimatedCredits.length > 0">
        <div class="card-header"><b>ESTIMATED EXPENDITURES</b></div>
        <div class="card-body px-5">
          <div [ngClass]="{
              totalBreakdown: !editMode,
              eligibleTotalBreakdown: editMode
            }">
            <!-- Column Headers  -->
            <!--  First column is not required due to magic of CSS GRID  -->
            <ng-container *ngFor="
                let years of expenditures?.yearsInSpreadsheet;
                let i = index
              ">
              <div class="header font-weight-bold bg-light border-bottom" [attr.data-order]="i">
                {{ years.year }}
              </div>
              <ng-container *ngIf="i === 0">
                <ng-container *ngTemplateOutlet="
                    editMode ? eligibleYear1 : year1;
                    context: { year: years.year, index: i }
                  "></ng-container>
              </ng-container>
              <ng-container *ngIf="i === 1">
                <ng-container *ngTemplateOutlet="
                    editMode ? eligibleYear2 : year2;
                    context: { year: years.year, index: i }
                  "></ng-container>
              </ng-container>
              <ng-container *ngIf="i === 2">
                <ng-container *ngTemplateOutlet="
                    editMode ? eligibleYear3 : year3;
                    context: { year: years.year, index: i }
                  "></ng-container>
              </ng-container>
            </ng-container>
            <div class="header font-weight-bold bg-light border-bottom" data-order="3">
              Totals
            </div>

            <!-- Row Headers -->
            <ng-container *ngTemplateOutlet="editMode ? eligibleRowHeaders : rowHeaders"></ng-container>

            <!--  Totals -->
            <ng-container *ngTemplateOutlet="
                editMode ? eligibleTotals : totals;
                context: { year: year, index: i }
              "></ng-container>
          </div>
        </div>
      </div>

      <!--#region Application Totals-->
      <ng-template #rowHeaders>
        <div data-name="inStateSalary" class="font-weight-bold rowHeader bg-light border-bottom">
          LA Resident Labor
        </div>
        <div data-name="outStateSalary" class="font-weight-bold rowHeader bg-light border-bottom">
          Non LA Resident Labor
        </div>
        <div data-name="totalLabor" class="font-weight-bold rowHeader bg-light border-bottom">
          Total Labor
        </div>
        <div data-name="totalNonLabor" class="font-weight-bold rowHeader bg-light border-bottom">
          Total Non Labor
        </div>
        <div data-name="totalExpenditures" class="font-weight-bold rowHeader bg-light border-bottom">
          Total Expenditures
        </div>
      </ng-template>

      <ng-template #year1 let-year="year">
        <div data-title="LA Resident Salary" class="border" data-name="inStateSalary" data-year="1">
          {{
            calCreditExpenditureFn(expenditures?.estimatedCredits, year, [
              creditTypesConst.inState
            ]) | currency
          }}
        </div>
        <div data-title="Non LA Resident Salary" class="border" data-name="outStateSalary" data-year="1">
          {{
            calCreditExpenditureFn(expenditures?.estimatedCredits, year, [
              creditTypesConst.outOfState
            ]) | currency
          }}
        </div>
        <div data-title="Total Labor" class="border" data-name="totalLabor" data-year="1">
          {{
            calCreditExpenditureFn(expenditures?.estimatedCredits, year, [
              creditTypesConst.inState,
              creditTypesConst.outOfState
            ]) | currency
          }}
        </div>
        <div data-title="Total Non Labor" class="border" data-name="totalNonLabor" data-year="1">
          {{
            calCreditExpenditureFn(expenditures?.estimatedCredits, year, [
              creditTypesConst.nonLabor
            ]) | currency
          }}
        </div>
        <div data-title="Total Expenditures" class="border" data-name="totalExpenditures" data-year="1">
          {{
            calCreditExpenditureFn(expenditures?.estimatedCredits, year, [
              creditTypesConst.inState,
              creditTypesConst.outOfState,
              creditTypesConst.nonLabor
            ]) | currency
          }}
        </div>
      </ng-template>
      <ng-template #year2 let-year="year">
        <div data-title="LA Resident Salary" class="border" data-name="inStateSalary" data-year="2">
          {{
            calCreditExpenditureFn(expenditures?.estimatedCredits, year, [
              creditTypesConst.inState
            ]) | currency
          }}
        </div>
        <div data-title="Non LA Resident Salary" class="border" data-name="outStateSalary" data-year="2">
          {{
            calCreditExpenditureFn(expenditures?.estimatedCredits, year, [
              creditTypesConst.outOfState
            ]) | currency
          }}
        </div>
        <div data-title="Total Labor" class="border" data-name="totalLabor" data-year="2">
          {{
            calCreditExpenditureFn(expenditures?.estimatedCredits, year, [
              creditTypesConst.inState,
              creditTypesConst.outOfState
            ]) | currency
          }}
        </div>
        <div data-title="Total Non Labor" class="border" data-name="totalNonLabor" data-year="2">
          {{
            calCreditExpenditureFn(expenditures?.estimatedCredits, year, [
              creditTypesConst.nonLabor
            ]) | currency
          }}
        </div>
        <div data-title="Total Expenditures" class="border" data-name="totalExpenditures" data-year="2">
          {{
            calCreditExpenditureFn(expenditures?.estimatedCredits, year, [
              creditTypesConst.inState,
              creditTypesConst.outOfState,
              creditTypesConst.nonLabor
            ]) | currency
          }}
        </div>
      </ng-template>
      <ng-template #year3 let-year="year">
        <div data-title="LA Resident Salary" class="border" data-name="inStateSalary" data-year="3">
          {{
            calCreditExpenditureFn(expenditures?.estimatedCredits, year, [
              creditTypesConst.inState
            ]) | currency
          }}
        </div>
        <div data-title="Non LA Resident Salary" class="border" data-name="outStateSalary" data-year="3">
          {{
            calCreditExpenditureFn(expenditures?.estimatedCredits, year, [
              creditTypesConst.outOfState
            ]) | currency
          }}
        </div>
        <div data-title="Total Labor" class="border" data-name="totalLabor" data-year="3">
          {{
            calCreditExpenditureFn(expenditures?.estimatedCredits, year, [
              creditTypesConst.inState,
              creditTypesConst.outOfState
            ]) | currency
          }}
        </div>
        <div data-title="Total Non Labor" class="border" data-name="totalNonLabor" data-year="3">
          {{
            calCreditExpenditureFn(expenditures?.estimatedCredits, year, [
              creditTypesConst.nonLabor
            ]) | currency
          }}
        </div>
        <div data-title="Total Expenditures" class="border" data-name="totalExpenditures" data-year="3">
          {{
            calCreditExpenditureFn(expenditures?.estimatedCredits, year, [
              creditTypesConst.inState,
              creditTypesConst.outOfState,
              creditTypesConst.nonLabor
            ]) | currency
          }}
        </div>
      </ng-template>
      <ng-template #totals let-year="year">
        <div data-title="LA Resident Salary" class="border totals" data-name="inStateSalary">
          {{
            calCreditExpenditureFn(expenditures?.estimatedCredits, null, [
              creditTypesConst.inState
            ]) | currency
          }}
        </div>
        <div data-title="Non LA Resident Salary" class="border totals" data-name="outStateSalary">
          {{
            calCreditExpenditureFn(expenditures?.estimatedCredits, null, [
              creditTypesConst.outOfState
            ]) | currency
          }}
        </div>
        <div data-title="Total Labor" class="border totals" data-name="totalLabor">
          {{
            calCreditExpenditureFn(expenditures?.estimatedCredits, null, [
              creditTypesConst.inState,
              creditTypesConst.outOfState
            ]) | currency
          }}
        </div>
        <div data-title="Total Non Labor" class="border totals" data-name="totalNonLabor">
          {{
            calCreditExpenditureFn(expenditures?.estimatedCredits, null, [
              creditTypesConst.nonLabor
            ]) | currency
          }}
        </div>
        <div data-title="Total Expenditures" class="border totals" data-name="totalExpenditures">
          {{
            calCreditExpenditureFn(expenditures?.estimatedCredits, null, [
              creditTypesConst.inState,
              creditTypesConst.outOfState,
              creditTypesConst.nonLabor
            ]) | currency
          }}
        </div>
      </ng-template>

      <!--#endregion-->

      <!--#region InitialCert Totals-->
      <ng-template #eligibleRowHeaders>
        <div data-name="totalUnMarkedExpenditures" class="font-weight-bold rowHeader bg-light border-bottom">
          Total Expenditures
        </div>
        <div data-name="totalIneligibleExpenditure" class="font-weight-bold rowHeader bg-light border-bottom">
          Total Ineligible Expenditure
        </div>
        <div data-name="totalEligibleLabor" class="font-weight-bold rowHeader bg-light border-bottom">
          Total Eligible Labor
        </div>
        <div data-name="totalEligibleNonLabor" class="font-weight-bold rowHeader bg-light border-bottom">
          Total Eligible Non Labor
        </div>
        <div data-name="totalEligibleExpenditures" class="font-weight-bold rowHeader bg-light border-bottom">
          Total Eligible Expenditures
        </div>
      </ng-template>

      <ng-template #eligibleYear1 let-year="year">
        <div data-title="Total Expenditures" class="border" data-name="totalUnMarkedExpenditures" data-year="1">
          {{
            calculateMarkedLaborExpendituresFn(
              expenditures?.laborInfo,
              year,
              null
            ) +
              calculateMarkedNonLaborExpendituresFn(
                expenditures?.nonLaborInfo,
                year,
                null
              ) | currency
          }}
        </div>
        <div data-title="Total Ineligible Expenditure" class="border" data-name="totalIneligibleExpenditure"
          data-year="1">
          {{
            calculateMarkedLaborExpendituresFn(expenditures?.laborInfo, year, [
              expenditureStatuses.ineligible
            ]) +
              calculateMarkedNonLaborExpendituresFn(
                expenditures?.nonLaborInfo,
                year,
                [expenditureStatuses.ineligible]
              ) | currency
          }}
        </div>
        <div data-title="Total Eligible Labor" class="border" data-name="totalEligibleLabor" data-year="1">
          {{
            calculateMarkedLaborExpendituresFn(expenditures?.laborInfo, year, [
              expenditureStatuses.eligible,
              expenditureStatuses.conditionallyEligible
            ]) | currency
          }}
        </div>
        <div data-title="Total Eligible Non Labor" class="border" data-name="totalEligibleNonLabor" data-year="1">
          {{
            calculateMarkedNonLaborExpendituresFn(
              expenditures?.nonLaborInfo,
              year,
              [
                expenditureStatuses.eligible,
                expenditureStatuses.conditionallyEligible
              ]
            ) | currency
          }}
        </div>
        <div data-title="Total Eligible Expenditures" class="border" data-name="totalEligibleExpenditures"
          data-year="1">
          {{
            calculateMarkedLaborExpendituresFn(expenditures?.laborInfo, year, [
              expenditureStatuses.eligible,
              expenditureStatuses.conditionallyEligible
            ]) +
              calculateMarkedNonLaborExpendituresFn(
                expenditures?.nonLaborInfo,
                year,
                [
                  expenditureStatuses.eligible,
                  expenditureStatuses.conditionallyEligible
                ]
              ) | currency
          }}
        </div>
      </ng-template>
      <ng-template #eligibleYear2 let-year="year">
        <div data-title="Total Expenditure" class="border" data-name="totalUnMarkedExpenditures" data-year="2">
          {{
            calculateMarkedLaborExpendituresFn(
              expenditures?.laborInfo,
              year,
              null
            ) +
              calculateMarkedNonLaborExpendituresFn(
                expenditures?.nonLaborInfo,
                year,
                null
              ) | currency
          }}
        </div>
        <div data-title="Total Ineligible Expenditure" class="border" data-name="totalIneligibleExpenditure"
          data-year="2">
          {{
            calculateMarkedLaborExpendituresFn(expenditures?.laborInfo, year, [
              expenditureStatuses.ineligible
            ]) +
              calculateMarkedNonLaborExpendituresFn(
                expenditures?.nonLaborInfo,
                year,
                [expenditureStatuses.ineligible]
              ) | currency
          }}
        </div>
        <div data-title="Total Eligible Labor" class="border" data-name="totalEligibleLabor" data-year="2">
          {{
            calculateMarkedLaborExpendituresFn(expenditures?.laborInfo, year, [
              expenditureStatuses.eligible,
              expenditureStatuses.conditionallyEligible
            ]) | currency
          }}
        </div>
        <div data-title="Total Eligible Non Labor" class="border" data-name="totalEligibleNonLabor" data-year="2">
          {{
            calculateMarkedNonLaborExpendituresFn(
              expenditures?.nonLaborInfo,
              year,
              [
                expenditureStatuses.eligible,
                expenditureStatuses.conditionallyEligible
              ]
            ) | currency
          }}
        </div>
        <div data-title="Total Eligible Expenditures" class="border" data-name="totalEligibleExpenditures"
          data-year="2">
          {{
            calculateMarkedLaborExpendituresFn(expenditures?.laborInfo, year, [
              expenditureStatuses.eligible,
              expenditureStatuses.conditionallyEligible
            ]) +
              calculateMarkedNonLaborExpendituresFn(
                expenditures?.nonLaborInfo,
                year,
                [
                  expenditureStatuses.eligible,
                  expenditureStatuses.conditionallyEligible
                ]
              ) | currency
          }}
        </div>
      </ng-template>
      <ng-template #eligibleYear3 let-year="year">
        <div data-title="Total Expenditure" class="border" data-name="totalUnMarkedExpenditures" data-year="3">
          {{
            calculateMarkedLaborExpendituresFn(
              expenditures?.laborInfo,
              year,
              null
            ) +
              calculateMarkedNonLaborExpendituresFn(
                expenditures?.nonLaborInfo,
                year,
                null
              ) | currency
          }}
        </div>
        <div data-title="Total Ineligible Expenditure" class="border" data-name="totalIneligibleExpenditure"
          data-year="3">
          {{
            calculateMarkedLaborExpendituresFn(expenditures?.laborInfo, year, [
              expenditureStatuses.ineligible
            ]) +
              calculateMarkedNonLaborExpendituresFn(
                expenditures?.nonLaborInfo,
                year,
                [expenditureStatuses.ineligible]
              ) | currency
          }}
        </div>
        <div data-title="Total Eligible Labor" class="border" data-name="totalEligibleLabor" data-year="3">
          {{
            calculateMarkedLaborExpendituresFn(expenditures?.laborInfo, year, [
              expenditureStatuses.eligible,
              expenditureStatuses.conditionallyEligible
            ]) | currency
          }}
        </div>
        <div data-title="Total Eligible Non Labor" class="border" data-name="totalEligibleNonLabor" data-year="3">
          {{
            calculateMarkedNonLaborExpendituresFn(
              expenditures?.nonLaborInfo,
              year,
              [
                expenditureStatuses.eligible,
                expenditureStatuses.conditionallyEligible
              ]
            ) | currency
          }}
        </div>
        <div data-title="Total Eligible Expenditures" class="border" data-name="totalEligibleExpenditures"
          data-year="3">
          {{
            calculateMarkedLaborExpendituresFn(expenditures?.laborInfo, year, [
              expenditureStatuses.eligible,
              expenditureStatuses.conditionallyEligible
            ]) +
              calculateMarkedNonLaborExpendituresFn(
                expenditures?.nonLaborInfo,
                year,
                [
                  expenditureStatuses.eligible,
                  expenditureStatuses.conditionallyEligible
                ]
              ) | currency
          }}
        </div>
      </ng-template>
      <ng-template #eligibleTotals let-year="year">
        <div data-title="Total Expenditure" class="border totals" data-name="totalUnMarkedExpenditures">
          {{
            calculateMarkedLaborExpendituresFn(
              expenditures?.laborInfo,
              null,
              null
            ) +
              calculateMarkedNonLaborExpendituresFn(
                expenditures?.nonLaborInfo,
                null,
                null
              ) | currency
          }}
        </div>
        <div data-title="Total Ineligible Expenditure" class="border totals" data-name="totalIneligibleExpenditure">
          {{
            calculateMarkedLaborExpendituresFn(expenditures?.laborInfo, null, [
              expenditureStatuses.ineligible
            ]) +
              calculateMarkedNonLaborExpendituresFn(
                expenditures?.nonLaborInfo,
                null,
                [expenditureStatuses.ineligible]
              ) | currency
          }}
        </div>
        <div data-title="Total Eligible Labor" class="border totals" data-name="totalEligibleLabor">
          {{
            getEligibleLabor() | currency
          }}
        </div>
        <div data-title="Total Non Labor" class="border totals" data-name="totalEligibleNonLabor">
          {{
            getEligibleNonLabor() | currency
          }}
        </div>
        <div data-title="Total Eligible Expenditures" class="border totals" data-name="totalEligibleExpenditures">
          {{
            getEligibleTotalExpenditure() | currency
          }}
        </div>
      </ng-template>

      <!--#endregion-->

      <ng-template #spin>
        <span> <i class="fa fa-spinner fa-spin"></i> </span>
      </ng-template>
      <!--#endregion-->

      <!--#region Esitmated Jobs-->
      <br />
      <div [formGroup]="estimatedJobsFormGroup">
        <div class="card" *ngIf="expenditures.laborInfo.length > 0">
          <div class="card-header"><b>ESTIMATED JOBS</b></div>
          <div class="card-body px-2">
            <div class="d-flex-column m-1 col-md-6 col-12">
              <div class="row">
                <div class="col-md-6 col-12 font-bold">
                  Total Estimated {{ editMode ? 'Eligible' : ' ' }} Jobs
                </div>
                <div class="col-md-6 col-12">
                  {{
                    editMode
                      ? calculateJobCountFn(expenditures.laborInfo, 'eligible')
                      : calculateJobCountFn(expenditures.laborInfo, null)
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-12 font-bold">
                  Total Employee
                  <span class="fa fa-info-circle ml-1" title="The Total Employee count should include the total number of employees in Louisiana regardless of qualification for Digital Media."
                        data-toggle="tooltip"
                        data-placement="top"></span>
                </div>
                <div class="col-md-6 col-12">
                  <input type="number" min="0" max="100" class="form-control col-12 col-md-10" data-toggle="tooltip"
                    data-placement="top" formControlName="totalEmployees" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--#endregion-->

      <!--#region Estimated Credits-->
      <br *ngIf="editMode" />
      <div class="card" *ngIf="expenditures.estimatedCredits.length > 0 && editMode">
        <div class="card-header"><b>ESTIMATED CREDITS</b></div>
        <div class="card-body px-2">
          <div class="estimated-credits-table mb-2 mx-1">
            <div class="estimated-credits-row header">
              <!-- DATA, WHICH IS ACTUALLY HEADERS ONLY -->

              <div class="cell" *ngFor="let header of estimatedCreditsHeaders">
                {{ header.display }}
              </div>
            </div>

            <ng-container *ngFor="
                let creditInfo of expenditures?.estimatedCredits;
                let i = index
              ">
              <!-- CREDIT INFO ROWS LOOP - AS MANY AS THERE ARE RECORDS -->
              <div class="estimated-credits-row">
                <div class="cell" [attr.data-name]="header.display" *ngFor="let header of estimatedCreditsHeaders">
                  <ng-container *ngIf="header.code !== 'rate'">{{
                    renderValue(header.code, creditInfo[header.code])
                  }}</ng-container>
                  <input *ngIf="header.code === 'rate'" class="form-control" type="number" min="0" max="100" step=".5"
                    pattern="\d+\.?\d*" [ngModel]="(creditInfo.rate * 100).toFixed(2)"
                    (blur)="checkValidity($event.target.value, i)" />
                </div>
              </div>
            </ng-container>
          </div>
          <div class="d-flex-column m-1 col-md-6 col-12">
            <div class="row mb-1">
              <div class="col-md-6 col-12 font-bold">Total Payroll Credits</div>
              <div class="col-md-6 col-12">
                {{
                  calCreditAmountFn(expenditures.estimatedCredits, null, [
                    'InState',
                    'OutOfState'
                  ]) | currency
                }}
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-md-6 col-12 font-bold">
                Total Investment Credits
              </div>
              <div class="col-md-6 col-12">
                {{
                  calCreditAmountFn(expenditures.estimatedCredits, null, [
                    'NonLabor'
                  ]) | currency
                }}
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-md-6 col-12 font-bold">Total Credits</div>
              <div class="col-md-6 col-12">
                {{
                  calCreditAmountFn(expenditures.estimatedCredits, null, [
                    'NonLabor',
                    'InState',
                    'OutOfState'
                  ]) | currency
                }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--#endregion-->

      <!--#region Labor Modal-->

      <div id="laborModal" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content p-3">
            <button type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
              <span class="sr-only">Close</span>
            </button>

            <div class="modal-header p-xs">
              <h2 class="modal-title">Edit Labor Expenditure Details</h2>
            </div>

            <div class="modal-body py-2 px-4">
              <form id="laborForm" *ngIf="laborFormGroup" [formGroup]="laborFormGroup" class="form-group no-borders"
                novalidate>
                <div class="form-group form-row d-flex justify-content-center align-items-center m-1">
                  <label class="form-control-label col-12 col-md-6">Eligibility Status:</label>
                  <select class="form-control col-12 col-md-6" formControlName="laborEligibilityStatus">
                    <option>Eligible</option>
                    <option value="Ineligible">InEligible</option>
                    <option>Conditionally Eligible</option>
                  </select>
                </div>
                <div class="form-group form-row d-flex justify-content-center align-items-center m-1">
                  <label class="form-control-label col-12 col-md-6">Employee Name:</label>
                  <input type="text" class="form-control col-12 col-md-6" data-toggle="tooltip" data-placement="top"
                    formControlName="laborEmployeeName" />
                </div>
                <div class="form-group form-row d-flex justify-content-center align-items-center m-1">
                  <label class="form-control-label col-12 col-md-6">Existing Or New Hire:</label>
                  <select class="form-control col-12 col-md-6" formControlName="laborExistingOrNewHire">
                    <option>Existing</option>
                    <option>New Hire</option>
                  </select>
                </div>
                <div class="form-group form-row d-flex justify-content-center align-items-center m-1">
                  <label class="form-control-label col-12 col-md-6">Job Category:</label>
                  <input type="text" class="form-control col-12 col-md-6" data-toggle="tooltip" data-placement="top"
                    formControlName="laborJobCategory" />
                </div>
                <div class="form-group form-row d-flex justify-content-center align-items-center m-1">
                  <label class="form-control-label col-12 col-md-6">Job Title:</label>
                  <input type="text" class="form-control col-12 col-md-6" data-toggle="tooltip" data-placement="top"
                    formControlName="laborTitle" />
                </div>
                <div class="form-group form-row d-flex justify-content-center align-items-center m-1">
                  <label class="form-control-label col-12 col-md-6">Job Description:</label>
                  <textarea class="form-control col-12 col-md-6" formControlName="laborJobDescription"
                    id="laborJobDescriptionTextarea" rows="3"></textarea>
                </div>
                <div class="form-group form-row d-flex justify-content-center align-items-center m-1">
                  <label class="form-control-label col-12 col-md-6">Job Count:</label>
                  <input type="number" class="form-control col-12 col-md-6" data-toggle="tooltip" data-placement="top"
                    formControlName="laborCount" />
                </div>
                <div class="form-group form-row d-flex justify-content-center align-items-center m-1">
                  <label class="form-control-label col-12 col-md-6">Louisiana Resident:</label>
                  <div class="col-12 col-md-6">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="laborLaResident" id="louisianaResident1"
                        formControlName="laborLaResident" [value]="'Yes'" />
                      <label class="form-check-label" for="louisianaResident1">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="laborLaResident" id="louisianaResident2"
                        formControlName="laborLaResident" [value]="'No'" />
                      <label class="form-check-label" for="louisianaResident2">No</label>
                    </div>
                  </div>
                </div>
                <div class="form-group form-row d-flex justify-content-center align-items-center m-1">
                  <label class="form-control-label col-12 col-md-6">Payment Type:</label>
                  <select class="form-control col-12 col-md-6" formControlName="laborPaymentType">
                    <option></option>
                    <option *ngFor="let type of paymentTypes" [value]="type.value">{{type.display}}</option>
                  </select>
                </div>
                <div class="form-group form-row d-flex justify-content-center align-items-center m-1">
                  <label class="form-control-label col-12 col-md-6">Average Allocation % :</label>
                  <input type="text" disabled="true" class="form-control col-12 col-md-6" data-toggle="tooltip"
                    data-placement="top" formControlName="laborAverageAllocation" />
                </div>
                <div class="form-group form-row d-flex justify-content-center align-items-center m-1">
                  <label class="form-control-label col-12 col-md-6">Total Salary:</label>
                  <input type="text" disabled="true" class="form-control col-12 col-md-6" data-toggle="tooltip"
                    data-placement="top" currencyMask [options]="{ align: 'left', precision: 2 }" placeholder="$"
                    pattern="\$? ?(\d{0,3},?)+([.](\d)+)?" formControlName="laborTotalSalary" />
                </div>
                <hr />
                <div class="row font-bold mb-2">
                  Schedule by Calendar Year
                </div>
                <div class="form-row font-bold d-flex justify-content-center align-items-center m-1">
                  <div class="col-3">Year</div>
                  <div class="col-3">Salary</div>
                  <div class="col-3">Percent Allocation</div>
                  <div class="col-3">Salary * Job Count</div>
                </div>
                <div *ngIf="laborYearDetails.controls.length > 0" formArrayName="laborYearDetails">
                  <div *ngFor="let yd of laborYearDetails.controls; let i = index">
                    <div class="form-row d-flex justify-content-center align-items-center m-1" [formGroupName]="i">
                      <div class="col-3">
                        <input disabled="true" class="form-control" formControlName="year" />
                      </div>
                      <div class="col-3">
                        <input currencyMask class="form-control" [options]="{ align: 'left', precision: 2 }"
                          placeholder="$" pattern="\$? ?(\d{0,3},?)+([.](\d)+)?" formControlName="salary" />
                      </div>
                      <div class="col-3">
                        <input formControlName="allocation" />
                      </div>
                      <div class="col-3">
                        <input disabled="true" class="form-control" currencyMask
                          [options]="{ align: 'left', precision: 2 }" placeholder="$"
                          pattern="\$? ?(\d{0,3},?)+([.](\d)+)?" formControlName="total" />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="modal-footer p-xs button-group">
              <button type="button" class="btn btn-white" data-dismiss="modal">
                CLOSE
              </button>
              <button type="submit" form="laborForm" class="btn btn-primary" (click)="saveLaborInfo()"
                [disabled]="laborFormGroup && laborFormGroup.invalid" data-dismiss="modal">
                SAVE
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--#endregion -->

      <!--#region Non Labor Modal-->
      <div id="nonLaborModal" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content p-3">
            <button type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
              <span class="sr-only">Close</span>
            </button>

            <div class="modal-header p-xs">
              <h2 class="modal-title">Edit Non Labor Expenditure Details</h2>
            </div>

            <div class="modal-body py-2 px-4">
              <form id="nonLaborForm" *ngIf="nonLaborFormGroup" [formGroup]="nonLaborFormGroup"
                class="form-group no-borders" novalidate>
                <div class="form-group form-row d-flex justify-content-center align-items-center m-1">
                  <label class="form-control-label col-12 col-md-6">Eligibility Status:</label>
                  <select class="form-control col-12 col-md-6" formControlName="nonLaborEligibilityStatus">
                    <option>Eligible</option>
                    <option value="Ineligible">InEligible</option>
                    <option>Conditionally Eligible</option>
                  </select>
                </div>
                <div class="form-group form-row d-flex justify-content-center align-items-center m-1">
                  <label class="form-control-label col-12 col-md-6">Item Name:</label>
                  <input type="text" class="form-control col-12 col-md-6" data-toggle="tooltip" data-placement="top"
                    formControlName="nonLaborItemName" />
                </div>
                <div class="form-group form-row d-flex justify-content-center align-items-center m-1">
                  <label class="form-control-label col-12 col-md-6">Item Description:</label>
                  <textarea class="form-control col-12 col-md-6" formControlName="nonLaborItemDescription"
                    id="nonLaborItemDescriptionTextarea" rows="3"></textarea>
                </div>
                <div class="form-group form-row d-flex justify-content-center align-items-center m-1">
                  <label class="form-control-label col-12 col-md-6">Total Item Cost:</label>
                  <input type="text" disabled="true" class="form-control ccol-12 col-md-6" data-toggle="tooltip"
                    data-placement="top" currencyMask [options]="{ align: 'left', precision: 2 }" placeholder="$"
                    pattern="\$? ?(\d{0,3},?)+([.](\d)+)?" formControlName="nonLaborTotalItemCost" />
                </div>
                <hr />
                <div class="row font-bold mb-2">
                  Schedule by Calendar Year
                </div>
                <div class="form-row font-bold d-flex justify-content-center align-items-center m-1">
                  <div class="col-3">Year</div>
                  <div class="col-3">Unit Cost</div>
                  <div class="col-3">Count</div>
                  <div class="col-3">Total</div>
                </div>
                <div *ngIf="nonLaborYearDetails.controls.length > 0" formArrayName="nonLaborYearDetails">
                  <div *ngFor="
                      let yd of nonLaborYearDetails.controls;
                      let i = index
                    ">
                    <div class="form-row d-flex justify-content-center align-items-center m-1" [formGroupName]="i">
                      <div class="col-3">
                        <input disabled="true" class="form-control" formControlName="year" />
                      </div>
                      <div class="col-3">
                        <input currencyMask class="form-control" [options]="{ align: 'left', precision: 2 }"
                          placeholder="$" pattern="\$? ?(\d{0,3},?)+([.](\d)+)?" formControlName="unitCost" />
                      </div>
                      <div class="col-3"><input formControlName="count" /></div>
                      <div class="col-3">
                        <input disabled="true" currencyMask class="form-control"
                          [options]="{ align: 'left', precision: 2 }" placeholder="$"
                          pattern="\$? ?(\d{0,3},?)+([.](\d)+)?" formControlName="total" />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="modal-footer p-xs button-group">
              <button type="button" class="btn btn-white" data-dismiss="modal">
                CLOSE
              </button>
              <button type="submit" form="nonLaborForm" class="btn btn-primary" (click)="saveNonLaborInfo()"
                [disabled]="nonLaborFormGroup && nonLaborFormGroup.invalid" data-dismiss="modal">
                SAVE
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--#endregion -->
    </div>
  </div>
</div>
