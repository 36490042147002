import { Attachment } from './attachment.model';
import { Contact } from './project-contact.model';
import { ProjectInfo } from './project-info.model';
import { StatusLog } from './status-log.model';
import { ProjectForm } from './form.model';
import { BalanceAdjustmentRequest } from './balance-adjustment-request.model';
import { Comment } from './comments.model';
import { ProjectInfoBase } from './project-info-base.model';
import { FormIdentifier } from './form-identifier.model';
import { incentiveProgram, contactType } from '../project.constants';
import { formTypes } from '../../form/form.constants';
import { Advance } from './advance.model';
import { Application as FilmApplication } from '../../entertainment/models/film/film-application.model';
import { Renewal } from '../../incentive/renewal/models/renewal.model';
import { AnnualCertificationReport } from '../../incentive/compliance/models/annual-certification-report.model';
import { ProjectCompletionReport } from '../../incentive/compliance/models/project-completion-report.model';
import { AffidavitFinalCost } from '../../incentive/compliance/models/affidavit-final-cost.model';
import { ContractChangeName } from '../../incentive/contract-change/models/contract-change-name.model';
import { ContractChangeLocation } from '../../incentive/contract-change/models/contract-change-location.model';
import { ContractChangeTransfer } from '../../incentive/contract-change/models/contract-change-transfer.model';
import { ContractChangeOwnership } from '../../incentive/contract-change/models/contract-change-ownership.model';
import { ContractChangeClosure } from '../../incentive/contract-change/models/contract-change-closure.model';
import { EmployeeCertificationReport } from '../../incentive/compliance/models/employee-certification-report.model';
import { ExtensionRequest } from './extension-request.model';
import { SpecialRequest } from './special-request.model';
import { InitialCertification as FilmInitialCertification } from '../../entertainment/models/film/initial-certification.model';

import { Application } from './application.model';
import { Application as DigitalApplication } from '../../entertainment/models/digital/application.model';
import { AdditionalSeasonApplication } from '../../entertainment/models/film/additional-season-application.model';
import { Audit as FilmAudit } from '../../entertainment/models/film/audit.model';
import { InitialCertification as DigitalInitialCertification } from '../../entertainment/models/digital/initial-certification.model';
import { Audit as DigitalAudit } from '../../entertainment/models/digital/audit.model';
import { Evr } from '../../entertainment/models/digital/expenditure-verification.model';
import { CertificiateComplianceForm } from '../../incentive/compliance/models/certificate-of-compliance.model';
import { StepApplication } from '../../step/models/step/step-application.model';

export class ProjectBase {
  public id: string;
  public attachments: Attachment[];
  public bar: BalanceAdjustmentRequest[];
  public projectContacts: Contact[];
  public projectInfo: ProjectInfoBase;
  public projectStatusLog: StatusLog[];
  public projectComments: Comment[];
  public userIds: string[];
  public projectHash: string;

  get businessSignatory(): Contact {
    return this.projectContacts.find(
      contact => contact.contactType === contactType.signee.code
    );
  }

  get isLegacyProject(): boolean {
    return (
      this.projectInfo.legacyUid !== undefined &&
      this.projectInfo.legacyUid != null
    );
  }

  constructor() {
    // Initialize Required properties for project
    this.attachments = [];
    this.projectContacts = [];
    this.projectInfo = new ProjectInfoBase();
    this.projectStatusLog = [];
    this.userIds = [];
    this.projectComments = [];
  }

  getProjectForm(formId: FormIdentifier, initialize?: boolean): ProjectForm {
    if (
      [
        formTypes.advance.abbrev,
        formTypes.application.abbrev,
        formTypes.renewalApplication.abbrev
      ].includes(formId.formType)
    ) {
      // This block is for forms that have a one-to-one mapping with the project
      if (!this[formId.formType]) {
        switch (formId.formType) {
          case formTypes.advance.abbrev: {
            this[formId.formType] = new Advance();
            break;
          }
          case formTypes.application.abbrev: {
            switch (this.projectInfo.incentiveProgram) {
              case incentiveProgram.film.code: {
                this[formId.formType] = new FilmApplication();
                break;
              }
              case incentiveProgram.dm.code: {
                this[formId.formType] = new DigitalApplication();
                break;
              }
              default: {
                this[formId.formType] = new Application();
                break;
              }
            }

            // This break is important because we do not want any other form logic to be hit.
            break;
          }
          case formTypes.renewalApplication.abbrev: {
            this[formId.formType] = new Renewal();
            break;
          }
        }
      }
      const oneToOneForm = <ProjectForm>this[formId.formType];
      if (initialize == undefined || initialize) {
        oneToOneForm.init();
      }
      return oneToOneForm;
    }

    // All other forms that may be in a project multiple times will be returned as follows
    if (formId.formIndex === 0) {
      if (!this[formId.formType]) {
        if (formId.formType === formTypes.acr.abbrev) {
          this[formId.formType] = [
            new AnnualCertificationReport(formId.formIndex)
          ];
        } else if (
          formId.formType === formTypes.balanceAdjustmentRequest.abbrev
        ) {
          this[formId.formType] = [
            new BalanceAdjustmentRequest(formId.formIndex)
          ];
        } else if (formId.formType === formTypes.pcr.abbrev) {
          this[formId.formType] = [
            new ProjectCompletionReport(formId.formIndex)
          ];
        } else if (formId.formType === formTypes.afc.abbrev) {
          this[formId.formType] = [new AffidavitFinalCost(formId.formIndex)];
        } else if (formId.formType === formTypes.changeCompanyName.abbrev) {
          this[formId.formType] = [new ContractChangeName(formId.formIndex)];
        } else if (formId.formType === formTypes.changeLocation.abbrev) {
          this[formId.formType] = [
            new ContractChangeLocation(formId.formIndex)
          ];
        } else if (formId.formType === formTypes.fullTransferOwnership.abbrev) {
          this[formId.formType] = [
            new ContractChangeTransfer(formId.formIndex, 'cft', true)
          ];
        } else if (
          formId.formType === formTypes.partialTransferOwnership.abbrev
        ) {
          this[formId.formType] = [
            new ContractChangeTransfer(formId.formIndex, 'cpt', true)
          ];
        } else if (
          formId.formType === formTypes.changeAffiliatesOwners.abbrev
        ) {
          this[formId.formType] = [
            new ContractChangeOwnership(formId.formIndex, true)
          ];
        } else if (formId.formType === formTypes.contractClosure.abbrev) {
          this[formId.formType] = [new ContractChangeClosure(formId.formIndex)];
        } else if (formId.formType === formTypes.ecr.abbrev) {
          this[formId.formType] = [
            new EmployeeCertificationReport(formId.formIndex)
          ];
        } else if (formId.formType === formTypes.extensionRequest.abbrev) {
          this[formId.formType] = [new ExtensionRequest(formId.formIndex)];
        } else if (formId.formType === formTypes.specialRequest.abbrev) {
          this[formId.formType] = [new SpecialRequest(formId.formIndex)];
        } else if (formId.formType === formTypes.coc.abbrev) {
          this[formId.formType] = [new CertificiateComplianceForm(formId.formIndex)];
        } else if (formId.formType === formTypes.stepApplication.abbrev) {
          this[formId.formType] = [new StepApplication(formId.formIndex)];
        } else if (formId.formType === formTypes.initialCert.abbrev) {
          if (
            this.projectInfo.incentiveProgram === incentiveProgram.film.code
          ) {
            this[formId.formType] = [
              new FilmInitialCertification(formId.formIndex)
            ];
          }
          if (this.projectInfo.incentiveProgram === incentiveProgram.dm.code) {
            this[formId.formType] = [
              new DigitalInitialCertification(formId.formIndex)
            ];
          }
        } else if (formId.formType === formTypes.asa.abbrev) {
          if (
            this.projectInfo.incentiveProgram === incentiveProgram.film.code
          ) {
            this[formId.formType] = [
              new AdditionalSeasonApplication(formId.formIndex)
            ];
          }
        } else if (formId.formType === formTypes.audit.abbrev || formId.formType === formTypes.evr.abbrev) {
          if (
            this.projectInfo.incentiveProgram === incentiveProgram.film.code
          ) {
            this[formId.formType] = [new FilmAudit(formId.formIndex)];
          }
          if (this.projectInfo.incentiveProgram === incentiveProgram.dm.code) {
            this[formId.formType] = [new DigitalAudit(formId.formIndex)];
          }
        } else if (formId.formType === formTypes.coc.abbrev) {
          this[formId.formType] = [new CertificiateComplianceForm(formId.formIndex)];
        } else if (formId.formType === formTypes.stepApplication.abbrev) {
          this[formId.formType] = [new StepApplication(formId.formIndex)];
        }
      }
    } else {
      const formGroup = <ProjectForm[]>this[formId.formType];
      if (formId.formIndex) {
        if (!formGroup[formId.formIndex]) {
          if (formId.formType === formTypes.acr.abbrev) {
            formGroup.push(new AnnualCertificationReport(formId.formIndex));
          } else if (
            formId.formType === formTypes.balanceAdjustmentRequest.abbrev
          ) {
            formGroup.push(new BalanceAdjustmentRequest(formId.formIndex));
          } else if (formId.formType === formTypes.pcr.abbrev) {
            formGroup.push(new ProjectCompletionReport(formId.formIndex));
          } else if (formId.formType === formTypes.afc.abbrev) {
            formGroup.push(new AffidavitFinalCost(formId.formIndex));
          } else if (formId.formType === formTypes.changeCompanyName.abbrev) {
            formGroup.push(new ContractChangeName(formId.formIndex));
          } else if (formId.formType === formTypes.changeLocation.abbrev) {
            formGroup.push(new ContractChangeLocation(formId.formIndex));
          } else if (formId.formType === formTypes.fullTransferOwnership.abbrev) {
            formGroup.push(
              new ContractChangeTransfer(
                formId.formIndex,
                formTypes.fullTransferOwnership.abbrev,
                true
              )
            );
          } else if (
            formId.formType === formTypes.partialTransferOwnership.abbrev
          ) {
            formGroup.push(
              new ContractChangeTransfer(
                formId.formIndex,
                formTypes.partialTransferOwnership.abbrev,
                true
              )
            );
          } else if (
            formId.formType === formTypes.changeAffiliatesOwners.abbrev
          ) {
            formGroup.push(new ContractChangeOwnership(formId.formIndex, true));
          } else if (formId.formType === formTypes.contractClosure.abbrev) {
            formGroup.push(new ContractChangeClosure(formId.formIndex));
          } else if (formId.formType === formTypes.ecr.abbrev) {
            formGroup.push(new EmployeeCertificationReport(formId.formIndex));
          } else if (formId.formType === formTypes.extensionRequest.abbrev) {
            formGroup.push(new ExtensionRequest(formId.formIndex));
          } else if (formId.formType === formTypes.specialRequest.abbrev) {
            formGroup.push(new SpecialRequest(formId.formIndex));
          } else if (formId.formType === formTypes.initialCert.abbrev) {
            if (
              this.projectInfo.incentiveProgram === incentiveProgram.film.code
            ) {
              formGroup.push(new FilmInitialCertification(formId.formIndex));
            }
            if (this.projectInfo.incentiveProgram === incentiveProgram.dm.code) {
              formGroup.push(new DigitalInitialCertification(formId.formIndex));
            }
          } else if (formId.formType === formTypes.asa.abbrev) {
            if (
              this.projectInfo.incentiveProgram === incentiveProgram.film.code
            ) {
              formGroup.push(new AdditionalSeasonApplication(formId.formIndex));
            }
          } else if (formId.formType === formTypes.audit.abbrev) {
            if (
              this.projectInfo.incentiveProgram === incentiveProgram.film.code
            ) {
              formGroup.push(new FilmAudit(formId.formIndex));
            }
            if (this.projectInfo.incentiveProgram === incentiveProgram.dm.code) {
              formGroup.push(new DigitalAudit(formId.formIndex));
            }
          } else if (formId.formType === formTypes.evr.abbrev) {
            formGroup.push(new Evr(formId.formIndex));
          } else if (formId.formType === formTypes.coc.abbrev) {
            formGroup.push(new CertificiateComplianceForm(formId.formIndex));
          } else if (formId.formType === formTypes.stepApplication.abbrev) {
            formGroup.push(new StepApplication(formId.formIndex));
          }
        }
      }
    }

    var form;

    if (formId.formIndex != undefined) {
      form = this[formId.formType][formId.formIndex];
    }
    if (form != undefined) {
      if (initialize == undefined || initialize) {
        form.init();
      }
    }

    return form;
  }

  init(): ProjectBase {
    this.attachments = this.attachments || new Array<Attachment>();
    this.projectContacts = this.projectContacts || [];
    this.projectContacts = this.projectContacts.map(contact =>
      Object.assign(new Contact(), contact).init()
    );

    this.projectComments = this.projectComments || [];

    this.projectInfo = this.projectInfo || new ProjectInfoBase();

    // This had to be instantiated besides line above given that previous Object.assign will overwrite it.
    this.projectInfo.contractSignatory =
      this.projectInfo.contractSignatory || new Contact().init();
    return this;
  }
}
