import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { User } from '../../../models/user.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PersonComponent } from '../../../../shared/components/person/person.component';
import { boardType } from '../../../../board/board.constants';
import { chain } from 'underscore';
declare var $;

@Component({
  selector: 'fl-user-detail-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})
export class UserDetailBoardComponent
  implements AfterViewInit, OnDestroy, OnInit {
  @Input()
  form: FormGroup;
  @Input()
  user: User;
  @ViewChild(PersonComponent, { static: true })
  personComponent: PersonComponent;
  boardTypesAvailable: string[];
  userForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  checkedBoardType(type: string, value: boolean) {
    if (value) {
      if (this.user.boards && !this.user.boards.includes(type)) {
        // Add the board type to this user's list of boards he is a member of
        this.user.boards.push(type);
      } else {
        // Sometimes the board list is null => initialize it with a value in this case
        this.user.boards = [type];
      }
    } else if (this.user.boards.includes(type)) {
      // Remove the board type from this user's list of boards he is a member of
      this.user.boards = this.user.boards.filter(bt => bt !== type);
    }

    // If list is empty, set this value to null so that it nullifies the value for the form control,
    // which invalidates the userForm
    if (this.user.boards.length === 0) {
      this.user.boards = null;
    }

    // Update form model
    this.userForm
      .get('boards')
      .patchValue(this.user.boards ? this.user.boards.join(',') : null);
  }

  ngAfterViewInit() {
    // Enable tooltips
    $('[data-toggle="tooltip"]').tooltip();
  }

  ngOnDestroy() {
    // Remove the form from the parent form
    this.form.removeControl('boardUser');
  }

  ngOnInit() {
    this.boardTypesAvailable = chain(boardType)
      .pairs()
      .map(pair => pair[1].name)
      .value();

    // This component doesn't neeed a group for itself, but rather simple as a container for its child forms
    this.userForm = this.fb.group({
      boards: ['', Validators.required]
    });

    // Add this form group to the parent form
    this.form.addControl('boardUser', this.userForm);
  }

  setFormData(user: User) {
    this.personComponent.setFormData(user);
  }
}
