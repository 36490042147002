import { ProjectForm } from '../../../project/models/form.model';

export abstract class ContractChange extends ProjectForm {
  effectiveDate: Date;

  constructor(formIndex?: number, form?: any) {
    super(formIndex, form);
  }

  init() {
    super.init();
    if (this.effectiveDate) {
      this.effectiveDate = new Date(this.effectiveDate);
    }
  }

  get qualifier() {
    return '';
  }
}
