import { Injectable } from '@angular/core';
import { FormShareService } from './share.service';
import {
  FormStatus,
  formStatus,
  formTypes,
  formStatuses
} from '../form.constants';
import { ProjectDetailService } from '../../project/services/project-shared.service';
import { ProjectDataService } from '../../project/services/project-data.service';
import {
  firstProgramForm,
  incentiveProgram,
  incentiveCategory,
  formDateActionMap
} from '../../project/project.constants';
import { UserContextService } from '../../user/services/user-context.service';
import { forkJoin, Subscription } from 'rxjs';
import {
  setProjectStatus,
  setProjectLegislationRule
} from '../../project/project.functions';
import * as _ from 'underscore';

@Injectable()
export class FormStatusService {
  count: number;
  constructor(
    private shareService: FormShareService,
    private projectShareService: ProjectDetailService,
    private userContext: UserContextService,
    private projectDataService: ProjectDataService
  ) {}

  changeStatus(newStatus: FormStatus): Subscription {
    const that = this;
    this.shareService.form.changeStatus(
      newStatus,
      null,
      this.userContext.currentUser.id
    );

    if (newStatus === formStatus.received) {
      this.shareService.form.addReceivedDate();
      // Get the current program
      const program = this.projectShareService.currentIncentiveProgram;
      const projectInfo = this.projectShareService.projectBase.projectInfo;
      // Sets and updates the project Dates
      const dateUpater = _.findWhere(formDateActionMap, {
        formDateAction: formStatuses.received.name,
        incentiveProgram: program,
        formType: that.shareService.form.type
      });
      if (dateUpater) {
        dateUpater.handler(
          that.projectShareService.projectDates,
          that.shareService.form,
          that.projectShareService.projectBase.id,
          that.projectShareService.projectBase.projectInfo
        );
      }
      if (incentiveCategory.entertainment.incentivePrograms.includes(program)) {
        // For now, we are not always calling the two enclosed methods to minimize executions
        // for whenever the first program form is received.
        if (firstProgramForm[program].includes(this.shareService.form.type)) {
          // Set the project status if applicable
          setProjectStatus(
            this.projectShareService.projectBase.projectStatusLog,
            program,
            this.shareService.form.type,
            this.userContext.currentUser.id
          );

          // Set the project legislation rule if applicable
          setProjectLegislationRule(
            this.projectShareService.getLegislationRule,
            projectInfo,
            this.shareService.form,
            program
          );
        }

        return Subscription.EMPTY;
      } else {
        // For now, we are not always calling the two enclosed methods to minimize executions
        // for whenever the first program form is received.
        if (firstProgramForm[program].includes(this.shareService.form.type)) {
          // Set the project status if applicable
          setProjectStatus(
            this.projectShareService.filmProject.projectStatusLog,
            program,
            this.shareService.form.type,
            this.userContext.currentUser.id
          );

          // Set the project legislation rule if applicable
          setProjectLegislationRule(
            this.projectShareService.getLegislationRule,
            projectInfo,
            this.shareService.form,
            program
          );
        }
      }
    }
    return Subscription.EMPTY;
  }
}
