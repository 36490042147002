import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDataService } from '../../../user/services/user-data.service';
import { SwalService } from '../../../fastlane-common/services/swal.service';
import { PasswordInputComponent } from '../input/new/new.component';

@Component({
  selector: 'fl-password-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  @ViewChild(PasswordInputComponent, { static: true })
  passwordInputComponent: PasswordInputComponent;
  passwordResetTokenId: string;
  resetFormGroup: FormGroup;
  verified: boolean;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private swal: SwalService,
    private userService: UserDataService,
    private router: Router
  ) {
    this.resetFormGroup = fb.group({});
  }

  ngOnInit() {
    const that = this;
    this.route.params.subscribe(params => {
      that.passwordResetTokenId = params['tok'];
      that.userService
        .verifyPasswordResetToken(that.passwordResetTokenId)
        .subscribe(
          res => {
            if (res.isVerified) {
              that.verified = true;
            } else {
              that.verified = false;
              that.swal.error({
                title: 'Invalid Request',
                text: res.reason,
                onClose: () => {
                  that.router.navigate(['/login']);
                }
              });
            }
          },
          error => {
            that.verified = false;
            that.swal.error({
              title: 'Oops!',
              text:
                'An error occurred while verifying your user account. Please try again later.'
            });
          }
        );
    });
  }

  submitResetRequest() {
    const that = this;
    this.userService
      .submitPasswordReset(
        this.passwordResetTokenId,
        this.passwordInputComponent.value
      )
      .subscribe(
        success => {
          that.swal.success({
            title: 'Password Reset',
            text:
              'Your password has successfully been reset. You will be redirected to the login screen.',
            onClose: () => {
              that.router.navigate(['/login']);
            }
          });
        },
        error => {
          that.swal.error({
            title: 'Error Resetting Password',
            text: 'An error ocurred while changing your password.'
          });
        }
      );
  }
}
