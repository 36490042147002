<div class="card border m-t m-b">
  <div class="card-header d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <b>RELATED PARTY TRANSACTIONS</b>
      <i [ngClass]="{'d-none': readonly || hasParty || isManager}" class="animated infinite rubberBand fa fa-exclamation-circle fa-2x ml-2 text-danger" data-toggle="tooltip" data-placement="top"
         title="You must add a party transaction or select 'None' to continue."></i>
    </div>

    <span
      data-toggle="tooltip"
      title="Add Related Transaction"
      data-placement="left"
    >
      <button
        *ngIf="!readonly && !noPartyTransactions"
        type="button"
        (click)="select('Add')"
        class="btn text-primary bg-transparent pt-0 pb-0"
        data-toggle="modal"
        data-target="#relatedTransactionModal"
      >
        <i class="fa fa-user-plus fa-2x" aria-hidden="true"></i>
      </button>
    </span>
  </div>
  <div *ngIf="!noPartyTransactions" class="card-body px-5">
    <table class="w-100 table table-striped table-bordered table-hover">
      <thead>
        <tr>
          <th>Name of Related Party</th>
          <th>Nature of Relationship</th>
          <th>Amount of Transaction</th>
          <th *ngIf="!readonly"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let relatedTransaction of relatedTransactions; index as i">
          <td>{{ relatedTransaction.name }}</td>
          <td>{{ relatedTransaction.relationship }}</td>
          <td>
            {{
              relatedTransaction.transactionAmount
                | currency: 'USD':'symbol':'1.2-2'
            }}
          </td>
          <td *ngIf="!readonly">
            <div class="d-flex align-items-center mx-1">
              <button
                type="button"
                class="btn px-1 py-1 bg-transparent"
                (click)="select('Save', i)"
                data-toggle="modal"
                data-target="#relatedTransactionModal"
              >
                <i class="fa fa-pencil fa-2x text-gray"></i>
              </button>
              <button
                *ngIf="i !== 0"
                type="button"
                class="btn px-1 py-1 bg-transparent"
                (click)="delete(i)"
              >
                <i class="fa fa-trash fa-2x text-gray"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="relatedTransactions.length == 0 && !readonly" class="card-body px-5">
    <label class="m-1 font-weight-bold">No Related Party Transactions </label>
    <input 
           type="checkbox"
           (change)="noTransactionChecked($event.target.checked)"
           [checked]="application.noPartyTransactions"/>
  </div>
</div>

<div
  id="relatedTransactionModal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content p-3">
      <button type="button" class="close" data-dismiss="modal">
        <span aria-hidden="true">&times;</span>
        <span class="sr-only">Close</span>
      </button>

      <div class="modal-header p-xs">
        <h2 class="modal-title">{{ mode }} Related Transaction</h2>
      </div>

      <form
        id="relatedTransactionForm"
        [formGroup]="relatedTransactionForm"
        class="form-group row no-borders"
        novalidate
      >
        <div class="my-1 col-12 d-flex">
          <label class="col-4 form-control-label">Name of Related Party:</label>
          <div class="col-8">
            <input
              type="text"
              class="form-control"
              formControlName="name"
              required
            />
          </div>
        </div>

        <div class="my-1 col-12 d-flex">
          <label class="col-4 form-control-label"
            >Nature of Relationship:</label
          >
          <div class="col-8">
            <input
              type="text"
              class="form-control"
              formControlName="relationship"
              required
            />
          </div>
        </div>

        <div class="my-1 col-12 d-flex">
          <label class="col-4 form-control-label">Amount of Transaction:</label>
          <div class="col-8">
            <input
              class="form-control"
              placeholder="$"
              pattern="\$? ?(\d{0,3},?)+([.](\d)+)?"
              currencyMask
              [options]="{ align: 'left', precision: 2 }"
              formControlName="transactionAmount"
              required
            />
          </div>
        </div>
      </form>

      <div class="modal-footer p-xs button-group">
        <button type="button" class="btn btn-white" data-dismiss="modal">
          CLOSE
        </button>
        <button
          type="submit"
          form="relatedTransactionForm"
          class="btn btn-primary"
          (click)="save()"
          [disabled]="relatedTransactionForm && relatedTransactionForm.invalid"
          data-dismiss="modal"
        >
          {{ mode | uppercase }}
        </button>
      </div>
    </div>
  </div>
</div>
