/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./program-selection.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/program-type-checkbox/program-type-checkbox.component.ngfactory";
import * as i3 from "../../../../shared/components/program-type-checkbox/program-type-checkbox.component";
import * as i4 from "./program-selection.component";
import * as i5 from "@angular/forms";
var styles_ProgramSelectionComponent = [i0.styles];
var RenderType_ProgramSelectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProgramSelectionComponent, data: {} });
export { RenderType_ProgramSelectionComponent as RenderType_ProgramSelectionComponent };
export function View_ProgramSelectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shr-program-type-checkbox", [], null, [[null, "selectionChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectionChanged" === en)) {
        var pd_0 = (_co.update() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ProgramTypeCheckboxComponent_0, i2.RenderType_ProgramTypeCheckboxComponent)), i1.ɵdid(1, 114688, null, 0, i3.ProgramTypeCheckboxComponent, [], { selectedPrograms: [0, "selectedPrograms"], programRestrictions: [1, "programRestrictions"] }, { selectionChanged: "selectionChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedPrograms; var currVal_1 = _co.restrictedPrograms; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ProgramSelectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fl-user-detail-program-selection", [], null, null, null, View_ProgramSelectionComponent_0, RenderType_ProgramSelectionComponent)), i1.ɵdid(1, 245760, null, 0, i4.ProgramSelectionComponent, [i5.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProgramSelectionComponentNgFactory = i1.ɵccf("fl-user-detail-program-selection", i4.ProgramSelectionComponent, View_ProgramSelectionComponent_Host_0, { form: "form", user: "user" }, {}, []);
export { ProgramSelectionComponentNgFactory as ProgramSelectionComponentNgFactory };
