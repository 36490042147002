import { MailAddress } from './mail-address.model';
import { FormDetails } from './form-details.model';
import { ManagerDetails } from './manager-details.model';
import { BoardMeetingDetails } from './board-meeting-details.model';
import { BoardSpecificFormDecisionDetails } from './board-specific-form-decision-details.model';
import { EmailTemplate, ReportTemplate } from '../email.enums';
import { environment } from 'environments/environment';
import { ProjectFormWrapper } from '../../project/models/project-form-wrapper.model';
import { FormIdentifier } from '../../project/models/form-identifier.model';

export class EmailParameters {
  additionalPaymentComments: string;
  amountRequested: number;
  auditingCompanyId: string;
  boardDecision: BoardSpecificFormDecisionDetails;
  boardMeetingDetails: BoardMeetingDetails;
  body: string;
  ccList: MailAddress[];
  companyName: string;
  doInjectToken: boolean;
  dueDate: Date;
  externalReviewAgency: string;
  form: FormDetails;
  isUserRequestingProjectAccessConsultant: boolean;
  incentivePrograms: string[];
  legislationRule: string;
  manager: ManagerDetails;
  numFormsAwaitingReview: number;
  parishes: string[];
  parish: string;
  paymentType: string;
  program: string;
  projectAccessComment: string;
  projectStatus: string;
  recipients: MailAddress[];
  redirectionUrl: string;
  reportTemplates: ReportTemplate[];
  readonly from: MailAddress = environment.emailFromAddress;
  testers: MailAddress[];
  uploadedDocumentTypes: string[];
  userRequestingProjectAccess: MailAddress;
  userRequestingProjectAccessId: string;
  projectForms: ProjectFormWrapper[];
  formId: FormIdentifier;
  isCocFormCompliant: boolean;
  isStepApplicationFormCompliant: boolean;
  stepApplicationFormAwardAmount: number;
  stepId: string;
  resetUser: string;
  resetSuccessful: boolean;
  resetError: string;
  constructor(
    public readonly projectId: string,
    public readonly subject: string,
    public readonly emailTemplate: EmailTemplate
  ) {
    this.ccList = [];
  }
}
