import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthenticationService } from '../../../security/services/authentication.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import {UserContextService} from '../../../user/services/user-context.service';
import {UserPermissions} from '../../../user/models/user-permissions.model';
import {of} from 'rxjs';

declare var $: any;

@Component({
  selector: 'fl-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements AfterViewInit, OnInit {
  // TODO : 1) verify user is logged in with password
  // 2) determine if they have 2FA set up or not
  // 3) make sure the navigation on the side doesn't allow user to navigate into app before through 2FA

  activeAuthentication = false;
  setUpQRUrl;
  manualEntryKey: string = null;
  validateForm: FormGroup;
  showSetupQR = false;
  invalidAuthCode = false;
  userPermissions = null;
  twoFactorRememberMe = false;

  constructor(
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private userContext: UserContextService
  ) {

  }

  validate() {
    const that = this;
    const passcode = this.validateForm.controls['passcode'].value;

    if (this.activeAuthentication) {
      this.authService.validateTwoFactor(this.authService.tempSession.payload.username, passcode, this.twoFactorRememberMe).subscribe(data => {
        if (data) {
          that.invalidAuthCode = false;
          that.authService.TwoFaLoginContinue();
          if (that.authService.redirectUrl) {
            that.router.navigate([that.authService.redirectUrl]);
            that.authService.redirectUrl = null;
          } else {
            that.router.navigate(['/applicant']);
          }
        } else {

          that.invalidAuthCode = true;
          this.validateForm.controls['passcode'].setValue('');
        }
      });
    } else {
      this.authService.confirmTwoFactor(this.authService.tempSession.payload.username, passcode, this.twoFactorRememberMe).subscribe(data => {
        if (data) {
          that.authService.TwoFaLoginContinue();
          if (that.authService.redirectUrl) {
            that.router.navigate([that.authService.redirectUrl]);
            that.authService.redirectUrl = null;
          } else {
            that.router.navigate(['/applicant']);
          }
        }
      })
    }

  }

  showSetup() {
    this.showSetupQR = !this.showSetupQR;
  }

  ngAfterViewInit() {
    if (!this.activeAuthentication) {
      this.getSetUpInfo();
    }
  }

  ngOnInit() {
    const that = this;
    if (this.authService.tempSession) {
      this.authService.isTwoFactorActive(this.authService.tempSession.payload.username).subscribe(data => {
        if (data === false) {
          that.showSetup()
        }
        this.activeAuthentication = data
      });
    } else {
      this.router.navigate(['/login']);
    }
    this.validateForm = this.formBuilder.group({
      passcode: ''
      });
  }

  getSetUpInfo() {
    this.authService.getTwoFactorSetup(this.authService.tempSession.payload.username).subscribe(data => {
      this.setUpQRUrl = data.qrCodeSetupImageUrl;
      this.manualEntryKey = data.manualEntryKey;
    });
  }

}
