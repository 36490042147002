import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { ProjectForm } from '../../../project/models/form.model';
import { Application as DigitalApplication } from '../../../entertainment/models/digital/application.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { formTypes } from '../../../form/form.constants';
import { ProgrammingLanguagesComponent } from './programming-languages/programming-languages.component';
import { ExpendituresComponent } from './expenditures/expenditures.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { StagesComponent } from './stages/stages.component';
import { ProjectDetailService } from '../../../project/services/project-shared.service';
import { legislationRuleNames } from '../../../project/project.constants';
import { UserFormPermissions } from '../../../form/models/permissions.model';
import { FormShareService } from '../../../form/services/share.service';
import { FormValidationService } from '../../../form/services/validation.service';
@Component({
  selector: 'fl-dm-application',
  templateUrl: './digital.component.html',
  styleUrls: ['./digital.component.scss']
})
export class DigitalApplicationComponent implements OnInit, OnDestroy {
  @Input() form: ProjectForm;
  @Input() hideExpenditures = false;
  @ViewChild('projectDetails', { static: true })
  projectDetailsComp: ProjectDetailsComponent;
  @ViewChild('programmingLanguages', { static: true })
  programmingLanguagesComp: ProgrammingLanguagesComponent;
  @ViewChild('stages', { static: true })
  stagesComp: StagesComponent;
  @ViewChild('estimatedExpenditures', { static: false })
  expendituresComp: ExpendituresComponent;
  digitalApplication: DigitalApplication;
  companyHistoryFormGroup: FormGroup;
  initialCertCustomDisplayTextFormGroup: FormGroup;
  hideDeposit = false;
  permissions: UserFormPermissions;
  private subscriptions: Subscription[] = [];
  constructor(
    private fb: FormBuilder,
    private projectDetailService: ProjectDetailService,
    private formShareService: FormShareService,
    private validationService: FormValidationService
  ) {}

  // #region "Custom Methods"
  /**
   * @summary Destroy the subscription after rendering the page to prevent leaks.
   * @param sub Subription that was register.
   */
  addSubscriberToBeDestroy(sub: Subscription) {
    // Add subscriberts to the Subscription array to be destroy later.
    this.subscriptions.push(sub);
  }
  /**
   * @summary Initialize the form controls
   */
  initializeForm() {
    const that = this;
    this.companyHistoryFormGroup = this.fb.group({
      companyHistory: [this.digitalApplication.companyHistory, Validators.required]
    });
    const subcompanyHistoryFormGroup = this.companyHistoryFormGroup.valueChanges.subscribe(
      value => {
        // Add event handlers to form to update data model
        that.digitalApplication.companyHistory = value.companyHistory;
      }
    );
    // Add FormGroup to validation Service
    this.validationService.form.setControl(
      'companyHistory',
      this.companyHistoryFormGroup
    );
    // Add subscriber that needs to be destroy
    this.addSubscriberToBeDestroy(subcompanyHistoryFormGroup);


    this.initialCertCustomDisplayTextFormGroup = this.fb.group({
      initialCertCustomDisplayText: [this.digitalApplication.initialCertCustomDisplayText]
    });
    const subinitialCertCustomDisplayTextFormGroup = this.initialCertCustomDisplayTextFormGroup.valueChanges.subscribe(
      value => {
        // Add event handlers to form to update data model
        that.digitalApplication.initialCertCustomDisplayText = value.initialCertCustomDisplayText;
      }
    );
    // Add FormGroup to validation Service
    this.validationService.form.setControl(
      'initialCertCustomDisplayText',
      this.initialCertCustomDisplayTextFormGroup
    );
    // Add subscriber that needs to be destroy
    this.addSubscriberToBeDestroy(subinitialCertCustomDisplayTextFormGroup);

    // Send data to subcomponent.
    this.projectDetailsComp.projectDetails = this.digitalApplication.projectDetails;
    this.stagesComp.stages = this.digitalApplication.stages;
    this.programmingLanguagesComp.programmingLanguages = this.digitalApplication.programmingLanguages;
  }
  getApplication() {
    if (
      this.form.type === formTypes.application.abbrev ||
      this.form.type === formTypes.initialCert.abbrev
    ) {
      this.digitalApplication = <DigitalApplication>this.form;
    }
  }
  // #endregion

  // #region "System Methods"
  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
  ngOnInit() {
    this.permissions = this.formShareService.permissions;
    if (
      this.projectDetailService.projectBase.projectInfo.legislation ===
      legislationRuleNames.dm.preJuly2015.name
    ) {
      this.hideDeposit = true;
    } else {
      this.hideDeposit = this.hideExpenditures;
    }
    this.getApplication();
    this.initializeForm();
  }
  // #endregion
}
