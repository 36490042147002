export class SupplementalQuestions {

  public isBusinessAffiliatedWithOtherGroup?: boolean;
  public businessAffiliationDetails: string;
  public isBusinessActivityPurchaseOrAcquistion?: boolean;
  public areJobsPreviouslyProvided?: boolean;
  public businessActivityDescription: string;
  public areDirectJobsRelatedToPreviousContract?: boolean;
  public jobsFromPreviousContractDetails: string;
  public hasReducedEmployment?: boolean;
  public reducedEmploymentDetails: string;
  public hasAppliedToLedIncentive?: boolean;
  public appliedToLedIncentiveDetails: string;
  public hasEmployerDefaulted?: boolean;
  public employerDefaultedDetails: string;
  public hasEmployerDeclaredBankruptcy?: boolean;
  public isEmployerInDefault?: boolean;
  public hasLicenseToGamingActivities?: boolean;
  public liscenseToGamingActivitiesDetails: string;
  public hasAnotherLocationClosedOrLostEmployment?: boolean;
  public hasAnotherLocationClosedOrLostEmploymentDetails?: string;
  public noMoreThanFiftyEmployees?: boolean;
  public noMoreThanFiftyEmployeesDetails: string;
  public hadPreviousEnterpriseZone?: boolean;
  public hadPreviousEnterpriseZoneDesc: string;
  public ldrSalesTaxCertFlag?: boolean;
  constructor() { }

}
