/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./applicant.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/person/person.component.ngfactory";
import * as i3 from "../../../../shared/components/person/person.component";
import * as i4 from "@angular/forms";
import * as i5 from "./applicant.component";
var styles_UserDetailApplicantComponent = [i0.styles];
var RenderType_UserDetailApplicantComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserDetailApplicantComponent, data: {} });
export { RenderType_UserDetailApplicantComponent as RenderType_UserDetailApplicantComponent };
export function View_UserDetailApplicantComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { personComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "shr-person", [], null, null, null, i2.View_PersonComponent_0, i2.RenderType_PersonComponent)), i1.ɵdid(2, 245760, [[1, 4]], 0, i3.PersonComponent, [i4.FormBuilder], { addressRequired: [0, "addressRequired"], form: [1, "form"], person: [2, "person"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.userForm; var currVal_2 = _co.user; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_UserDetailApplicantComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fl-user-detail-applicant", [], null, null, null, View_UserDetailApplicantComponent_0, RenderType_UserDetailApplicantComponent)), i1.ɵdid(1, 245760, null, 0, i5.UserDetailApplicantComponent, [i4.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserDetailApplicantComponentNgFactory = i1.ɵccf("fl-user-detail-applicant", i5.UserDetailApplicantComponent, View_UserDetailApplicantComponent_Host_0, { form: "form", user: "user" }, {}, []);
export { UserDetailApplicantComponentNgFactory as UserDetailApplicantComponentNgFactory };
