// Constant
import { formTypes } from '../../../form/form.constants';
// Model
import { ProjectForm } from '../../../project/models/form.model';
import { Stages } from './stages.model';
import { Expenditures } from './expenditures.model';
import { ProjectDetail } from './project-detail.model';
import { EntertainmentForm } from '../entertainment-form.model';
import { FormIdentifier } from '../../../project/models/form-identifier.model';
import { EligibilityQuestions } from './eligibility-questions.model';

export class Application extends EntertainmentForm {
  companyHistory: string;
  projectDetails: ProjectDetail;
  stages: Stages[];
  programmingLanguages: String[];
  estimatedExpenditures: Expenditures;
  relatedAuditFormId: FormIdentifier;
  eligibilityQuestions: EligibilityQuestions;
  initialCertCustomDisplayText: string;
  constructor() {
    super();
  }

  // Create any missing properties that doesn't exist from the database yet.
  // The specific property will be undefined rather than null when it is missing
  // from the database.
  init(): Application {
    super.init();
    this.type = formTypes.application.abbrev;
    this.companyHistory = this.companyHistory || null;
    this.projectDetails = this.projectDetails || new ProjectDetail();
    this.stages = this.stages || [];
    this.programmingLanguages = this.programmingLanguages || [];
    this.estimatedExpenditures = new Expenditures(this.estimatedExpenditures);
    this.eligibilityQuestions = new EligibilityQuestions(this.eligibilityQuestions);
    return this;
  }

  //#region Properties - Getter/Setter
  /*
   * @summary Get the Digital application computed Audit Form Id
   */
  get auditFormId() {
    return this.relatedAuditFormId;
  }
  set auditFormId(value) {
    this.relatedAuditFormId = value;
  }

  /**
   * @summary Get the applicable LA Expenditure total for calculating the deposit.
   * This is not related to laResident in the spreadsheet.
   */
  get laExpenditure(): number {
    return this.estimatedExpenditures.expenditureTotal;
  }
  set laExpenditure(value: number) {
    this.estimatedExpenditures.expenditureTotal = value;
  }

  get qualifier() {
    if (this['productionDetails'] && this['productionDetails'].season) {
      return `Season: <MODEL CHANGE>`;
    }
    return null;
  }
  //#endregion
}
