export class EstimatedPayroll {
  construction: number;
  existing: number;
  inStateTransfers?: number;
  new: number;
  newCurrentPhase: number;
  outOfStateTransfers?: number;
  totalPayroll: number;
  contract: number;
  statewide: number;

  constructor(payroll?: any) {
    payroll = payroll || {};

    this.construction = payroll.construction === 0 ? payroll.construction :
    payroll.construction ? payroll.construction : null;

    this.existing = payroll.existing === 0 ? payroll.existing :
    payroll.existing ? payroll.existing : null;

    this.new = payroll.new === 0 ? payroll.new :
    payroll.new ? payroll.new : null;

    this.inStateTransfers = payroll.inStateTransfers || null;

    this.newCurrentPhase = payroll.newCurrentPhase || null;
    this.outOfStateTransfers = payroll.outOfStateTransfers || null;
    this.totalPayroll = payroll.totalPayroll || null;
    this.contract = payroll.contract || null;
    this.statewide = payroll.statewide || null;
  }
}
