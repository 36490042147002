export class Programs {
  qj: boolean;
  ez: boolean;
  rta: boolean;
  ite: boolean;
  dm: boolean;
  film: boolean;
  step: boolean;

  constructor(
    qj: boolean = false,
    ez: boolean = false,
    rta: boolean = false,
    ite: boolean = false,
    dm: boolean = false,
    film: boolean = false,
    step: boolean = false
  ) {
    this.qj = qj;
    this.ez = ez;
    this.ite = ite;
    this.rta = rta;
    this.dm = dm;
    this.film = film;
    this.step = step;
  }

  getSelectedPrograms(): string[] {
    return Object.keys(this)
      .filter(key => this[key] === true)
      .map(key => key.toUpperCase());
  }

  getNegation(): Programs {
    return new Programs(!this.qj, !this.ez, !this.rta, !this.ite, !this.dm, !this.film, !this.step);
  }
}
