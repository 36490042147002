import { FeeParameters } from './fee-parameters.model';
import { round } from '../../shared/shared.functions';
import { BenefitParameters } from './benefit-parameters.model';

export class FeeCalculations {
  amountDue: number;
  amountPaid: number;
  assessedFee: number;
  estimatedBenefits: number;
  estimatedRebates: number; // QJ and EZ
  investmentTaxCreditWithCap: number;
  feeParameters: FeeParameters;
  benefitParameters: BenefitParameters;
  isDepositAdjusted?: boolean;
  isRefundable?: boolean;

  constructor(calculations?: any) {
    if (!calculations) {
      calculations = {};
    }
    this.amountDue = calculations.amountDue || 0;
    this.amountPaid = calculations.amountPaid || 0;
    this.assessedFee = calculations.assessedFee || 0;
    this.estimatedBenefits = calculations.estimatedBenefits || 0;
    this.estimatedRebates = calculations.estimatedRebates || 0;
    this.investmentTaxCreditWithCap =
      calculations.investmentTaxCreditWithCap || 0;
    this.feeParameters = calculations.feeParameters || new FeeParameters();
    this.benefitParameters =
      calculations.benefitParameters || new BenefitParameters();

    // There is no OR condition because we do not want this to serialize to database. We want this to present only for true.
    this.isDepositAdjusted = calculations.isDepositAdjusted;
    this.isRefundable = calculations.isRefundable;
  }

  roundValues(): FeeCalculations {
    this.amountDue = round(this.amountDue, 2);
    this.amountPaid = round(this.amountPaid, 2);
    this.assessedFee = round(this.assessedFee, 2);
    this.estimatedBenefits = this.estimatedBenefits
      ? round(this.estimatedBenefits, 2)
      : null;
    this.investmentTaxCreditWithCap = this.investmentTaxCreditWithCap
      ? round(this.investmentTaxCreditWithCap, 2)
      : null;
    this.estimatedRebates = this.estimatedRebates
      ? round(this.estimatedRebates, 2)
      : null;
    return this;
  }
}
