export class ProductionDetails {
  detailedSynopsis: string;
  episodesCount: number;
  isScreenPlayCreatedByResident: boolean;
  productionType: string;
  season: number;
  wasTaxReturnFiledPreviousYear: boolean;

  constructor(obj?: any) {
    if (!obj) {
      obj = {};
    }
    this.detailedSynopsis = obj.detailedSynopsis || null;
    this.episodesCount = obj.episodesCount >= 0 ? obj.episodesCount : null;
    this.season = obj.season >= 0 ? obj.season : null;
    this.productionType = obj.productionType || null;
  }
}
