import { Project } from '../project/models/project.model';
import { FormType, FormStatus, formStatuses } from '../form/form.constants';
import {
  programFormUserTypeMap,
  CarbonCopyUserType,
  carbonCopyUserTypes
} from './email.contants';
import { userType, UserType } from '../user/user.constants';
import { EventType, events } from '../fastlane-common/event/event.constants';
import {
  externalReviewAgencies,
  incentiveProgram
} from '../project/project.constants';
import { formTypes, formStatus } from '../form/form.constants';
import { EmailService } from './services/email.service';
import { ProjectInfo } from '../project/models/project-info.model';
import { User } from '../user/models/user.model';
import { ProjectInfoBase } from '../project/models/project-info-base.model';
import { FormIdentifier } from '../project/models/form-identifier.model';
import { ScheduledEmail } from './interfaces/scheduled-email.interface';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';

/**
 * @summary Use this function to determine whether or not an email should be sent for the given form status
 * @param projectInfo Project Info related to the event context
 * @param formType Form type
 * @param status Status being set in the form
 */
export function shouldSkipAllEmailNotifications(
  projectInfo: ProjectInfoBase,
  formType: FormType,
  status: FormStatus
) {
  const skipableCombinations = {
    [incentiveProgram.film.code]: {
      [formTypes.application.abbrev]: [formStatus.reviewComplete],
      [formTypes.asa.abbrev]: [formStatus.reviewComplete],
      [formTypes.audit.abbrev]: [
        //formStatus.depositReceived,
        //formStatus.received,
        formStatus.reviewComplete,
        formStatus.auditorAssigned, // SHOULD NOT USE THE REGULAR TEMPLATES AND RECIPIENTS ARE NOT CONTACTS
        formStatus.awaitingAudit, // SHOULD NOT USE THE REGULAR TEMPLATES AND RECIPIENTS ARE NOT CONTACTS
        formStatus.denied // SHOULD NOT USE THE REGULAR TEMPLATES AND RECIPIENTS ARE NOT CONTACTS
      ],
      [formTypes.initialCert.abbrev]: [formStatus.reviewComplete]
    },
    [incentiveProgram.dm.code]: {
      [formTypes.application.abbrev]: [formStatus.reviewComplete],
      [formTypes.audit.abbrev]: [
        //formStatus.depositReceived,
        //formStatus.received,
        formStatus.reviewComplete
      ],
      [formTypes.initialCert.abbrev]: [formStatus.reviewComplete]
    }
  };

  const shouldItSkip =
    skipableCombinations[projectInfo.incentiveProgram] &&
    skipableCombinations[projectInfo.incentiveProgram][formType] &&
    skipableCombinations[projectInfo.incentiveProgram][formType].length > 0
      ? skipableCombinations[projectInfo.incentiveProgram][formType].includes(
          status
        )
      : false;

  return shouldItSkip;
}

// TODO: Rename given is no longer a handler
export function formStatusChangeNotificationHandler(
  projectInfo: ProjectInfoBase,
  formType: string,
  action: string
) {
  // If project is confidential, there are no carbon copies
  if (projectInfo.isConfidential) {
    return [];
  }

  const applicableCarbonCopyUserTypes =
    programFormUserTypeMap[action][projectInfo.incentiveProgram.toLowerCase()][
      formType
    ];

  // if there are no applicable cc user types
  if (!applicableCarbonCopyUserTypes) {
    return [];
  }
  return applicableCarbonCopyUserTypes.map(ut => getCarbonCopyRecipients(ut));
}

export function formCertificationStatusChangeNotificationHandler(
  projectInfo: ProjectInfoBase,
  formType: string,
  action: string
) {
  const applicableCarbonCopyUserTypes =
    programFormUserTypeMap[action][projectInfo.incentiveProgram.toLowerCase()][
      formType
    ];

  if (!applicableCarbonCopyUserTypes) {
    return [];
  }
  return applicableCarbonCopyUserTypes.map(ut => getCarbonCopyRecipients(ut));
}
export function auditDepositTransactionNotificationHandler(
  projectInfo: ProjectInfoBase,
  formType: string,
  action: string
) {
  if (action && projectInfo && projectInfo.incentiveProgram && formType) {
    if (
      programFormUserTypeMap[action] &&
      programFormUserTypeMap[action][projectInfo.incentiveProgram.toLowerCase()]
    ) {
      const applicableCarbonCopyUserTypes =
        programFormUserTypeMap[action][
          projectInfo.incentiveProgram.toLowerCase()
        ][formType];

      if (!applicableCarbonCopyUserTypes) {
        return [];
      }
      return applicableCarbonCopyUserTypes.map(ut =>
        getCarbonCopyRecipients(ut)
      );
    }
  }
  return [];
}
export function externalReviewStatusChangeNotificationHandler(
  projectInfo: ProjectInfoBase,
  formType: string,
  action: string
) {
  const applicableCarbonCopyUserTypes =
    programFormUserTypeMap[action][projectInfo.incentiveProgram.toLowerCase()][
      formType
    ];

  if (!applicableCarbonCopyUserTypes) {
    return [];
  }
  return applicableCarbonCopyUserTypes.map(ut => getCarbonCopyRecipients(ut));
}

export function boardStatusChangeNotificationHandler(
  projectInfo: ProjectInfoBase,
  formType: string,
  action: string
) {
  // If project is confidential, there are no carbon copies
  if (projectInfo.isConfidential) {
    return [];
  }

  const applicableCarbonCopyUserTypes =
    programFormUserTypeMap[action][projectInfo.incentiveProgram.toLowerCase()][
      formType
    ];

  if (!applicableCarbonCopyUserTypes) {
    return [];
  }
  return applicableCarbonCopyUserTypes.map(ut => getCarbonCopyRecipients(ut));
}

export const eventHandlers: {
  [eventType: string]: (
    projectInfo: ProjectInfoBase,
    formType: string,
    action: string
  ) => User[];
} = {
  formStatusChanges: formStatusChangeNotificationHandler,
  agreementStatusChanges: null,
  boardStatusChanges: boardStatusChangeNotificationHandler,
  externalReviewStatusChanges: externalReviewStatusChangeNotificationHandler,
  formCertificationChanges: formCertificationStatusChangeNotificationHandler,
  auditDepositTransaction: auditDepositTransactionNotificationHandler,
  [events.auditManagementChange.code]: (
    projectInfo: ProjectInfoBase,
    formType: string,
    action: string
  ) => [getManagers()]
};

export function getCarbonCopyRecipients(
  carbonCopyUserType: CarbonCopyUserType
) {
  switch (carbonCopyUserType) {
    case userType.assessor.code:
      return getAssessors();
    case externalReviewAgencies.lga.abbrev:
      return getLocalGoverningAuthorities();
    case externalReviewAgencies.ldr.abbrev:
      return getRevenueDeptRecipients();
    case userType.management.code:
      return getManagers();
  }
}

export function getProjectContacts(
  projectInfo: ProjectInfo,
  emailService: EmailService,
  eventType: EventType,
  action: string
) {
  return '';
}

export function getBusinessSignatory(
  projectInfo: ProjectInfo,
  emailService: EmailService,
  eventType: EventType,
  action: string
) {
  return '';
}

export function getAssessors() {
  return <User>{
    userType: userType.assessor.code
  };
}

export function getLocalGoverningAuthorities() {
  return <User>{
    userType: userType.external.code,
    externalReviewAgency: externalReviewAgencies.lga.code
  };
}

export function getRevenueDeptRecipients() {
  return <User>{
    userType: userType.external.code,
    externalReviewAgency: externalReviewAgencies.ldr.code
  };
}

export function getManagers() {
  return <User>{
    userType: userType.management.code
  };
}

/**
 * @summary Schedules Email Reminders if applicable. Should only do something for Film projects
 * @param formId Id of the form to verify whether a set of reminders should be created for
 * @param status New status of the form
 */
export function scheduleReminders(
  formId: FormIdentifier,
  status: string,
  scheduleEmails: (formId: FormIdentifier) => Observable<any>,
  callback: () => void,
  errorHandler: (error) => void
) {
  // Only schedule for initial certs that are reviewed complete
  if (
    formId.formType === formTypes.initialCert.abbrev &&
    status === 'Review Complete'
  ) {
    scheduleEmails(formId).subscribe(callback, errorHandler);
  }
}

export function generateLinksForUsers(
  userTypes: UserType[],
  rawUrlTree: string[],
  convertUrlTreeToStringsPerUserType: (
    rawUrlTree: string[],
    userTypes: UserType[],
    queryParams?: Params
  ) => {},
  queryParams?: Params
) {
  // Get Urls
  const userTypeUrls = convertUrlTreeToStringsPerUserType(
    rawUrlTree,
    userTypes,
    queryParams
  );

  // Get Names
  const userTypeNames = userTypes.map(u => u.name);

  // Generate HTML of URLs
  let htmlString = `
  <u>Click below based on the type of user that you are to navigate:</u><br/>`;

  // Append the urls and description for each user
  userTypeNames.forEach(u => {
    // Current user url
    const url = userTypeUrls[u];

    htmlString += `
      <br/>
      <a href="${url}">${u}</a>&nbsp;&nbsp;
    `;
  });

  return htmlString;
}
