/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./date-range-picker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../checkbox/checkbox.component.ngfactory";
import * as i3 from "../checkbox/checkbox.component";
import * as i4 from "@angular/forms";
import * as i5 from "ngx-bootstrap/datepicker";
import * as i6 from "ngx-bootstrap/component-loader";
import * as i7 from "@angular/common";
import * as i8 from "./date-range-picker.component";
var styles_DateRangePickerComponent = [i0.styles];
var RenderType_DateRangePickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DateRangePickerComponent, data: {} });
export { RenderType_DateRangePickerComponent as RenderType_DateRangePickerComponent };
function View_DateRangePickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn mr-2 position-absolute bg-transparent p-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resetAndFocusInput() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null))], null, null); }
function View_DateRangePickerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "ml-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "shr-checkbox", [], null, [[null, "checkedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkedChange" === en)) {
        var pd_0 = (_co.last90DaysChecked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CheckboxComponent_0, i2.RenderType_CheckboxComponent)), i1.ɵdid(2, 49152, null, 0, i3.CheckboxComponent, [], { controlId: [0, "controlId"], label: [1, "label"], checked: [2, "checked"] }, { checkedChange: "checkedChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "90days"; var currVal_1 = "Last 90 Days"; var currVal_2 = _co.areLast90DaysSelected; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_DateRangePickerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "d-flex w-100 position-relative align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, null, null, 6, "input", [["bsDaterangepicker", ""], ["class", "form-control"], ["placeholder", "MM/DD/YYYY - MM/DD/YYYY"], ["type", "text"]], [[8, "id", 0]], [[null, "bsValueChange"], [null, "change"], [null, "keyup.esc"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("keyup.esc" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).hide() !== false);
        ad = (pd_1 && ad);
    } if (("blur" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).onBlur() !== false);
        ad = (pd_2 && ad);
    } if (("bsValueChange" === en)) {
        var pd_3 = (_co.newRangeSelected($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.BsDaterangepickerInputDirective]), i1.ɵprd(5120, null, i4.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i5.BsDaterangepickerInputDirective]), i1.ɵdid(5, 737280, null, 0, i5.BsDaterangepickerDirective, [i5.BsDaterangepickerConfig, i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i6.ComponentLoaderFactory], { bsValue: [0, "bsValue"], bsConfig: [1, "bsConfig"], minDate: [2, "minDate"], maxDate: [3, "maxDate"] }, { bsValueChange: "bsValueChange" }), i1.ɵdid(6, 16384, null, 0, i5.BsDaterangepickerInputDirective, [i5.BsDaterangepickerDirective, i5.BsLocaleService, i1.Renderer2, i1.ElementRef, i1.ChangeDetectorRef], null, null), i1.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DateRangePickerComponent_1)), i1.ɵdid(10, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DateRangePickerComponent_2)), i1.ɵdid(12, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.selectedDateRange; var currVal_2 = _co.config; var currVal_3 = _co.minDate; var currVal_4 = _co.maxDate; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = "form-control"; var currVal_6 = _co.cssClasses; _ck(_v, 8, 0, currVal_5, currVal_6); var currVal_7 = (_co.selectedDateRange && (_co.selectedDateRange.length === 2)); _ck(_v, 10, 0, currVal_7); var currVal_8 = _co.show90Days; _ck(_v, 12, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.elementId; _ck(_v, 2, 0, currVal_0); }); }
export function View_DateRangePickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fl-date-range-picker", [], null, null, null, View_DateRangePickerComponent_0, RenderType_DateRangePickerComponent)), i1.ɵdid(1, 114688, null, 0, i8.DateRangePickerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DateRangePickerComponentNgFactory = i1.ɵccf("fl-date-range-picker", i8.DateRangePickerComponent, View_DateRangePickerComponent_Host_0, { elementId: "elementId", cssClasses: "cssClasses", selectedDateRange: "selectedDateRange", show90Days: "show90Days" }, { selectedDateRangeChange: "selectedDateRangeChange" }, []);
export { DateRangePickerComponentNgFactory as DateRangePickerComponentNgFactory };
